import { Atom, atom } from 'jotai';

export const checked_coupon_discount_atom = atom<boolean>(true);
export const checked_point_discount_atom = atom<boolean>(true);
export const checked_payment_id_atom = atom<string | null>(null);

export const createMaxBenefitInitialValues = (): Array<[Atom<unknown>, unknown]> => {
  return [
    [checked_coupon_discount_atom, true],
    [checked_point_discount_atom, true],
    [checked_payment_id_atom, null],
  ];
};
