import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

// MEMO: 첫구매딜 방어로직 적용을 위한 UUID 변경필요 하지 않은 고정값
export const FBK_EVENT_METADATA_UUID = '6648881f-1284-4176-ae57-d7ede868e88b';

/**
 * 첫구매 이벤트 페이지에서 가격 고정 노출
 * https://croquis.atlassian.net/browse/FBK-2192
 */
export function getWelcomeDealInfo() {
  const is_promotion_days = dayjs().isBetween('2024-04-08 12:00:00', '2024-04-12 23:59:59');
  if (is_promotion_days) {
    const cms_uuid = '3e2173a9-f4ca-4029-b985-eaf5aae2e81f';
    const meta_data_uuid = '42f8e638-a704-4014-9901-d2512c5310b0';

    const product_list = [
      '106750384',
      '111123275',
      '111323919',
      '136012258',
      '118686776',
      '130998845',
      '133206733',
      '134244499',
      '122276463',
      '136775645',
    ];

    return { price: 990, url: `/events/cms/${cms_uuid}`, uuid: cms_uuid, meta_data_uuid, product_list };
  }

  return { price: 0, url: '', uuid: '', meta_data_uuid: '', product_list: [] };
}
