import { useQuery } from '@tanstack/react-query';

import { getImageSearchPresetProducts } from 'api2';
import { GetImageSearchPresetProducts } from 'api2/types';

type Product = GetImageSearchPresetProducts['fbk_image_search_preset_product_list']['item_list'][number];

export const IMAGE_SEARCH_PRESET_PRODUCTS_QUERY_KEY = ['getImageSearchPresetProducts'];

export const useImageSearchPresetProducts = () => {
  const { data, ...rest } = useQuery(IMAGE_SEARCH_PRESET_PRODUCTS_QUERY_KEY, () => getImageSearchPresetProducts());
  const products: Product[] = data?.data.fbk_image_search_preset_product_list.item_list ?? [];
  return {
    data,
    products,
    ...rest,
  };
};
