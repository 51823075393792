import { useQuery } from '@tanstack/react-query';
import { getCarouselProductList } from 'api2';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const CAROUSEL_PRODUCT_LIST_QUERY_KEY = 'getCarouselProductList';
export type CAROUSEL_KEY = 'TREND_TAB_LIST' | 'TREND_TAB_CAROUSEL' | 'KIDS_CAROUSEL' | 'ZIGZIN_CAROUSEL';

interface CarouselProduct {
  product_list: ProductCardProps[];
  benefit_section_id: string;
  main_title: string;
  sub_title: string | null;
}
export const fetchCarouselProductList = async (carousel_key: CAROUSEL_KEY) => {
  const context_request = { show_alert: false };
  try {
    const {
      data: { carousel_list },
    } = await getCarouselProductList({ carousel_page_name: carousel_key }, context_request);
    const result: CarouselProduct[] = carousel_list.map(({ carousel_product_list, sub_title, ...rest }) => ({
      ...rest,
      sub_title: sub_title ?? null,
      product_list: convertProductList(carousel_product_list),
    }));
    return result;
  } catch {
    return [];
  }
};

export const useCarouselProductList = (carousel_key: CAROUSEL_KEY) => {
  const {
    data: carousel_product_list = [],
    isLoading: is_loading,
    ...rest
  } = useQuery<CarouselProduct[]>([CAROUSEL_PRODUCT_LIST_QUERY_KEY, carousel_key], () =>
    fetchCarouselProductList(carousel_key),
  );

  return {
    carousel_product_list,
    is_loading,
    ...rest,
  };
};
