import { IncomingMessage } from 'http';
import { parseContentEditorItemPayload, ParsePayload } from '@croquiscom-pvt/event-modules';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { NextPageContext } from 'next';
import { getCmsContentEditor } from 'api2';
import { CmsContentEditor } from 'api2/types';

export const QUERY_KEY = 'getCmsContentEditor';
export type ContentEditorParsedPayload = ParsePayload<CmsContentEditor, 'content_editor_json'>;

async function fetchData(uuid: string, context?: IncomingMessage): Promise<ContentEditorParsedPayload | null> {
  const result = await getCmsContentEditor({ uuid }, { context }).then(({ data }) => data.cms_content_editor);

  if (!result) {
    return null;
  }

  return { ...result, content_editor_json: parseContentEditorItemPayload(result.content_editor_json ?? '') };
}

export async function prefetchContentEditorQuery({ query, req }: NextPageContext, query_client: QueryClient) {
  const { uuid } = query as { uuid: string };
  await query_client.prefetchQuery([QUERY_KEY, uuid], () => fetchData(uuid, req));
  return query_client.getQueryData<ContentEditorParsedPayload>([QUERY_KEY, uuid]);
}

export function useContentEditorQuery(uuid: string) {
  return useQuery([QUERY_KEY, uuid], () => fetchData(uuid), { refetchOnWindowFocus: false });
}
