import { ProductListModuleProps } from '@croquiscom-pvt/event-modules';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import ProductGrid from 'components/content-editor/ProductGrid';

export const ProductList = ({ group_header_data, columns, products_preset_uuid }: ProductListModuleProps) => {
  return (
    <>
      {group_header_data?.map((header_data, group_index) => {
        return (
          <Fragment key={group_index}>
            {header_data.type === 'text' && (
              <SectionTitle
                css={css({
                  backgroundColor: header_data.background_color,
                  color: header_data.color,
                  textAlign: 'center',
                })}
              >
                {header_data.title}
              </SectionTitle>
            )}
            {header_data.type === 'image' && <img src={header_data.img_src} alt='' />}
            <ProductGrid columns={columns ?? 2} products_preset_uuid={products_preset_uuid} group_index={group_index} />
          </Fragment>
        );
      })}
    </>
  );
};

const SectionTitle = styled.div`
  padding: 18px 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;
