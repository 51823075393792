import { useState } from 'react';
import { finishSplash, isSupportAppHandler } from 'util/app_utils';

/**
 * 앱 최초 실행시 스플래시가 나오고 종료 후 웹뷰가 보이는 시점 callback 트리거
 * 인터페이스 미지원의 경우 항상 true로 처리
 * @hook
 */
export const useFinishSplash = () => {
  const is_support_finish_slapsh = isSupportAppHandler('finishSplash');
  const [is_finish_splash, setIsFinishSplash] = useState(is_support_finish_slapsh ? false : true);

  finishSplash(() => {
    setIsFinishSplash(true);
  });

  return {
    is_finish_splash,
  };
};
