import { SerializedStyles } from '@emotion/react';
import { useAtom } from 'jotai';
import { FC } from 'react';
import ProductSection, { ProductExposeType } from 'components/common/ProductSection';
import { carousel_swipe_index_atom } from 'components/home/store/trend';
import { CAROUSEL_KEY, useCarouselProductList } from 'components/special-categories/hooks/useCarouselProductList';
import { useMount } from 'hooks';
import { Log } from 'util/Tracker';

type TrendTabKey = Exclude<CAROUSEL_KEY, 'KIDS_CAROUSEL' | 'ZIGZIN_CAROUSEL'>;

const EXPOSE_PRODUCT_TYPE_BY_KEY: Record<TrendTabKey, ProductExposeType> = {
  TREND_TAB_CAROUSEL: 'carousel',
  TREND_TAB_LIST: 'swipe',
};

interface Props {
  type: TrendTabKey;
  ubl?: Omit<Log, 'category'>;
  custom_css?: SerializedStyles;
  expose_index: number;
}

/**
 * 트랜드탭 - 캐러셀리스트
 * @component
 */
const CarouselList: FC<Props> = ({ type, expose_index, custom_css, ubl }) => {
  const [carousel_swipe_index, setCarouselSwipeIndex] = useAtom(carousel_swipe_index_atom);
  const { carousel_product_list } = useCarouselProductList(type);
  const swipe_key = `${type}-${expose_index}`;

  useMount(() => setCarouselSwipeIndex({ [swipe_key]: 0 }));

  const is_expose_condition = carousel_product_list.length > 0 && carousel_product_list.length > expose_index;
  if (!is_expose_condition) {
    return null;
  }

  const carousel_product = carousel_product_list[expose_index];
  const product_expose_type = EXPOSE_PRODUCT_TYPE_BY_KEY[type];
  const product_ubl = ubl
    ? {
        ...ubl,
        object_section: product_expose_type === 'carousel' ? 'promotion_product_carousel' : 'product_list',
        object_type: 'catalog',
        data: {
          ...ubl.data,
          carousel_title: carousel_product.main_title,
          carousel_id: carousel_product.benefit_section_id,
        },
      }
    : undefined;

  return (
    <ProductSection
      title={carousel_product.main_title}
      description={carousel_product.sub_title ?? undefined}
      product_list={carousel_product.product_list}
      product_expose_type={product_expose_type}
      swipe_index={carousel_swipe_index[swipe_key] ?? 0}
      onSwipe={(index) => setCarouselSwipeIndex({ [swipe_key]: index })}
      css={custom_css}
      product_ubl={product_ubl}
    />
  );
};

export default CarouselList;
