import { Action, ContentEditorComponentType } from '@croquiscom-pvt/event-modules';

export function getClickedImage(
  current_module: Extract<ContentEditorComponentType<void>, { __type: 'ImageMap' } | { __type: 'ImageCarousel' }>,
  action: Action,
) {
  return current_module.image_list.find(({ action_map }) => {
    const action_map_has_current_action = Object.keys(action_map).some(
      (action_uuid) => action_map[action_uuid] === action,
    );

    return action_map_has_current_action;
  });
}
