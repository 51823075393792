import styled from '@emotion/styled';
import { CSSProperties, FC } from 'react';

import { ProductCardSize } from '.';
import Star16Icon from 'components/base/Icon/Star16';
import Text from 'components/base/Text';
import { yellow_review } from 'styles';

interface Props {
  style?: CSSProperties;
  /**
   * 리뷰 점수
   */
  review_score: number;
  /**
   * 리뷰 수
   */
  review_count: number;
  /**
   * 리뷰갯수 노출 유무
   */
  is_show_review_count?: boolean;
  size: ProductCardSize;
}

/**
 * 상품 카드의 리뷰 정보 UI 입니다.
 * @param review_count: 리뷰 수
 * @param review_score: 리뷰 점수
 */
const Review: FC<Props> = ({ review_count, review_score, is_show_review_count, size, ...styles }) => {
  const font_variant = size === 'small' ? 'small3_medium' : 'small2_medium';
  const fixed_count = size === 'small' ? 0 : 1;

  return (
    <SC.ReviewRoot {...styles}>
      <Star16Icon className='star' width={12} height={12} color={yellow_review} />
      <Text variant={font_variant} color='gray_secondary'>
        {review_score.toFixed(fixed_count)}
      </Text>
      {is_show_review_count && (
        <Text ml={2} variant={font_variant} color='gray_tertiary'>
          ({review_count.toLocaleString('ko-kr')})
        </Text>
      )}
    </SC.ReviewRoot>
  );
};

export default Review;

const SC = {
  ReviewRoot: styled.div`
    padding: 0;
    display: flex;
    align-items: center;

    .star {
      margin: 1px 2px;
    }
  `,
};
