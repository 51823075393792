import { useRouter } from 'next/router';
import { CmsEventsMain } from 'components/content-editor/CmsEvents';
import config from 'util/config';

/**
 * 홈 - 프로모션 탭
 * @component
 */
const Promotion = () => {
  const {
    query: { cms_uuid },
  } = useRouter();
  const canonical_url = `${config.appUrl}/?tab=promotion`;

  if (!cms_uuid) {
    return;
  }

  return <CmsEventsMain uuid={String(cms_uuid)} canonical_url={canonical_url} is_home_tab />;
};

export default Promotion;
