import { useUpdateAtom } from 'jotai/utils';
import { kakao_share_option_atom } from 'components/common/ShareBottomSheet/store/KakaoShareOption';
import { makeOneLink } from 'util/app_utils';
import config from 'util/config';

/**
 *
 * @hook
 * 프로모션 카카오톡 공유하기 위해 option을 set하기 위한 공통 hook
 * @description
 * 프로모션 내의 공유하기 버튼, 공통 share icon 버튼에서 사용하기 위해 추가하였습니다.
 */
export const useShareKakaoSend = () => {
  const setKakaoShareContents = useUpdateAtom(kakao_share_option_atom);

  const handleSendKakaoMessage = async (path: string) => {
    const deep_link_path = await makeOneLink(path);
    setKakaoShareContents({
      type: 'custom',
      data: {
        custom: {
          template_id: config.kakao_share_king_template_id || 0,
          template_args: {
            web_url: path,
            mobile_url: deep_link_path,
          },
        },
      },
    });
  };

  return {
    handleSendKakaoMessage,
  };
};
