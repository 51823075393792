import { useUpdateAtom } from 'jotai/utils';
import { HomeTabType } from '../Tabs';
import { HOT_PRODUCT_LIST_KEY_NAME } from '../Tabs/Trend/AdProductList';
import { resetVirtualProductListScrollIndexAtom } from 'components/common/store/product';
import { useScrollRestoration } from 'context/ScrollRestorationContext';

export const useHomeScrollReset = () => {
  const resetVirtualProductListScrollIndex = useUpdateAtom(resetVirtualProductListScrollIndexAtom);
  const { setScroll } = useScrollRestoration();

  // HOT(트렌드) 탭 스크롤 보정 값 전체 초기화
  const resetHotTabScrollRestoration = () => {
    resetVirtualProductListScrollIndex(HOT_PRODUCT_LIST_KEY_NAME);
    setScroll(HomeTabType.HOT, 0);
  };

  return { resetHotTabScrollRestoration };
};
