import { Atom, atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export type InitialValues = Array<[Atom<unknown>, unknown]>;

/**
 * atomFamily generator
 * Record<string, T> 타입의 atom을 만들고 key를 기준으로 memoization합니다.
 *
 * @param fallback_value 대응하는 값이 없는 경우 반환값
 * @param setter 새로운 값을 반환하는 setter
 * @returns
 */
export function createAtomFamily<TArgs, TValue>(fallback_value: TValue, setter: (arg: TArgs) => TValue) {
  const store_atom = atom<Record<string, TValue | undefined>>({});

  return atomFamily((key?: string) =>
    atom(
      (get) => {
        if (typeof key === 'undefined') {
          return fallback_value;
        }

        return get(store_atom)[key] ?? fallback_value;
      },
      (get, set, arg: TArgs) => {
        if (typeof key === 'undefined') {
          return;
        }

        set(store_atom, { ...get(store_atom), [key]: setter(arg) });
      },
    ),
  );
}
