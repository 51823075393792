import styled from '@emotion/styled';
import { FC, MouseEvent } from 'react';
import { IconNavigationHome24 } from 'components/base/Icon';
import Link from 'components/base/Link';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { pink600 } from 'styles';
import { typography } from 'styles/font';
import { MAIN_TAB_ID, moveMainTab } from 'util/app_utils';

interface Props {
  onClick?: () => void;
}
/**
 * 헤더 - 장바구니 아이콘
 * @component
 */
const Home: FC<Props> = ({ onClick }) => {
  const { is_native_version } = useAppVersion();

  const handleHomeLinkClick = (e: MouseEvent<HTMLElement>) => {
    if (is_native_version) {
      e.preventDefault();
      moveMainTab({ main_tab: MAIN_TAB_ID.HOME });
    }
    onClick?.();
  };
  return (
    <SC.Link href='/' aria-label='홈으로 이동합니다.' onClick={handleHomeLinkClick}>
      <IconNavigationHome24 />
    </SC.Link>
  );
};

export default Home;

const SC = {
  Link: styled(Link)`
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
  `,
  Reddot: styled.p`
    ${typography.small4_semibold}
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 5px;
    background: ${pink600};
    color: ${pink600};
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    right: -1px;
  `,
};
