import { Options, Splide, SplideEventHandlers, SplideSlide } from '@splidejs/react-splide';
import chunk from 'lodash-es/chunk';
import { FC, useMemo } from 'react';
import ItemSkeleton from './ItemSkeleton';
import SlideProductitem from './SlideProductitem';
import { ProductCardProps } from 'components/common/ProductCard';
import { Log } from 'util/Tracker';

const splide_options: Options = {
  type: 'loop',
  autoplay: true,
  interval: 4000,
  lazyLoad: true,
  arrows: false,
  pagination: false,
  gap: 10,
  speed: 500,
  easing: 'ease',
};

const GROUPED_COUNT = 3;

interface Props {
  product_list: ProductCardProps[];
  is_loading?: boolean;
  ubl?: Omit<Log, 'category'>;
}

/**
 * 타임특가 - 슬라이드 상품 리스트
 * @component
 */
const SlideProductList: FC<Props> = ({ product_list, is_loading, ubl }) => {
  const grouped_product_list = useMemo(() => {
    const grouped = chunk(
      product_list.map((item, index) => ({ ...item, index })),
      GROUPED_COUNT,
    );
    return grouped;
  }, [product_list]);

  const slide_options = useMemo(() => {
    if (grouped_product_list.length === 1) {
      return {
        ...splide_options,
        type: 'slide',
        autoplay: false,
      };
    }
    return splide_options;
  }, [grouped_product_list.length]);

  const handleSplideReady: SplideEventHandlers['onReady'] = (splide) => {
    // Splide 마지막 열에서 다음 첫번째 열의 클론 img가 base64 이미지로 처리되어 비노출되는 이슈로 src 강제 세팅
    const $clone_first_imgs = splide.root.querySelectorAll(
      `.splide__slide--clone[aria-label="1 of ${grouped_product_list.length}"] img`,
    );
    $clone_first_imgs.forEach(($img, index) => {
      const image_url = grouped_product_list[0][index]?.image_url;
      image_url && $img.setAttribute('src', image_url);
      $img.classList.add('in');
    });
  };

  if (!is_loading && !grouped_product_list.length) {
    return null;
  }

  if (is_loading) {
    return (
      <>
        {[...Array(3)].map((_, index) => (
          <div className='slide_item' key={`skeleton_${index}`}>
            <ItemSkeleton />
          </div>
        ))}
      </>
    );
  }

  return (
    <Splide options={slide_options} onReady={handleSplideReady}>
      {grouped_product_list.map((product_list, grouped_index) => (
        <SplideSlide key={`grouped_${grouped_index}`} className='slide_item'>
          {product_list.map((product, index) => (
            <SlideProductitem
              product={product}
              ubl={ubl}
              key={`${product.catalog_product_id}_${grouped_index}_${index}`}
            />
          ))}
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default SlideProductList;
