import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getHomeProductAdOrProductIdList } from 'api2';
import { FbkHomeAdOrProductId } from 'api2/types';

export const PRODUCT_AD_ID_LIST_QUERY_KEY = 'getProductAdIdList';

export const fetchAdOrProductIdList = async (category_key: string | null, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const request_param = category_key ? { category_key } : {};
  const { data } = await getHomeProductAdOrProductIdList(request_param, context_request);

  return data.home_ad_or_product_id_list.item_list;
};

export const useProductAdOrProductIdList = (category_key: string | null) => {
  const {
    data,
    isInitialLoading: is_ad_or_product_id_list_loading,
    ...rest
  } = useQuery([PRODUCT_AD_ID_LIST_QUERY_KEY, category_key], () => fetchAdOrProductIdList(category_key));

  const ad_or_product_ld_list: FbkHomeAdOrProductId[] = data ?? [];

  return { ad_or_product_ld_list, is_ad_or_product_id_list_loading, ...rest };
};
