import styled from '@emotion/styled';
import { FC } from 'react';
import Alarm from './Alarm';
import Back from './Back';
import Cart from './Cart';
import Home from './Home';
import { IconCamera24, IconClose24, IconNavigationSearch24, IconSetting24 } from 'components/base/Icon';
import Link from 'components/base/Link';
import { gray_secondary } from 'styles';

export type HeaderIconType = 'home' | 'cart' | 'search' | 'close' | 'setting' | 'back' | 'alarm' | 'image_search';
interface Props {
  type: HeaderIconType;
  onClickIcon?: () => void;
}
const HeaderIcon: FC<Props> = ({ type, onClickIcon }) => {
  const renderHeader = {
    home: <Home onClick={onClickIcon} />,
    search: (
      <SC.Link href='/search' aria-label='검색페이지로 이동합니다.' onClick={onClickIcon}>
        <IconNavigationSearch24 />
      </SC.Link>
    ),
    close: (
      <SC.Link href='/' onClick={onClickIcon}>
        <IconClose24 />
      </SC.Link>
    ),
    setting: (
      <SC.Link href='/my-page/settings' aria-label='설정 메뉴로 이동합니다.' onClick={onClickIcon}>
        <IconSetting24 />
      </SC.Link>
    ),
    image_search: (
      <Link href='/search/image' aria-label='이미지 검색 페이지로 이동합니다.'>
        <IconCamera24 color={gray_secondary} onClick={onClickIcon} />
      </Link>
    ),
    alarm: <Alarm onClick={onClickIcon} />,
    back: <Back onClick={onClickIcon} />,
    cart: <Cart onClick={onClickIcon} />,
  }[type];

  return renderHeader;
};

export default HeaderIcon;

const SC = {
  Link: styled(Link)`
    width: 24px;
    height: 24px;
  `,
};
