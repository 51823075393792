import { FC } from 'react';
import Chips from 'components/common/Chips';
import { ChipItemWithLink } from 'components/common/Chips/Item';
import { useCategoryStickeyHeight } from 'components/home/hooks/useCategoryStickeyHeight';
import { Log } from 'util/Tracker';

export interface CategoryChipItem {
  name: string;
  value: string;
}

const CATEGORY_CHIPS: ChipItemWithLink[] = [
  {
    name: '전체',
    value: 'ALL',
    href: '/?product_category=ALL',
  },
  {
    name: '아우터',
    value: 'OUTER',
    href: '/?product_category=OUTER',
  },
  {
    name: '상의',
    value: 'TOP',
    href: '/?product_category=TOP',
  },
  {
    name: '하의',
    value: 'PANTS',
    href: '/?product_category=PANTS',
  },
  {
    name: '액세서리',
    value: 'ACCESSORY',
    href: '/?product_category=ACCESSORY',
  },
  {
    name: '슈즈',
    value: 'SHOES',
    href: '/?product_category=SHOES',
  },
  {
    name: '원피스',
    value: 'ONE_PIECE_SET',
    href: '/?product_category=ONE_PIECE_SET',
  },
];

interface Props {
  ubl?: Pick<Log, 'navigation' | 'data'>;
  category: null | string;
  onChipClick: () => void;
}
/**
 * 홈 페이지 - HOT탭 상품 리스트
 * @component
 */
const CategoryChips: FC<Props> = ({ ubl, category, onChipClick }) => {
  const stickey_height = useCategoryStickeyHeight();

  return (
    <Chips
      is_replace
      chips={CATEGORY_CHIPS}
      active_chip_value={getIsActiveCategory(category)}
      ubl={ubl && { ...ubl, object_type: 'category' }}
      onChipClick={onChipClick}
      stickey_height={stickey_height}
    />
  );
};

function getIsActiveCategory(selected_category: string | null) {
  if (selected_category === null) {
    return 'ALL';
  }
  return selected_category;
}

export default CategoryChips;
