import { RelatedContentModuleProps, useContentMetadata } from '@croquiscom-pvt/event-modules';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRelatedContentGroup } from 'util/content-editor/related-content-data';

export const RelatedContent = ({ content_group_id, header_title }: RelatedContentModuleProps) => {
  const { uuid } = useContentMetadata();
  const query = useRelatedContentGroup(content_group_id, uuid, {
    select: (data) => {
      if (data) {
        const even_length_processed_content_list =
          data.content_list.length % 2 === 0
            ? data.content_list
            : data.content_list.slice(0, data!.content_list.length - 1);
        return {
          ...data,
          content_list: even_length_processed_content_list,
        };
      }
      return null;
    },
    enabled: Boolean(content_group_id) && Boolean(uuid),
  });

  return (
    <>
      {query.data && (
        <RelatedContentWrapper>
          <GroupHeader>{header_title}</GroupHeader>
          <ContentsGroupGrid>
            {query.data.content_list.map((content, i) => (
              <Link href={`/events/cms/${content.uuid}`} className='card_link' passHref key={i}>
                <figure>
                  {content.share_info?.image_src && (
                    <img src={content.share_info.image_src} alt='콘텐츠 공유정보 이미지' />
                  )}
                </figure>
                <ShareTitle is_visible={Boolean(content.share_info?.title)}>{content.share_info?.title}</ShareTitle>
              </Link>
            ))}
          </ContentsGroupGrid>
        </RelatedContentWrapper>
      )}
    </>
  );
};

const RelatedContentWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0 16px;
`;

const GroupHeader = styled.h1`
  padding: 14px 0 8px;
  font-family: Pretendard-SemiBold;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  line-height: 1.4;
`;

const ContentsGroupGrid = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  padding-bottom: 80px;

  .card_link {
    display: block;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    flex: 1 1 calc(50% - 8px);

    figure {
      background: rgba(0, 0, 0, 0.03);
      margin-bottom: 8px;
      border-radius: 12px;
      width: 100%;
      aspect-ratio: 7 / 5;
      position: relative;

      &:after {
        border-radius: 12px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: rgba(0, 0, 0, 0.03);
      }
    }

    img {
      border-radius: 12px;
      width: 100%;
      aspect-ratio: 7 / 5;
      object-position: center;
      object-fit: cover;
    }
  }
`;

const ShareTitle = styled.a<{ is_visible: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  font-family: Pretendard;
  font-weight: 500;
  font-size: 13px;
  color: #202327;
  line-height: 1.4;
  overflow: hidden;
  -webkit-line-clamp: 2;

  ${({ is_visible }) =>
    !is_visible &&
    css`
      background: rgba(0, 0, 0, 0.03);
      width: 100%;
      border-radius: 12px;
      height: 1rem;
    `}
`;
