import { FC, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { Log, useTracker } from 'util/Tracker';

interface Props {
  product_id: string;
  ubl?: Omit<Log, 'category'>;
  index: number;
  image_url: string;
}

const Item: FC<Props> = ({ ubl, index, image_url, product_id }) => {
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const tracker = useTracker();
  const product_ubl = useMemo(
    () => (ubl ? { ...ubl, object_type: 'catalog', object_id: product_id, object_idx: index } : null),
    [ubl, product_id, index],
  );

  useEffect(() => {
    if (in_view && product_ubl) {
      tracker.addLog({ ...product_ubl, category: 'impression' });
    }
  }, [in_view, tracker, product_ubl]);

  const handleImageClick = () => {
    if (product_ubl) {
      tracker.addLog({ ...product_ubl, category: 'click' });
    }
  };

  return (
    <li ref={ref} key={index} onClick={handleImageClick}>
      <Link href={`/catalog/products/${product_id}`}>
        <Image layout='fill' objectFit='cover' src={image_url} alt={`${index + 1}번째 상품 이미지`} use_fade />
      </Link>
    </li>
  );
};

export default Object.assign(Item, {
  Skeleton: () => (
    <li>
      <Ratio>
        <Skeleton />
      </Ratio>
    </li>
  ),
});
