import { createRoot, Root } from 'react-dom/client';
import Loading from 'components/base/Loading';

let loading_view_open_count: number = 0;
let container: HTMLDivElement | undefined;
let close_completed: boolean = true;
let root: Root | null = null;

function createContainer() {
  if (!container) {
    container = document.createElement('div');
    document.body.appendChild(container);
  }
}

function unmountLoadingView() {
  close_completed = true;

  try {
    // unmount 안되는 시점은 해당 시점보다 상위 랜더러 에서 unmount 완료되어 제거할 node가 없는 경우 (논이슈 영역)
    root?.unmount();
  } catch {
    console.warn('already unmount loading view');
  }
}

const LoadingManager = {
  open: () => {
    if (typeof document !== 'undefined') {
      loading_view_open_count++;
      if (loading_view_open_count === 1) {
        createContainer();

        if (!close_completed) {
          unmountLoadingView();
        }
        root = createRoot(container!);
        root.render(<Loading use_backdrop />);
      }
    }
  },
  close: (delay_time: number | undefined = 600) => {
    if (loading_view_open_count > 0 && container) {
      loading_view_open_count--;

      close_completed = false;
      setTimeout(() => unmountLoadingView(), delay_time);
    }
  },
};
export default LoadingManager;
