import styled from '@emotion/styled';
import lottie from 'lottie-web';
import { FC, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CircleCategory } from 'components/base/CircleCategories';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { useTracker } from 'util/Tracker';

interface Props {
  category: CircleCategory;
  index: number;
  loop_count: number;
  is_live?: boolean;
  onClick: () => void;
}
/**
 * 로디 피쳐 처리
 * @component
 */
const LottieFeature: FC<Props> = ({ category, index, loop_count, is_live = false, onClick }) => {
  const { link, title, ubl, img_url } = category;
  const tracker = useTracker();
  const { ref: item_ref, inView: in_view } = useInView({ threshold: 0.5, triggerOnce: true });
  const [is_send_impression, setIsSendImpression] = useState<boolean>(false);
  const animation_ref = useRef<HTMLDivElement>(null);
  const link_url = is_live ? '' : link;

  useEffect(() => {
    async function getLottieIcon() {
      try {
        const res = await fetch(img_url);
        const res_json = await res.json();
        if (animation_ref.current) {
          lottie.loadAnimation({
            container: animation_ref.current,
            autoplay: true,
            renderer: 'svg',
            loop: loop_count === -1 ? true : loop_count,
            animationData: res_json,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (img_url) {
      getLottieIcon();
    }
  }, [title, img_url, loop_count]);

  useEffect(() => {
    if (in_view && ubl && !is_send_impression) {
      tracker.addLog({ ...ubl, object_idx: index, category: 'impression' });
      setIsSendImpression(true);
    }
  }, [tracker, ubl, index, in_view, is_send_impression]);

  const handleItemClick = () => {
    if (ubl) {
      tracker.addLog({ ...ubl, object_idx: index, category: 'click' });
    }

    onClick();
  };

  return (
    <Link href={link_url} onClick={handleItemClick}>
      <SC.Item ref={item_ref}>
        <SC.CateogryItem onClick={onClick}>
          <SC.LiveCategory ref={animation_ref} />
          <Text variant='small1_medium' color='gray900'>
            {title}
          </Text>
        </SC.CateogryItem>
      </SC.Item>
    </Link>
  );
};

const SC = {
  LiveCategory: styled.div`
    width: 60px;
    height: 60px;
    margin-bottom: 6px;
  `,
  Item: styled.li`
    width: 60px;
    margin-right: 8px;
    display: inline-block;
    align-items: center;
    cursor: pointer;
  `,
  CateogryItem: styled.a`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};

export default LottieFeature;
