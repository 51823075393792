import { FloatingButtonModuleProps } from '@croquiscom-pvt/event-modules';
import { FloatingButtonWithAction } from './FloatingButtonWithAction';

export const FloatingButton = ({ action, ...props }: FloatingButtonModuleProps) => {
  if (!action) {
    return <></>;
  }

  return <FloatingButtonWithAction action={action} {...props} />;
};
