import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { useEffect } from 'react';
import { getCartItemsCount } from 'api2';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { updateCart } from 'util/app_utils';

type Request = IncomingMessage & {
  cookies: NextApiRequestCookies;
};

const CART_COUNT_QUERY_KEY = 'getCartItemsCount';

const fetchCartCount = async (req?: Request) => {
  const result = await getCartItemsCount(undefined, req ? { context: req } : undefined);
  return result.data.cart_items_count;
};

export const cartCountQueryOption = (req?: Request) => ({
  queryFn: async () => {
    const result = await fetchCartCount(req);
    return result;
  },
  queryKey: [CART_COUNT_QUERY_KEY],
});

export const useCartCount = () => {
  const { is_native_version } = useAppVersion();
  const { data, ...rest } = useQuery([CART_COUNT_QUERY_KEY], () => fetchCartCount(), {
    refetchOnWindowFocus: true,
    cacheTime: 0,
    staleTime: 0,
  });

  const cart_count = data || 0;

  useEffect(() => {
    if (is_native_version) {
      updateCart();
    }
  }, [cart_count, is_native_version]);

  return {
    cart_count,
    ...rest,
  };
};
