import { IncomingMessage } from 'http';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getUserAdNotiStatus, updateUserAdNotiStatus } from 'api2';
import { UserNotiStatus } from 'api2/types';
import { Toast } from 'util/Toast';

export const USER_AD_NOTI_STATUS_QUERY_KEY = ['getUserAdNotiStatus'];

export const fetchUserAdNotiStatus = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { user },
  } = await getUserAdNotiStatus(undefined, context_request);

  return user;
};

/**
 * 마케팅 수신 동의/미동의 상태를 조회하는 hook
 * @hook
 */
export const useUserAdNotiStatus = () => {
  const query_client = useQueryClient();
  const updateUserAdNotiStatusMutate = useMutation(updateUserAdNotiStatus);
  const { data } = useQuery(USER_AD_NOTI_STATUS_QUERY_KEY, () => fetchUserAdNotiStatus());

  const setAppPush = (ad_noti_status: boolean) => {
    query_client.setQueryData(USER_AD_NOTI_STATUS_QUERY_KEY, {
      ad_noti_status: ad_noti_status ? 'AGREE' : 'DISAGREE',
    });
  };

  const setUserAdNotiStatus = ({ update_status, show_toast }: { update_status: boolean; show_toast: boolean }) => {
    updateUserAdNotiStatusMutate.mutate(
      {
        input: { status: update_status ? UserNotiStatus.AGREE : UserNotiStatus.DISAGREE },
      },
      {
        onSuccess() {
          if (show_toast) {
            const agree_date = dayjs().format('YY.MM.DD');
            Toast.show(`${agree_date}\n패션바이카카오 알림 수신 동의가 완료되었어요.`);
          }
          setAppPush(update_status);
        },
        onError: (e: any) => Toast.show(e.message || '다시 시도해 주세요.'),
      },
    );
  };
  return {
    ad_noti_status: data?.ad_noti_status === 'AGREE',
    setAppPush,
    setUserAdNotiStatus,
  };
};
