import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getRelatedProductList } from 'api2';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const RELATED_PRODUCT_LIST_QUERY_KEY = 'getRelatedProductList';

export enum INVENTORY_NAME {
  PDP_RELATED_PRODUCT = 'PDP_RELATED_PRODUCT',
}

interface FetchProps {
  product_id: string;
  inventory_name?: string;
}

interface RelatedProductList {
  item_list: ProductCardProps[];
  is_sponsored: boolean;
}

const init_fetch_data: RelatedProductList = {
  item_list: [],
  is_sponsored: false,
};

export const fetchRelatedProductList = async ({ product_id, inventory_name }: FetchProps, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { related_product_list },
    } = await getRelatedProductList({ product_id, inventory_name }, context_request);

    if (isNil(related_product_list)) {
      return init_fetch_data;
    }

    const item_list = convertProductList(related_product_list);

    return {
      item_list,
      is_sponsored: item_list.some((item) => item.is_ad),
    };
  } catch {
    return init_fetch_data;
  }
};

export const useRelatedProductList = ({
  product_id,
  inventory_name = INVENTORY_NAME.PDP_RELATED_PRODUCT,
}: FetchProps) => {
  const { data, isLoading: is_loading } = useQuery<RelatedProductList>(
    [RELATED_PRODUCT_LIST_QUERY_KEY, product_id, inventory_name],
    () => fetchRelatedProductList({ product_id, inventory_name }),
  );
  const { item_list: related_product_list, is_sponsored } = data ?? init_fetch_data;

  return {
    related_product_list,
    is_sponsored,
    is_loading,
  };
};
