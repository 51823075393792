import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimateSharedLayout } from 'framer-motion';
import isNil from 'lodash-es/isNil';
import isUndefined from 'lodash-es/isUndefined';
import Link from 'next/link';
import {
  forwardRef,
  Fragment,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import Tab from './Tab';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { useMount } from 'hooks';
import { useStickeyScrollReset } from 'hooks/layout/useStickeyScrollReset';
import { gray300, white } from 'styles';
import LoadingManager from 'util/loading_manager';
import { mergeRefs } from 'util/mergeRefs';
import { Log } from 'util/Tracker';

export type TabSize = 'small' | 'medium';

export interface TabItem {
  /**
   * 해당 탭의 id값을 정의 합니다. (unique 해야 합니다.)
   * @default '''
   */
  id: string;
  /**
   * tab에 노출되는 label 값을 정의 합니다.
   * @default ''
   */
  label: string;
  /**
   * 탭 이동 href 설정을 정의 합니다.
   * @default null
   */
  href?: string;
  /**
   * new badge 활성 유무를 정의 합니다.
   * @default false
   */
  is_new?: boolean;
  /**
   * label 좌측의 icon 을 정의 합니다.
   * @default null
   */
  start_icon?: ReactNode;
  /**
   * label 우측의 icon 을 정의 합니다.
   * @default null
   */
  end_icon?: ReactNode;
  /**
   * 해당 탭의 css를 정의 합니다.
   */
  tab_css?: SerializedStyles;
  /**
   * 선택된 탭 이름의 색상값을 정의 합니다.
   */
  active_name_color?: string;
  /**
   * 선택되지 않은 탭 이름의 색상값을 정의 합니다.
   */
  inactive_name_color?: string;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * tabs 구성에 필요한 tab 들을 정의 합니다.
   * @default []
   */
  tab_list: TabItem[];
  /**
   * 선택되어있는 tab의 id를 정의 합니다.
   * @default ''
   */
  selected_tab_id: string;
  /**
   * 탭 라인 표시 여부를 설정합니다.
   * @default true
   */
  is_show_tab_line?: boolean;
  /**
   * 탭 사이즈를 지정합니다. (small, medium)
   * @default 'medium'
   */
  size?: TabSize;
  /**
   * class 이름을 사용할수 있도록 정의 합니다.
   * @default ''
   */
  className?: string;
  /**
   * tab ubl을 정의합니다.
   */
  ubl?: Pick<Log, 'navigation' | 'object_section' | 'data'>;
  /**
   * transition시 로딩 인터렉션이 필요한 경우 (최적화처리) 정의합니다.
   * @default false
   */
  use_transition_loading?: boolean;
  /**
   * 탭 포커스 이동 여부 설정
   * @default false
   */
  use_tab_focus?: boolean;
  stickey_height?: number;
  /**
   * tab 클릭시 발생하는 이벤트 입니다.
   */
  onClickTab?: (tab: TabItem, e: MouseEvent<HTMLElement>) => void;
}

const Tabs = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      tab_list,
      is_show_tab_line = true,
      selected_tab_id,
      size = 'medium',
      ubl,
      use_transition_loading = false,
      use_tab_focus = false,
      stickey_height,
      onClickTab = () => undefined,
      ...props
    },
    parent_ref,
  ) => {
    const tab_list_ref = useRef<HTMLDivElement>(null);
    const tab_item_ref = useRef<Array<HTMLElement | null>>([]);
    const [local_active_tab_id, setLocalActiveTabId] = useState(selected_tab_id);
    const [is_pending, startTransition] = useTransition();
    const { scroll_target_ref, resetScrollTo } = useStickeyScrollReset();
    const use_stickey = stickey_height !== undefined;

    useMount(() => {
      if (!use_stickey || !selected_tab_id) {
        return;
      }

      const selected_index = tab_list.findIndex((tab) => tab.id === selected_tab_id);

      if (tab_list_ref.current && !isUndefined(tab_item_ref.current[selected_index])) {
        const offset_left = Number(tab_item_ref.current[selected_index]?.offsetLeft);
        const offset_width = Number(tab_item_ref.current[selected_index]?.offsetWidth);

        tab_list_ref.current.scrollTo({ left: offset_left - offset_width });
      }
    });

    useEffect(() => {
      setLocalActiveTabId(selected_tab_id);
    }, [selected_tab_id]);

    useEffect(() => {
      const is_show_loading = use_transition_loading && is_pending;
      is_show_loading ? LoadingManager.open() : LoadingManager.close(0);
    }, [is_pending, use_transition_loading]);

    const handleTabClick = (tab: TabItem, e: MouseEvent<HTMLElement>) => {
      setLocalActiveTabId(tab.id);

      startTransition(() => {
        onClickTab(tab, e);

        if (use_stickey) {
          resetScrollTo(stickey_height);
        }
      });
    };

    const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
      if (!isNil(e.currentTarget) && use_tab_focus) {
        const { offsetLeft: offset_left, offsetWidth: offset_width } = e.currentTarget;
        tab_list_ref.current?.scrollTo({
          left: offset_left - offset_width,
          behavior: 'smooth',
        });
      }
    };

    return (
      <>
        {use_stickey && <div ref={scroll_target_ref} />}
        <SC.Container
          ref={mergeRefs([parent_ref, tab_list_ref])}
          className={className}
          stickey_height={stickey_height}
          is_show_tab_line={is_show_tab_line}
          {...props}
        >
          <SC.Wrapper>
            <AnimateSharedLayout>
              {tab_list.map((tab, index) => {
                const { id, href } = tab;

                const renderTab = (
                  <Tab
                    tab={tab}
                    ubl={ubl}
                    index={index}
                    local_active_tab_id={local_active_tab_id}
                    size={size}
                    onClick={handleTabClick}
                  />
                );

                if (href) {
                  return (
                    <Link
                      key={id}
                      href={href}
                      ref={(element) => (tab_item_ref.current[index] = element)}
                      replace
                      shallow
                      passHref
                      className='item_link'
                      onClick={handleLinkClick}
                    >
                      {renderTab}
                    </Link>
                  );
                }

                return <Fragment key={id}>{renderTab}</Fragment>;
              })}
            </AnimateSharedLayout>
          </SC.Wrapper>
        </SC.Container>
      </>
    );
  },
);

export default Tabs;

const SC = {
  Container: styled.div<{ is_show_tab_line: boolean; stickey_height?: number }>`
    ${({ stickey_height }) =>
      stickey_height &&
      `
        position: sticky;
        z-index: 100;
        top: ${stickey_height}px;
    `}
    ${invisible_scroll_bar}
    width: 100%;
    padding: 0 16px;
    overflow-x: scroll;
    white-space: nowrap;
    background: ${white};
    ${({ is_show_tab_line }) => is_show_tab_line && `border-bottom: 0.5px solid ${gray300};`};
  `,
  Wrapper: styled.div`
    display: flex;

    .item_link {
      width: 100%;
      cursor: pointer;
    }
  `,
};
