import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import SkeletonList from './SkeletonList';
import ProductCard, { ProductCardProps } from 'components/common/ProductCard';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { Log } from 'util/Tracker';
interface Props
  extends Pick<ProductCardProps, 'size' | 'is_show_badge' | 'is_show_shop_name' | 'discount_expose_type'> {
  product_list: ProductCardProps[];
  is_show_shop_name?: boolean;
  toast_bottom_floating_position?: number;
  use_rank?: boolean;
  classname?: string;
  start_index?: number;
  only_csr?: boolean;
  is_loading?: boolean;
  padding?: string;
  gap?: number;
  ubl?: Omit<Log, 'category' | 'object_idx' | 'object_id'>;
  onProductClick?: () => void;
}

/**
 * 상품 카드 캐러셀 리스트
 * @component
 */
const ProductCarouselList: FC<Props> = ({
  product_list,
  classname,
  ubl,
  gap = 7,
  size = 'small',
  padding = '0 16px',
  start_index = 0,
  is_loading,
  use_rank,
  ...rest
}) => {
  if (is_loading) {
    return <SkeletonList classname={classname} size={size} />;
  }

  return (
    <ItemList padding={padding} className={classname} gap={gap}>
      {product_list.map((product, index) => {
        const product_index = start_index + index;

        return (
          <li key={product.catalog_product_id} className={size}>
            <ProductCard
              {...product}
              size={size}
              rank={use_rank ? product_index + 1 : undefined}
              ubl={ubl}
              index={product_index}
              meta_info_css={product_card_css}
              {...rest}
            />
          </li>
        );
      })}
    </ItemList>
  );
};

export default Object.assign(ProductCarouselList, {
  Skeleton: SkeletonList,
});

const product_card_css = css`
  margin: 6px 0 0 0;
`;

const ItemList = styled.ul<{ gap: number; padding: string }>`
  ${invisible_scroll_bar}
  padding: ${({ padding }) => padding};
  display: flex;
  gap: ${({ gap }) => gap}px;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  .small {
    min-width: 136px;
    max-width: 175px;
  }

  .medium {
    min-width: 175px;
    max-width: 175px;
  }

  .large {
    min-width: 220px;
    max-width: 220px;
  }

  li {
    flex: 0;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
