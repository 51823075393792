import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import React, { ButtonHTMLAttributes } from 'react';
import { bg_secondary } from 'styles';

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
  /**
   * width 값을 px 단위로 정의 합니다.
   * @default 100%
   */
  width?: number;
  /**
   * height 값을 px 단위로 정의 합니다.
   * @default 100%
   */
  height?: number;
  /**
   * radius 값을 조정 합니다.
   * @default 0
   */
  radius?: number;
}

/**
 * 공통 Skeleton 표기 컴포넌트
 * @component
 */
const Skeleton = React.forwardRef<HTMLDivElement, Props>(({ width, height, radius = 0, className }, ref) => {
  return (
    <AnimatePresence>
      <SC.Container
        ref={ref}
        className={className}
        animate={{ opacity: [1, 0.4, 1] }}
        transition={{ duration: 1.5, times: [0, 0.5, 1], repeat: Infinity, repeatDelay: 0.5 }}
        css={css`
          ${width ? `width:${width}px;` : 'width: 100%;'}
          ${height ? `height:${height}px;` : 'height: 100%;'}
          border-radius: ${radius}px;
        `}
      />
    </AnimatePresence>
  );
});

export default Skeleton;

const SC = {
  Container: styled(motion.div)`
    background-color: ${bg_secondary};
  `,
};
