import { IncomingMessage } from 'http';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getPaymentBenefit, getProductMaxBenefit } from 'api2';
import {
  GetProductMaxBenefit,
  PaymentMethod,
  PaymentNotificationType,
  GetPaymentBenefit,
  FinancialCompanyCode,
} from 'api2/types';
import { getNameByFinancialCompanyCode } from 'constants/payment';
import { formatKoreanNumber } from 'util/number';

export type ProductMaxBenefit = GetProductMaxBenefit & GetPaymentBenefit;

type PaymentBenefitItem =
  | GetPaymentBenefit['event']['item_list'][number]
  | GetPaymentBenefit['notification']['item_list'][number];
type PaymentItem = {
  id: string;
  payment_method: PaymentMethod;
  financial_company_code: FinancialCompanyCode | null | undefined;
  discount_amount: number;
  min_amount: number;
};

export const PRODUCT_MAX_BNENFIT_QUERY_KEY = 'getProductMaxBenefit';

export const fetchProductMaxBenefit = async (product_id: string, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const [
      {
        data: { event, notification },
      },
      {
        data: { product_max_benefit },
      },
    ] = await Promise.all([
      getPaymentBenefit(undefined, context_request),
      getProductMaxBenefit({ product_id }, context_request),
    ]);

    const payment_event = event.item_list.reduce((acc: PaymentItem[], item) => {
      if (item.discount_amount > 0 && item.min_amount > 0) {
        acc.push(setPaymentProps(item));
      }

      return acc;
    }, []);

    const payment_notification = notification.item_list.reduce((acc: PaymentItem[], item) => {
      const is_usable_payment =
        item.notification_type === PaymentNotificationType.EVENT && item.min_amount && item.discount_amount;
      if (is_usable_payment) {
        acc.push(setPaymentProps(item));
      }
      return acc;
    }, []);

    return {
      product_max_benefit,
      payment_list: payment_event.concat(payment_notification),
    };
  } catch (error) {
    return null;
  }
};

/**
 * 상품의 최대 혜택을 조회하는 hook
 * @hooks
 */
export const useProductMaxBenefit = () => {
  const query_client = useQueryClient();
  const {
    query: { catalog_product_id },
  } = useRouter();
  const {
    data,
    isLoading: is_loading,
    ...reset
  } = useQuery([PRODUCT_MAX_BNENFIT_QUERY_KEY, catalog_product_id], () =>
    fetchProductMaxBenefit(String(catalog_product_id)),
  );

  const payment_benefit_list = useMemo(() => {
    if (data?.payment_list.length) {
      return data.payment_list.map((item) => getPaymentContents(item));
    }
    return [];
  }, [data?.payment_list]);

  const updateMaxBenefitCouponIssued = () => {
    query_client.setQueryData<ProductMaxBenefit>([PRODUCT_MAX_BNENFIT_QUERY_KEY, catalog_product_id], (prev) => {
      if (prev) {
        if (prev.product_max_benefit?.max_coupon_discount) {
          prev.product_max_benefit.max_coupon_discount.issue_status = 'ISSUED';
        }
      }
      return prev;
    });
  };

  return {
    is_loading,
    product_max_benefit: data?.product_max_benefit ?? null,
    max_coupon_discount: data?.product_max_benefit?.max_coupon_discount ?? null,
    user_account_point: data?.product_max_benefit?.user_account_point ?? null,
    payment_benefit_list,
    updateMaxBenefitCouponIssued,
    ...reset,
  };
};

function setPaymentProps(item: PaymentBenefitItem) {
  const payment_props: PaymentItem = {
    id: item.id,
    payment_method: item.payment_method,
    financial_company_code: item.financial_company_code,
    discount_amount: item.discount_amount ?? 0,
    min_amount: item.min_amount ?? 0,
  };
  return payment_props;
}

function getPaymentContents(payment_item: PaymentItem) {
  const { id, payment_method, financial_company_code, discount_amount, min_amount } = payment_item;
  const content = `${formatKoreanNumber(discount_amount)}원 즉시 할인 (${formatKoreanNumber(min_amount)}원 이상)`;

  if (payment_method === PaymentMethod.KAKAOPAY) {
    return {
      id,
      content: `카카오페이 ${content}`,
      discount_amount,
      min_amount,
    };
  }

  if (financial_company_code) {
    return {
      id,
      content: `${getNameByFinancialCompanyCode(financial_company_code)} ${content}`,
      discount_amount,
      min_amount,
    };
  }

  return null;
}
