import { ScrollableTabAreaModuleProps, useTabLayout } from '@croquiscom-pvt/event-modules';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import { useCmsEventLoggers } from 'util/content-editor/log';

const TAB_HEIGHT = 52;
interface Props extends ScrollableTabAreaModuleProps {
  uuid?: string;
}

function useDefaultActiveTabid({ uuid, tabs }: { uuid?: string; tabs: Array<{ uuid: string }> }) {
  const { query } = useRouter();
  const default_active_index = Number(query[`${uuid}_default_active_index`]);
  const default_active_id = query[`${uuid}_default_active_id`];

  if (default_active_id) {
    return default_active_id;
  }

  if (isNaN(default_active_index)) {
    return tabs[0]?.uuid;
  }

  return tabs[default_active_index]?.uuid;
}

export const TabArea = ({ uuid, active_color, normal_color, background_color, tab_contents, children }: Props) => {
  const default_active_tab_id = useDefaultActiveTabid({ uuid, tabs: tab_contents });
  const [active_tab_id, setActiveTabId] = useState(default_active_tab_id);
  const handleActiveTabIdChange = (id: string) => {
    const url_search_params = new URLSearchParams(window.location.search);
    url_search_params.delete(`${uuid}_default_active_index`);
    url_search_params.set(`${uuid}_default_active_id`, `${id}`);
    window.history.replaceState(window.history.state, '', `?${url_search_params.toString()}${window.location.hash}`);
    return setActiveTabId(id);
  };
  const active_tab_index = tab_contents.findIndex(({ uuid }) => uuid === active_tab_id);

  const button_holder_ref = useRef<HTMLDivElement | null>(null);
  const section_ref = useRef<HTMLDivElement | null>(null);

  const { logTabClick } = useCmsEventLoggers();
  const { tab_header_top, scrollToTarget } = useTabLayout();

  return (
    <>
      {tab_contents.length > 0 && (
        <TabContainer
          ref={button_holder_ref}
          role='sectionlist'
          css={css({ top: tab_header_top, backgroundColor: background_color })}
        >
          {tab_contents.map(({ title: tab_title, uuid }, tab_index) => {
            const active = active_tab_id === uuid;
            return (
              <TabButton
                role='section'
                key={uuid}
                css={css({ color: active ? active_color : normal_color })}
                active={active}
                onClick={() => {
                  handleActiveTabIdChange(uuid);
                  scrollToTarget(section_ref.current, { behavior: 'smooth' });
                  logTabClick({ tab_title, tab_index, tab_type: 'page_tab' });
                }}
              >
                {tab_title}
              </TabButton>
            );
          })}
        </TabContainer>
      )}
      <Section ref={section_ref}>
        <SectionAnchor />
        {React.Children.toArray(children)[active_tab_index]}
      </Section>
    </>
  );
};

const TabContainer = styled.div`
  position: sticky;
  z-index: 299;
  overflow: auto;
  display: flex;
  flex-direction: row;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabButton = styled.div<{ active: boolean }>`
  position: relative;
  cursor: pointer;
  flex: 1 0 auto;
  min-width: 64px;
  height: ${TAB_HEIGHT}px;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  line-height: 1.2;
  padding-top: 17px;
  white-space: nowrap;

  &:after {
    display: block;
    position: absolute;
    right: 0px;
    bottom: 8px;
    left: 0px;
    transition: opacity 0.23s cubic-bezier(0, 0.55, 0.45, 1);
    margin: auto;
    border-radius: 3px;
    background-color: currentColor;
    width: 6px;
    height: 6px;
    content: '';
    opacity: ${(props) => (props.active ? 1 : 0)};
  }
`;

const Section = styled.div`
  position: relative;
`;

const SectionAnchor = styled.div`
  position: relative;
`;
