import { useQuery } from '@tanstack/react-query';
import { getShippingCompanyList } from 'api2';
import { GetShippingCompanyList } from 'api2/types';

const SHIPPING_COMPANY_LIST_QUERY_KEY = 'getShippingCompanyList';

const fetchShippingCompanyList = async () => {
  try {
    const {
      data: { shipping_company_list },
    } = await getShippingCompanyList(undefined, { show_alert: false });
    return shipping_company_list;
  } catch {
    return [];
  }
};

export function useShippingCompanyList() {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery([SHIPPING_COMPANY_LIST_QUERY_KEY], fetchShippingCompanyList);

  const shipping_company_option_list = getShippingCompanyOptionList(data);

  const getShippingCompany = (shipping_company_code: string) => {
    const shipping_company = shipping_company_option_list.find((item) => item.value === shipping_company_code);
    return shipping_company?.text ?? '';
  };

  return {
    ...rest,
    is_loading,
    getShippingCompany,
    shipping_company_option_list,
  };
}

export const getShippingCompanyOptionList = (
  shipping_company_list?: GetShippingCompanyList['shipping_company_list'],
) => {
  return (shipping_company_list || []).map((shipping_company) => ({
    value: shipping_company.code,
    text: shipping_company.name,
  }));
};
