import ReactDOM from 'react-dom';
import { Dialog, DialogProps } from 'components/content-editor/Dialog';

export async function dialog(
  options: Omit<DialogProps, 'visible' | 'onClose'> & { onBeforeClose?: () => Promise<boolean> },
) {
  const container = document.createElement('div');
  document.body.append(container);

  const props = {
    key: 'cms_event_dialog',
    ...options,
  };

  ReactDOM.render(<Dialog {...props} />, container);
  await halt(0);
  await new Promise((resolve) =>
    ReactDOM.render(
      <Dialog
        {...props}
        visible
        onClose={async () => {
          if ((await options.onBeforeClose?.()) ?? true) {
            resolve(null);
          }
        }}
      />,
      container,
    ),
  );
  ReactDOM.render(<Dialog {...props} />, container);
  await halt(300);
  ReactDOM.unmountComponentAtNode(container);
  container.remove();
}

function halt(duration: number) {
  return new Promise((res) => setTimeout(res, duration));
}
