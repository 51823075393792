import { ImageCarouselModuleProps, SingleImage } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { Splide, SplideSlide, SplideTrack, SplideEventHandlers } from '@splidejs/react-splide';
import { useState } from 'react';
import Text from 'components/base/Text';
import { gray200 } from 'styles';
// TODO: splide css 가 실제로 import 되지만 unresolved 로 인식되고 있음.
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css';

export const ImageCarousel = ({ image_list, options }: ImageCarouselModuleProps) => {
  const [active_idx, setActiveIdx] = useState(0);

  const handleReady: SplideEventHandlers['onReady'] = (splide) => {
    const $clones = splide.root.querySelectorAll('.splide__slide--clone');
    // splide loop일 경우, item 클릭 동작안함 이슈로 clone item 내 <map>'name' 속성 삭제
    $clones.forEach(($item) => {
      const $map = $item.querySelector('map');
      $map?.removeAttribute('name');
    });
  };

  // 기본 dots(...)형 indicator 노출 조건
  const show_dots_indicator = Boolean(options?.loop) && Boolean(options?.autoplay);

  return (
    <SC.Wrapper>
      <Splide
        hasTrack={false}
        options={{
          ...options,
          type: options?.loop || options?.autoplay ? 'loop' : 'slide',
          autoplay: options?.autoplay,
          pagination: show_dots_indicator,
        }}
        aria-label='image-carousel'
        onReady={handleReady}
        onMove={(_, real_idx) => setActiveIdx(real_idx)}
      >
        <SplideTrack>
          {image_list.map((item) => (
            <SplideSlide key={item.id}>
              <SingleImage data_source={item} />
            </SplideSlide>
          ))}
        </SplideTrack>
        {!show_dots_indicator && (
          <SC.Paging>
            <Text variant='small1_medium' color='white' mr={2}>
              {active_idx + 1}
            </Text>
            <Text variant='small1_regular' color='gray200' mr={2}>
              /
            </Text>
            <Text variant='small1_regular' color='gray200'>
              {image_list.length}
            </Text>
          </SC.Paging>
        )}
      </Splide>
    </SC.Wrapper>
  );
};

const SC = {
  Wrapper: styled.div`
    position: relative;

    .splide__arrows {
      display: none;
    }

    .splide__pagination__page {
      height: 5px;
      width: 5px;

      &.is-active {
        background-color: #000;
        transform: scale(1);
      }
    }
  `,
  Paging: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 16px;
    right: 14px;
    padding: 2px 10px;
    min-width: 45px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.6);
    color: ${gray200};
    border-radius: 200px;
    z-index: 1;
  `,
};
