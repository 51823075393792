import { StoreGridModuleProps } from '@croquiscom-pvt/event-modules';
import { StoreGridWithValidProps } from './StoreGridWithValidProps';

export const StoreGrid = ({ store_preset_uuid, ...props }: StoreGridModuleProps) => {
  if (!store_preset_uuid) {
    return <></>;
  }

  return <StoreGridWithValidProps store_preset_uuid={store_preset_uuid} {...props} />;
};
