import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';
import { white } from 'styles';

interface Props extends HTMLAttributes<HTMLElement> {
  /**
   * 상품카드 section 에 대한 custom margin 을 설정합니다.
   * @default '0'
   */
  margin?: string;
  /**
   * 상품카드 section 에 대한 custom padding 을 설정합니다.
   * @default '8px 8px 24px'
   */
  padding?: string;
}

/**
 * 상품카드 가상 리스트 wrapper
 * @component
 */
const Section: FC<Props> = ({ margin = '0', padding = '8px 16px 24px', children, ...rest }) => {
  return (
    <SC.Section margin={margin} padding={padding} {...rest}>
      {children}
    </SC.Section>
  );
};

export default Section;

const SC = {
  Section: styled.section<{
    margin: string;
    padding: string;
  }>`
    position: relative;
    background-color: ${white};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
  `,
};
