import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import { useMemo } from 'react';
import { getProductReviewList } from 'api2';
import { GetProductReviewList, ProductReviewAttachmentStatus, ProductReviewListOrderType } from 'api2/types';

type ProductReviewItem = GetProductReviewList['product_review_list']['item_list'][number];

export const PHOTO_REVIEW_LIST_QUERY_KEY = 'getPhotoReviewList';

export interface AttachmentReviewList extends ProductReviewItem {
  attachment: ProductReviewItem['attachment_list'][number];
  all_index: number;
  attachment_index: number;
}

interface FetchProps {
  product_id: string;
  skip_count: number | null;
  limit_count?: number;
  req?: IncomingMessage;
}

const LIMIT_COUNT = 21;

export const fetchPhotoReviewList = async ({ product_id, limit_count, skip_count, req }: FetchProps) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { product_review_list },
  } = await getProductReviewList(
    {
      product_id,
      skip_count,
      limit_count: limit_count ?? LIMIT_COUNT,
      order: ProductReviewListOrderType.DATE_CREATED_DESC,
      has_attachment: true,
    },
    context_request,
  );

  return product_review_list;
};

/**
 * 상품 리뷰 중 사진 리뷰만 조회하는 hook
 * @hook
 */
export const usePhotoReviewList = ({ product_id, limit_count }: { product_id: string; limit_count?: number }) => {
  const {
    data,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    [PHOTO_REVIEW_LIST_QUERY_KEY, product_id],
    ({ pageParam }) => {
      return fetchPhotoReviewList({
        product_id,
        skip_count: typeof pageParam === 'number' ? pageParam : 0,
        limit_count,
      });
    },
    {
      getNextPageParam: (last_page, all_pages) => {
        const current_page_list_count = flatten(all_pages.map((value) => value.item_list)).length;
        return last_page.total_count > current_page_list_count ? current_page_list_count : false;
      },
    },
  );

  const photo_total_count = useMemo(() => (data ? data.pages[0]?.attachment_total_count || 0 : 0), [data]);

  const review_list = useMemo(() => {
    return flatten(data ? data.pages.map((value) => value.item_list) : []);
  }, [data]);

  const photo_review_list: AttachmentReviewList[] = useMemo(
    () =>
      review_list
        .flatMap((review, index) =>
          review.attachment_list.map((attachment, attachment_index) => ({
            ...review,
            attachment,
            attachment_index,
            all_index: index,
          })),
        )
        .filter((x) => x.attachment.status === ProductReviewAttachmentStatus.NORMAL),
    [review_list],
  );

  return {
    is_loading: rest.isInitialLoading,
    is_fetching: rest.isFetching,
    photo_review_list,
    photo_total_count,
    has_next_page,
    ...rest,
  };
};
