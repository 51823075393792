import { useRouter } from 'next/router';
import Banner from '../Banner';
import StoreExhibition from '../StoreExhibition';
import BestProductList from './BestProductList';
import Category from './Category';
import RecommendStore from './RecommendStore';
import Search from './Search';
import { SEARCH_FROM_TYPE } from 'components/search/hooks/useSearchRouterProps';
import SpecialCategoryHeader from 'components/special-categories/SpecialCategoryHeader';
import { useMount } from 'hooks';
import { BANNER_PAGE_NAME } from 'hooks/queries';
import { Log, useTracker } from 'util/Tracker';

const COMMON_UBL: Pick<Log, 'navigation'> = { navigation: 'kids_main' };

/**
 * 키즈관
 * @component
 */
const Kid = () => {
  const search_path = `/search?from=${SEARCH_FROM_TYPE.KID}`;
  const tracker = useTracker();
  const {
    query: { tab },
  } = useRouter();

  useMount(() => {
    tracker.homeKids({ home_kids: 'home_kids' });
  });

  return (
    <main>
      {tab !== 'kids' && <SpecialCategoryHeader title='키즈관' search_path={search_path} />}
      <Banner banner_type={BANNER_PAGE_NAME.KIDS} banner_ubl={COMMON_UBL} />
      <Search search_path={search_path} />
      <Category />
      <StoreExhibition category_type='kid' store_exhibition_ubl={COMMON_UBL} />
      <RecommendStore />
      <BestProductList />
    </main>
  );
};

export default Kid;
