import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import uniqBy from 'lodash-es/uniqBy';
import { useMemo } from 'react';
import { useProductAdOrProductIdList } from './useProductAdIdList';
import { getHomeAdAndPersonalizedProductList, getProductListByAdIdList, getProductListByIdList } from 'api2';
import { FbkHomeAdOrProductId, GetProductListByAdIdList, GetProductListByIdList } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { splitAt } from 'util/array_utils';
import { convertProductList } from 'util/product_util';
import { GqlRequestOptions } from 'util/request';

export const HOT_PRODUCT_LIST_QUERY_KEY = 'getHotProductList';

export type ProductListByIdList = NonNullable<GetProductListByIdList['product_list_by_id_list']['item_list']>[number];
export type ProductListByAdIdList = NonNullable<
  GetProductListByAdIdList['product_list_by_id_list']['item_list']
>[number];

const PAGE_COUNT = 30;
const TOP_PRODUCT_COUNT = 3;

const fetchProductListByIdList = async (product_id_list: string[], context_request: GqlRequestOptions) => {
  try {
    const { data } = await getProductListByIdList({ product_id_list }, context_request);

    if (!data.product_list_by_id_list.item_list) {
      return [];
    }

    return convertProductList(data.product_list_by_id_list.item_list);
  } catch (e) {
    return [];
  }
};

const fetchProductListByAdIdList = async (ad_id_list: string[], context_request: GqlRequestOptions) => {
  try {
    const { data } = await getProductListByAdIdList({ ad_id_list }, context_request);

    if (!data.product_list_by_id_list.item_list) {
      return [];
    }

    return convertProductList(data.product_list_by_id_list.item_list);
  } catch (e) {
    return [];
  }
};

export const fetchHomeAdAndPersonalizedProductList = async (
  ad_id_list: string[],
  context_request: GqlRequestOptions,
): Promise<ProductCardProps[]> => {
  try {
    const {
      data: { homeAdAndPersonalizedProductList },
    } = await getHomeAdAndPersonalizedProductList({ ad_id_list }, context_request);
    return convertProductList(homeAdAndPersonalizedProductList.item_list);
  } catch {
    return [];
  }
};

async function fetchAdProductList(ad_id_list: string[], product_id_list: string[], context_request: GqlRequestOptions) {
  const fetch_promises: Array<Promise<ProductCardProps[]>> = [];

  if (ad_id_list.length > 0) {
    fetch_promises.push(fetchProductListByAdIdList(ad_id_list, context_request));
  }

  if (product_id_list.length > 0) {
    fetch_promises.push(fetchProductListByIdList(product_id_list, context_request));
  }

  const product_list_group = await Promise.all(fetch_promises);
  const product_list = flatten(product_list_group);
  return product_list;
}

interface FetchProps {
  page?: number;
  req?: IncomingMessage;
  ad_or_product_ld_list: FbkHomeAdOrProductId[];
}

export const fetchTrendProductList = async (props: FetchProps) => {
  const { page = 1, req, ad_or_product_ld_list } = props;
  const has_suffle_personal_recommend = page === 1;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  if (ad_or_product_ld_list.length === 0) {
    return {
      item_list: [],
      next_page: 0,
    };
  }

  const ad_or_product_id_list_slice_paging = ad_or_product_ld_list.slice((page - 1) * PAGE_COUNT, page * PAGE_COUNT);

  const ad_id_list = ad_or_product_id_list_slice_paging.filter((item) => !!item.ad_id).map((item) => item.ad_id ?? '');
  const product_id_list = ad_or_product_id_list_slice_paging
    .filter((item) => !!item.product_id)
    .map((item) => item.product_id ?? '');

  let product_list: ProductCardProps[] = [];
  if (has_suffle_personal_recommend) {
    product_list = await fetchHomeAdAndPersonalizedProductList(ad_id_list, context_request);
  } else {
    product_list = await fetchAdProductList(ad_id_list, product_id_list, context_request);
  }

  return {
    item_list: product_list,
    next_page: page + 1,
  };
};

export const useTrendProductList = (category_key: string | null) => {
  const { ad_or_product_ld_list, is_ad_or_product_id_list_loading, refetch } =
    useProductAdOrProductIdList(category_key);
  const max_page = Math.ceil(ad_or_product_ld_list.length / PAGE_COUNT);

  const {
    data: hot_product_list,
    isInitialLoading: is_product_loading,
    isFetching: is_product_fetching,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    [HOT_PRODUCT_LIST_QUERY_KEY, category_key, ad_or_product_ld_list],
    ({ pageParam }) => fetchTrendProductList({ page: pageParam, ad_or_product_ld_list }),
    {
      getNextPageParam: (last_page) => {
        return last_page.next_page > max_page ? null : last_page.next_page;
      },
    },
  );

  const product_list = useMemo(() => {
    return getProductList(hot_product_list?.pages.map((value) => value.item_list) ?? []);
  }, [hot_product_list]);

  const [top_product_list, ad_product_list] = splitAt(product_list, TOP_PRODUCT_COUNT);

  const is_loading = is_ad_or_product_id_list_loading || is_product_loading;

  return {
    is_loading,
    product_list,
    top_product_list,
    ad_product_list,
    is_product_loading,
    is_product_fetching,
    total_count: ad_or_product_ld_list.length,
    has_next_page,
    refetchAdIdList: refetch,
    ...rest,
  };
};

function getProductList(product_list_group: ProductCardProps[][]) {
  // 중복 상품 제거
  const product_list = uniqBy(flatten(product_list_group), 'catalog_product_id');

  const is_product_list_empty_or_even_number = product_list.length % 2 === 0;
  // MEMO: 광고풀 부족 or 중복 이슈로 30를 요청해도 30개 오는게 보장이 되지 않는다 때문에 홀수인 경우 카드 ui를 위해 짝수로 노출 처리
  return is_product_list_empty_or_even_number ? product_list : product_list.slice(0, product_list.length - 1);
}
