import { FC } from 'react';
import Banners, { BannerItem } from 'components/base/Banners';
import Skeleton from 'components/base/Skeleton';
import { useFbkRouter } from 'hooks';
import { BANNER_PAGE_NAME, useActiveBannerList } from 'hooks/queries';
import { Log, useTracker } from 'util/Tracker';

interface Props {
  banner_type: BANNER_PAGE_NAME;
  banner_ubl: Pick<Log, 'navigation'>;
}
/**
 * 전용 카테고리 - 배너
 * @component
 */
const Banner: FC<Props> = ({ banner_type, banner_ubl }) => {
  const tracker = useTracker();
  const router = useFbkRouter();
  const {
    main_banner_list: { active_banner_list },
    is_loading,
  } = useActiveBannerList(banner_type);
  const common_ubl: Pick<Log, 'navigation' | 'object_type'> = { ...banner_ubl, object_type: 'banner' };

  const banner_list = active_banner_list.map((banner) => ({
    id: banner.id,
    image_url: banner.image_url,
    link: banner.link,
    alt: banner.title,
  }));

  const handleImpressionBanner = (banner: BannerItem, index: number) => {
    tracker.addLog({
      ...common_ubl,
      category: 'impression',
      object_id: banner.id,
      object_idx: index,
    });
  };

  const handleBannerClick = (banner: BannerItem, index: number) => {
    tracker.addLog({
      ...common_ubl,
      category: 'click',
      object_type: 'banner',
      object_id: banner.id,
      object_idx: index,
    });
  };

  const handleAllViewClick = () => {
    tracker.addLog({
      ...common_ubl,
      category: 'click',
      object_section: 'banner_all',
      object_type: 'button',
    });
    router.push(`/banner-views/${banner_type}`);
  };

  if (is_loading) {
    return (
      <section>
        <Skeleton height={160} />
      </section>
    );
  }

  return (
    <section>
      <Banners
        is_loading={is_loading}
        banner_list={banner_list}
        size='medium'
        onBannerClick={handleBannerClick}
        onViewBanner={handleImpressionBanner}
        onAllViewClick={handleAllViewClick}
      />
    </section>
  );
};

export default Banner;
