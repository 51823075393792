import styled from '@emotion/styled';
import { IconRefresh16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { pink_primary, white } from 'styles';

interface Props {
  onRefresh?: () => void;
}

/**
 * 타임특가 종료 딤드
 * @component
 */
const EndDimmed = ({ onRefresh }: Props) => {
  return (
    <SC.Container>
      <SC.TitleWrapper>
        <Text variant='title4_bold' color='gray_primary'>
          타임특가가 종료되었어요
        </Text>
        <Text variant='title6_medium' color='gray_secondary'>
          새로운 특가상품을 만나보세요
        </Text>
      </SC.TitleWrapper>
      <SC.RefreshButton onClick={onRefresh}>
        <IconRefresh16 color={pink_primary} />
        <Text variant='body4_medium' color='pink_primary' ml={4}>
          새로운 상품 보기
        </Text>
      </SC.RefreshButton>
    </SC.Container>
  );
};

export default EndDimmed;

const SC = {
  Container: styled.div`
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  `,
  RefreshButton: styled.button`
    height: 36px;
    padding: 0 12px;
    border: 1px solid ${pink_primary};
    border-radius: 4px;
    background-color: ${white};

    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
