import { TextEditorModuleProps } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';

export const TextEditor = ({ html }: TextEditorModuleProps) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: html }} />;
};

const Wrapper = styled.div`
  > ul {
    padding-left: 40px;
    margin: 1em;
    list-style: disc;
  }

  > .align-left {
    text-align: left;
  }

  > .align-center {
    text-align: center;
  }

  > .align-right {
    text-align: right;
  }
`;
