import styled from '@emotion/styled';
import TimeSaleList from '../../TimeSaleList';
import DiscountedNewProducts from './DiscountedNewProducts';
import StorePickList from './StorePickList';
import TopButton from 'components/common/TopButton';
import CouponStore from 'components/home/common/CouponStore';
import TopBanner from 'components/home/TopBanner';
import { useMount } from 'hooks';
import { BANNER_PAGE_NAME } from 'hooks/queries';
import { bg_secondary } from 'styles';
import { Log, useTracker } from 'util/Tracker';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';

const COMMON_UBL: Pick<Log, 'navigation'> = { navigation: 'home_benefits' };

const Sale = () => {
  const tracker = useTracker();

  useMount(() => {
    tracker.homeSaleTab({ home_sale: 'home_sale' });
  });

  return (
    <SC.Container>
      <TopBanner banner_page_name={BANNER_PAGE_NAME.SALE} banner_size='medium' ubl={COMMON_UBL} />
      <TimeSaleList title='놓칠 수 없는 타임특가' product_expose_type='slide' ubl={COMMON_UBL} />
      <CouponStore ubl={{ navigation: 'home_benefits' }} />
      <SC.Line />
      <StorePickList />
      <DiscountedNewProducts />
      <TopButton ubl_navigation='home_sale' />
    </SC.Container>
  );
};

export default Sale;

const SC = {
  Container: styled.section`
    padding-bottom: 40px;
  `,

  Line: styled.div`
    width: 100%;
    height: 8px;
    background-color: ${bg_secondary};
  `,
};
