import styled from '@emotion/styled';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { IconShare20 } from 'components/base/Icon';
import { isShowTopButtonAtom } from 'components/common/AppLandingNotify/store/isShowTopButton';
import ShareBottomSheet from 'components/common/ShareBottomSheet';
import { kakao_share_option_atom } from 'components/common/ShareBottomSheet/store/KakaoShareOption';
import { useShareKakaoSend } from 'components/events/hooks/useShareKakaoSend';
import { useToggle } from 'hooks';
import { WEB_MAX_WIDTH, colors } from 'styles';
import { getShareUrl } from 'util/share';
import { useTracker } from 'util/Tracker';

interface Props {
  /**
   * 카카오톡 공유 시 전달할 이벤트의 제목을 지정합니다.
   * @default '공유하기'
   */
  event_title?: string;
  /**
   * 카카오톡 공유 시 전달할 이벤트의 설명을 지정합니다.
   */
  event_description?: string;
  /**
   * 카카오톡 공유시 표시되는 썸네일 이미지를 지정합니다.
   */
  event_image_url?: string;
  /**
   *
   */
  use_template?: boolean;
  /**
   * 기본 bottom 값을 지정합니다.
   */
  default_bottom?: number;
  /**
   * 공유할 url을 지정합니다.
   */
  share_url?: string;
  /**
   * ubl navigation 값을 정의합니다.
   */
  ubl_navigation: string;
}

const TOP_BUTTON_BOTTOM = 42;
const SHARE_BUTTON_BOTTOM = 30;

/**
 * 이벤트페이지 - 공유하기
 * @component
 */
const Share = ({
  event_title = '공유하기',
  event_image_url = '',
  event_description = '',
  use_template = true,
  default_bottom = 0,
  share_url = '',
  ubl_navigation,
}: Props) => {
  const { asPath: as_path } = useRouter();
  const [is_open_dialog, toggleIsOpenDialog] = useToggle(false);
  const is_show_top_button = useAtomValue(isShowTopButtonAtom);
  const setKakaoShareContents = useUpdateAtom(kakao_share_option_atom);
  const { handleSendKakaoMessage } = useShareKakaoSend();
  const { ref: share_button_ref, inView: in_view } = useInView({ triggerOnce: true });
  const tracker = useTracker();

  const share_ubl = useMemo(() => {
    return {
      navigation: ubl_navigation,
      object_section: 'floating_button',
      object_type: 'button',
      object_id: 'share',
    };
  }, [ubl_navigation]);

  useEffect(() => {
    if (in_view) {
      tracker.addLog({
        category: 'impression',
        ...share_ubl,
      });
    }
  }, [in_view, share_ubl, tracker]);

  const handleShareClick = () => {
    if (as_path.includes('promotion') && use_template) {
      handleSendKakaoMessage(as_path);
    } else {
      setKakaoShareContents({
        type: 'event',
        data: {
          content: {
            title: typeof event_title === 'string' ? event_title : '공유하기',
            description: event_description,
            image_url: event_image_url,
          },
        },
      });
    }
    toggleIsOpenDialog();
    tracker.addLog({
      category: 'click',
      ...share_ubl,
    });
  };

  const bottom = default_bottom + SHARE_BUTTON_BOTTOM + (is_show_top_button ? TOP_BUTTON_BOTTOM : 0);

  return (
    <>
      <SC.Container ref={share_button_ref}>
        <SC.Button bottom={bottom} onClick={handleShareClick}>
          <IconShare20 />
        </SC.Button>
      </SC.Container>
      <ShareBottomSheet
        share_url={share_url ? share_url : getShareUrl()}
        is_open={is_open_dialog}
        onCloseDialog={toggleIsOpenDialog}
      />
    </>
  );
};

export default Share;

const SC = {
  Container: styled.div`
    position: fixed;
    max-width: ${WEB_MAX_WIDTH}px;
    width: 100%;
    bottom: 0;
    margin: auto;
    z-index: 200;
  `,
  Button: styled.button<{ bottom: number }>`
    position: absolute;
    bottom: ${({ bottom }) => `${bottom}px`};
    bottom: ${({ bottom }) => `calc(env(safe-area-inset-bottom) + ${bottom}px)`};
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    border: 1px solid ${colors.border1};
    cursor: pointer;

    &:active {
      background-color: ${colors.white};
    }
  `,
};
