import { appLandingUrl } from './useAdLadingNotify';
import { useBottomDialog, CONFIRM_ID } from 'context/ButtomDialogContext';

export const useAppLandingDialog = () => {
  const { open } = useBottomDialog();
  const openApp = () => {
    window.location.assign(appLandingUrl);
  };

  const openAppLandingDialog = async () => {
    const id = await open({
      contents: {
        title: '패션 바이 카카오\n앱에서만 참여할 수 있어요',
        text: '앱을 열거나 설치할까요?',
      },
      buttons: [
        { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '나중에 하기' },
        { id: CONFIRM_ID.OK, size: 'large', children: '앱 열기' },
      ],
    });

    if (id === CONFIRM_ID.OK) {
      openApp();
    }
  };
  return { openAppLandingDialog };
};
