import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Options, Splide, SplideEventHandlers, SplideSlide } from '@splidejs/react-splide';
import { FC } from 'react';
import ContentTitle from 'components/base/ContentTitle';
import ImageAndProductGrid from 'components/common/ImageAndProductGrid';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { RecommendShop, useRecommendShopList } from 'hooks/queries/useRecommendShopList';
import { WEB_MAX_WIDTH } from 'styles';
import { convertProductList } from 'util/product_util';
import { Log } from 'util/Tracker';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';

const splide_options: Options = {
  type: 'loop',
  lazyLoad: true,
  arrows: false,
  pagination: false,
  gap: 10,
  easing: 'ease',
};

const PRODUCT_COUNT = 3;

interface Props {
  ubl?: Omit<Log, 'category'>;
  custom_css?: SerializedStyles;
}
/**
 * 트랜드탭 - 추천스토어
 * @component
 */
const RecommendStore: FC<Props> = ({ ubl, custom_css }) => {
  const { recommend_shop_list, is_fetching } = useRecommendShopList({
    page_name: 'TREND',
  });

  const common_ubl = ubl
    ? {
        ...ubl,
        data: {
          ...ubl.data,
        },
      }
    : undefined;

  const handleSplideReady: SplideEventHandlers['onReady'] = (splide) => {
    setTimeout(() => {
      if (recommend_shop_list.length === 0) {
        return;
      }
      // Splide 마지막 열에서 다음 첫번째 열의 클론 img가 base64 이미지로 처리되어 비노출되는 이슈로 src 강제 세팅
      const $clone_first_imgs = splide.root.querySelectorAll(
        `.splide__slide--clone[aria-label="1 of ${recommend_shop_list.length}"] img`,
      );

      $clone_first_imgs.forEach(($img, index) => {
        const is_main_image = index === 0;
        if (is_main_image) {
          $img.setAttribute('src', recommend_shop_list[0]?.typical_image_url);
        } else {
          if (index > 3) {
            return;
          }
          $img.setAttribute('src', recommend_shop_list[0]?.product_list[index - 1].webp_image_url ?? '');
        }

        $img.classList.add('in');
      });
    }, 200);
  };

  return (
    <SC.Container css={custom_css}>
      <ContentTitle size='large' title='떠오르는 추천 스토어' />
      {is_fetching && (
        <div className='store_card'>
          {[...Array(PRODUCT_COUNT)].map((_, index) => (
            <ImageAndProductGrid.Skeleton key={index} />
          ))}
        </div>
      )}
      {!is_fetching && (
        <div className='store_card'>
          <Splide options={splide_options} className='splide_card_list' onReady={handleSplideReady}>
            {recommend_shop_list.map((store, index) => (
              <SplideSlide key={index} className='slide_item'>
                <ImageAndProductGrid
                  key={index}
                  main_image={{
                    src: getMainImage(store),
                    alt: store.shop_name,
                    link: `/store/${store.shop_id}`,
                  }}
                  title={store.title ?? ''}
                  description={store.shop_name}
                  product_list={convertProductList(store.product_list).slice(0, PRODUCT_COUNT)}
                  ubl={
                    common_ubl && {
                      ...common_ubl,
                      object_idx: index,
                      data: {
                        ...common_ubl.data,
                        type: 'shop',
                        store_name: store.shop_name,
                      },
                    }
                  }
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
    </SC.Container>
  );
};

export default RecommendStore;

const SC = {
  Container: styled.section`
    padding: 10px 0 28px;

    .splide_card_list {
      width: min(100vw, ${WEB_MAX_WIDTH}px);
    }
    .slide_item {
      // splide pagePage, perMove 옵션이 정수 타입으로만 세팅 가능하여 다음 슬라이드 일부노출 영역을 css로 강제함
      // inline style로 splide에서 적용되어 !important로 우선순위 적용
      width: calc(((100% + 10px) / 1.1) - 10px) !important;
      padding-left: 16px;
      margin-right: 0 !important;
    }

    .tab {
      margin-top: -8px;
    }
    .store_card {
      ${invisible_scroll_bar}
      overflow-x: scroll;
      display: flex;
      gap: 10px;
      padding: 0 0 12px;
    }
  `,
};

function getMainImage(store: RecommendShop) {
  if (store.manual_uploaded_image_url) {
    return store.manual_uploaded_image_url;
  }

  if (store.typical_image_url) {
    return store.typical_image_url;
  }
  return '';
}
