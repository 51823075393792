// @ts-nocheck
import { toastMessage, ContentReplyModuleProps } from '@croquiscom-pvt/event-modules';
import { useQueryClient, useQuery, useInfiniteQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import once from 'lodash-es/once';
import { useState, useEffect, useRef, useMemo } from 'react';
import { getCmsEventContentReplyList, createCmsEventContentReply, deleteCmsEventContentReply } from 'api2';
import { EventContentReplyStatus, GetCmsEventContentReplyList, GetCmsEventContentReplyMetadata } from 'api2/types';
import { useBottomDialog } from 'context/ButtomDialogContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useContentEditorQuery } from 'util/content-editor/content-editor-data';
import {
  getQueryKeyContentReplyList,
  getQueryKeyContentReplyMetadata,
  fetchContentReplyMetadata,
} from 'util/content-editor/content-reply-data';

type GetCmsEventContentReplyList_getEventContentReplyList = GetCmsEventContentReplyList['getEventContentReplyList'];
type GetCmsEventContentReplyMetadata_getEventContentReplyMetadata =
  GetCmsEventContentReplyMetadata['getEventContentReplyMetadata'];
type GetCmsEventContentReplyList_getEventContentReplyList_item_list =
  GetCmsEventContentReplyList_getEventContentReplyList['item_list'];

enum COMMENT_REPLY_DELETE_ID {
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
}

export type UseEventContentReplyListOptions = { uuid: string };
const useEventContentReplyList = (event_content_reply_metadata_uuid: string) => {
  return useInfiniteQuery({
    queryKey: getQueryKeyContentReplyList(event_content_reply_metadata_uuid),
    queryFn: async ({ pageParam = null }) => {
      try {
        const result = await getCmsEventContentReplyList({
          event_content_reply_metadata_uuid,
          after: pageParam,
          limit_count: 10,
        });
        return result.data.getEventContentReplyList;
      } catch (error) {
        console.log('error', error);
      }
    },
    enabled: Boolean(event_content_reply_metadata_uuid.length),
    getNextPageParam: (last_page: GetCmsEventContentReplyList_getEventContentReplyList) => {
      if (last_page.has_next_page) {
        return last_page.end_cursor;
      }
      return null;
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useEventContentReplyListInfiinit = (event_content_reply_metadata_uuid: string, is_summary: boolean) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { data, fetchNextPage, hasNextPage, isFetched } = useEventContentReplyList(event_content_reply_metadata_uuid);
  const loadMore = useMemo(() => {
    return once(() => {
      if (!data || !loadMoreRef.current || !hasNextPage || is_summary) {
        return;
      }
      fetchNextPage();
    });
  }, [data, fetchNextPage, is_summary, hasNextPage]);

  useIntersectionObserver(
    {
      target: loadMoreRef,
      onIntersect: loadMore,
      enabled: hasNextPage,
      rootMargin: '100px',
    },
    [loadMore],
  );

  return {
    replies: (data?.pages
      ? data.pages
          .map((page) => page.item_list)
          .filter(Boolean)
          .flat()
      : []) as GetCmsEventContentReplyList_getEventContentReplyList_item_list,
    isFetched,
    loadMoreRef,
    hasNextPage,
  };
};

export const useCreateReplyMutation = (event_content_reply_metadata_uuid: string) => {
  const query_client = useQueryClient();
  const { mutate } = useMutation((content: string) =>
    createCmsEventContentReply({ content, event_content_reply_metadata_uuid })
      .then(() => {
        query_client.invalidateQueries({
          queryKey: getQueryKeyContentReplyList(event_content_reply_metadata_uuid),
        });
      })
      .catch((e) => {
        toastMessage(e.message ?? '콘텐츠 댓글 요청을 실패했어요');
      }),
  );
  return mutate;
};

export type DeleteReplyMutationArgs = Pick<
  GetCmsEventContentReplyList_getEventContentReplyList_item_list,
  'id' | 'event_content_reply_metadata_uuid'
>;

export type EventContentReplyListResponse = ReturnType<typeof useEventContentReplyList>['data'];

export const useDeleteReplyMutation = () => {
  const query_client = useQueryClient();
  const { open: openBottomDialog } = useBottomDialog();
  const { mutate } = useMutation(({ id: reply_id, event_content_reply_metadata_uuid }: DeleteReplyMutationArgs) =>
    deleteCmsEventContentReply({ reply_id, event_content_reply_metadata_uuid })
      .then(() => {
        const page_mapper = (page: GetCmsEventContentReplyList_getEventContentReplyList | undefined) => {
          if (!page) {
            return page;
          }
          return {
            ...page,
            item_list: page.item_list.map((reply) =>
              reply.id === reply_id ? { ...reply, status: EventContentReplyStatus.DELETED } : reply,
            ),
          };
        };
        query_client.setQueryData(
          getQueryKeyContentReplyList(event_content_reply_metadata_uuid),
          (prev: EventContentReplyListResponse) => ({
            ...prev,
            pages: prev?.pages.map(page_mapper) ?? [],
          }),
        );
      })
      .catch(() => toastMessage('콘텐츠 댓글_요청을 실패했어요')),
  );
  const handleDeleteReply = async ({ id, event_content_reply_metadata_uuid }: DeleteReplyMutationArgs) => {
    const result_id = await openBottomDialog({
      contents: {
        title: '댓글을 삭제하시겠어요?',
        text: '삭제한 댓글은 복구할 수 없어요',
      },
      buttons: [
        { id: COMMENT_REPLY_DELETE_ID.CANCEL, variant: 'tertiary_gray', children: '취소' },
        { id: COMMENT_REPLY_DELETE_ID.DELETE, children: '삭제' },
      ],
    });
    if (result_id === COMMENT_REPLY_DELETE_ID.DELETE) {
      mutate({ event_content_reply_metadata_uuid, id });
    }
  };
  return handleDeleteReply;
};

export type UseEventContentReplyMetadataOptions = {
  uuid: string;
  options?: Omit<
    UseQueryOptions<
      GetCmsEventContentReplyMetadata_getEventContentReplyMetadata | null,
      unknown,
      GetCmsEventContentReplyMetadata_getEventContentReplyMetadata | null,
      string[]
    >,
    'queryKey' | 'queryFn'
  >;
};
export function useEventContentReplyMetadata({ uuid, options }: UseEventContentReplyMetadataOptions) {
  return useQuery(getQueryKeyContentReplyMetadata(uuid), () => fetchContentReplyMetadata(uuid), options);
}

export function useEventContentReplyMessages(uuid: string) {
  const [content_reply_messages, set_content_reply_messages] = useState({
    prepare_text: '이벤트 시작 전이에요',
    progress_text: '댓글을 남겨보세요',
    complete_text: '이벤트가 종료되었어요',
    need_auth_text: '로그인 후 댓글을 남길 수 있어요',
  });
  const { data } = useContentEditorQuery(uuid);

  useEffect(() => {
    if (data) {
      const reply_module = Object.values(data.content_editor_json.component_map).find(
        ({ __type }) => __type === 'ContentReply',
      ) as ContentReplyModuleProps | undefined;
      if (reply_module) {
        set_content_reply_messages({
          prepare_text: reply_module.prepare_text,
          progress_text: reply_module.progress_text,
          complete_text: reply_module.complete_text,
          need_auth_text: reply_module.need_auth_text,
        });
      }
    }
  }, [data]);

  return content_reply_messages;
}
