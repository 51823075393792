import { atom } from 'jotai';
import cloneDeep from 'lodash-es/cloneDeep';

export enum ScrollRefTitle {
  TAB_BAR = 'tab_bar_ref',
  MORE_SEE_BUTTON = 'more_see_button_ref',
}

interface ScrollRef {
  [ScrollRefTitle.TAB_BAR]: HTMLElement | null;
  [ScrollRefTitle.MORE_SEE_BUTTON]: HTMLElement | null;
}

const init_scroll_ref = {
  [ScrollRefTitle.TAB_BAR]: null,
  [ScrollRefTitle.MORE_SEE_BUTTON]: null,
};

export const scroll_target_ref_atom = atom<ScrollRef>(init_scroll_ref);
export const set_scroll_target_ref_atom = atom(
  null,
  (_, set, { target, key }: { target: HTMLElement; key: ScrollRefTitle }) => {
    set(scroll_target_ref_atom, (prev) => {
      const copied = cloneDeep(prev);
      copied[key] = target;

      return copied;
    });
  },
);

export const is_saved_shop_status_atom = atom<boolean>(false);
