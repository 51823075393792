import { Dialog as ContentUiDialog, DialogProps as _DialogProps } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { usePreventScroll } from 'hooks';

export type DialogProps = _DialogProps & { type: string };

export const Dialog = ({ icon_src, ...props }: DialogProps) => {
  const buttons =
    props.buttons?.map((button) => ({
      ...button,
      onClick: () => {
        button.onClick?.();
        props.onClose?.();
      },
    })) ?? [];

  usePreventScroll(true);

  return (
    <AnimatePresence>
      <SC.Wrapper animate={{ background: 'rgba(0, 0, 0, 0.4)' }}>
        <ContentUiDialog
          icon_node={icon_src && <SC.Icon alt='dialog_icon' src={icon_src} />}
          {...props}
          buttons={buttons}
        />
      </SC.Wrapper>
    </AnimatePresence>
  );
};

const SC = {
  Wrapper: styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 999;
  `,

  Icon: styled.img`
    width: 60px;
    height: 60px;
  `,
};
