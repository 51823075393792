import { FC } from 'react';
import DefaultHeader, { SideIconType } from './DefaultHeader';
import Text from 'components/base/Text';

interface Props {
  title: string;
  icons?: SideIconType;
}
const MainHeader: FC<Props> = ({ title, icons = ['search', 'cart'] }) => {
  return (
    <DefaultHeader
      left_icons={
        <Text variant='title2_bold' color='gray_primary' as='h2'>
          {title}
        </Text>
      }
      right_icons={icons}
      use_bottom_border={false}
    />
  );
};

export default MainHeader;
