import styled from '@emotion/styled';
import MdProductList from './MdProductList';
import NewProductList from './NewProductList';
import TopButton from 'components/common/TopButton';
import { bg_secondary } from 'styles';

/**
 * 홈 - 신상 탭
 * @component
 */
const New = () => {
  return (
    <SC.Container>
      <MdProductList />
      <SC.Line />
      <NewProductList />
      <TopButton ubl_navigation='home_curation' />
    </SC.Container>
  );
};

export default New;

const SC = {
  Container: styled.div`
    padding-bottom: calc(env(safe-area-inset-bottom) + 48px);
    padding-bottom: calc(constant(safe-area-inset-bottom) + 48px);
  `,
  Line: styled.div`
    width: 100%;
    height: 8px;
    background-color: ${bg_secondary};
  `,
};
