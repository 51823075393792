import { toastMessage } from '@croquiscom-pvt/event-modules';
import { getUserAdNotiStatus } from 'api2';
import { AdNotiStatus } from 'api2/types';

export async function getStatus() {
  const user = await getUserAdNotiStatus().then(({ data }) => data.user);
  return { is_user: Boolean(user), is_agreed: Boolean(user && user.ad_noti_status === AdNotiStatus.AGREE) };
}

export async function insuffientUserInput(user_input: string | undefined) {
  if (typeof user_input === 'string' && user_input.length === 0) {
    toastMessage('답안을 입력(선택)해주세요');

    return true;
  }

  return false;
}
