import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, Fragment } from 'react';
import { FeatureCategory, PageName, useActiveFeatureMenu } from '../hooks';
import LottieFeature from './LottieFeature';
import CircleCategories, { CircleCategory } from 'components/base/CircleCategories';
import Skeleton from 'components/base/Skeleton';
import { invisible_scroll_bar } from 'components/products/details/styles';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { useFbkRouter } from 'hooks';
import { useLiveShop } from 'queries/live/useLiveShop';
import { border4, white } from 'styles';
import { typography } from 'styles/font';
import { getHomeSubTabId, MAIN_TAB_ID, SUB_TAB_ID } from 'util/app_utils';
import { Log } from 'util/Tracker';

const CATEGORY_SIZE = {
  TREND: { min: 52, max: 60 },
  CATEGORY_TAB: { min: 60, max: 60 },
};

const LOTTIE_FILE_TYPE = '.json';
const LIVE = '라이브';

interface Props {
  page_name: string;
  ubl?: Omit<Log, 'category'>;
}

/**
 * 카테고리관 - 피처메뉴 리스트
 * @component
 */
const FeatureMenus: FC<Props> = ({ page_name, ubl }) => {
  const { feature_menu_list, is_loading } = useActiveFeatureMenu(page_name);
  const { goMainTab, goLive, push } = useFbkRouter();
  const { live_day_info } = useLiveShop();
  const is_trend_page = page_name === PageName.TREND;

  const handleClick = (sub_tab_id: SUB_TAB_ID | undefined, feature: FeatureCategory) => {
    const is_live_feature = feature.title.includes(LIVE);
    if (is_live_feature) {
      handleLiveClick(feature);
      return;
    }

    sub_tab_id && goMainTab({ main_tab: MAIN_TAB_ID.HOME, sub_tab: sub_tab_id });
  };

  const handleLiveClick = (feature: FeatureCategory) => {
    if (!live_day_info) {
      return;
    }

    if (feature.link?.includes('/events/cms')) {
      push(feature.link);
      return;
    }

    goLive(live_day_info.campaign_key);
  };

  const size_props = is_trend_page ? CATEGORY_SIZE.TREND : CATEGORY_SIZE.CATEGORY_TAB;

  if (is_loading) {
    const skeleton_length = is_trend_page ? 10 : 4;
    return (
      <SC.StyledSection is_trend={is_trend_page}>
        <SC.FeatureMenuList is_trend={is_trend_page}>
          {[...Array(skeleton_length)].map((_, index) => (
            <SC.SkeletonFeatureMenuItem key={index}>
              <div className='item_wrap'>
                <Ratio className='img_wrap'>
                  <Skeleton radius={48} />
                </Ratio>
                <Skeleton width={60} height={18} radius={4} className='store_name' />
              </div>
            </SC.SkeletonFeatureMenuItem>
          ))}
        </SC.FeatureMenuList>
      </SC.StyledSection>
    );
  }

  return (
    <SC.StyledSection is_trend={is_trend_page}>
      <SC.FeatureMenuList is_trend={is_trend_page}>
        {feature_menu_list.map((feature, index) => {
          const features = getListFeatureMenus(feature, is_trend_page, ubl);
          const is_lottie_json = features.img_url.includes(LOTTIE_FILE_TYPE);

          if (is_lottie_json) {
            return (
              <Fragment key={feature.title}>
                <LottieFeature
                  category={getListFeatureMenus(feature, is_trend_page, ubl)}
                  index={index}
                  onClick={() => handleClick(features.sub_tab_id, feature)}
                  loop_count={feature.loop_count}
                  is_live={feature.title.includes(LIVE)}
                />
              </Fragment>
            );
          }

          return (
            <Fragment key={feature.title}>
              <CircleCategories.Item
                index={index}
                category={features}
                size={size_props}
                title_image_gap='6px'
                use_filter={false}
                onClick={() => handleClick(features.sub_tab_id, feature)}
              />
            </Fragment>
          );
        })}
      </SC.FeatureMenuList>
    </SC.StyledSection>
  );
};

/**
 * @description
 * 네이티브 버전에서 메인 탭 영역의 피처인 경우 앱호출을 하여야 헤더가 표시되어
 * link로 sub tab id 값을 확인하여 goMainTab 함수 사용할 수 있도록 하기 위한 함수입니다.
 */
function getListFeatureMenus(feature: CircleCategory, is_trend_page: boolean, default_ubl?: Omit<Log, 'category'>) {
  const sub_tab_id = getHomeSubTabId(feature.link ?? '');
  const ubl = {
    ...default_ubl,
    navigation: is_trend_page ? 'home_ad' : 'category_tab',
    object_section: 'feature_menu',
    object_id: feature.title,
  };

  if (sub_tab_id) {
    return {
      img_url: feature.img_url,
      title: feature.title,
      sub_tab_id,
      ubl,
    };
  }

  return {
    ...feature,
    ubl,
  };
}

export default FeatureMenus;

const SC = {
  StyledSection: styled.section<{ is_trend: boolean }>`
    ${({ is_trend }) =>
      is_trend &&
      css`
        border-bottom: 1px solid ${border4};
        user-select: none;
        padding: 16px;
      `}
  `,
  FeatureMenuList: styled.ul<{ is_trend: boolean }>`
    ${({ is_trend }) =>
      is_trend
        ? css`
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            gap: 16px 10.75px;
            place-items: center;
          `
        : css`
            ${invisible_scroll_bar};
            display: flex;
            align-items: center;
            overflow-x: scroll;
            white-space: nowrap;
            gap: 14px;
            padding: 16px;

            .name {
              ${typography.body4_medium};
            }
          `}

    li {
      margin-right: 0px;

      .store_img {
        img {
          border-radius: 0;
          background-color: ${white};
        }
      }

      a:active {
        background-color: ${white};
      }
    }
  `,
  SkeletonFeatureMenuItem: styled.div`
    .item_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .store_name {
      margin-top: 6px;
    }
  `,
};
