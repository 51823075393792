import { useQuery } from '@tanstack/react-query';
import { getNewCoupon } from 'api2';

export const NEW_COUPON_QUERY_KEY = 'getNewCoupon';

export const fetchNewCoupon = async () => {
  try {
    const { data } = await getNewCoupon(undefined, { show_alert: false });
    return data.new_coupon.new_product_coupon_policy;
  } catch {
    return undefined;
  }
};

/**
 * 신상탭 콘텐츠 리스트 조회
 * @hook
 */
export const useNewCoupon = () => {
  const { data, isLoading: is_loading, ...rest } = useQuery([NEW_COUPON_QUERY_KEY], () => fetchNewCoupon());

  return {
    coupon_info: data,
    is_loading,
    ...rest,
  };
};
