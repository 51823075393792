import { useContentMetadata, useModuleHierarchy } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { memo, useMemo } from 'react';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import { getColumnList } from './virtuso';
import ProductCard from 'components/common/ProductCard';
import { usePromotionBadgeList } from 'components/home/hooks/usePromotionBadgeList';
import { useProductsPresetGroupData } from 'util/content-editor/products-preset-data';

export type ProductGridProps = {
  columns: number;
  products_preset_uuid: string;
  group_index: number;
};

const cms_product_ubl = {
  navigation: 'content_detail',
  object_type: 'catalog',
  object_section: 'product_section',
};

const ProductGrid = ({ columns, products_preset_uuid, group_index }: ProductGridProps) => {
  const { data } = useProductsPresetGroupData({
    products_preset_uuid,
    group_index,
    enabled: true,
  });
  const { modules } = useModuleHierarchy();
  const { page_url, custom_data } = useContentMetadata();
  const { getProductCardWithFbkBadgeList } = usePromotionBadgeList();

  const ColumnList = useMemo(() => getColumnList(columns), [columns]);
  const tab_title_list = useMemo(
    () =>
      modules
        .filter(({ __type }) => __type === 'TabContent' || __type === 'ProductTab')
        .map((module) => {
          if (module.__type === 'TabContent') {
            return module.title;
          }

          if (module.__type === 'ProductTab') {
            return module.group_header_data?.[group_index] ?? '-';
          }

          return '-';
        }),
    [group_index, modules],
  );
  const product_ubl = useMemo(
    () => ({ ...cms_product_ubl, navigation_sub: { page_url }, data: { tab_title_list } }),
    [page_url, tab_title_list],
  );

  const is_prefetched_data = useMemo(() => data?.is_prefetched_data, [data?.is_prefetched_data]);

  return (
    <SC.Container>
      <VirtuosoGrid
        useWindowScroll
        components={{ List: ColumnList, Item: SC.Item }}
        totalCount={data?.item_list.length || 0}
        itemContent={(index) => {
          if (is_prefetched_data) {
            return <ProductCard.Skeleton />;
          }

          const product_card = data?.product_card_list?.[index];
          if (!product_card) {
            return null;
          }
          return (
            <ProductCard
              ubl={product_ubl}
              only_csr={!custom_data.is_home_tab}
              size={columns === 3 ? 'small' : 'medium'}
              index={index}
              {...getProductCardWithFbkBadgeList(product_card)}
            />
          );
        }}
      />
    </SC.Container>
  );
};

export default memo(ProductGrid);

const SC = {
  Container: styled.div`
    position: relative;
    padding: 8px 0 24px;
  `,
  Item: styled.li`` as GridComponents['Item'],
};
