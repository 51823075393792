import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Options } from '@popperjs/core';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement, FC, HTMLAttributes, ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useForkElementRef } from '../../../hooks';
import { ColorType, colors, white } from '../../../styles';
import { IconCancel16 } from '../Icon';

type PopperOptions = Partial<Options>;
type MotionDivProps = Omit<HTMLAttributes<HTMLDivElement>, 'onAnimationStart' | 'onDrag' | 'onDragEnd' | 'onDragStart'>;
type MotionOptions = {
  initial?: Record<string, number>;
  animate?: Record<string, number>;
  transition?: Record<string, number>;
};
interface Props extends PopperOptions, MotionDivProps {
  /**
   * 툴팁 표시 여부
   */
  open?: boolean;
  /**
   * 툴팁이 표시 될 컴포넌트
   */
  children: JSX.Element;
  /**
   * 툴팁의 컨텐츠
   * 텍스트의 경우 base/Text 컴포넌트 사용
   */
  contents: ReactNode;
  /**
   * true 일 경우 react-dom 의 createPortal 을 이용해 mount
   */
  use_portal?: boolean;
  /**
   * 툴팁 색상
   * @default gray800
   */
  tooltip_color?: ColorType;
  /**
   * 툴팁 shadow 유무
   * @default true
   */
  is_box_shadow?: boolean;
  /**
   * close 아이콘 유무
   * @default false
   */
  is_close_icon?: boolean;
  /**
   * close 아이콘 색
   * @default gray_tertiary
   */
  close_icon_color?: ColorType;
  /**
   * 툴팁 애니메이션 옵션 정의
   */
  motion_option?: MotionOptions;
  /**
   * 툴팁 close 함수
   */
  close?: () => void;
}

/**
 * popper.js 로 개발 된 공통 툴팁 컴포넌트 입니다.
 * popper.js의 usePopper hook 은 툴팁의 위치를 계산하기 위해 DOM node 를 참조 하도록 개발 되어 있습니다.
 * 따라서 Tooltip 컴포넌트의 children 은 ref 를 이용해 DOM node 에 대한 참조를 넘겨 줄 수 있어야 합니다.
 *
 * ex 1)
 * <Tooltip>
 *  <div>툴팁을 표시 할 component</div>
 * </Tooltip>
 *
 * ex 2)
 * <Tooltip>
 *   <ComponentWithForwardRef />
 * </Tooltip>
 *
 * // 이 컴포넌트는 forwardRef 를 통해 내부의 DOM 노드를 참조 할 수 있도록 ref 를 전달 합니다.
 * const ComponentWithForwardRef = forwardRef((props, ref) => {
 *   return (
 *     <div ref={ref}>{...}</div>
 *   )
 * })
 *
 * 자세한 옵션은 https://popper.js.org/docs/v2/ 를 참고 해 주세요!
 */
const Tooltip: FC<Props> = (props) => {
  const {
    open,
    children,
    contents,
    use_portal,
    placement,
    modifiers,
    strategy,
    onFirstUpdate,
    tooltip_color = 'black',
    is_box_shadow = true,
    is_close_icon = false,
    close_icon_color = 'gray_tertiary',
    close,
    ...rest
  } = props;
  const [is_tooltip_open, setIsTooltipOpen] = useState<boolean>(false);
  const [reference, setReference] = useState<HTMLElement | null>(null);
  const [popper, setPopper] = useState<HTMLElement | null>(null);
  const [arrow, setArrow] = useState<HTMLElement | null>(null);
  const fork = useForkElementRef<HTMLElement>(children.props.ref, setReference);

  const { styles, attributes } = usePopper(reference, popper, {
    placement,
    strategy,
    onFirstUpdate,
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrow } },
      { name: 'computeStyles', options: { gpuAcceleration: rest.motion_option ? false : true } },
      ...(modifiers ?? []),
    ],
  });

  useLayoutEffect(() => {
    setIsTooltipOpen(open ?? false);
  }, [open]);

  const getTooltip = () => {
    if (!is_tooltip_open) {
      return <AnimatePresence />;
    }
    return (
      <AnimatePresence>
        <SC.Root
          key='tooltip'
          className={cx({ is_close_icon }, rest.className)}
          {...rest}
          initial={rest.motion_option?.initial ?? { opacity: 0 }}
          animate={rest.motion_option?.animate ?? { opacity: 1 }}
          transition={rest.motion_option?.transition ?? { duration: 0.2 }}
          exit={{ opacity: 0 }}
          ref={setPopper}
          style={{ ...rest.style, ...styles.popper }}
          background_color={tooltip_color}
          is_box_shadow={is_box_shadow}
          {...attributes.popper}
        >
          {contents}
          <SC.Arrow data-popper-arrow className='arrow' ref={setArrow} style={styles.arrow} {...attributes.arrow} />
          {is_close_icon && (
            <SC.IconWrapper className='close' onClick={close}>
              <IconCancel16 color={colors[close_icon_color]} />
            </SC.IconWrapper>
          )}
        </SC.Root>
      </AnimatePresence>
    );
  };

  const renderTooltip = () => {
    if (typeof document === 'undefined') {
      return null;
    }
    const tooltip = getTooltip();
    if (use_portal) {
      return createPortal(tooltip, document.body);
    }
    return tooltip;
  };

  return (
    <>
      {cloneElement(children, { ...children.props, ...fork() })}
      {renderTooltip()}
    </>
  );
};

export default Tooltip;

const SC = {
  Root: styled(motion.div)<{
    background_color: ColorType;
    is_box_shadow: boolean;
  }>`
    background-color: ${({ background_color }) => colors[background_color]};
    color: ${white};
    border-radius: 6px;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    z-index: 310;
    box-shadow: ${({ is_box_shadow }) => is_box_shadow && `0px 2px 4px rgba(0, 0, 0, 0.08)`};

    &[data-popper-placement^='top'] > .arrow {
      bottom: -4px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: -4px;
    }

    &[data-popper-placement^='left'] > .arrow {
      right: -4px;
    }

    &[data-popper-placement^='right'] > .arrow {
      left: -4px;
    }

    &.is_close_icon {
      padding: 9px 8px 9px 12px;
    }
  `,
  Arrow: styled.div`
    visibility: hidden;
    position: absolute;
    background-color: inherit;
    width: 10px;
    height: 10px;

    &::before {
      width: 100%;
      height: 100%;
      visibility: visible;
      content: '';
      position: absolute;
      transform: rotate(45deg);
      background-color: inherit;
      border-radius: 2px;
    }
  `,
  IconWrapper: styled.button`
    padding-left: 6px;
    align-items: center;
    display: flex;
    cursor: pointer;

    svg {
      margin: 0px;
    }
  `,
};
