import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { SearchOptions } from '../store/review';
import { useReviewSearch } from './useReviewSearch';
import { getProductReviewList, getUserCustomFilterInfo } from 'api2';
import { useUserInfo } from 'components/my-page/hooks';

export interface UserCustomFilterInfo_user_custom_filter_info_component_list_ReviewCheckOptionList {
  type: string;
  position: number;
  key: string;
  display_title: string;
  selected_option_id_list: string[];
  option_list: Array<{
    type: string;
    position: number;
    id: string;
    label: string;
  }>;
}

export interface UserCustomFilterInfo_user_custom_filter_info_component_list_ReviewRangeOption {
  type: string;
  position: number;
  key: string;
  display_title: string;
  min: number;
  max: number;
  gap: number;
  measure_unit: string;
  selected_range: number[];
}

export type RangeOptionFilter = UserCustomFilterInfo_user_custom_filter_info_component_list_ReviewRangeOption;
export type CheckOPtionFilter = UserCustomFilterInfo_user_custom_filter_info_component_list_ReviewCheckOptionList;

export const USER_CUSTOM_FILTER_INFO_QUERY_KEY = 'getUserCustomFilterInfo';

interface FetchProps {
  product_id: string;
  size_option_list: SearchOptions[];
  my_custom_filter_checked: boolean;
  is_user_body_info: boolean;
  req?: IncomingMessage;
}
export const fetchUserCustomFilterInfo = async ({
  product_id,
  size_option_list,
  my_custom_filter_checked,
  is_user_body_info,
  req,
}: FetchProps) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const custom_filter_query_input = {
    product_id,
    my_custom_filter_checked,
    option_list: size_option_list,
  };

  const [
    {
      data: { user_custom_filter_info },
    },
    {
      data: { product_review_list },
    },
  ] = await Promise.all([
    getUserCustomFilterInfo({ custom_filter_query_input }, context_request),
    getProductReviewList(
      {
        product_id,
        custom_filter_query_input: { product_id, my_custom_filter_checked: is_user_body_info, option_list: [] },
      },
      context_request,
    ),
  ]);

  return {
    ...user_custom_filter_info,
    my_custom_filter_review_count: product_review_list.total_count,
  };
};

/**
 * 사이즈 맞춤 필터의 정보를 조회하는 hook
 * product에 맞는 컴포넌트 리스트를 리턴합니다.
 * @hook
 */
export const useReviewCustomFilterComponent = () => {
  const {
    query: { catalog_product_id },
  } = useRouter();
  const product_id = String(catalog_product_id);
  const { search_value } = useReviewSearch();
  const { size_option_list, my_custom_filter_checked } = search_value;
  const { user_info } = useUserInfo();
  const is_user_body_info = !!user_info.body;

  const {
    data,
    isInitialLoading: is_loading,
    ...rest
  } = useQuery(
    [USER_CUSTOM_FILTER_INFO_QUERY_KEY, product_id, size_option_list, my_custom_filter_checked, is_user_body_info],
    () => fetchUserCustomFilterInfo({ product_id, size_option_list, my_custom_filter_checked, is_user_body_info }),
  );

  return {
    is_loading,
    component_list: data?.component_list ?? [],
    my_custom_filter_checked: data?.my_custom_filter_checked ?? false,
    my_custom_filter_review_count: data?.my_custom_filter_review_count ?? 0,
    ...rest,
  };
};
