import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import RecentProductButtonCarousel from './RecentProductButtonCarousel';
import RecentRelatedProductList from './RecentRelatedProductList';
import ContentTitle from 'components/base/ContentTitle';
import { useRecentRelatedProductList } from 'components/home/hooks/useRecentRelatedProductList';
import {
  object_idx_by_recent_list_atom,
  recent_related_active_product_id_atom,
  recent_related_carousel_swipe_index_atom,
} from 'components/home/store/trend';
import { bg_secondary, border3, pink_primary, WEB_MAX_WIDTH } from 'styles';
import { Log } from 'util/Tracker';

const SPLIDE_OPTIONS: Options = {
  lazyLoad: true,
  arrows: false,
  pagination: false,
};

interface Props {
  ubl?: Omit<Log, 'category'>;
  custom_css?: SerializedStyles;
}

/**
 * 트랜드탭 - 최근 본 상품의 연관상품
 * @component
 */
const RecentRelatedProductSection: FC<Props> = ({ ubl, custom_css }) => {
  const swipe_ref = useRef<Splide>(null);
  const [swipe_index, setSwipeIndex] = useAtom(recent_related_carousel_swipe_index_atom);
  const [active_id, setActiveId] = useAtom(recent_related_active_product_id_atom);
  const setObjectIdxByRecentList = useUpdateAtom(object_idx_by_recent_list_atom);
  const [is_slide_moving, setIsSlideMoving] = useState(false);
  const [active_index, setActiveIndex] = useState<number>(0);

  const {
    is_loading,
    main_title,
    sub_title,
    recent_related_product_list: { product_list_by_recent_view, recent_view_list },
  } = useRecentRelatedProductList();

  useEffect(() => {
    if (!product_list_by_recent_view.length || is_loading) {
      return;
    }

    if (!active_id) {
      const init_product_id = product_list_by_recent_view[0].recent_view_product_id;
      setActiveId(init_product_id);
      return;
    }

    const selected_index = product_list_by_recent_view.findIndex((item) => item.recent_view_product_id === active_id);
    if (swipe_ref.current && selected_index > -1) {
      swipe_ref.current.go(selected_index + swipe_index);
    }
  }, [is_loading, product_list_by_recent_view, active_id, swipe_index, setActiveId]);

  useEffect(() => {
    if (recent_view_list.length) {
      const object_idx = recent_view_list.findIndex((item) => item.catalog_product_id === active_id);
      setObjectIdxByRecentList(object_idx);
    }
  }, [recent_view_list, active_id, setObjectIdxByRecentList]);

  const active_product_list_length = useMemo(() => {
    return product_list_by_recent_view.filter((item) => item.recent_view_product_id === active_id).length;
  }, [active_id, product_list_by_recent_view]);

  const onSwipe = (index: number) => {
    setIsSlideMoving(true);
    const active_recent_product_id = product_list_by_recent_view[index]?.recent_view_product_id;
    const is_first_group = product_list_by_recent_view[index]?.product_list.some(
      (product) => product.catalog_product_id === active_recent_product_id,
    );

    setActiveIndex(index);
    setSwipeIndex(is_first_group ? 0 : 1);
    setActiveId(active_recent_product_id);
  };

  if (!product_list_by_recent_view.length) {
    return null;
  }

  return (
    <SC.Container css={custom_css}>
      <ContentTitle size='large' title={main_title} description={sub_title} />
      <RecentProductButtonCarousel ubl={ubl} setSlideMoving={setIsSlideMoving} />
      <Splide
        ref={swipe_ref}
        options={SPLIDE_OPTIONS}
        onMove={(_, index) => {
          onSwipe(index);
        }}
        className='swipe'
        onMoved={() => setIsSlideMoving(false)}
        onDragging={() => setIsSlideMoving(true)}
      >
        {product_list_by_recent_view.map((item, index) => {
          const is_real_product_section = Math.abs(active_index - index) < 2;
          return (
            <SplideSlide key={index}>
              {is_real_product_section ? (
                <RecentRelatedProductList
                  product_list={item.product_list}
                  is_loading={is_loading}
                  ubl={ubl}
                  is_moving={is_slide_moving}
                />
              ) : (
                <SC.Loading />
              )}
            </SplideSlide>
          );
        })}
      </Splide>
      <SC.Pagination>
        {[...Array(active_product_list_length)].map((_, index) => {
          return <SC.Dot key={index} is_active={index === swipe_index} />;
        })}
      </SC.Pagination>
    </SC.Container>
  );
};

export default RecentRelatedProductSection;

const SC = {
  Container: styled.section`
    padding: 10px 0 28px;

    .swipe {
      // 상품카드 반응형 width 이용하여 스와이프 영역 높이 계산
      --width: min(100vw, ${WEB_MAX_WIDTH}px);
    }
  `,
  Pagination: styled.div`
    position: relative;
    display: flex;
    height: 14px;
    gap: 4px;
    justify-content: center;
    align-items: center;
  `,
  Dot: styled.span<{ is_active: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${({ is_active }) => (is_active ? pink_primary : border3)};
  `,
  Loading: styled.div`
    width: calc(100% - 16px);
    height: 100%;
    background-color: ${bg_secondary};
    margin: 8px auto;
  `,
};
