import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { colors } from 'styles';

export type ContentReplyMoreButtonProps = {
  reply_uuid: string;
  children: React.ReactNode;
};

export const ContentReplyMoreButton = ({ reply_uuid, children }: ContentReplyMoreButtonProps) => {
  const router = useRouter();
  const uuid = router.query.uuid as string;

  const handleMoveReplyPage = () => {
    if (uuid) {
      router.push(`/events/cms/${uuid}/reply/${reply_uuid}`);
    }
  };

  return (
    <Wrapper>
      <Button className={colors.gray400} onClick={handleMoveReplyPage}>
        {children}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Button = styled.button`
  height: 50px;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.gray900};
  border: 1px solid ${colors.gray400};
  border-radius: 12px;
`;
