import { useAtomValue } from 'jotai';
import { FC, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductCardProps } from 'components/common/ProductCard';
import ProductCardList from 'components/common/ProductCardList';
import { object_idx_by_recent_list_atom, recent_related_carousel_swipe_index_atom } from 'components/home/store/trend';
import { Log } from 'util/Tracker';

interface Props {
  product_list: ProductCardProps[];
  is_loading: boolean;
  ubl?: Omit<Log, 'category'>;
  is_moving: boolean;
}

const RecentRelatedProductList: FC<Props> = ({ product_list, is_loading, ubl, is_moving }) => {
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const swipe_index = useAtomValue(recent_related_carousel_swipe_index_atom);
  const object_idx_by_recent_list = useAtomValue(object_idx_by_recent_list_atom);

  const product_ubl = useMemo(() => {
    if (ubl && !is_moving && in_view) {
      return {
        ...ubl,
        object_section: 'recently_view_related_item_carousel',
        object_type: 'catalog',
        data: { ...ubl.data, clicked_button_object_idx: object_idx_by_recent_list },
      };
    }
  }, [ubl, is_moving, in_view, object_idx_by_recent_list]);

  return (
    <div ref={ref}>
      <ProductCardList
        product_list={product_list}
        product_ubl={product_ubl}
        is_loading={is_loading}
        column={3}
        start_index={swipe_index > 0 ? 6 : 0}
      />
    </div>
  );
};

export default RecentRelatedProductList;
