import { PdpDeepLinkActionHandler } from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { getClickedImage } from '../image-action';
import { getCmsEventLoggers } from '../log';
import { fbkRouter } from 'hooks';

export const handlePdpDeepLink: PdpDeepLinkActionHandler<ModuleRootCustomData> = async (
  action,
  context,
  action_state,
) => {
  logAction([action, context, action_state]);

  const { goPdpPage } = fbkRouter();
  goPdpPage(action.value.product_id);
};

function logAction([action, { metadata, module_hierarchy }]: Parameters<
  PdpDeepLinkActionHandler<ModuleRootCustomData>
>) {
  try {
    const { logPdpDeepLinkByFloatingButton, logPdpDeepLinkByImageModule } = getCmsEventLoggers(metadata);
    const catalog_product_id = action.value.product_id;
    const current_module = module_hierarchy.modules[module_hierarchy.modules.length - 1];

    if (current_module.__type === 'FloatingButton') {
      logPdpDeepLinkByFloatingButton(catalog_product_id);
    }

    if (current_module.__type === 'ImageCarousel' || current_module.__type === 'ImageMap') {
      const clicked_image = getClickedImage(current_module, action);

      if (clicked_image) {
        logPdpDeepLinkByImageModule({
          component_type: current_module.__type,
          component_uuid: current_module.uuid,
          catalog_product_id,
          image_id: clicked_image.id,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}
