import { ProductTabModuleProps, useTabLayout } from '@croquiscom-pvt/event-modules';
import { cx } from '@emotion/css';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import Chip from 'components/base/Chip';
import { HScrollBox } from 'components/common/HScrollBox';
import ProductGrid from 'components/content-editor/ProductGrid';
import { typography } from 'styles/font';
import { useCmsEventLoggers } from 'util/content-editor/log';

interface ButtonColors {
  normal: SerializedStyles;
  active: SerializedStyles;
}

export const ProductTab = ({
  products_preset_uuid,
  columns,
  group_header_data,
  title,
  active_background_color,
  active_color,
  normal_background_color,
  normal_color,
}: ProductTabModuleProps) => {
  const button_holder_ref = useRef<HTMLDivElement | null>(null);
  const button_ref = useRef<Array<HTMLButtonElement | null>>([]);
  const section_ref = useRef<Array<HTMLDivElement | null>>([]);
  const [shown_list, setShownList] = useState<boolean[]>([]);

  const { logTabClick } = useCmsEventLoggers();

  const button_colors: ButtonColors = {
    normal: css`
      background-color: ${normal_background_color};
      color: ${normal_color};
    `,
    active: css`
      background-color: ${active_background_color};
      color: ${active_color};
      border: 1px solid ${active_background_color};
    `,
  };

  let first_shown_index = shown_list.findIndex((shown) => shown === true);
  first_shown_index = first_shown_index === -1 ? 0 : first_shown_index;

  useEffect(() => {
    if (button_holder_ref.current && button_ref.current[first_shown_index]) {
      button_holder_ref.current.scrollTo({
        behavior: 'smooth',
        left: button_ref.current[first_shown_index]?.offsetLeft,
      });
    }
  }, [first_shown_index]);

  const updateShown = (shown: boolean, index: number) => {
    setShownList((prev) => {
      const next = [...prev];
      next[index] = shown;
      return next;
    });
  };

  const { tab_header_top, waypoint_top_offset, scrollToTarget } = useTabLayout();

  return (
    <>
      <SC.SectionTitle>{title}</SC.SectionTitle>
      <SC.TabContainer ref={button_holder_ref} style={{ top: tab_header_top }}>
        <SC.Tab role='tablist'>
          {group_header_data?.map((tab_title, i) => (
            <SC.TabButton key={i} colors={button_colors}>
              <Chip
                className={cx(['tab_button', { active: first_shown_index === i }])}
                text={tab_title}
                role='tab'
                active={first_shown_index === i}
                onClick={() => {
                  scrollToTarget(section_ref.current[i], { behavior: 'smooth' });
                  logTabClick({
                    tab_title,
                    tab_index: i,
                    tab_type: 'product_tab',
                  });
                }}
                ref={(element) => (button_ref.current[i] = element)}
              />
            </SC.TabButton>
          ))}
        </SC.Tab>
      </SC.TabContainer>
      {group_header_data?.map((header_data, group_index) => (
        <SC.Section key={group_index}>
          <Waypoint
            topOffset={waypoint_top_offset}
            onPositionChange={({ currentPosition }) => updateShown(currentPosition === Waypoint.inside, group_index)}
          >
            <SC.WayPointView />
          </Waypoint>
          <SC.SectionAnchor
            ref={(element) => {
              section_ref.current[group_index] = element;
            }}
          />
          <SC.SectionTitle>{header_data}</SC.SectionTitle>
          <ProductGrid columns={columns ?? 2} products_preset_uuid={products_preset_uuid} group_index={group_index} />
        </SC.Section>
      ))}
    </>
  );
};

const SC = {
  TabContainer: styled.div`
    position: relative;
    position: sticky;
    z-index: 4;
    background-color: white;
    padding: 8px 0;
  `,
  Tab: styled(HScrollBox)`
    height: 50px;
    display: flex;
    align-items: center;
  `,
  TabButton: styled.li<{ colors: ButtonColors }>`
    .tab_button {
      ${({ colors }) => colors.normal}
      &.active {
        ${({ colors }) => colors.active}
      }
    }
  `,
  Section: styled.div`
    position: relative;
  `,
  WayPointView: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
  `,
  SectionAnchor: styled.div`
    position: relative;
  `,
  SectionTitle: styled.div`
    color: #212529;
    padding: 16px;
    ${typography.title4};
  `,
};
