import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { FC, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { IconCoupon20, IconCoupon60, IconDownload16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { useMdPickProductList } from 'components/home/hooks/useMdPickProductList';
import { useUserInfo } from 'components/my-page/hooks';
import { CONFIRM_ID, useBottomDialog } from 'context/ButtomDialogContext';
import { useFbkRouter } from 'hooks';
import { useCouponIssueStatus } from 'hooks/queries';
import useIssueCoupon, { COUPON_ISSUE_MSG } from 'queries/coupon/useIssueCoupon';
import { gray800, pink300, white } from 'styles';
import config from 'util/config';
import { Toast } from 'util/Toast';
import { Log, useTracker } from 'util/Tracker';

dayjs.extend(isBetween);
interface Props {
  ubl: Pick<Log, 'navigation' | 'object_section'>;
}
const MdCoupon: FC<Props> = ({ ubl }) => {
  const [is_issued, setIsIssued] = useState(false);
  const tracker = useTracker();
  const { ref: coupon_ref, inView: in_view } = useInView({ threshold: 0.5 });
  const { is_login } = useUserInfo();
  const { coupon_policy_id } = useMdPickProductList();
  const { is_issue_policy_id_list } = useCouponIssueStatus(
    {
      policy_id_list: coupon_policy_id ? [coupon_policy_id] : undefined,
    },
    { enabled: is_login },
  );
  const { openLoginDialog } = useLoginDialog();
  const { mutate: issue_coupon } = useIssueCoupon();
  const { push } = useFbkRouter();
  const { open } = useBottomDialog();

  const common_ubl = useMemo(
    () => ({ ...ubl, object_type: 'button', object_id: 'mp_pick_coupon', data: { coupon_id: coupon_policy_id } }),
    [ubl, coupon_policy_id],
  );

  useEffect(() => {
    if (in_view) {
      tracker.addLog({ category: 'impression', ...common_ubl });
    }
  }, [common_ubl, tracker, in_view]);

  useEffect(() => setIsIssued(is_issue_policy_id_list), [is_issue_policy_id_list, setIsIssued]);

  const handleIssueCouponClick = async () => {
    if (!is_login) {
      openLoginDialog();
      return;
    }

    tracker.addLog({ category: 'click', ...common_ubl });

    issue_coupon(
      { type: 'coupon', id: coupon_policy_id ?? '' },
      {
        onSuccess: async (status) => {
          if ('SUCCEEDED' !== status) {
            Toast.show(COUPON_ISSUE_MSG[status]);
            return;
          }
          setIsIssued(true);
          const id = await open({
            contents: {
              title: `신상탭 전용 15% 할인 쿠폰이\n지급되었어요.`,
              text: '다음주 수요일 오전 11시 59분까지 사용할 수 있어요.',
              icon: <IconCoupon60 />,
              type: 'icon',
              src: `${config.s3_cf_url}/common/CouponPink60.svg`,
            },
            buttons: [
              { id: CONFIRM_ID.OK, size: 'large', variant: 'tertiary_gray', children: '쿠폰 확인하기' },
              { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'primary', children: '신상탭 구경하기' },
            ],
            use_native_sheet: true,
            sheet_type: 'icon',
            src: `${config.s3_cf_url}/common/CouponPink60.svg`,
          });

          if (id === CONFIRM_ID.OK) {
            push('/coupons');
          }
        },
      },
    );
  };

  return (
    <SC.Coupon ref={coupon_ref}>
      <div className='item'>
        <IconCoupon20 />
        <Text variant='title5_bold' color='white' ml={8}>
          MD 추천상품 <b>15%</b> 쿠폰
        </Text>
      </div>
      <div className={cx('item', { opacity: is_issued })} onClick={handleIssueCouponClick}>
        <Text variant='body4_bold' color='white' mr={4}>
          {is_issued ? '받기완료' : '쿠폰받기'}
        </Text>
        <IconDownload16 color={white} />
      </div>
    </SC.Coupon>
  );
};

export default MdCoupon;

const SC = {
  Coupon: styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: ${gray800};
    border-radius: 4px;
    padding: 13px 16px 14px;

    b {
      color: ${pink300};
      font-weight: 600;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .opacity {
      opacity: 0.4;
    }
  `,
};
