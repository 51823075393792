import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { IconSizeInfo60 } from 'components/base/Icon';
import { DialogOption } from 'components/common/BottomSheet';
import { useUserInfo } from 'components/my-page/hooks';
import { useBottomDialog } from 'context/ButtomDialogContext';
import { useFbkRouter } from 'hooks';
import { useTracker } from 'util/Tracker';

enum CONFIRM_ID {
  CANCEL = 'CANCEL',
  INPUT_SIZE = 'INPUT_SIZE',
}

export const INPUT_BODY_SIZE_NOTI_REMIND_DATE = 'INPUT_BODY_SIZE_NOTI_REMIND_DATE';

const common_ubl = {
  navigation: 'product_detail_review',
  object_section: 'new_user_size_request_modal',
};

const bottom_sheet_options: DialogOption = {
  contents: {
    icon: <IconSizeInfo60 />,
    title: '비슷한 사이즈의 리뷰를 보고싶다면 \n사이즈 정보를 입력해 주세요',
    text: '사이즈를 입력하면 ‘내 사이즈 리뷰만’ 기능을 통해서 \n 내 사이즈에 속하는 회원들의 리뷰만 골라볼 수 있어요.',
  },
  buttons: [
    { id: CONFIRM_ID.CANCEL, children: '다음에 하기', size: 'large', variant: 'tertiary_color' },
    { id: CONFIRM_ID.INPUT_SIZE, children: '사이즈 입력하기', size: 'large' },
  ],
  disable_outside_click: true,
};

/**
 * 사이즈 입력 유도 바텀시트를 표시하는 hook
 * @component
 */
export const useBodySizeNoti = () => {
  const { push } = useFbkRouter();
  const tracker = useTracker();
  const { is_login } = useUserInfo();
  const { open } = useBottomDialog();
  const { openLoginDialog } = useLoginDialog();

  const openInputSizeBottom = useCallback(
    async (handleOpenSheet?: (open: boolean) => void) => {
      tracker.addLog({
        ...common_ubl,
        category: 'impression',
        data: { is_opened: false },
      });

      const result_id = await open(bottom_sheet_options);
      if (result_id === CONFIRM_ID.CANCEL) {
        tracker.addLog({
          ...common_ubl,
          category: 'click',
          object_type: 'button',
          object_id: 'cancel',
        });

        localStorage.setItem(INPUT_BODY_SIZE_NOTI_REMIND_DATE, dayjs().add(30, 'day').format('YYYY-MM-DD HH:mm'));
        handleOpenSheet?.(true);
        return;
      }

      if (!is_login) {
        return openLoginDialog();
      }

      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_type: 'button',
        object_id: 'enter_size',
      });

      push('/my-page/size-info');
    },
    [is_login, tracker, push, open, openLoginDialog],
  );

  return {
    openInputSizeBottom,
  };
};
