import { ContentEditorComponentType, ContentEditorItemPayload } from '@croquiscom-pvt/event-modules';
import { QueryClient } from '@tanstack/react-query';
import { NextPageContext } from 'next';
import { ContentEditorParsedPayload } from '../content-editor-data';
import { getQueryKey, ProductsPresetData } from './products-preset-data';
import { getPrefetchedCmsProductsPresetProductCardItemList } from 'api2';

const PREFETCH_ITEM_LIST_LIMIT = 24;

export type ProductModule = Extract<
  ContentEditorComponentType<void>,
  { __type: 'ProductList' } | { __type: 'ProductTab' }
>;

export function getProductModuleList(component_map: ContentEditorItemPayload['component_map']) {
  return Object.keys(component_map)
    .filter((uuid) => {
      const current_module = component_map[uuid];
      const is_product_module = current_module.__type === 'ProductList' || current_module.__type === 'ProductTab';

      return is_product_module && current_module.products_preset_uuid;
    })
    .map((uuid) => component_map[uuid] as ProductModule);
}

export async function prefetchAllGroupsData(
  { products_preset_uuid }: ProductModule,
  { query_client, context }: { query_client: QueryClient; context: NextPageContext },
) {
  const { groups, item_list } = (
    await getPrefetchedCmsProductsPresetProductCardItemList(
      { products_preset_uuid, limit: PREFETCH_ITEM_LIST_LIMIT },
      { context: context.req },
    )
  ).data.cms_products_preset_product_card_item_list;

  return Promise.all(
    groups.map(({ total_count }, i) =>
      query_client.prefetchQuery<ProductsPresetData>(getQueryKey(products_preset_uuid, i), () => ({
        total_count,
        item_list: i === 0 ? item_list.filter((_, i) => i < PREFETCH_ITEM_LIST_LIMIT) : [],
        end_cursor: null,
        has_next_page: false,
        is_prefetched_data: true,
      })),
    ),
  );
}

export async function prefetchProductsPresetDataQuery(
  data: ContentEditorParsedPayload,
  context: NextPageContext,
  query_client: QueryClient,
) {
  const product_module_list = getProductModuleList(data.content_editor_json.component_map);
  return Promise.all(product_module_list.map((data) => prefetchAllGroupsData(data, { query_client, context })));
}
