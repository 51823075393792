import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { ROOT_PRODUCT_CATEGORY } from 'hooks/queries';

export enum BestSubTab {
  PRODUCT = 'product',
  STORE = 'store',
}

/**
 * 홈 > Best tab 에서 사용되는 쿼리를 파싱하여 반환 하거나 변경하는 기능을 지원 합니다.
 * 쿼리값을 변경 할 경우, replace 로 동작 합니다.
 * @hook
 */
export const useHomeBestTabQuery = () => {
  const { query } = useRouter();
  const { tab, category } = parseBestTabQuery(query);

  return {
    tab,
    category,
  };
};

const parseBestTabQuery = (query: ParsedUrlQuery) => {
  const { tab, category } = query;
  return {
    tab: tab as string,
    category: String(category || '') || ROOT_PRODUCT_CATEGORY.id,
  };
};
