import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MdCoupon from './MdCoupon';
import Text from 'components/base/Text';
import ProductCardList from 'components/common/ProductCardList';
import { useMdPickProductList } from 'components/home/hooks/useMdPickProductList';
import { Log } from 'util/Tracker';

const COMMON_UBL: Pick<Log, 'navigation' | 'object_section'> = {
  navigation: 'home_curation',
  object_section: 'md_pick_plp',
};

const MdProductList = () => {
  const { md_product_list, is_loading, has_next_page, fetchNextPage } = useMdPickProductList();

  return (
    <SC.Container>
      <SC.TopSection>
        <SC.TitleInfo>
          <Text variant='small1_medium' color='pink_inactive' as='p' mb={8} alignment='center'>
            당신을 위한 수요일 MD추천!
          </Text>
          <Text variant='title2_bold' color='white' alignment='center'>
            패바카 MD가 고르고 골랐어요,
            <br />
            할인에 쿠폰까지 드려요!
          </Text>
        </SC.TitleInfo>
        <MdCoupon ubl={COMMON_UBL} />
      </SC.TopSection>
      <ProductCardList
        custom_css={product_list_css}
        product_list={md_product_list}
        is_loading={is_loading}
        product_ubl={COMMON_UBL}
        icon_bottom_left={{ type: 'md' }}
        has_next_page={has_next_page}
        onIntersect={fetchNextPage}
      />
      <SC.ContinueInfo>
        <Text variant='small1_medium' color='gray_tertiary'>
          다음주 수요일 MD PICK 상품도 기대해 주세요.
        </Text>
      </SC.ContinueInfo>
    </SC.Container>
  );
};

export default MdProductList;

const product_list_css = css`
  margin-top: -130px !important;
  background-color: transparent;
`;

const SC = {
  Container: styled.section``,
  TopSection: styled.div`
    padding: 40px 16px 0;
    height: 354px;
    background: linear-gradient(
      180deg,
      #242424 77.67%,
      rgba(36, 36, 36, 0.88) 81.33%,
      rgba(36, 36, 36, 0.75) 84.08%,
      rgba(36, 36, 36, 0) 100%
    );
  `,
  TitleInfo: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  `,
  ContinueInfo: styled.div`
    padding: 12px 16px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
