import { StoreGridModuleProps } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { StoreGridItem } from './StoreGridItem';
import { useStorePresetItemList } from 'util/content-editor/store-preset-data/store-preset-data';

export type StoreGridWithValidPropsProps = Omit<StoreGridModuleProps, 'store_preset_uuid'> & {
  store_preset_uuid: string;
};

export const StoreGridWithValidProps = ({
  store_preset_uuid,
  background_color,
  border_color,
  color,
  border_radius,
  columns,
  padding_top,
  padding_bottom,
}: StoreGridWithValidPropsProps) => {
  const { data } = useStorePresetItemList({ store_preset_uuid });

  return (
    <Wrapper
      columns={columns}
      style={{ backgroundColor: background_color, paddingTop: padding_top, paddingBottom: padding_bottom }}
    >
      {data?.map((data) => (
        <StoreGridItem
          key={data.id}
          data={data}
          color={color}
          border_color={border_color}
          border_radius={border_radius}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(3, 80px);
  gap: 30px 10px;
  justify-content: center;

  @media screen and (min-width: 370px) {
    grid-template-columns: repeat(${(props) => props.columns}, 80px);
  }
`;
