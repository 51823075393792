import { ScrollableTabAreaModuleProps, useTabLayout } from '@croquiscom-pvt/event-modules';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

export const ScrollableTabArea = ({
  active_color,
  normal_color,
  background_color,
  tab_contents,
  children,
}: ScrollableTabAreaModuleProps) => {
  const button_holder_ref = useRef<HTMLDivElement | null>(null);
  const button_ref = useRef<Array<HTMLDivElement | null>>([]);
  const section_ref = useRef<Array<HTMLDivElement | null>>([]);
  const [shown_list, setShownList] = useState<boolean[]>([]);

  const first_shown_index = shown_list.findIndex((shown) => shown === true);

  useEffect(() => {
    if (button_holder_ref.current && button_ref.current[first_shown_index]) {
      button_holder_ref.current.scrollTo({
        behavior: 'smooth',
        left: button_ref.current[first_shown_index]?.offsetLeft,
      });
    }
  }, [first_shown_index]);

  const updateShown = (shown: boolean, index: number) => {
    setShownList((prev) => {
      const next = [...prev];
      next[index] = shown;
      return next;
    });
  };

  const { tab_header_top, waypoint_top_offset, scrollToTarget } = useTabLayout();

  return (
    <>
      {tab_contents.length > 0 && (
        <TabContainer
          ref={button_holder_ref}
          role='sectionlist'
          css={css({ top: tab_header_top, backgroundColor: background_color })}
        >
          {tab_contents.map(({ title: tab_title, uuid }, i) => {
            const active = first_shown_index === i;
            return (
              <TabButton
                role='section'
                key={uuid}
                css={css({ color: active ? active_color : normal_color })}
                active={active}
                onClick={() => scrollToTarget(section_ref.current[i], { behavior: 'smooth' })}
                ref={(elem) => (button_ref.current[i] = elem)}
              >
                {tab_title}
              </TabButton>
            );
          })}
        </TabContainer>
      )}
      {React.Children.map(children, (children, i) => (
        <Section key={i}>
          <Waypoint
            topOffset={waypoint_top_offset}
            onPositionChange={({ currentPosition }) => updateShown(currentPosition === Waypoint.inside, i)}
          >
            <WaypointView />
          </Waypoint>
          <SectionAnchor ref={(elem) => (section_ref.current[i] = elem)} />
          {children}
        </Section>
      ))}
    </>
  );
};

const TabContainer = styled.div`
  position: sticky;
  z-index: 299;
  overflow: auto;
  display: flex;
  flex-direction: row;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabButton = styled.div<{ active: boolean }>`
  position: relative;
  cursor: pointer;
  flex: 1 0 auto;
  min-width: 64px;
  height: 52px;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  line-height: 1.2;
  padding-top: 17px;
  white-space: nowrap;

  &:after {
    display: block;
    position: absolute;
    right: 0px;
    bottom: 8px;
    left: 0px;
    transition: opacity 0.23s cubic-bezier(0, 0.55, 0.45, 1);
    margin: auto;
    border-radius: 3px;
    background-color: currentColor;
    width: 6px;
    height: 6px;
    content: '';
    opacity: ${(props) => (props.active ? 1 : 0)};
  }
`;

const Section = styled.div`
  position: relative;
`;

const WaypointView = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
`;

const SectionAnchor = styled.div`
  position: relative;
`;
