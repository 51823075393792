import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';
import isNil from 'lodash-es/isNil';
import isUndefined from 'lodash-es/isUndefined';
import { FC, MouseEvent, useEffect, useMemo, useRef } from 'react';
import Item from './item';
import { useRecentRelatedProductList } from 'components/home/hooks/useRecentRelatedProductList';
import {
  recent_related_active_product_id_atom,
  recent_related_carousel_swipe_index_atom,
} from 'components/home/store/trend';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { Log } from 'util/Tracker';

export const RELATED_SWIPE_KEY = 'related_product_swipe';

interface Props {
  ubl?: Omit<Log, 'category'>;
  setSlideMoving: (value: boolean) => void;
}

/**
 * 트랜드탭 - 최근 본 상품의 연관상품 > 최근 본 상품 버튼 캐러셀
 * @component
 */
const RecentProductButtonCarousel: FC<Props> = ({ ubl, setSlideMoving }) => {
  const [active_id, setActiveId] = useAtom(recent_related_active_product_id_atom);
  const setSwipeIndex = useSetAtom(recent_related_carousel_swipe_index_atom);
  const button_list_ref = useRef<HTMLUListElement>(null);
  const button_item_ref = useRef<Array<HTMLElement | null>>([]);

  const {
    recent_related_product_list: { recent_view_list },
  } = useRecentRelatedProductList();

  const selected_idx = useMemo(() => {
    return recent_view_list.findIndex((item) => item.catalog_product_id === active_id);
  }, [active_id, recent_view_list]);

  useEffect(() => {
    if (button_list_ref.current && !isUndefined(button_item_ref.current[selected_idx])) {
      const offset_left = Number(button_item_ref.current[selected_idx]?.offsetLeft);
      const offset_width = Number(button_item_ref.current[selected_idx]?.offsetWidth);

      button_list_ref.current.scrollTo({ left: offset_left - offset_width, behavior: 'smooth' });
    }
  }, [selected_idx]);

  const product_ubl = useMemo(() => {
    if (ubl) {
      return {
        ...ubl,
        object_section: 'recently_view_related_item_carousel',
        object_type: 'button',
      };
    }
  }, [ubl]);

  const handleItemClick = (e: MouseEvent<HTMLElement>, selected_id: string) => {
    if (!isNil(e.currentTarget)) {
      const { offsetLeft: offset_left, offsetWidth: offset_width } = e.currentTarget;
      button_list_ref.current?.scrollTo({
        left: offset_left - offset_width,
        behavior: 'smooth',
      });
    }

    setSlideMoving(true);
    setSwipeIndex(0);
    setActiveId(selected_id);
  };

  return (
    <SC.Container>
      <SC.ButtonCarousel ref={button_list_ref}>
        {recent_view_list.map((item, index) => (
          <Item
            ref={(element) => (button_item_ref.current[index] = element)}
            active_id={active_id}
            product={item}
            index={index}
            product_ubl={product_ubl}
            key={item.catalog_product_id ?? ''}
            onClick={handleItemClick}
          />
        ))}
      </SC.ButtonCarousel>
    </SC.Container>
  );
};

export default RecentProductButtonCarousel;

const SC = {
  Container: styled.section`
    position: relative;
  `,
  ButtonCarousel: styled.ul`
    ${invisible_scroll_bar}
    display: flex;
    padding: 8px 16px;
    gap: 10px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  `,
};
