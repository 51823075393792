import { css } from '@emotion/react';
import styled from '@emotion/styled';

export interface BreakPoint {
  column: number;
  minWidth: number;
  gap?: string;
}

export type BreakPoints = BreakPoint[];

export interface GridTileProps {
  /** @default 2 */
  column?: number;
  /** @default '16px' */
  gap?: string | number;
  breakpoint?: BreakPoints;
}

const column_value = (props: GridTileProps) => props.column;
const gap_value = (props: GridTileProps) => (typeof props.gap === 'string' ? props.gap : props.gap + 'px');
const break_point = ({ breakpoint }: GridTileProps) => {
  return breakpoint?.map(
    ({ column, minWidth, gap }) => css`
      @media screen and (min-width: ${minWidth}px) {
        grid-template-columns: repeat(${column}, 1fr);
        grid-gap: ${gap};
      }
    `,
  );
};

/**
 * - Grid를 편하게 사용하려고 만든 컴포넌트
 */
const GridTile = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(${column_value}, 1fr);
  grid-gap: ${gap_value};
  width: 100%;
  min-width: 0px;
  > * {
    min-width: 0;
  }
  ${break_point}
`;

GridTile.defaultProps = {
  column: 2,
  gap: '16px',
};

export default GridTile;
