import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import chunk from 'lodash-es/chunk';
import isEmpty from 'lodash-es/isEmpty';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { VirtialProductListProps } from '..';
import { virtual_product_list_scroll_index_atom } from '../../store/product';
import Row from '../Row';
import Section from '../Section';
import ProductCard, { ProductCardProps, ProductCardSize } from 'components/common/ProductCard';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface Props extends VirtialProductListProps {}

/**
 * 상품카드 가상 리스트 구현체
 * @component
 */
const VirtualList: FC<Props> = ({
  key_name,
  product_list,
  use_rank,
  has_next_page,
  margin,
  padding,
  use_search,
  use_search_tooltip,
  onFetchNextPage,
  onProductClick,
  product_ubl,
  only_csr = false,
  start_index = 0,
  row_css,
  afterRowItem,
  beforeRowItem,
  column = 2,
}) => {
  const ref = useRef<VirtuosoHandle>(null);
  const footer_ref = useRef<HTMLDivElement>(null);
  const [is_virtuoso_load, setIsVirtuosoLoad] = useState(false);
  const [virtual_product_list_scroll_index, setVirtualProductListScrollIndex] = useAtom(
    virtual_product_list_scroll_index_atom(key_name),
  );
  const size: ProductCardSize = column === 3 ? 'small' : 'medium';
  useIntersectionObserver(
    {
      target: footer_ref,
      enabled: has_next_page,
      rootMargin: '100px',
      onIntersect: () => {
        onFetchNextPage?.();
      },
    },
    [has_next_page],
  );

  /*
   * react-virtuoso 렌더링 트릭 코드 (이슈: https://github.com/petyosi/react-virtuoso/issues/734)
   * virtuoso list 렌더 도중 footer 영역이 그대로 노출되어 nextPage 무한 호출 되는 이슈 처리를 위함
   */
  useEffect(() => {
    if (product_list.length > 0 && is_virtuoso_load === false) {
      setTimeout(() => setIsVirtuosoLoad(true), 200);
    }
  }, [product_list, is_virtuoso_load]);

  const serialized_product_list = useMemo(
    () => getSerializedProductList(product_list, start_index, column),
    [product_list, start_index, column],
  );

  const handleRowInView = useCallback(
    (index: number) => setVirtualProductListScrollIndex(index - 1 < 0 ? 0 : index - 1),
    [setVirtualProductListScrollIndex],
  );

  const is_show_footer = has_next_page && is_virtuoso_load && virtual_product_list_scroll_index > 0;
  /*
   * react-virtuoso 렌더링 트릭 코드 (이슈: https://github.com/petyosi/react-virtuoso/issues/734)
   * virtuoso list 렌더 최초 시점에 height 영역을 못잡아 상품 하단 요소가 노출되었다 사라지는 깜빡이는 현상 처리 위함
   */
  const virtuoso_init_render_style = is_virtuoso_load ? {} : { minHeight: '100vh' };

  return (
    <Section margin={margin} padding={padding} style={virtuoso_init_render_style}>
      <Virtuoso
        useWindowScroll
        ref={ref}
        style={{ height: '100%' }}
        data={serialized_product_list}
        totalCount={serialized_product_list.length}
        overscan={2500}
        initialTopMostItemIndex={virtual_product_list_scroll_index}
        // Virtuoso List 타입정의 이슈로 List 타입 any 처리.
        components={{ Item: SC.Item, List: SC.List as any }}
        itemContent={(index, product_group) => {
          return (
            <>
              {beforeRowItem && beforeRowItem(index)}
              <Row key={index} index={index} css={row_css} onView={handleRowInView} column={column}>
                {product_group.item.map((product) => {
                  return (
                    <ProductCard
                      key={`${product.catalog_product_id},${product.index}`}
                      {...product}
                      size={size}
                      only_csr={only_csr}
                      ubl={product_ubl}
                      rank={use_rank ? product.index + 1 : undefined}
                      use_search={use_search}
                      use_search_tooltip={use_search_tooltip}
                      onProductClick={onProductClick}
                    />
                  );
                })}
              </Row>
              {afterRowItem && afterRowItem(index)}
            </>
          );
        }}
      />
      {is_show_footer && (
        <div ref={footer_ref}>
          <ul>
            <Row.Skeleton css={row_css} column={column} size={size} />
          </ul>
        </div>
      )}
    </Section>
  );
};

export default VirtualList;

const getSerializedProductList = (product_list: ProductCardProps[], start_index: number, column: number) => {
  if (isEmpty(product_list)) {
    return [];
  }
  const product_list_with_index = product_list.map((product, i) => ({ ...product, index: start_index + i }));
  return chunk(product_list_with_index, column).map((item, i) => ({ item, index: i + 1 }));
};

const SC = {
  List: styled.ul``,
  Item: styled.li``,
};
