import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

import { Button } from '../../../../base/Button';
import Text from '../../../../base/Text';
import BottomDialog from '../../../../toolbox/BottomDialog';
import config from 'util/config';

const first_row = [
  {
    guidance: '옷이 정면에서 잘 보이면 좋아요.',
    image: `${config.s3_cf_url}/search/image_search_guidance/good@x3.png`,
  },
  {
    guidance: '옷이 잘려보이거나, 다른 것이 겹쳐지면 검색이 어려워요.',
    image: `${config.s3_cf_url}/search/image_search_guidance/bad_1@x3.png`,
  },
];

const second_row = [
  {
    guidance: '악세사리, 가방, 신발 등은 검색이 어려워요.',
    image: `${config.s3_cf_url}/search/image_search_guidance/bad_2@x3.png`,
  },
  {
    guidance: '수영복, 속옷 등은 검색이 어려워요.',
    image: `${config.s3_cf_url}/search/image_search_guidance/bad_3@x3.png`,
  },
];

const guidance_matrix = [
  first_row,
  second_row,
];

interface Props {
  active?: boolean;
  close?: () => void;
}

const ImageSearchGuidance: FC<Props> = ({ active, close }) => {
  return (
    <BottomDialog css={css`padding-top: 20px;`} active={active}>
      <SC.TitleSection>
        <Text variant='title3_bold' color='gray_primary'>
          정확한 검색을 위한 이미지 업로드 가이드
        </Text>
      </SC.TitleSection>
      <SC.CardSection>
        {guidance_matrix.map((row, i) => {
          return (
            <SC.Row key={i}>
              {row.map(({ guidance, image }, j) => {
                return (
                  <SC.Card key={`${i}${j}`}>
                    <Text variant='body4_medium' color='gray_secondary' mb={10}>
                      {guidance}
                    </Text>
                    <SC.Picture>
                      <SC.Thumbnail src={image} alt='업로드 예시 이미지' />
                    </SC.Picture>
                  </SC.Card>
                );
              })}
            </SC.Row>
          );
        })}
      </SC.CardSection>
      <SC.ButtonSection>
        <Button size='large' fill variant='tertiary_gray' onClick={close}>
          <Text variant='title4_medium' color='gray_active'>
            확인
          </Text>
        </Button>
      </SC.ButtonSection>
    </BottomDialog>
  );
};

export default ImageSearchGuidance;

const SC = {
  TitleSection: styled.section`
    padding: 0 16px 8px;
    text-align: left;
  `,
  CardSection: styled.section`
    padding: 8px 16px 0;
  `,
  Row: styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  `,
  Card: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-of-type {
      margin-right: 14px;
    }
  `,
  Picture: styled.picture`
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;

    @supports not (aspect-ratio: 1) {
      position: relative;
      padding-top: 100%;
    }
  `,
  Thumbnail: styled.img`
    @supports not (aspect-ratio: 1) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  `,
  ButtonSection: styled.section`
    padding: 8px 16px;
  `,
};
