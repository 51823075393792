// @ts-nocheck
import { ContentEditorModuleMap } from '@croquiscom-pvt/event-modules';
import { BasicTimer } from 'components/content-editor/BasicTimer';
import { BoxItem } from 'components/content-editor/BoxItem';
import { ContentReply } from 'components/content-editor/ContentReply';
import { Dialog } from 'components/content-editor/Dialog';
import { FloatingButton } from 'components/content-editor/FloatingButton';
import { ImageCarousel } from 'components/content-editor/ImageCarousel';
import { ProductList } from 'components/content-editor/ProductList';
import { ProductTab } from 'components/content-editor/ProductTab';
import { RelatedContent } from 'components/content-editor/RelatedContent';
import { ScrollableTabArea } from 'components/content-editor/ScrollableTabArea';
import { StoreGrid } from 'components/content-editor/StoreGrid';
import { TabArea } from 'components/content-editor/TabArea';
import { TextEditor } from 'components/content-editor/TextEditor';
import { ToggleArea } from 'components/content-editor/ToggleArea';

export const module_map: Partial<ContentEditorModuleMap> = {
  ProductList,
  ProductTab,
  ImageCarousel,
  ToggleArea,
  ScrollableTabArea,
  TabArea,
  StoreGrid,
  RelatedContent,
  TextEditor,
  BasicTimer,
  FloatingButton,
  Dialog,
  ContentReply,
  BoxItem,
};
