import styled from '@emotion/styled';

import { white } from '../../../../styles';
import Cancel16 from 'components/base/Icon/Cancel16';

const DeleteIcon = () => {
  return (
    <SC.Root>
      <SC.IconContainer>
        <Cancel16 color={white} />
      </SC.IconContainer>
    </SC.Root>
  );
};

export default DeleteIcon;

const SC = {
  Root: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px 0 0;

    &:active {
      background-color: transparent;
    }
  `,
  IconContainer: styled.div`
    padding-bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
  `,
};
