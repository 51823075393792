import { ContentShareInfo, toastMessage, UrlShareActionHandler } from '@croquiscom-pvt/event-modules';
import copy from 'copy-to-clipboard';
import { ModuleRootCustomData } from '../custom-data';

export async function shareWithNavigator(url: string, share_info: Pick<ContentShareInfo, 'title' | 'description'>) {
  if (typeof window.navigator.share === 'function') {
    return window.navigator.share({ url, title: share_info.title ?? '', text: share_info.description ?? '' });
  }

  copy(url);
}

export const handleUrlShare: UrlShareActionHandler<ModuleRootCustomData> = async (_, context) => {
  const { metadata } = context;
  return shareWithNavigator(metadata.page_url, { title: metadata.title, description: metadata.description }).then(() =>
    toastMessage('URL 복사가 완료되었습니다'),
  );
};
