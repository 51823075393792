import styled from '@emotion/styled';
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import chunk from 'lodash-es/chunk';
import { ComponentProps, FC, HTMLAttributes } from 'react';
import ContentTitle from 'components/base/ContentTitle';
import Link from 'components/base/Link';
import Tabs from 'components/base/Tabs';
import Text from 'components/base/Text';
import { ProductCardProps, ProductCardUbl } from 'components/common/ProductCard';
import ProductCardList from 'components/common/ProductCardList';
import ProductCarouselList from 'components/common/ProductCarouselList';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { ColorType, border3, pink_primary } from 'styles';

const SPLIDE_OPTIONS: Options = {
  lazyLoad: true,
  arrows: false,
  pagination: false,
};

export type ProductExposeType = 'list' | 'carousel' | 'carousel_double' | 'swipe';

interface Props extends HTMLAttributes<HTMLElement> {
  /**
   * 섹션 제목을 정의합니다.
   */
  title: string;
  /**
   * 섹션에 대한 설명이 필요한 경우 정의합니다.
   */
  description?: string;
  /**
   * 탭 컴포넌트 사용시 정의합니다.
   */
  tab_info?: Pick<ComponentProps<typeof Tabs>, 'tab_list' | 'selected_tab_id' | 'onClickTab' | 'ubl'>;
  /**
   * 우측 섹션 정보를 작성합니다.
   */
  right_info?: {
    /**
     * 노출될 텍스트를 정의합니다.
     */
    text: string;
    /**
     * 우측 텍스트 클릭시 링크이동이 필요한 경우 이를 정의합니다.
     */
    href?: string;
    /**
     * 노출 컬러를 설정 합니다.
     */
    color?: ColorType;
    /**
     * 우측 노출 텍스트 클릭시에 대한 이벤트 콜백입니다.
     */
    onClick?: () => void;
  };
  /**
   * 상품리스트를 정의합니다.
   */
  product_list: ProductCardProps[];
  /**
   * 상품 ubl에 대한 공통 ubl을 설정합니다.
   */
  product_ubl?: ProductCardUbl;
  /**
   * 상품 랭킹 노출을 정의합니다.
   * @default false
   */
  is_show_product_rank?: boolean;
  /**
   * 상품리스트의 노출 타입을 정의합니다.
   * @default 'list'
   */
  product_expose_type?: ProductExposeType;
  /**
   * 상품의 노출 갯수를 정의합니다. 없는 경우 3단위로 잘라서 노출합니다.
   */
  product_expose_count?: number;
  /**
   * 로딩 유무를 정의합니다.
   * @default false
   */
  is_loading?: boolean;
  /**
   * className을 지정합니다.
   */
  classname?: string;
  /**
   * 스와이프 index 를 지정합니다.
   * @default 0
   */
  swipe_index?: number;
  /**
   * 리스트의 컬럼 값을 정의합니다.
   * @default 3
   */
  column?: number;
  /**
   * 스와이프 콜백 이벤트 입니다.
   */
  onSwipe?: (index: number) => void;
}

/**
 * 각종 상품 노출 섹션지면에 대한 공통 정의 컴포넌트
 * @component
 */
const ProductSection: FC<Props> = ({
  title,
  description,
  right_info,
  product_list,
  product_ubl,
  product_expose_type = 'list',
  product_expose_count = 6,
  is_show_product_rank,
  tab_info,
  is_loading,
  classname,
  swipe_index = 0,
  column = 3,
  onSwipe,
  children,
  ...rest
}) => {
  const is_show_black = !tab_info && description;

  return (
    <SC.Container {...rest}>
      <ContentTitle
        size='large'
        title={title}
        is_loading={is_loading}
        description={description}
        right={
          right_info && (
            <Link href={right_info.href} onClick={right_info.onClick}>
              <Text variant='small1_regular' color={right_info.color ?? 'gray_secondary'}>
                {right_info.text}
              </Text>
            </Link>
          )
        }
      />
      {tab_info && <Tabs className='product_tab' size='small' is_show_tab_line={false} {...tab_info} />}
      {is_show_black && <SC.Black />}
      <ProductListWithType
        classname={classname}
        product_ubl={product_ubl}
        product_list={product_list}
        product_expose_type={product_expose_type}
        is_show_product_rank={is_show_product_rank}
        is_loading={is_loading}
        product_expose_count={product_expose_count}
        swipe_index={swipe_index}
        column={column}
        onSwipe={onSwipe}
      />
      {children}
    </SC.Container>
  );
};

export default ProductSection;

const ProductListWithType: FC<
  Pick<
    Props,
    | 'classname'
    | 'onSwipe'
    | 'swipe_index'
    | 'product_ubl'
    | 'is_loading'
    | 'product_list'
    | 'product_expose_type'
    | 'is_show_product_rank'
    | 'product_expose_count'
    | 'column'
  >
> = ({
  classname,
  product_ubl,
  is_loading,
  product_list,
  product_expose_type,
  is_show_product_rank,
  product_expose_count = 6,
  swipe_index,
  column = 3,
  onSwipe,
}) => {
  const product_list_with_swipe = chunk(product_list, 6);
  const total_page = product_list_with_swipe.length;

  if (product_expose_type === 'swipe') {
    return (
      <>
        <Splide
          className={classname}
          options={SPLIDE_OPTIONS}
          onMove={(_, newIndex) => {
            onSwipe?.(newIndex);
          }}
        >
          {product_list_with_swipe.map((product_list_by_page, index) => {
            return (
              <SplideSlide key={index}>
                <ProductCardList
                  product_ubl={product_ubl}
                  product_list={product_list_by_page}
                  use_rank={is_show_product_rank}
                  padding='0 16px 16px'
                  start_index={index === 0 ? 0 : index * 6}
                  is_loading={is_loading}
                  column={column}
                />
              </SplideSlide>
            );
          })}
        </Splide>
        <SC.Pagination>
          {[...Array(total_page)].map((_, index) => {
            return <SC.Dot key={index} is_active={index === swipe_index} />;
          })}
        </SC.Pagination>
      </>
    );
  }

  if (product_expose_type === 'carousel') {
    return (
      <ProductCarouselList
        classname={classname}
        is_loading={is_loading}
        ubl={product_ubl}
        use_rank={is_show_product_rank}
        product_list={product_list}
      />
    );
  }

  if (product_expose_type === 'carousel_double') {
    const half_index = product_list.length > 1 ? product_list.length / 2 : 0;
    const top_product_list = product_list.slice(0, half_index);
    const bottom_product_list = product_list.slice(half_index);
    return (
      <SC.DoubleCarousel>
        <li className='item'>
          <ProductCarouselList
            is_loading={is_loading}
            ubl={product_ubl}
            use_rank={is_show_product_rank}
            product_list={top_product_list}
          />
          <ProductCarouselList
            is_loading={is_loading}
            ubl={product_ubl}
            start_index={half_index}
            use_rank={is_show_product_rank}
            product_list={bottom_product_list}
          />
        </li>
      </SC.DoubleCarousel>
    );
  }

  return (
    <ProductCardList
      use_rank={is_show_product_rank}
      product_ubl={product_ubl}
      is_loading={is_loading}
      product_list={product_list.slice(0, product_expose_count)}
      padding='0 16px'
      column={column}
    />
  );
};

const SC = {
  Container: styled.section`
    padding: 10px 0 28px;

    .product_tab {
      margin-top: -8px;
      margin-bottom: 10px;
    }
  `,
  DoubleCarousel: styled.ul`
    ${invisible_scroll_bar}
    overflow-x: scroll;
    .item {
      ul {
        overflow-y: unset;
        overflow-x: unset;
      }
      > * + * {
        margin-top: 20px;
      }
    }
  `,
  Pagination: styled.div`
    position: relative;
    display: flex;
    height: 14px;
    align-items: flex-end;
    gap: 4px;
    justify-content: center;
  `,
  Dot: styled.span<{ is_active: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${({ is_active }) => (is_active ? pink_primary : border3)};
  `,
  Black: styled.div`
    height: 6px;
  `,
};
