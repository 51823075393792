import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { OrderProductInfo } from './useCompletedReviewList';
import { getReviewableOrderItemList, getUserOrderList } from 'api2';
import { GetReviewableOrderItemList, OrderItemStatus } from 'api2/types';

type OrderListItem = GetReviewableOrderItemList['reviewable_order_item_list']['item_list'][number];
export const REVIEWABLE_ORDER_ITEM_LIST_QUERY_KEY = 'getReviewableOrderItemList';

interface FetchProps {
  req?: IncomingMessage;
}

export interface ReviewableListProps extends OrderProductInfo {
  review_info: {
    date_deadline: number;
    max_point: number;
  };
  quantity: number;
  total_amount: number;
  order_item_number: string;
}

function uiItemToListProps(item: OrderListItem) {
  const list_item: ReviewableListProps = {
    product_info: {
      image_url: item.product_info.image_url,
      name: item.product_info.name,
      options: item.product_info.options,
    },
    review_info: {
      date_deadline: item.product_review_create_info.date_deadline,
      max_point: item.product_review_create_info.point_amounts.normal.with_photo,
    },
    quantity: item.quantity,
    total_amount: item.total_amount,
    order_item_number: item.order_item_number,
  };

  return list_item;
}

export const fetchReviewableOrderItemList = async (props: FetchProps) => {
  const { req } = props;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const {
    data: { reviewable_order_item_list },
  } = await getReviewableOrderItemList(undefined, context_request);

  if (reviewable_order_item_list.total_count > 0) {
    return {
      has_shipped_item: false,
      reviewable_order_item_list: reviewable_order_item_list.item_list,
      review_count: reviewable_order_item_list.total_count,
    };
  }

  // 작성할 리뷰가 없고 구매확정이 가능한 상품이 있는 경우 화면을 표시하기 위해 배송완료된 주문건 조회
  const {
    data: { user_order_list },
  } = await getUserOrderList({ status_list: [OrderItemStatus.SHIPPED] });

  return {
    has_shipped_item: user_order_list.item_list.length > 0,
    reviewable_order_item_list: [],
    review_count: 0,
  };
};

/**
 * 마이페이지 [리뷰] - 작성가능한 리뷰 목록 조회
 * @hook
 */
export const useReviewableReviewList = () => {
  const {
    data,
    isInitialLoading,
    refetch: refetchReviewableList,
    ...rest
  } = useQuery([REVIEWABLE_ORDER_ITEM_LIST_QUERY_KEY], () => fetchReviewableOrderItemList({}));

  const reviewable_list = useMemo(() => {
    return data ? data.reviewable_order_item_list.map((value) => uiItemToListProps(value)) : [];
  }, [data]);

  const max_review_point = useMemo(() => {
    return reviewable_list.reduce((acc, cur) => {
      return (acc += cur.review_info.max_point);
    }, 0);
  }, [reviewable_list]);

  return {
    reviewable_list,
    max_review_point,
    review_count: data?.review_count,
    has_shipped_item: data?.has_shipped_item,
    is_loading: isInitialLoading,
    refetchReviewableList,
    ...rest,
  };
};
