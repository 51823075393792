import { ConditionalStatus, FindMatchedStickerImage, SingleImageItem } from '@croquiscom-pvt/event-modules';
import { useQueryClient } from '@tanstack/react-query';
import { getConditionStateQueryKey } from './getConditionStateQueryKey';

export function useFindMatchedStickerImage(): FindMatchedStickerImage<SingleImageItem> {
  const query_client = useQueryClient();

  const findMatchedStickerImage: FindMatchedStickerImage<SingleImageItem> = ({ conditions }) => {
    return conditions.reduce((acc, condition) => {
      const query_key = getConditionStateQueryKey({ sticker_image_condition: condition });
      const query_data = query_key ? Boolean(query_client.getQueryData([query_key])) : false;

      switch (condition.status) {
        case ConditionalStatus.USER_LOGGED_IN:
        case ConditionalStatus.EVENT_METADATA_IN_PROGRESS:
        case ConditionalStatus.EVENT_METADATA_PARTICIPATED:
          return acc || query_data;
        case ConditionalStatus.USER_NOT_LOGGED_IN:
        case ConditionalStatus.EVENT_METADATA_NOT_IN_PROGRESS:
        case ConditionalStatus.EVENT_METADATA_NOT_PARTICIPATED:
          return acc || !query_data;
        default:
          return acc;
      }
    }, false);
  };

  return findMatchedStickerImage;
}
