import { ToggleAreaModuleProps } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

export const ToggleArea = ({ children, default_open = false, header }: ToggleAreaModuleProps) => {
  const [open, setOpen] = useState(default_open);

  return (
    <>
      <HeaderWrapper onClick={() => setOpen((open) => !open)}>
        {header?.type === 'image' && <img src={header.img_src} alt={open ? 'Click to collapse' : 'Click to expand'} />}
      </HeaderWrapper>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{ collapsed: { height: 0 }, open: { height: 'auto' } }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const HeaderWrapper = styled.div`
  position: relative;

  > img {
    width: 100%;
  }
`;
