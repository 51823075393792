import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { getOrderItemForReview, getProductReview } from 'api2';
import { ProductReviewAttachmentStatus } from 'api2/types';
import { set_edit_review_data_atom } from 'components/reviews/store/write';

export const REVIEW_EDIT_INFO_QUERY_KEY = 'getReviewEditInfo';

export const fetchEditReviewInfo = async (review_id: string, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const attachment_status_list = [ProductReviewAttachmentStatus.DELETED_BY_ADMIN, ProductReviewAttachmentStatus.NORMAL];
  const {
    data: { product_review },
  } = await getProductReview(
    {
      id: review_id,
      attachment_status_list,
    },
    context_request,
  );

  if (!product_review) {
    return {
      order_item: null,
      product_review: null,
    };
  }

  const order_item_number = product_review.order_item_number;
  const {
    data: { order_item },
  } = await getOrderItemForReview({ order_item_number }, context_request);

  return {
    attribute_option_list: order_item?.product_review_create_info.attribute_option_list,
    product_review,
  };
};

/**
 * 리뷰 수정을 위해 작성된 리뷰 정보와 상품특성 리스트 조회
 * @hook
 */
export const useEditReviewInfo = (review_id: string) => {
  const { data, ...rest } = useQuery([REVIEW_EDIT_INFO_QUERY_KEY, review_id], () => fetchEditReviewInfo(review_id));

  const product_info = useMemo(() => {
    return {
      image_url: data?.product_review?.order_item.product_info.image_url ?? '',
      name: data?.product_review?.order_item.product_info.name ?? '',
      options: data?.product_review?.order_item.product_info.options ?? '',
    };
  }, [data?.product_review]);

  const review_info = useMemo(() => {
    return {
      rating: data?.product_review?.rating ?? 0,
      contents: data?.product_review?.contents ?? '',
      attribute_list: data?.product_review?.attribute_list ?? [],
      attachment_list: data?.product_review?.attachment_list ?? [],
    };
  }, [data?.product_review]);

  const attribute_option_list = useMemo(() => {
    return data?.attribute_option_list ?? [];
  }, [data?.attribute_option_list]);

  const setReviewInfo = useUpdateAtom(set_edit_review_data_atom);
  useEffect(() => {
    setReviewInfo(review_info);
  }, [review_info, setReviewInfo]);

  return {
    is_loading: rest.isInitialLoading,
    review_info: data?.product_review && !rest.isError ? review_info : null,
    product_info,
    attribute_option_list,
    ...rest,
  };
};
