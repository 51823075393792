import { useMutation } from '@tanstack/react-query';
import { useUpdateAtom } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import { saveProductLike, unSaveProductLike } from 'api2';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { is_show_like_tab_new_badge_atom } from 'components/common/store/like';
import { useUserInfo } from 'components/my-page/hooks/useUserInfo';
import { useBroadCastConext } from 'context/BroadCastContext';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { vibrateWithDuration } from 'util/app_utils';
import { getClientProductLike, setClientProductLike } from 'util/like/product_like';
import { Toast } from 'util/Toast';
import { Log, useTracker } from 'util/Tracker';

export type ProductLikeUbl = Omit<Log, 'category'>;
export interface ProductLikeInfo {
  is_like: boolean;
  shop_id: string;
  shop_product_no: string | null;
  catalog_product_id: string | null;
  save_ubl_navigation?: string;
  product_like_ubl?: ProductLikeUbl;
  bottom_floating_position?: number;
  shop_name: string;
  product_name: string;
  price: number;
  product_category_lv1?: { id?: number; name?: string };
  onLikeSuccess?: (is_like: boolean) => void;
}

const MESSAGE = {
  LIKE: '찜한 아이템에 추가했어요.',
  UN_LIKE: '찜 상품에서 해제했어요.',
  ERROR: '다시 시도해 주세요.',
};

const ERROR_MSG = {
  NEED_LOGIN: '로그인 후 이용해주세요.',
};

const LIKE_VIBRATE_MS = 15;

const updateProductLikeStatus = async (
  product_like_info: Omit<ProductLikeInfo, 'shop_name' | 'product_name' | 'price'>,
) => {
  const { is_like, ...product_Info } = product_like_info;

  vibrateWithDuration(LIKE_VIBRATE_MS);

  if (is_like) {
    const { errors } = await saveProductLike({ product_ids: { ...product_Info } }, { show_alert: false });
    if (errors) {
      throw errors;
    }
  } else {
    const { errors } = await unSaveProductLike(
      { product_info: { product_ids_list: [{ ...product_Info }] } },
      { show_alert: false },
    );
    if (errors) {
      throw errors;
    }
  }
};

// 상품 좋아요 처리
export const useProductLike = (product_like_info: ProductLikeInfo) => {
  const { is_native_version } = useAppVersion();
  const { refetchBroadCast } = useBroadCastConext();
  const is_client_like_info = getClientProductLike(product_like_info.catalog_product_id || '');
  const tracker = useTracker();
  const { openLoginDialog } = useLoginDialog();
  const { is_login } = useUserInfo();
  const [is_like_product, setIsLikeProduct] = useState(
    is_client_like_info ? is_client_like_info.is_like : product_like_info.is_like,
  );
  const setIsShowLikeTabNewBadge = useUpdateAtom(is_show_like_tab_new_badge_atom);

  useEffect(() => {
    // 찜하기 동기화
    if (is_client_like_info) {
      setIsLikeProduct(is_client_like_info.is_like);
    }
  }, [is_client_like_info]);

  useEffect(() => {
    const is_client_like_info = getClientProductLike(product_like_info.catalog_product_id || '');
    if (is_client_like_info) {
      setIsLikeProduct(is_client_like_info.is_like);
    }
    setIsLikeProduct(product_like_info.is_like);
  }, [product_like_info.is_like, product_like_info.catalog_product_id]);

  // 앱에서 PDP -> PLP 뒤로가기시 포커스만으로 이벤트 감지해서 싱크맞추어야 하는 부분
  const handleFocus = useCallback(() => {
    const is_client_like_info = getClientProductLike(product_like_info.catalog_product_id || '');
    setIsLikeProduct(is_client_like_info ? is_client_like_info.is_like : product_like_info.is_like);
  }, [setIsLikeProduct, product_like_info.is_like, product_like_info.catalog_product_id]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('visibilitychange', handleFocus, false);
      window.addEventListener('focus', handleFocus, false);
    }

    return () => {
      window.removeEventListener('visibilitychange', handleFocus);
      window.removeEventListener('focus', handleFocus);
    };
  }, [handleFocus]);

  const like_product_mutation = useMutation(
    ['like_product'],
    //@ts-ignore
    async () => {
      if (!is_login) {
        throw Error('로그인 후 이용해주세요.');
      }

      const { catalog_product_id, shop_id, shop_product_no } = product_like_info;
      const update_product_like_info = { catalog_product_id, shop_id, shop_product_no, is_like: !is_like_product };
      await updateProductLikeStatus(update_product_like_info);

      return update_product_like_info;
    },
    {
      onSuccess(res: ProductLikeInfo) {
        // 찜하기 성공하면 클라이언트 싱크를 위한 작업
        setClientProductLike({
          product_catalog_id: res.catalog_product_id || '',
          is_like: res.is_like,
        });
        // ubl navigation 존재시 찜스펙 ubl 추가
        if (product_like_info.product_like_ubl) {
          tracker.addLog({
            ...product_like_info.product_like_ubl,
            category: res.is_like ? 'save_product' : 'remove_saved_product',
          });
        }
        tracker.likeProduct(
          {
            id: product_like_info.shop_id,
            name: product_like_info.shop_name,
          },
          {
            id: res.catalog_product_id ?? '',
            name: product_like_info.product_name,
            price: product_like_info.price,
            product_category_lv1: product_like_info.product_category_lv1,
          },
        );
        // 찜탭 외에서 찜하기 업데이트 시 하단 뉴뱃지 활성화
        !location.pathname.includes('/like') && setIsShowLikeTabNewBadge(true);

        if (product_like_info.onLikeSuccess) {
          product_like_info.onLikeSuccess(res.is_like);
        } else {
          if (!is_native_version) {
            Toast.show(res.is_like ? MESSAGE.LIKE : MESSAGE.UN_LIKE, {
              bottom_floating_position: product_like_info.bottom_floating_position,
            });
          }
        }
        // 앱 찜상품 웹뷰간 싱크
        refetchBroadCast({ key: 'like_product_list' });
      },
      onError: async (error) => {
        if (error instanceof Error && error.message === ERROR_MSG.NEED_LOGIN) {
          openLoginDialog();
          return;
        }

        Toast.show(typeof error === 'string' ? error : MESSAGE.ERROR, {
          bottom_floating_position: product_like_info.bottom_floating_position,
        });
      },
    },
  );
  return { is_like_product, toggleProductLike: like_product_mutation.mutate };
};
