import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getProductReviewList, getProductReviewSummary } from 'api2';
import { ProductReviewListOrderType } from 'api2/types';

export const REVIEW_SUMMARY_QUERY_KEY = 'getProductReviewSummary';

export const fetchReviewSummary = async (product_id: string, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const [
    {
      data: { product_review_summary },
    },
    {
      data: { product_review_list },
    },
  ] = await Promise.all([
    getProductReviewSummary({ product_id }, context_request),
    getProductReviewList(
      {
        product_id,
        limit_count: 5,
        order: ProductReviewListOrderType.BEST_SCORE_DESC,
      },
      context_request,
    ),
  ]);

  return { product_review_summary, product_review_list };
};

/**
 * 상품 리뷰의 평균 데이터 조회
 * @hook
 */
export const useReviewSummary = () => {
  const {
    query: { catalog_product_id },
  } = useRouter();
  const product_id = String(catalog_product_id);
  const {
    data,
    isInitialLoading: is_loading,
    isSuccess: is_success,
    ...rest
  } = useQuery([REVIEW_SUMMARY_QUERY_KEY, product_id], () => fetchReviewSummary(product_id));

  const summary_info = useMemo(() => {
    return {
      attribute_list: data?.product_review_summary.attribute_list ?? [],
      rating_list: data?.product_review_summary.rating_list ?? [],
      ratings_average: data?.product_review_summary.ratings_average ?? 0,
    };
  }, [data]);

  const review_list = useMemo(() => {
    return data?.product_review_list.item_list ?? [];
  }, [data]);

  const total_count = useMemo(() => {
    return data?.product_review_list.total_count ?? 0;
  }, [data]);

  return {
    is_loading,
    is_success,
    total_count,
    summary_info,
    review_list,
    ...rest,
  };
};
