import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getFbkSaleTimeSaleProductList } from 'api2';
import { GetFbkSaleTimeSaleProductList } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertIsFastDelivery } from 'util/product_util';

export const TIME_SALE_PRODUCT_LIST_QUERY_KEY = ['getTimeSaleProductList'];

export type FbkSaleTimeSaleProductList = GetFbkSaleTimeSaleProductList['fbk_sale_timesale_product_list'];
export type TimeSaleProduct = NonNullable<FbkSaleTimeSaleProductList['item_list']>[number];

function uiItemToCardProps({ product: item }: TimeSaleProduct) {
  const product_card: ProductCardProps = {
    ...item,
    catalog_product_id: item.catalog_product_id ?? '',
    is_new: item.is_new ?? false,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
    zpay: item.zpay ?? null,
    image_url: item.image_url ?? '',
    webp_image_url: item.webp_image_url ?? '',
    title: item.title ?? '',
    discount_rate: item.discount_rate ?? null,
    price: item.price ?? null,
    free_shipping: item.free_shipping ?? null,
    shop_product_no: item.shop_product_no ?? null,
    is_fast_delivery: convertIsFastDelivery(item.badge_list),
  };
  return product_card;
}

const init_fetch_data = {
  time_sale_product_list: [],
  date_started: 0,
  date_ended: 0,
};

export const fetchTimeSaleProductList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { fbk_sale_timesale_product_list },
    } = await getFbkSaleTimeSaleProductList(undefined, context_request);

    if (isNil(fbk_sale_timesale_product_list) || !fbk_sale_timesale_product_list.item_list.length) {
      return init_fetch_data;
    }
    return {
      time_sale_product_list: fbk_sale_timesale_product_list.item_list.map(uiItemToCardProps),
      date_started: fbk_sale_timesale_product_list.item_list.find((item) => item.date_started)?.date_started ?? 0,
      date_ended: fbk_sale_timesale_product_list.item_list.find((item) => item.date_ended)?.date_ended ?? 0,
    };
  } catch {
    return init_fetch_data;
  }
};

export const useTimeSaleProductList = () => {
  const {
    data,
    isInitialLoading: is_loading,
    isFetching: is_fetching,
    refetch: refetchTimeSaleProductList,
  } = useQuery(TIME_SALE_PRODUCT_LIST_QUERY_KEY, () => fetchTimeSaleProductList());

  return {
    time_sale_product_list: data?.time_sale_product_list ?? [],
    date_started: data?.date_started ?? 0,
    date_ended: data?.date_ended ?? 0,
    is_fetching,
    is_loading,
    refetchTimeSaleProductList,
  };
};
