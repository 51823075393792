import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import { IconNavigationBasket24 } from 'components/base/Icon';
import { pink600, white } from 'styles';
import { typography } from 'styles/font';

interface CartButtonProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  count?: number;
  href?: string;
}

const CartButton: React.FC<CartButtonProps> = (props) => {
  const { href, onClick, count = 0 } = props;

  const formatted_count = useMemo(() => {
    if (count < 0) {
      return 0;
    }
    if (count > 99) {
      return '99+';
    }
    return count;
  }, [count]);

  return (
    <CartWrapper href={href || '#'} onClick={onClick}>
      <StyledGeneralZpaySymbolIcon />
      {Boolean(formatted_count) && <CartBadge>{formatted_count}</CartBadge>}
    </CartWrapper>
  );
};

export default CartButton;

const CartWrapper = styled.a`
  display: flex;
  margin: 0 0 0 auto;
  flex-shrink: 0;
  position: relative;
  width: 26px;
  height: 26px;
`;

const StyledGeneralZpaySymbolIcon = styled(IconNavigationBasket24)`
  margin: 0 0 0 auto;
  flex-shrink: 0;
`;

const CartBadge = styled.p`
  ${typography.small4_semibold}
  padding: 5px;
  height: 16px;
  position: absolute;
  border-radius: 8px;
  background: ${pink600};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: -4px;
`;
