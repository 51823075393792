import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import { getFbkStorePickList } from 'api2';
import { GetFbkStorePickList } from 'api2/types';

type StorePickList = GetFbkStorePickList['fbk_storepick_list'];

export const STORE_PICK_LIST_QUERY_KEY = 'getStorePickList';

const PAGE_PER_COUNT = 40;

const init_fetch_data: StorePickList = {
  end_cursor: null,
  item_list: [],
};

export const fetchStorePickList = async (after: string | null, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const { data } = await getFbkStorePickList({ limit_count: PAGE_PER_COUNT, after }, context_request);

    if (isNil(data) || isNil(data.fbk_storepick_list)) {
      return init_fetch_data;
    }
    return data.fbk_storepick_list;
  } catch {
    return init_fetch_data;
  }
};

export const useStorePickList = () => {
  const {
    data,
    isInitialLoading: is_loading,
    hasNextPage: has_next_page,
    fetchNextPage,
  } = useInfiniteQuery([STORE_PICK_LIST_QUERY_KEY], ({ pageParam }) => fetchStorePickList(pageParam), {
    getNextPageParam: (last_page) => last_page.end_cursor,
  });

  const store_pick_list = useMemo(() => flatten(data?.pages.map((store_pick) => store_pick.item_list)), [data]);

  return {
    store_pick_list,
    is_loading,
    has_next_page,
    fetchNextPage,
  };
};

