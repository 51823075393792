import { IncomingMessage } from 'http';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSimilarProductList } from 'api2';
import { GetSimilarProductListVariables, SearchProductsByImageVariables } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductCard } from 'util/product_util';

type Option = Omit<
  UseQueryOptions<ProductCardProps[], unknown, ProductCardProps[], Array<string | SearchProductsByImageVariables>>,
  'queryKey' | 'queryFn'
>;

export const SIMILAR_PRODUCT_LIST_QUERY_KEY = 'getSimilarProductList';

export const fetchSimilarProductList = async (
  { catalog_product_id, limit_count }: GetSimilarProductListVariables,
  req?: IncomingMessage,
) => {
  if (catalog_product_id === '') {
    return [];
  }
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const { data } = await getSimilarProductList({ catalog_product_id, limit_count }, context_request);
  return data.fbk_similar_product_list.map(convertProductCard);
};

export const useSimilarProductList = (payload: GetSimilarProductListVariables, option?: Option) => {
  const {
    data,
    isLoading: is_loading,
    isFetching: is_similar_product_list_fetching,
    ...rest
  } = useQuery<ProductCardProps[]>(
    [SIMILAR_PRODUCT_LIST_QUERY_KEY, payload],
    () => fetchSimilarProductList(payload),
    // @ts-ignore
    option,
  );
  // @ts-ignore
  const similar_product_list: ProductCardProps[] = data ?? [];
  return {
    similar_product_list,
    is_loading,
    is_similar_product_list_fetching,
    ...rest,
  };
};
