import copy from 'copy-to-clipboard';
import { Toast } from './Toast';
import { ShareType } from 'components/common/ShareBottomSheet';
import { OptionType } from 'components/common/ShareBottomSheet/store/KakaoShareOption';
import { makeOneLink } from 'util/app_utils';

export const getShareUrl = (is_search = true) => {
  if (typeof window === 'undefined') {
    return '';
  }

  const url = window.location.origin + window.location.pathname;
  const urlWithSearch = is_search ? url + window.location.search : url;
  return urlWithSearch;
};

export const shareWithFbWithBand = (channel: string, share_url: string, is_open_widow?: boolean) => {
  const link =
    channel === ShareType.FACEBOOK
      ? `https://www.facebook.com/sharer/sharer.php?u=${share_url}`
      : `http://band.us/plugin/share?body=${share_url}&route=${window.location.origin}`;

  if (is_open_widow) {
    window.open(link, '_blank');
  } else {
    window.location.href = link;
  }
};

export const shareWithUrl = (share_url: string) => {
  copy(share_url);
  Toast.show('링크가 복사됐어요.\n친구에게 링크를 공유하세요.');
};
export interface ShareWithKaKaoOption {
  title: string;
  description: string;
  image?: string;
  button_title: string;
}
export const createKakaoShare = (share_url: string, option: OptionType) => async () => {
  const Kakao = (window as any).Kakao;
  const {
    private_link_url,
    data: { content, commerce, custom },
  } = option;

  if (!Kakao) {
    return;
  }

  const web_url = private_link_url ?? share_url;
  const { pathname, search } = new URL(web_url);
  const deep_link_path = await makeOneLink(pathname + search);

  const link = { mobileWebUrl: deep_link_path, webUrl: web_url };

  if (option.type === 'pdp') {
    Kakao.Share.sendDefault({
      objectType: 'commerce',
      content: {
        title: content?.title,
        imageUrl: content?.image_url,
        link,
      },
      commerce: commerce?.discount_rate
        ? {
            regularPrice: commerce.regular_price,
            discountRate: commerce.discount_rate,
            discountPrice: commerce.discount_price,
          }
        : {
            regularPrice: commerce?.regular_price,
          },
      buttons: [
        {
          title: option.button_title,
          link,
        },
      ],
      installTalk: true,
    });
  }

  if (option.type === 'event') {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: content?.title,
        description: content?.description,
        imageUrl: content?.image_url,
        link,
      },
      buttons: [
        {
          title: '앱으로 보기',
          link,
        },
      ],
      installTalk: true,
    });
  }

  if (option.type === 'custom') {
    Kakao.Share.sendCustom({ templateId: custom?.template_id, templateArgs: custom?.template_args });
  }
};
