import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import { getSearchProductListFilter } from 'api2';
import {
  UxGoodsCardItem,
  SearchedGoodsPriceRange,
  UxGoodsFilterOptionType,
  UxGoodsColorFilterOptionList,
  UxGoodsDeliveryFilterOptionList,
  UxGoodsPriceRangeSelectFilterOptionList,
} from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { useSearchQueryString } from 'hooks';
import { convertIsFastDelivery } from 'util/product_util';
import { getFilterIdList, SearchFilter } from 'util/search_utils';

type ProductItem = Omit<UxGoodsCardItem, 'ubl'>;
type ColorFilter = UxGoodsColorFilterOptionList;
type PriceFilter = UxGoodsPriceRangeSelectFilterOptionList;
type DeliveryFilter = UxGoodsDeliveryFilterOptionList;
type ProductCard = Omit<ProductCardProps, 'thumbnail_emblem_badge_list'>;

export const SEARCH_PRODUCT_LIST_QUERY_KEY = 'getSearchProductList';

const PAGE_PER_COUNT = 101;

const init_value = {
  item_list: [],
  ad_product_list: [],
  total_count: 0,
  end_cursor: null,
  has_next: false,
};

function uiItemToCardProps(item: ProductItem) {
  const product_card: ProductCard = {
    ...item,
    catalog_product_id: item.catalog_product_id ?? '',
    is_new: item.is_new ?? false,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
    zpay: item.zpay ?? null,
    image_url: item.image_url ?? '',
    webp_image_url: item.webp_image_url ?? '',
    title: item.title ?? '',
    discount_rate: item.discount_rate ?? null,
    price: item.price ?? null,
    free_shipping: item.free_shipping ?? null,
    shop_product_no: item.shop_product_no ?? null,
    is_fast_delivery: convertIsFastDelivery(item.badge_list),
  };
  return product_card;
}
type SearchListType = ColorFilter | PriceFilter | DeliveryFilter;

interface FetchProps {
  after: string | null;
  req?: IncomingMessage;
  keyword: string;
  search_filter: SearchFilter;
  limit?: number;
}

export const fetchCategoryProductList = async (props: FetchProps) => {
  const { after, req, keyword, search_filter, limit } = props;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const price_range: SearchedGoodsPriceRange | null = search_filter.price
    ? {
        gte: search_filter.price.min,
        lte: search_filter.price.max,
      }
    : null;
  const display_category_id_list = search_filter.category_list
    ? search_filter.category_list.map((category) => category.id)
    : null;

  const { data } = await getSearchProductListFilter(
    {
      after,
      goods_filter_option: {
        title: keyword,
        price_range,
        limit_count: limit ?? PAGE_PER_COUNT,
        color_list: search_filter.color_list,
        display_category_id_list,
      },
      filter_id_list: getFilterIdList(search_filter),
    },
    context_request,
  );

  if (!data.fbk_srp_search_product_list) {
    return init_value;
  }

  const { goods_filter_option_list, end_cursor, has_next, ui_item_list, top_item_list } =
    data.fbk_srp_search_product_list;
  const price_section = goods_filter_option_list?.item_list.find(
    (item) => (item as SearchListType).type === UxGoodsFilterOptionType.UX_GOODS_PRICE_RANGE_FILTER_OPTION_LIST,
  );

  const total_count = price_section ? (price_section as PriceFilter).total_count : 0;
  return {
    total_count,
    item_list: ui_item_list,
    ad_product_list: top_item_list,
    end_cursor,
    has_next,
  };
};

/**
 * 검색어 에 해당하는 상품 리스트 조회 쿼리
 * @deprecated hooks/useSearchProductList 로 치환 예정
 * @hook
 */
const useSearchProductList = (keyword: string, search_filter_info?: SearchFilter, limit?: number) => {
  const { search_props } = useSearchQueryString();
  const search_filter = search_filter_info ?? search_props;
  const {
    data,
    isInitialLoading: is_search_product_loading,
    isFetching: is_search_product_fetching,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    makeQueryKey(keyword, search_filter),
    ({ pageParam }) => fetchCategoryProductList({ after: pageParam, keyword, search_filter, limit }),
    {
      getNextPageParam: (last_page) => {
        return last_page.end_cursor;
      },
      keepPreviousData: true,
    },
  );

  const product_list = useMemo(() => {
    // @ts-ignore
    return flatten(data?.pages.map((value) => value.item_list)).map(uiItemToCardProps);
  }, [data]);

  const ad_product_list: ProductCardProps[] = useMemo(() => {
    if (isNil(data) || isNil(data.pages[0]?.ad_product_list)) {
      return [];
    }
    // @ts-ignore
    return data.pages[0].ad_product_list.map(uiItemToCardProps);
  }, [data]);

  const total_count = useMemo(() => (data ? data.pages[0]?.total_count || 0 : 0), [data]);

  return {
    product_list,
    ad_product_list,
    is_search_product_loading,
    is_search_product_fetching,
    total_count,
    has_next_page,
    ...rest,
  };
};

export default useSearchProductList;

function makeQueryKey(keyword: string, search_filter: SearchFilter) {
  return [
    SEARCH_PRODUCT_LIST_QUERY_KEY,
    keyword,
    search_filter.color_list,
    search_filter.is_fast_shipping,
    search_filter.is_free_shipping,
    search_filter.is_zigin_shipping,
    search_filter.sort_type,
    search_filter.price?.max,
    search_filter.price?.min,
    search_filter.category_list,
  ];
}
