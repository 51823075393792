import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import filter from 'lodash-es/filter';
import flow from 'lodash-es/flow';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';

import uniq from 'lodash-es/uniq';
import { getPaymentBenefit, getActivatedBenefit } from 'api2';
import {
  GetPaymentBenefit,
  PaymentNotificationType,
  FinancialCompanyCode,
  GetActivatedBenefit,
  UserAccountPointRewardUnit,
} from 'api2/types';
import { getNameByFinancialCompanyCode } from 'constants/payment';

type ActivatedPointReward = GetActivatedBenefit['activated_point_reward'];

export const PRODUCT_BENEFIT_QUERY_KEY = 'getProductBenefit';

export interface InterestFreeItem {
  name: string;
  month_list: string;
  min_price: number;
}

interface ProductBenefit {
  reward: {
    review: number;
    confirm: number;
  };
  payment: {
    event_list: GetPaymentBenefit['event']['item_list'];
    notification_list: string[];
    interest_free_list: InterestFreeItem[];
  };
}

interface Props {
  shop_id: string;
  product_final_price: number;
}

const init_data: ProductBenefit = {
  reward: {
    review: 0,
    confirm: 0,
  },
  payment: {
    event_list: [],
    notification_list: [],
    interest_free_list: [],
  },
};

const calcPointReward = (pr: ActivatedPointReward) => {
  if (!pr) {
    return 0;
  }
  if (pr.unit === UserAccountPointRewardUnit.POINT) {
    return pr.amount;
  } else {
    return pr.amount / 100;
  }
};

export async function fetchProductBenefit({ shop_id, product_final_price }: Props, req?: IncomingMessage) {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const [
      {
        data: { event, notification, vendor_interest_free },
      },
      {
        data: { review, activated_point_reward },
      },
    ] = await Promise.all([
      getPaymentBenefit(undefined, context_request),
      getActivatedBenefit({ shop_id, item_price: product_final_price }, context_request),
    ]);

    const calculated_point_reward = calcPointReward(activated_point_reward);
    const confirm_point_reward = Math.floor((product_final_price * calculated_point_reward) / 100);
    const notification_list = notification.item_list
      .filter(({ notification_type }) => notification_type === PaymentNotificationType.EVENT)
      .map(({ payment_notification_content_list, financial_company_code }) => {
        const content =
          payment_notification_content_list.find((c) => c.language === 'ko' && c.content.length > 0)?.content ?? '';
        return getContentWithCardName(financial_company_code, content);
      })
      .filter((content) => content !== '');

    return {
      reward: {
        review: review.maximum_point,
        confirm: confirm_point_reward,
      },
      payment: {
        notification_list,
        event_list: event.item_list,
        interest_free_list: getFreeInterest(vendor_interest_free.item_list),
      },
    };
  } catch (e) {
    console.log(e);
  }
  return init_data;
}

export const useProductBenefit = ({ shop_id, product_final_price }: Props) => {
  const { data, ...rest } = useQuery([PRODUCT_BENEFIT_QUERY_KEY, shop_id, product_final_price], () =>
    fetchProductBenefit({ shop_id, product_final_price }),
  );

  const product_benefit: ProductBenefit = data ?? init_data;

  return {
    reward: product_benefit.reward,
    payment: product_benefit.payment,
    ...rest,
  };
};

function getContentWithCardName(financial_company_code: FinancialCompanyCode | null | undefined, content: string) {
  if (content === '') {
    return content;
  }

  if (financial_company_code) {
    return `***${getNameByFinancialCompanyCode(financial_company_code)}*** ${content}`;
  }

  // MEMO: 카카오페이의 경우 financial_company_code 가 없어서 contents 값으로 처리
  if (content.includes('페이머니')) {
    return `***카카오페이*** ${content}`;
  }
  return content;
}

/**
 * 카드별 무이자 기간 리스트 조회 (아래의 조건을 충족시켜야 한다.)
 * 1. 무이자 기간은 각 밴더사 2개 and 조건으로 있어야 노출
 * 2. 금액은 제일 높은것으로 노출
 */
function getFreeInterest(vendor_interest_free_list: GetPaymentBenefit['vendor_interest_free']['item_list']) {
  const interest_free_list_with_group = groupBy(vendor_interest_free_list, 'financial_company_code');

  return Object.entries(interest_free_list_with_group)
    .map((i) => {
      const [name, interest_list] = i;
      const min_price = Math.max(...interest_list.map((i) => i.min_amount));
      const installment_month_list = interest_list.map((i) => i.installment_month);
      const month_list = flow(
        filter((item: number) => installment_month_list.filter((row_item) => row_item === item).length > 1),
        uniq,
        sortBy,
      )(installment_month_list).join(', ');

      return {
        name: getNameByFinancialCompanyCode(name),
        month_list,
        min_price,
      };
    })
    .filter((item) => item.name !== '');
}
