import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import ProductCard, { ProductCardProps, ProductUbl } from 'components/common/ProductCard';
import { white } from 'styles';

interface Props {
  product: ProductCardProps;
  ubl?: ProductUbl;
}

/**
 * 타임특가 상품리스트 > 가로형 상품아이템
 * 신규 상품카드 유형으로 디자인 공용화 스펙정의 후 productCard 옵션으로 처리할 예정
 * @component
 */
const Item = ({ product, ubl }: Props) => {
  return (
    <SC.Container>
      <ProductCard {...product} ubl={ubl} direction='horizontal' size='medium' meta_info_css={horizontal_meta_style} />
    </SC.Container>
  );
};

export default memo(Item);

const horizontal_meta_style = css`
  padding: 0 0 0 12px;
`;

const horizontal_css = css`
  display: grid;
  grid-template-columns: 1.25fr 2fr;
  align-items: flex-start;
  gap: 12px;
`;

const SC = {
  Container: styled.div`
    position: relative;
    background-color: ${white};
    overflow: hidden;

    :not(:last-child) {
      padding-bottom: 6px;
    }

    > a {
      ${horizontal_css}
    }
  `,
};
