import { isBrowser } from '@croquiscom/web2app';

const KEY_MAP = 'PRODUCT_LIKE_INFO';

interface ProductLikeInfo {
  product_catalog_id: string;
  is_like: boolean;
}

export const setClientProductLike = (product_like_info: ProductLikeInfo) => {
  if (isBrowser() && typeof localStorage !== 'undefined') {
    const product_like_list: ProductLikeInfo[] = JSON.parse(localStorage.getItem(KEY_MAP) || '[]');

    if (product_like_list.length > 0) {
      const list_info = [...product_like_list];
      const index = list_info.findIndex((i) => i.product_catalog_id === product_like_info.product_catalog_id);

      if (index >= 0) {
        list_info[index].is_like = product_like_info.is_like;
      } else {
        list_info.push(product_like_info);
      }
      localStorage.setItem(KEY_MAP, JSON.stringify(list_info));
    } else {
      localStorage.setItem(KEY_MAP, JSON.stringify([{ ...product_like_info }]));
    }
  }
};

export const getClientProductLike = (product_catalog_id: string) => {
  if (isBrowser() && typeof localStorage !== 'undefined') {
    try {
      const product_like_list: ProductLikeInfo[] = JSON.parse(localStorage.getItem(KEY_MAP) || '[]');
      return product_like_list.find((product_like) => product_like.product_catalog_id === product_catalog_id);
    } catch {
      return false;
    }
  } else {
    return false;
  }
};

export const removeClientProductLikeInfo = () => {
  localStorage.removeItem(KEY_MAP);
};
