import React, { useMemo } from 'react';
import ProductCardVirtualList from 'components/common/ProductCardVirtualList';
import { useBestProductRankingList } from 'hooks/queries';

export const BEST_PRODUCT_LIST = 'BestProductList';

interface Props {
  category_id: string;
}

/**
 * 베스트 상품 리스트
 * @component
 */
const ProductList: React.FC<Props> = ({ category_id }) => {
  const { best_product_list, is_loading } = useBestProductRankingList(category_id);

  const best_product_ubl = useMemo(() => {
    return {
      navigation: 'home_best',
      object_type: 'catalog',
      object_section: 'product',
      data: { category: category_id },
    };
  }, [category_id]);

  return (
    <ProductCardVirtualList
      use_rank
      key_name={BEST_PRODUCT_LIST}
      is_loading={is_loading}
      product_list={best_product_list}
      product_ubl={best_product_ubl}
    />
  );
};

export default ProductList;
