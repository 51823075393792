import { useRouter } from 'next/router';
import { useSearchQueryString } from 'hooks';
import { ROOT_PRODUCT_CATEGORY } from 'hooks/queries';
/**
 * 카테고리 리스트 페이지에서 사용하는 라우터 QueryString 공용 hook
 * @hook
 */
const useCategoryRouterProps = () => {
  const {
    query: { category_id, sub_category_id, sort_type, hide_one_depth_category, product_list_page },
  } = useRouter();
  const { search_filter_ubl } = useSearchQueryString();
  const is_selected_sort_type = !!sort_type;
  const new_category_id = category_id ?? ROOT_PRODUCT_CATEGORY.id;

  const data_ubl = {
    category_id: new_category_id,
    sub_category_id: sub_category_id || '-1',
  };

  const category_data_ubl = {
    ...search_filter_ubl,
    category_id: new_category_id,
    sub_category_id: sub_category_id || '-1',
  };

  const category_id_with_sub_category_id = sub_category_id
    ? `${new_category_id};${sub_category_id}`
    : String(new_category_id);

  return {
    category_id: Number(new_category_id),
    sub_category_id: sub_category_id && String(sub_category_id),
    selected_category_id: Number(sub_category_id || new_category_id),
    category_id_with_sub_category_id,
    is_selected_sort_type,
    data_ubl,
    category_data_ubl,
    hide_one_depth_category: hide_one_depth_category ? String(hide_one_depth_category) === 'true' : false,
    product_list_page_type: product_list_page,
  };
};
export default useCategoryRouterProps;
