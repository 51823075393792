import styled from '@emotion/styled';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';

interface Props {
  title: string;
  sub_title: string;
  is_loading?: boolean;
  use_all_view_button?: boolean;
  onAllViewClick?: () => void;
}

/**
 * 세일탭 > 공통 타이틀
 * @component
 */
const Title = ({ title, sub_title, is_loading, use_all_view_button, onAllViewClick }: Props) => {
  if (is_loading) {
    return (
      <SC.Container>
        <SC.TitleWrap className='skeleton'>
          <Skeleton width={82} height={22} radius={2} />
          <Skeleton width={211} height={18} radius={2} />
        </SC.TitleWrap>
        {use_all_view_button && <Skeleton width={49} height={18} radius={2} />}
      </SC.Container>
    );
  }

  return (
    <SC.Container>
      <SC.TitleWrap>
        <Text variant='title4_bold' mb={2}>
          {title}
        </Text>
        <Text variant='body4_regular' color='gray_secondary'>
          {sub_title}
        </Text>
      </SC.TitleWrap>
      {use_all_view_button && (
        <button onClick={onAllViewClick}>
          <Text variant='body4_medium' color='gray_secondary'>
            모두보기
          </Text>
        </button>
      )}
    </SC.Container>
  );
};

export default Title;

const SC = {
  Container: styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    padding: 8px 16px;
  `,
  TitleWrap: styled.div`
    display: flex;
    flex-direction: column;

    &.skeleton > * {
      margin-bottom: 2px;
    }
  `,
};
