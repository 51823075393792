import styled from '@emotion/styled';

import { pink_primary, white } from '../../../../styles';
import Check16 from 'components/base/Icon/Check16';

const CheckIcon = () => {
  return (
    <SC.Root>
      <SC.IconContainer checked>
        <Check16 color={white} />
      </SC.IconContainer>
    </SC.Root>
  );
};

export default CheckIcon;

const SC = {
  Root: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px 0 0;

    &:active {
      background-color: transparent;
    }
  `,
  IconContainer: styled.div<{checked: boolean}>`
    padding-bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ checked }) => checked ? pink_primary : 'rgba(0, 0, 0, 0.4)'};
  `,
};
