import { useMutation } from '@tanstack/react-query';

import isNil from 'lodash-es/isNil';
import { shopliveIssueCoupon, issueCouponPack as issueCouponPackMutate } from 'api2';
import { ShopliveCouponStatus } from 'api2/types';

export const COUPON_ISSUE_MSG: {
  [key in ShopliveCouponStatus]: string;
} = {
  [ShopliveCouponStatus.SUCCEEDED]: '쿠폰이 발급되었어요.',
  [ShopliveCouponStatus.FAILED]: '쿠폰을 받을 수 없어요.',
  [ShopliveCouponStatus.ALREADY_ISSUED]: '이미 발급 받은 쿠폰이에요.\n마이페이지 > 쿠폰을 확인해주세요.',
  [ShopliveCouponStatus.NOT_FOUND]: '쿠폰을 받을 수 없어요.',
  [ShopliveCouponStatus.NOT_LOGGED_IN]: '로그인 후 이용해 주세요.',
  [ShopliveCouponStatus.EXPIRED]: '쿠폰의 발급 기한이 지났어요.',
  [ShopliveCouponStatus.EXHAUSTED]: '쿠폰이 모두 소진되었어요.',
};

// 단건 쿠폰 발급
export const issueCoupon = async (user_account_coupon_policy_id: string): Promise<ShopliveCouponStatus> => {
  try {
    const {
      data: {
        fbkShopliveIssueCoupon: {
          user_account_coupon: { status },
        },
      },
    } = await shopliveIssueCoupon({ input: { user_account_coupon_policy_id } });
    return status;
  } catch (e) {
    console.error(e);
  }
  return ShopliveCouponStatus.FAILED;
};

//  쿠폰팩 발급
export const issueCouponPack = async (user_account_coupon_pack_id: string): Promise<ShopliveCouponStatus> => {
  try {
    const {
      data: { fbkIssueCouponPack },
    } = await issueCouponPackMutate({ input: { user_account_coupon_pack_id } });
    if (isNil(fbkIssueCouponPack)) {
      return ShopliveCouponStatus.FAILED;
    }
    return ShopliveCouponStatus[fbkIssueCouponPack.status as ShopliveCouponStatus];
  } catch (e) {
    console.error(e);
  }
  return ShopliveCouponStatus.FAILED;
};

interface Props {
  // 단일쿠폰: coupon, 쿠폰팩: pack
  type: 'coupon' | 'pack';
  // 발급받을 쿠폰 policy_id
  id: string;
}

// 단일 쿠폰 or 쿠폰 팩을 발급받기 위한 api hook
const useIssueCoupon = () => {
  return useMutation(({ type, id }: Props) => {
    return type === 'pack' ? issueCouponPack(id) : issueCoupon(id);
  });
};

export default useIssueCoupon;
