import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getFbkPromotionBdageList } from 'api2';
import { GetFbkPromotionBdageList } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';

export const PROMOTION_BADGE_LIST_QUERY_KEY = ['getPromotionBadgeList'];

const init_data: GetFbkPromotionBdageList['promotion_badge_list'] = {
  item_list: [],
};

export const fetchPromotionBadgeList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { promotion_badge_list },
    } = await getFbkPromotionBdageList(undefined, context_request);

    return promotion_badge_list;
  } catch (error) {
    return init_data;
  }
};

/**
 * fbk 서버를 거치지 않고 내려오는 상품카드 중 프로모션 뱃지를 표시하기 위해 조회하는 hook
 * e.g) cms product item
 * @hook
 */
export const usePromotionBadgeList = () => {
  const { data = init_data, ...rest } = useQuery(PROMOTION_BADGE_LIST_QUERY_KEY, () => fetchPromotionBadgeList());

  const getProductCardWithFbkBadgeList = (product_card: ProductCardProps) => {
    const found_item = data.item_list.find(
      (badge) =>
        (badge.type === 'PRODUCT' && badge.id === product_card.catalog_product_id) ||
        (badge.type === 'SHOP' && badge.id === product_card.shop_id),
    );

    if (found_item) {
      return {
        ...product_card,
        thumbnail_emblem_badge_list: [{ type: 'FBK_PROMOTION_BADGE', image_url: found_item.badge_image_url }],
      };
    }
    return {
      ...product_card,
      thumbnail_emblem_badge_list: [],
    };
  };

  return {
    getProductCardWithFbkBadgeList,
    ...rest,
  };
};
