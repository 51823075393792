import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { black, border2, gray500, pink050, pink600, white } from '../../../styles';
import Review from './Review';
import Tags from './Tags';
import { ProductCardDirection, ProductCardProps, ProductCardSize } from './index';
import Text from 'components/base/Text';
import { FontVarient, typography } from 'styles/font';
import { getWelcomeDealInfo } from 'util/promotion_utils';

const REVIEW_SCORE_EXPOSE_CONDITION = 3.5;

type SizeMetaStyle = Record<
  ProductCardSize,
  {
    meta_css: SerializedStyles;
    shop_name: FontVarient;
    title: FontVarient;
    price: FontVarient;
    price_css: SerializedStyles;
    title_css: SerializedStyles;
    sale_css: SerializedStyles;
    top_css: SerializedStyles;
    discount_css: SerializedStyles;
    review_css: SerializedStyles;
    footer_css: SerializedStyles;
  }
>;

const VERTICAL_STYLE: SizeMetaStyle = {
  large: {
    meta_css: css`
      margin: 6px 2px 0 0;
    `,
    top_css: css`
      justify-content: space-between;
      height: 22px;
    `,
    sale_css: css`
      ${typography.title4_bold}
      margin-right: 2px;
    `,
    price: 'title4_bold',
    price_css: css`
      ${typography.title4_bold}
      margin: 0 2px 0 0;
    `,
    discount_css: css`
      margin-left: 4px;
    `,
    title: 'small1_regular',
    title_css: css`
      margin-top: 1px;
      height: 18px;
    `,
    shop_name: 'small2_bold',
    review_css: css``,
    footer_css: css`
      margin-top: 2px;
      height: 15px;
    `,
  },
  medium: {
    meta_css: css`
      margin: 5px 2px 0 0;
    `,
    top_css: css`
      justify-content: space-between;
      height: 21px;
    `,
    sale_css: css`
      ${typography.title5_bold}
      margin-right: 3px;
    `,
    price: 'body4_bold',
    price_css: css`
      ${typography.title5_bold}
      margin: 0 2px 0 0;
    `,
    title: 'small1_regular',
    title_css: css`
      margin-top: 1px;
      height: 15px;
    `,
    shop_name: 'small3_bold',
    discount_css: css``,
    review_css: css``,
    footer_css: css`
      margin-top: 4px;
      margin-bottom: 1px;
      height: 15px;
    `,
  },
  small: {
    meta_css: css`
      margin: 5px 2px 0 0;
    `,
    top_css: css`
      justify-content: space-between;
      height: 18px;
    `,
    sale_css: css`
      ${typography.body4_bold}
      margin-right: 2px;
    `,
    price: 'title4_medium',
    price_css: css`
      ${typography.title6_bold}
      margin: 0 2px 0 0;
    `,
    discount_css: css``,
    title: 'small2_regular',
    title_css: css`
      height: 14px;
    `,
    shop_name: 'small3_bold',
    review_css: css`
      margin-top: 0;
    `,
    footer_css: css`
      margin-top: 3px;
    `,
  },
};

const HORIZONTAL_STYLE: SizeMetaStyle = {
  large: {
    meta_css: css`
      margin: 6px 2px 0 0;
    `,
    top_css: css`
      height: 18px;
    `,
    sale_css: css`
      ${typography.title4_bold}

      margin-right: 4px;
    `,
    price: 'title4_bold',
    price_css: css`
      ${typography.title4_bold}
      justify-content: flex-start;
      height: 22px;
    `,
    discount_css: css`
      margin-left: 4px;
    `,
    title: 'body4_regular',
    title_css: css`
      height: 18px;
    `,
    shop_name: 'small2_regular',
    review_css: css`
      margin-top: 4px;
    `,
    footer_css: css`
      margin-top: 2px;
      height: 15px;
    `,
  },
  medium: {
    meta_css: css``,
    top_css: css`
      justify-content: flex-start;
      height: 21px;
    `,
    sale_css: css`
      ${typography.title5_bold}
      margin-right: 4px;
    `,
    price: 'body4_bold',
    price_css: css`
      ${typography.title5_bold}
      margin: 0 2px 0 0;
    `,
    title: 'small1_regular',
    title_css: css`
      margin-top: 2px;
      height: 15px;
    `,
    shop_name: 'small3_bold',
    discount_css: css``,
    review_css: css`
      margin-top: 4px;
    `,
    footer_css: css`
      margin-top: 4px;
      flex-direction: column;
      align-items: flex-start;
    `,
  },
  small: {
    meta_css: css``,
    top_css: css`
      justify-content: flex-start;
      height: 21px;
    `,
    sale_css: css`
      ${typography.title6_bold}
      margin-right: 4px;
    `,
    price: 'title5_medium',
    price_css: css`
      ${typography.title6_bold}
      margin-right: 4px;
    `,
    discount_css: css``,
    title: 'small1_regular',
    title_css: css`
      margin-top: 1px;
      height: 17px;
    `,
    shop_name: 'small2_regular',
    review_css: css`
      margin-top: 0;
    `,
    footer_css: css``,
  },
};

const META_STYLE_INFO: Record<ProductCardDirection, SizeMetaStyle> = {
  horizontal: HORIZONTAL_STYLE,
  vertical: VERTICAL_STYLE,
};

const MetaInfo: FC<ProductCardProps> = ({
  review_count,
  review_score,
  shop,
  size = 'medium',
  is_show_badge = true,
  title,
  discount_rate,
  final_price,
  free_shipping,
  is_new,
  is_zonly,
  is_fast_delivery,
  is_ad,
  meta_info_css,
  direction = 'vertical',
  catalog_product_id,
  thumbnail_emblem_badge_list,
}) => {
  const { asPath: as_path } = useRouter();
  const card_size = META_STYLE_INFO[direction][size];
  const is_show_review_score = useMemo(() => {
    return !!review_count && !!review_score && review_score >= REVIEW_SCORE_EXPOSE_CONDITION;
  }, [review_count, review_score]);

  const is_empty_discount_rate = discount_rate === 0 || discount_rate === null;
  const max_price = is_empty_discount_rate ? final_price : Math.floor(final_price / (1 - discount_rate * 0.01));
  const promotion_discount = getPromotionDiscount(max_price, catalog_product_id ?? '', as_path);

  const getPriceInfo = () => {
    function getFinalPrice() {
      if (promotion_discount) {
        return promotion_discount.final_price;
      }

      return final_price;
    }

    function getDiscountRate() {
      if (promotion_discount) {
        return promotion_discount.discount_rate;
      }

      return discount_rate;
    }

    return {
      final_price: getFinalPrice(),
      discount_rate: getDiscountRate(),
    };
  };

  const price_info = getPriceInfo();

  const is_show_discount_rate = !!price_info.discount_rate && size !== 'large';
  const is_show_tags = is_show_badge && direction === 'horizontal';
  const is_show_review = is_show_review_score && direction === 'horizontal';
  const is_show_seller = !!shop.name || !!is_ad;
  const is_show_footer = is_show_review || is_show_seller;
  return (
    <SC.MetaData css={meta_info_css ?? card_size.meta_css}>
      <SC.Top css={card_size.top_css}>
        <div className='left'>
          {is_show_discount_rate && <SC.Sale css={card_size.sale_css}>{price_info.discount_rate}%</SC.Sale>}
          <SC.Price css={card_size.price_css}>{price_info.final_price.toLocaleString('ko-KR')}</SC.Price>
        </div>
        {is_show_review_score && direction !== 'horizontal' && (
          <Review
            css={card_size.review_css}
            is_show_review_count={size !== 'small'}
            review_score={review_score!}
            review_count={review_count!}
            size={size}
          />
        )}
      </SC.Top>
      <SC.Title variant={card_size.title} css={card_size.title_css} color='gray800' as='p'>
        {title}
      </SC.Title>
      {is_show_footer && (
        <SC.Footer css={card_size.footer_css}>
          <SC.Seller>
            {is_ad && (
              <div className='ad_badge'>
                <Text variant='small4_medium' color='gray_tertiary' as='p'>
                  AD
                </Text>
              </div>
            )}
            {shop.name && (
              <Text className='seller_name' variant={card_size.shop_name} color='gray600'>
                {shop.name}
              </Text>
            )}
          </SC.Seller>
          {is_show_review && (
            <Review
              css={card_size.review_css}
              is_show_review_count={size !== 'small'}
              review_score={review_score!}
              review_count={review_count!}
              size={size}
            />
          )}
        </SC.Footer>
      )}
      {is_show_tags && (
        <Tags
          size={size}
          custom_css={badge_css}
          free_shipping={free_shipping ?? false}
          is_new={!!is_new}
          is_zonly={is_zonly}
          is_fast_delivery={is_fast_delivery}
          thumbnail_emblem_badge_list={thumbnail_emblem_badge_list}
        />
      )}
    </SC.MetaData>
  );
};

export default MetaInfo;

// 프로모션 기간 최종가격 및 할인율 보정
function getPromotionDiscount(max_price: number, product_id: string, path: string) {
  const { price, url, product_list } = getWelcomeDealInfo();
  const is_welcome_deal_product = product_list.find((id) => id === product_id);
  const is_welcome_deal_page = path.indexOf(url) > -1;
  if (is_welcome_deal_product && is_welcome_deal_page) {
    return {
      final_price: price,
      discount_rate: max_price > 0 ? Math.floor(((max_price - price) / max_price) * 100) : 99,
    };
  }

  return null;
}

const badge_css = css`
  margin-top: 7px;
`;

const SC = {
  MetaData: styled.div`
    display: flex;
    flex-direction: column;
    background: ${white};
  `,
  Title: styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  Top: styled.div`
    display: flex;
    align-items: center;

    .left {
      display: flex;
    }
  `,
  CouponBadge: styled.div`
    border-radius: 2px;
    background-color: ${pink050};
    width: 32px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Seller: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .seller_name {
      display: flex;
      align-items: center;
      height: 12px;
      letter-spacing: -0.3px;
    }

    .ad_badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${border2};
      border-radius: 2px;
      width: 19px;
      height: 13px;
      margin-right: 2px;
    }
  `,
  Sale: styled.span`
    display: flex;
    align-items: center;
    color: ${pink600};
  `,
  Price: styled.span`
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: ${black};
  `,
  NoneZPriceText: styled.div`
    ${typography.small3_bold}
    margin: 2px 0 0;
    padding: 0;
    color: ${gray500};
  `,
};
