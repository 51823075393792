import { ActionContext, AddFavoriteStoreActionHandler } from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { getCmsEventLoggers } from '../log';
import { saveStoreLike, unSaveStoreLike } from 'api2';
import { StoreLikeInfo, getClientStoreLike, setClientStoreLike } from 'util/like/store_like';
import { Toast } from 'util/Toast';

const MESSAGE = {
  LIKE: '찜 스토어에 추가했어요.',
  UN_LIKE: '찜 스토어에서 해제했어요.',
  ERROR: '다시 시도해 주세요.',
};

export const handleAddFavoriteStore: AddFavoriteStoreActionHandler<ModuleRootCustomData> = async (action, context) => {
  const {
    value: { shop_id },
  } = action;
  const { is_login, openLoginDialog } = context.metadata.custom_data;

  if (!is_login) {
    openLoginDialog();
    return;
  }

  const is_client_like_info = getClientStoreLike(shop_id);
  const is_like_store = Boolean(is_client_like_info && is_client_like_info.is_like);
  const store_like_info: StoreLikeInfo = { shop_id, is_like: !is_like_store };

  if (store_like_info.is_like) {
    const { errors } = await saveStoreLike({ shop_id }, { show_alert: false });
    if (errors) {
      throw errors;
    }
  } else {
    const { errors } = await unSaveStoreLike({ shop_id }, { show_alert: false });
    if (errors) {
      throw errors;
    }
  }
  setClientStoreLike(store_like_info);
  logAction([store_like_info, context]);

  Toast.show(store_like_info.is_like ? MESSAGE.LIKE : MESSAGE.UN_LIKE, { bottom_floating_position: 90 });
};

function logAction([store_like_info, { metadata }]: [StoreLikeInfo, ActionContext]) {
  try {
    const { logSavedUmdShop } = getCmsEventLoggers(metadata);

    logSavedUmdShop({
      category: store_like_info.is_like ? 'add_bookmark' : 'remove_bookmark',
      shop_id: store_like_info.shop_id,
      is_bookmarked: store_like_info.is_like,
    });
  } catch (error) {
    console.error(error);
  }
}
