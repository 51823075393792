const DeleteIcon = () => {
  return (
    <svg width='20' height='20' fill='none' viewBox='0 0 32 32'>
      <path
        fill='currentColor'
        d='M18.25 7.667v.666h1.5v-.666h-1.5Zm-4.5.666v-.666h-1.5v.666h1.5ZM16 5.75c1.328 0 2.25.939 2.25 1.917h1.5c0-1.968-1.764-3.417-3.75-3.417v1.5Zm0-1.5c-1.986 0-3.75 1.45-3.75 3.417h1.5c0-.978.922-1.917 2.25-1.917v-1.5ZM13.75 9v-.667h-1.5V9h1.5Zm4.5-.667V9h1.5v-.667h-1.5Z'
      />
      <path stroke='currentColor' strokeWidth={1.5} d='M6 9h20' />
      <path
        fill='currentColor'
        d='M22.222 25v.75h.671l.075-.667-.746-.083ZM9.778 25l-.746.083.075.667h.67V25ZM23.255 8.917l-1.778 16 1.49.166 1.778-16-1.49-.166ZM22.222 24.25H9.778v1.5h12.444v-1.5Zm-11.699.667-1.778-16-1.49.166 1.777 16 1.491-.166Z'
      />
      <path stroke='currentColor' strokeWidth={1.5} d='M14 13v8M18 13v8' />
    </svg>
  );
};

export default DeleteIcon;
