import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { IconDownload16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { useNewCoupon } from 'components/home/hooks/useNewCouponInfo';
import { useUserInfo } from 'components/my-page/hooks';
import useIssueCoupon, { COUPON_ISSUE_MSG } from 'queries/coupon/useIssueCoupon';
import { gray900, pink300, white } from 'styles';
import { Toast } from 'util/Toast';
import { Log, useTracker } from 'util/Tracker';

interface Props {
  ubl: Pick<Log, 'navigation' | 'object_section'>;
}

const NewCoupon: FC<Props> = ({ ubl }) => {
  const tracker = useTracker();
  const { ref: coupon_ref, inView: in_view } = useInView({ threshold: 0.5 });
  const [is_issued, setIsIssued] = useState(false);
  const { is_login } = useUserInfo();
  const { coupon_info } = useNewCoupon();
  const { openLoginDialog } = useLoginDialog();
  const { mutate: issue_coupon } = useIssueCoupon();
  const common_ubl = useMemo(
    () => ({
      ...ubl,
      object_type: 'button',
      object_id: 'new_product_coupon',
      object_idx: 0,
      data: { coupon_id: coupon_info?.coupon_policy_id },
    }),
    [ubl, coupon_info?.coupon_policy_id],
  );

  const coupon_discount_date = (coupon_info?.coupon_discount_rate_bp ?? 100) / 100;

  useEffect(() => {
    if (in_view) {
      tracker.addLog({ ...common_ubl, category: 'impression' });
    }
  }, [tracker, common_ubl, in_view]);

  useEffect(() => setIsIssued(coupon_info?.is_issued ?? false), [coupon_info?.is_issued, setIsIssued]);

  const handleIssueCouponClick = async () => {
    if (!is_login) {
      openLoginDialog();
      return;
    }

    tracker.addLog({ ...common_ubl, category: 'click' });
    issue_coupon(
      { type: 'coupon', id: coupon_info?.coupon_policy_id ?? '' },
      {
        onSuccess: async (status) => {
          setIsIssued(true);
          Toast.show(COUPON_ISSUE_MSG[status]);
        },
      },
    );
  };

  return (
    <SC.Container ref={coupon_ref}>
      <SC.Button>
        <Text variant='title6_bold' color='white' as='p' alignment='center'>
          신상품 <b className='price_info'>{coupon_discount_date}%</b> 할인해 드려요.
        </Text>
        <div className={cx('item', { opacity: is_issued })} onClick={handleIssueCouponClick}>
          <Text variant='body4_bold' color='white' as='p' mr={4}>
            {is_issued ? '받기완료' : '쿠폰받기'}
          </Text>
          <IconDownload16 color={white} />
        </div>
      </SC.Button>
    </SC.Container>
  );
};

export default NewCoupon;

const SC = {
  Container: styled.section`
    padding: 8px 16px 12px;
  `,
  Button: styled.button`
    padding: 12px 16px 13px;
    background-color: ${gray900};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;

    &:active {
      background-color: ${gray900};
    }

    b {
      color: ${pink300};
      font-weight: 600;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .opacity {
      opacity: 0.4;
    }
  `,
};
