import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';

import { ImageSearchTip } from '../common';
import ImageSearchGuidance from '../common/ImageSearchGuidance';
import PresetImageItem from '../common/PresetImageItem';
import { push_image_search_history_atom } from '../store';
import { Button } from 'components/base/Button';
import { IconPlus16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import Thumbnail from 'components/base/Thumbnail';
import { useImageSearchPresetProducts } from 'components/search/hooks/useImageSearchPresetProducts';
import { useCheckKakaoAosBrowser } from 'hooks';
import { ImageSearchType } from 'pages/search/image/index.page';
import { white } from 'styles';
import { fileToBase64 } from 'util/image';
import { useTracker } from 'util/Tracker';

/**
 * 이미지 검색 홈 화면입니다.
 */
const ImageSearchMain: FC = () => {
  const router = useRouter();
  const tracker = useTracker();
  const { products } = useImageSearchPresetProducts();
  const [is_show_guidance, setIsShowGuidance] = useState<boolean>(false);
  const pushImageSearchHistory = useUpdateAtom(push_image_search_history_atom);

  const handleInputClick = useCheckKakaoAosBrowser({
    contents: {
      text: '현재 사용중인 버전의 카카오톡에서는\n이미지 검색 기능을 지원하지 않습니다.\n\n패션 바이 카카오 앱에서 이용해주세요.',
      text_color: 'gray700',
    },
    buttons: [
      { id: 'dismiss', size: 'large', variant: 'tertiary_gray', children: '취소' },
      { id: 'confirm', size: 'large', children: '앱으로 이동하기' },
    ],
    callback: ({ currentTarget }: MouseEvent<HTMLInputElement>) => {
      const { objectId: object_id } = currentTarget.dataset;
      tracker.addLog({
        category: 'click',
        navigation: 'image_search',
        object_type: 'button',
        object_id,
      });
    },
  });

  const handleFile = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files?.item(0);
    if (file) {
      const source = await fileToBase64(file);
      pushImageSearchHistory({
        source,
        cropped: '',
      });
      router.push('/search/image/cropper');
    }
  };

  const handlePresetClick = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const { currentTarget } = e;
    const { imageUrl: image_url, objectId: catalog_product_id } = currentTarget.dataset;
    pushImageSearchHistory({
      source: image_url!,
      cropped: image_url!,
    });
    router.push(`/search/image?search_type=${ImageSearchType.catalog}&catalog_product_id=${catalog_product_id}`);
  };

  const openGuidance = () => setIsShowGuidance(true);
  const closeGuidance = () => setIsShowGuidance(false);

  return (
    <>
      <SC.Root>
        <SC.TitleSection>
          <Text variant='title4_bold' color='gray_primary' mb={2}>
            이미지 업로드
          </Text>
          <Text variant='body4_regular' color='gray_secondary'>
            이미지와 비슷한 상품을 찾아보세요.
          </Text>
        </SC.TitleSection>
        <SC.InputSection>
          <Button fill variant='primary'>
            <IconPlus16 color={white} />
            <Text variant='title5_medium' color='white'>
              업로드 하기
            </Text>
            <SC.Input
              data-object-id={ImageSearchType.upload}
              type='file'
              accept='image/*'
              onChange={handleFile}
              onClick={handleInputClick}
            />
          </Button>
        </SC.InputSection>
        <SC.TipSection>
          <ImageSearchTip onDetailClick={openGuidance} />
        </SC.TipSection>
        <SC.TitleSection>
          <Text variant='title4_bold' color='gray_primary'>
            베스트 상품 코디 검색
          </Text>
          <Text variant='body4_regular' color='gray_secondary'>
            코디와 비슷한 상품을 찾아보세요.
          </Text>
        </SC.TitleSection>
        <SC.PresetImageListSection>
          <SC.PresetImageList>
            {products.map(({ catalog_product_id, image_url }, index) => {
              return (
                <PresetImageItem
                  key={catalog_product_id}
                  catalog_product_id={catalog_product_id ?? ''}
                  image_url={image_url ?? ''}
                  onClick={handlePresetClick}
                  data-image-url={image_url}
                  data-navigation='image_search'
                  data-object-id={catalog_product_id}
                  data-object-idx={index}
                />
              );
            })}
          </SC.PresetImageList>
        </SC.PresetImageListSection>
      </SC.Root>
      <ImageSearchGuidance active={is_show_guidance} close={closeGuidance} />
    </>
  );
};

export default ImageSearchMain;

const SC = {
  Root: styled.div`
    padding-top: 12px;
  `,
  TitleSection: styled.section`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  `,
  InputSection: styled.section`
    padding: 8px 16px;
  `,
  Input: styled.input`
    position: absolute;
    inset: 0;
    opacity: 0;
  `,
  TipSection: styled.section`
    padding: 8px 16px;
    margin-bottom: 20px;
  `,
  PresetImageListSection: styled.section`
    padding: 8px;
  `,
  PresetImageList: styled.ul`
    display: flex;
    flex-wrap: wrap;
  `,
  PresetImageItem: styled.li`
    position: relative;
    flex: 0;
    min-width: calc(50% - 1.5px);
    margin-bottom: 3px;

    &:nth-child(odd) {
      margin-right: 3px;
    }
  `,
  PresetImage: styled(Thumbnail)`
    width: 100%;
    aspect-ratio: 0.89;
    object-fit: cover;
    border-radius: 2px;

    @supports not (aspect-ratio: 0.89) {
      padding-top: ${100 / 0.89}%;
    }
  `,
};
