import { Action, FloatingButtonModuleProps, useAction, useContentMetadata } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { WEB_MAX_WIDTH } from 'styles';

export type FloatingButtonWithActionProps = Omit<FloatingButtonModuleProps, 'action'> & { action: Action };

export const FloatingButtonWithAction = ({
  action,
  active_background_color,
  active_color,
  button_round,
  complete_text,
  disabled_background_color,
  disabled_color,
  font_weight,
  prepare_text,
  progress_text,
}: FloatingButtonWithActionProps) => {
  const { is_after, is_before } = useContentMetadata();
  const { dispatch, state } = useAction(action);
  const disabled = is_after || is_before || state.disabled || state.dispatching;

  const button_text = useMemo(() => {
    if (is_before) {
      return prepare_text;
    }

    if (is_after) {
      return complete_text;
    }

    return progress_text;
  }, [complete_text, is_after, is_before, prepare_text, progress_text]);

  return (
    <Wrapper>
      <Button
        style={{
          color: disabled ? disabled_color : active_color,
          backgroundColor: disabled ? disabled_background_color : active_background_color,
          borderRadius: button_round,
        }}
        disabled={disabled}
        onClick={dispatch}
        font_weight={font_weight}
      >
        {button_text}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 300;
  width: 100%;
  max-width: ${WEB_MAX_WIDTH}px;
`;

const Button = styled.button<{ font_weight: FloatingButtonModuleProps['font_weight'] }>`
  position: absolute;
  right: 20px;
  bottom: 60px;
  left: 20px;
  padding: 16px;
  border: 0;
  font-size: 15px;
  font-weight: ${({ font_weight }) => (font_weight === 'bold' ? 700 : 400)};
  line-height: 1.2;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
