import { SerializedStyles } from '@emotion/react';
import { useAtom } from 'jotai';
import { FC, useMemo } from 'react';
import { TabItem } from 'components/base/Tabs';
import ProductSection from 'components/common/ProductSection';
import { category_ranking_active_tab_id_atom } from 'components/home/store/trend';
import useSearchProductList from 'components/search/hooks/useSearchProductList';
import { CategoryItem, ROOT_PRODUCT_CATEGORY, useActivatedDisplayCategory } from 'hooks/queries';
import { SORT_TYPE } from 'util/search_utils';
import { Log, useTracker } from 'util/Tracker';

interface Props {
  ubl?: Omit<Log, 'category'>;
  custom_css?: SerializedStyles;
  use_category?: boolean;
}

/**
 * 트랜드탭 - 카테고리 랭킹
 * @component
 */
const CategoryRanking: FC<Props> = ({ ubl, use_category, custom_css }) => {
  const tracker = useTracker();
  const [category_ranking_active_tab_id, setCategoryRankingActiveTabId] = useAtom(category_ranking_active_tab_id_atom);
  const { product_category_list, is_loading: is_category_loading } = useActivatedDisplayCategory({
    use_root_category: true,
  });
  const category_ranking_tab_id = use_category ? category_ranking_active_tab_id : ROOT_PRODUCT_CATEGORY;
  const product_count = use_category ? 6 : 9;
  const title = use_category ? '카테고리 랭킹' : '오늘의 랭킹';

  const { product_list, is_search_product_fetching } = useSearchProductList(
    '',
    {
      sort_type: SORT_TYPE.SCORE_DESC,
      category_list: [
        {
          id: category_ranking_tab_id.id,
          name: category_ranking_tab_id.name,
          depth: 1,
        },
      ],
    },
    product_count,
  );
  const category_ranking_ubl = useMemo(
    () =>
      ubl
        ? {
            ...ubl,
            object_section: 'category_best_list',
          }
        : undefined,
    [ubl],
  );

  const handleAllClick = () => {
    if (category_ranking_ubl) {
      tracker.addLog({
        ...category_ranking_ubl,
        category: 'click',
        object_id: 'view_all',
      });
    }
  };

  const product_ubl = category_ranking_ubl
    ? {
        ...category_ranking_ubl,
        data: { ...category_ranking_ubl.data, category_1st: { name: category_ranking_tab_id.name } },
      }
    : undefined;

  const tab_info = use_category
    ? {
        tab_list: is_category_loading ? [] : getCategoryTabList(product_category_list),
        selected_tab_id: category_ranking_tab_id.id,
        ubl: category_ranking_ubl,
        onClickTab: (tab: TabItem) => {
          setCategoryRankingActiveTabId({ id: tab.id, name: tab.label, icon_url: '' });
        },
      }
    : undefined;

  return (
    <ProductSection
      is_show_product_rank
      title={title}
      product_expose_type='list'
      css={custom_css}
      is_loading={is_search_product_fetching}
      product_list={product_list}
      product_ubl={product_ubl}
      right_info={{
        text: '모두보기',
        href: `/product-list/best?category_id=${ROOT_PRODUCT_CATEGORY.id}`,
        onClick: handleAllClick,
      }}
      tab_info={tab_info}
    />
  );
};

export default CategoryRanking;

function getCategoryTabList(product_category_list: CategoryItem[]): TabItem[] {
  return product_category_list.map((category) => ({
    label: category.name,
    id: category.id,
  }));
}
