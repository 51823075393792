import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import Title from '../Title';
import List, { DISCOUNTED_NEW_PRODUCT_LIST_KEY_NAME } from './List';
import Chips from 'components/common/Chips';
import { resetVirtualProductListScrollIndexAtom } from 'components/common/store/product';
import { useCategoryStickeyHeight } from 'components/home/hooks/useCategoryStickeyHeight';
import { PRODUCT_LIST_TYPE, ROOT_PRODUCT_CATEGORY, useActivatedDisplayCategory } from 'hooks/queries';
/**
 * 홈 세일탭 > 할인하는 신상품
 * @component
 */
const DiscountedNewProducts = () => {
  const {
    query: { category_id },
  } = useRouter();
  const { product_category_chip_list, is_loading } = useActivatedDisplayCategory({
    product_list_type: PRODUCT_LIST_TYPE.DISCOUNTED_NEW,
    use_root_category: true,
  });
  const active_category_id = String(category_id || '') || ROOT_PRODUCT_CATEGORY.id;
  const stickey_height = useCategoryStickeyHeight();
  const resetVirtualProductListScrollIndex = useUpdateAtom(resetVirtualProductListScrollIndexAtom);

  const handleChipClick = () => {
    resetVirtualProductListScrollIndex(DISCOUNTED_NEW_PRODUCT_LIST_KEY_NAME);
  };

  const chips = product_category_chip_list.map((chip) => ({
    ...chip,
    href: `/?tab=sale&category_id=${chip.value}`,
  }));

  return (
    <SC.Container>
      <Title title='할인하는 신상품' sub_title='신상품을 할인 가격에 구매하세요.' is_loading={is_loading} />
      <Chips
        is_replace
        chips={chips}
        active_chip_value={active_category_id}
        is_loading={is_loading}
        stickey_height={stickey_height}
        onChipClick={handleChipClick}
      />
      <List active_category_id={active_category_id} />
    </SC.Container>
  );
};

export default DiscountedNewProducts;

const SC = {
  Container: styled.article`
    padding-top: 8px;
  `,
};
