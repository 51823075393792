import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { FC, HTMLAttributes, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from './Image';
import MetaInfo from './MetaInfo';
import { ProductCardItem } from './product';
import Skeleton from './Skeleton';
import Link from 'components/base/Link';
import { useFbkRouter } from 'hooks';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { Log, useTracker } from 'util/Tracker';

dayjs.extend(isBetween);

export type ProductCardUbl = Omit<Log, 'category' | 'object_idx' | 'object_id' | 'obejct_type'>;
export type ProductUbl = Omit<Log, 'category'> | undefined;
export type ProductCardDirection = 'vertical' | 'horizontal';
export type ProductCardSize = 'small' | 'medium' | 'large';

/**
 * LARGE 상품카드의 경우 심플스타일 타입이 사용되는 케이스가 종종 존재하여 별도정의
 */
export const LARGE_PRODUCT_SIMPLE_TYPE: Pick<
  ProductCardProps,
  'size' | 'is_show_badge' | 'is_show_shop_name' | 'discount_expose_type' | 'icon_top_left'
> = {
  size: 'large',
  is_show_badge: false,
  is_show_shop_name: false,
  discount_expose_type: 'image',
  icon_top_left: { type: 'discount' },
};
export interface ProductCardProps extends ProductCardItem {
  /**
   * 검색 아이콘 사용 여부를 정의합니다.
   */
  use_search?: boolean;
  /**
   * 검색 아이콘 툴팁 노출 여부를 정의합니다.
   */
  use_search_tooltip?: boolean;
  /**
   * 할인율 노출 위치를 세팅 합니다.
   * @default 'meta'
   */
  discount_expose_type?: 'meta' | 'image';
  /**
   * 뱃지 정보 노출 여부를 정의합니다.
   * @default true
   */
  is_show_badge?: boolean;
  /**
   * 스토어 이름 노출 여부
   * @default true
   */
  is_show_shop_name?: boolean;
  /**
   * 상품카드 액션(좋아요)를 통한 floading bottom 조정시 사용합니다.
   * @default 0
   */
  toast_bottom_floating_position?: number;
  /**
   * 상품카드의 index 를 정의합니다.
   * @default 0
   */
  index?: number;
  /**
   * 상품카드의 size를 정의합니다.
   * @default 'medium'
   */
  size?: ProductCardSize;
  /**
   * meta_info 영역 일부 커스텀 스타일링이 필요한 경우 정의합니다.
   */
  meta_info_css?: SerializedStyles;
  /**
   * 상품카드에 공통으로 들어가는 ubl 을 정의합니다.
   */
  ubl?: ProductCardUbl;
  /**
   * 상품카드가 viewport 에 노출될때 콜백함수로 호출해줍니다.
   */
  onView?: () => void;
  /**
   * 상품카드 클릭시 app 에서도 csr 로 접근가능하도록(새창 안뜨도록하기위함) 설정합니다. (네이티브 이전버전만 적용 deprecated 예정)
   * @default false
   */
  only_csr?: boolean;
  /**
   * 가로형 카드인지 세로형 카드인지 정의합니다.
   * @default 'vertical'
   */
  direction?: ProductCardDirection;
  /**
   * 썸네일 이미지 위에 icon들을 표시할지 정의합니다.
   * @default true
   */
  use_icon_slot?: boolean;
  /**
   * 왼쪽 상단 icon 유형을 정의합니다.
   * @default
   * { type: 'rank' }
   */
  icon_top_left?: {
    type: 'rank' | 'discount' | 'label';
    label?: string;
  };
  /**
   * 좌측 하단 icon 유형을 정의합니다.
   * @default
   * { type: 'md' }
   */
  icon_bottom_left?: {
    type: 'md';
    label?: string;
  };
  /**
   * 상품카드 클릭 이벤트 callback
   */
  onProductClick?: (catalog_product_id: string, index: number) => void;
}

type Props = ProductCardProps & Omit<HTMLAttributes<HTMLDivElement>, 'title'>;

/**
 * @components 상품카드
 * @description
 *  삭제 및 체크 아이콘이 필요하면 icons 디렉토리 내부 코드를 참고 해 주세요!
 */
const ProductCard: FC<Props> = (props) => {
  const {
    image_url,
    title,
    ubl,
    rank,
    catalog_product_id,
    onView,
    index = 0,
    log,
    only_csr,
    onProductClick,
    direction = 'vertical',
    ...rest
  } = props;
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const { getPdpPath } = useFbkRouter();
  const tracker = useTracker();
  const { is_native_version, is_not_native_app_version } = useAppVersion();

  const product_ubl: ProductUbl = useMemo(() => {
    if (ubl) {
      const data = log ? { ...ubl.data, server_log: log } : ubl.data;
      const ubl_info = {
        object_id: String(catalog_product_id),
        object_idx: index,
        object_type: 'catalog',
        ...ubl,
        data,
      };

      if (data) {
        ubl_info.data = data;
      }
      return ubl_info;
    }
    return;
  }, [ubl, index, catalog_product_id, log]);

  useEffect(() => {
    if (in_view) {
      onView?.();
    }
    if (in_view && product_ubl) {
      tracker.addLog({
        category: 'impression',
        ...product_ubl,
      });
    }
  }, [tracker, in_view, product_ubl, onView]);

  const handleClick = () => {
    if (product_ubl) {
      tracker.addLog({
        category: 'click',
        ...product_ubl,
      });
    }
    onProductClick?.(String(catalog_product_id), index);
  };
  const pdp_link_href = getPdpPath({ catalog_product_id });
  const is_new_webview_for_legacy_version = is_not_native_app_version && !only_csr;
  const LinkTag = is_native_version || is_new_webview_for_legacy_version ? SC.AppLink : Link;
  const card_css = card_direction_css[direction];

  return (
    <SC.Box {...rest} ref={ref} css={card_css}>
      <LinkTag
        href={pdp_link_href}
        onClick={handleClick}
        show_new_webview_for_legacy={is_new_webview_for_legacy_version}
      >
        <Image classname='image_container' use_icon_slot {...props} product_ubl={product_ubl} />
        <MetaInfo {...props} meta_info_css={props.meta_info_css} />
      </LinkTag>
    </SC.Box>
  );
};

export default Object.assign(ProductCard, {
  Skeleton,
});

const card_direction_css: Record<ProductCardDirection, SerializedStyles> = {
  vertical: css`
    .image_container {
      position: relative;
      width: 100%;
      aspect-ratio: 0.89;
      border-radius: inherit;

      img {
        border-radius: 4px;
      }
    }
  `,
  horizontal: css`
    a {
      display: flex;

      &:active {
        background-color: transparent;
      }
    }

    .image_container {
      position: relative;
      min-width: 110px;
      max-width: 168px;
      aspect-ratio: 0.89;

      img {
        border-radius: 4px;
      }
    }
  `,
};

const SC = {
  Box: styled.div`
    position: relative;
    overflow: hidden;
  `,
  AppLink: styled.a``,
};
