import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import AdBannerList from '../../../banner/AdBannerList';
import AdProductList from './AdProductList';
import CarouselList from './CarouselList';
import CategoryRanking from './CategoryRanking';
import LineBanner from './LineBanner';
import NewProductList from './NewProductList';
import PersonalProductSection from './PersonalProductSection';
import RecentRelatedProductSection from './RecentRelatedProductSection';
import RecommendStore from './RecommendStore';
import SearchRankingKeyword from './SearchRankingKeyword';
import FeatureMenus from 'components/categories/main/FeatureMenus';
import { PageName } from 'components/categories/main/hooks';
import AppAdNotifyAllow from 'components/common/AppAdNotifyAllow';
import Footer from 'components/common/Footer';
import CouponStore from 'components/home/common/CouponStore';
import TimeSaleList from 'components/home/TimeSaleList';
import TopBanner from 'components/home/TopBanner';
import { useMount } from 'hooks';
import { BANNER_PAGE_NAME } from 'hooks/queries';
import { border4 } from 'styles';
import { Log, useTracker } from 'util/Tracker';

const COMMON_UBL: Pick<Log, 'navigation'> = { navigation: 'home_ad' };
const CHUNK_COUNT: number = 4;

export type ModuleType =
  | {
      __typename: 'top_banner';
      comp: typeof TopBanner;
      args?: ComponentProps<typeof TopBanner>;
    }
  | {
      __typename: 'feature_menu';
      comp: typeof FeatureMenus;
      args?: ComponentProps<typeof FeatureMenus>;
    }
  | {
      __typename: 'line_banner';
      comp: typeof LineBanner;
      args?: ComponentProps<typeof LineBanner>;
    }
  | {
      __typename: 'ad_banner_list';
      comp: typeof AdBannerList;
      args?: ComponentProps<typeof AdBannerList>;
    }
  | {
      __typename: 'carousel_list';
      comp: typeof CarouselList;
      args?: ComponentProps<typeof CarouselList>;
    }
  | {
      __typename: 'personal_product';
      comp: typeof PersonalProductSection;
      args?: ComponentProps<typeof PersonalProductSection>;
    }
  | {
      __typename: 'category_ranking';
      comp: typeof CategoryRanking;
      args?: ComponentProps<typeof CategoryRanking>;
    }
  | {
      __typename: 'search_ranking_keyword';
      comp: typeof SearchRankingKeyword;
      args?: ComponentProps<typeof SearchRankingKeyword>;
    }
  | {
      __typename: 'recommend_store';
      comp: typeof RecommendStore;
      args?: ComponentProps<typeof RecommendStore>;
    }
  | {
      __typename: 'time_sale_list';
      comp: typeof TimeSaleList;
      args?: ComponentProps<typeof TimeSaleList>;
    }
  | {
      __typename: 'new_product_list';
      comp: typeof NewProductList;
      args?: ComponentProps<typeof NewProductList>;
    }
  | {
      __typename: 'recent_related_product_list';
      comp: typeof RecentRelatedProductSection;
      args?: ComponentProps<typeof RecentRelatedProductSection>;
    }
  | {
      __typename: 'coupon_store';
      comp: typeof CouponStore;
      args?: ComponentProps<typeof CouponStore>;
    }
  | {
      __typename: 'ad_product_list';
      comp: typeof AdProductList;
      args?: ComponentProps<typeof AdProductList>;
    };

const TREND_TAB_MODULE_LIST: ModuleType[] = [
  { __typename: 'top_banner', comp: TopBanner, args: { banner_page_name: BANNER_PAGE_NAME.TREND } },
  {
    __typename: 'line_banner',
    comp: LineBanner,
    args: {},
  },
  {
    __typename: 'feature_menu',
    comp: FeatureMenus,
    args: { page_name: PageName.TREND },
  },
  {
    __typename: 'ad_product_list',
    comp: AdProductList,
    args: {
      use_category_chips: false,
      renderBeforeProductRow: renderTrendTabAdModuleTypeB,
    },
  },
];

const AD_PRODUCT_MODULE_LIST: ModuleType[] = [
  {
    __typename: 'recent_related_product_list',
    comp: RecentRelatedProductSection,
    args: {
      custom_css: getSectionStyle('14px 0 32px'),
    },
  },
  {
    __typename: 'coupon_store',
    comp: CouponStore,
    args: {
      custom_css: getSectionStyle('14px 0 24px'),
    },
  },
  {
    __typename: 'category_ranking',
    comp: CategoryRanking,
    args: {
      custom_css: getSectionStyle('14px 0 32px'),
    },
  },
  {
    __typename: 'carousel_list',
    comp: CarouselList,
    args: { custom_css: getSectionStyle('14px 0 32px'), type: 'TREND_TAB_CAROUSEL', expose_index: 0 },
  },
  {
    __typename: 'carousel_list',
    comp: CarouselList,
    args: { custom_css: getSectionStyle('14px 0 32px'), type: 'TREND_TAB_CAROUSEL', expose_index: 1 },
  },
  {
    __typename: 'recommend_store',
    comp: RecommendStore,
    args: {
      custom_css: getSectionStyle('14px 0 32px'),
      ubl: { object_section: 'home_ad', navigation: 'store_carousel' },
    },
  },
  {
    __typename: 'time_sale_list',
    comp: TimeSaleList,
    args: { custom_css: getSectionStyle('20px 0 32px'), title: '오늘 놓치면 안되는 특가' },
  },
  {
    __typename: 'carousel_list',
    comp: CarouselList,
    args: { custom_css: getSectionStyle('10px 0 32px'), type: 'TREND_TAB_CAROUSEL', expose_index: 2 },
  },
  {
    __typename: 'carousel_list',
    comp: CarouselList,
    args: { custom_css: getSectionStyle('14px 0 32px'), type: 'TREND_TAB_CAROUSEL', expose_index: 3 },
  },
];

/**
 * 홈 페이지 - Trend탭 영역
 * @component
 */
const Trend = () => {
  const tracker = useTracker();

  useMount(() => {
    tracker.homeTrend({ home_trend: 'home_trend' });
  });

  return (
    <>
      {TREND_TAB_MODULE_LIST.map(({ comp: Comp, args, __typename }, index) => {
        const ubl_preset = __typename === 'ad_banner_list' && args?.ubl ? args.ubl : COMMON_UBL;
        const ubl_info: Omit<Log, 'category'> = {
          ...ubl_preset,
          data: { component_idx: index },
        };
        //@ts-ignore
        return <Comp {...args} key={index} ubl={ubl_info} />;
      })}
      <AppAdNotifyAllow />
      <Footer />
    </>
  );
};

export default Trend;

function renderTrendTabAdModuleTypeB(
  row_index: number,
  ad_product_component_idx: number,
  ubl_data?: Pick<Log, 'data'>,
) {
  const is_show_trend_tab_module = row_index !== 0 && row_index % CHUNK_COUNT === 0;
  if (is_show_trend_tab_module) {
    const chunk_index = Math.floor(row_index / CHUNK_COUNT);
    const trend_tab_module: ModuleType | undefined = AD_PRODUCT_MODULE_LIST[chunk_index - 1];

    // @ts-ignore
    if (!trend_tab_module) {
      return null;
    }

    const component_ubl = {
      ...COMMON_UBL,
      data: { ...ubl_data, component_idx: ad_product_component_idx + chunk_index },
    };
    const { comp: Component, args } = trend_tab_module;
    //@ts-ignore
    return <Component {...args} ubl={component_ubl} />;
  }

  return null;
}

function getSectionStyle(padding: string) {
  return css`
    margin: 14px 0 20px;
    padding: ${padding};
    border-top: 1px solid ${border4};
    border-bottom: 1px solid ${border4};
  `;
}
