import styled from '@emotion/styled';
import { forwardRef, PropsWithChildren } from 'react';
import Item from './Item';
import Skeleton from 'components/base/Skeleton';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { Log } from 'util/Tracker';

export interface CircleCategory {
  title: string;
  link?: string;
  img_url: string;
  ubl?: Omit<Log, 'category' | 'object_index'>;
}

export type CircleSize = {
  min: number;
  max: number;
};

interface Props extends PropsWithChildren {
  /**
   * 카테고리 리스트를 정의합니다.
   * @default []
   */
  categories?: CircleCategory[];
  /**
   * 카테고리 이미지 사이즈를 조정 합니다.
   * @default { min: 52, max: 64 }
   */
  size?: CircleSize;
  /**
   * 카테고리간 간격을 정의합니다.
   * @default '16px 9px'
   */
  gap?: string;
  /**
   * 제목과 이미지 사이 간격을 정의합니다.
   * @default '8px'
   */
  title_image_gap?: string;
  /**
   * 카테고리 여백을 조정합니다.
   * @default '12px 20px 24px'
   */
  padding?: string;
  /**
   * 로딩상태를 정의합니다.
   * @default false
   */
  is_loading?: boolean;
  /**
   * 흰색 이미지를 위한 black layer 필터 사용유무
   * @default true
   */
  use_filter?: boolean;
  /**
   * 카테고리 클릭 이벤트를 정의합니다.
   */
  onCategoryClick?: (category: CircleCategory) => void;
}

/**
 * 공통 원형 카테고리 리스트
 * @component
 */
const CircleCategories = forwardRef<HTMLElement, Props>(
  (
    {
      children,
      categories = [],
      size = {
        min: 52,
        max: 64,
      },
      gap = '16px 9px',
      padding = '12px 20px 24px',
      title_image_gap = '8px',
      use_filter = true,
      is_loading,
      onCategoryClick,
    },
    ref,
  ) => {
    if (is_loading) {
      return (
        <SC.Container padding={padding}>
          <SC.StoreList gap={gap}>
            {[...Array(10)].map((_, index) => (
              <SC.SkeletonStoreItem key={index} size={size} title_image_gap={title_image_gap}>
                <div className='item_wrap'>
                  <Ratio className='img_wrap'>
                    <Skeleton radius={48} />
                  </Ratio>
                  <Skeleton width={56} height={17} radius={2} className='store_name' />
                </div>
              </SC.SkeletonStoreItem>
            ))}
          </SC.StoreList>
        </SC.Container>
      );
    }

    return (
      <SC.Container ref={ref} padding={padding}>
        <SC.StoreList gap={gap}>
          {children && children}
          {!children &&
            categories.map((category, index) => (
              <Item
                key={`${category.title}-${index}`}
                size={size}
                index={index}
                category={category}
                use_filter={use_filter}
                title_image_gap={title_image_gap}
                onClick={onCategoryClick}
              />
            ))}
        </SC.StoreList>
      </SC.Container>
    );
  },
);

export default Object.assign(CircleCategories, {
  Item,
});

const SC = {
  Container: styled.section<{ padding: string }>`
    padding: ${({ padding }) => padding};
  `,
  StoreList: styled.ul<{ gap: string }>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: ${({ gap }) => gap};
    justify-items: center;
  `,
  SkeletonStoreItem: styled.div<{ size: CircleSize; title_image_gap: string }>`
    width: 100%;
    min-width: ${({ size }) => size.min}px;
    max-width: ${({ size }) => size.max}px;

    .item_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .img_wrap {
      margin-bottom: 0;
    }

    .store_name {
      margin-top: ${({ title_image_gap }) => title_image_gap};
    }
  `,
};
