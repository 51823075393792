import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconHeartShadow24 } from 'components/base/Icon';
import Skeleton from 'components/base/Skeleton';
import { white } from 'styles';

const ItemSkeleton = () => {
  return (
    <SC.Container>
      <div css={horizontal_css}>
        <SC.ImageContainer>
          <Skeleton />
          <div className='skeleton_heart'>
            <IconHeartShadow24 />
          </div>
        </SC.ImageContainer>
        <SC.MetaData className='skeleton_meta'>
          <Skeleton width={50} height={13} radius={1} />
          <Skeleton width={170} height={17} radius={2} />
          <Skeleton width={48} height={15} radius={2} />
          <Skeleton width={87} height={21} radius={1} />
          <div className='skeleton_tags'>
            <Skeleton width={42} height={19} radius={2} />
            <Skeleton width={42} height={19} radius={2} />
            <Skeleton width={34} height={19} radius={2} />
          </div>
        </SC.MetaData>
      </div>
    </SC.Container>
  );
};

export default ItemSkeleton;

const horizontal_css = css`
  display: grid;
  grid-template-columns: 1.25fr 2fr;
  align-items: flex-start;
  gap: 12px;
`;

const SC = {
  Container: styled.div`
    position: relative;
    background-color: ${white};
    overflow: hidden;

    :not(:last-child) {
      padding-bottom: 6px;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 0.89;
    border-radius: inherit;

    img {
      border-radius: 2px;
    }

    @supports not (aspect-ratio: 0.89) {
      padding-top: ${100 / 0.89}%;
    }

    .skeleton_heart {
      position: absolute;
      right: 11px;
      bottom: 11px;
    }
  `,
  MetaData: styled.div`
    display: inherit;
    background: ${white};

    &.skeleton_meta > * {
      margin-bottom: 1px;

      &.skeleton_tags {
        display: flex;
        margin-top: 7px;
        > * {
          margin-right: 4px;
        }
      }
    }
  `,
};
