import { useQuery } from '@tanstack/react-query';
import { getProductImageList } from 'api2';
import { CatalogProductImageType, GetProductImageList } from 'api2/types';

type ProductImageItem = NonNullable<GetProductImageList['catalog_product_list']>['item_list'][number];
const PRODUCT_IMAGE_LIST_QUERY_KEY = 'getProductImageList';

interface ProductImageListInfo {
  catalog_product_id_list: string[];
  display_type?: DisplayType;
}
type DisplayType = 'PLP' | 'PDP';

export const fetchProductImageList = async (id_list: string[]) => {
  try {
    const { data } = await getProductImageList({ input: { id_list } });

    return data.catalog_product_list.item_list;
  } catch (e) {
    return [];
  }
};

export const useProductImageList = (
  { catalog_product_id_list, display_type = 'PLP' }: ProductImageListInfo,
  { enabled }: { enabled?: boolean },
) => {
  const { data, isFetching: is_product_image_list_fetching } = useQuery(
    [PRODUCT_IMAGE_LIST_QUERY_KEY, catalog_product_id_list],
    () => fetchProductImageList(catalog_product_id_list),
    { enabled },
  );

  return {
    product_image_list: data ? convertMainImageList(data, display_type) : [],
    is_product_image_list_fetching,
  };
};

function convertMainImageList(item_list: ProductImageItem[], display_type: DisplayType) {
  const converted = item_list.map((item) => {
    const main_image_item = item.product_image_list.find(
      (product_image) => product_image.image_type === CatalogProductImageType.MAIN,
    );
    return {
      catalog_product_id: item.id,
      image_url: main_image_item?.image_url ? getWebpUrl(main_image_item.image_url, display_type) : '',
    };
  });
  return converted;
}

function getWebpUrl(origin_url: string, display_type: DisplayType) {
  if (!origin_url) {
    return '';
  }
  // webp 기본 파라미터셋 참고
  // https://www.notion.so/croquis/Dynamic-Image-Resizing-9726f519b5b040d8892ff96dbfd0e1e6
  const param_set = {
    PLP: 'width=720&height=720&quality=80&format=webp',
    PDP: 'width=1080&height=1080&quality=80&format=webp',
  };

  return `${origin_url}?${param_set[display_type]}`;
}
