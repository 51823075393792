import { ContentEditorComponentType } from '@croquiscom-pvt/event-modules';
import { useConditionQueries } from './useConditionQueries';
import { useConditionQueryKeyEntryList } from './useConditionQueryKeyEntryList';

export function useIntegratedStickerImageConditionQueries({
  component_map,
  event_content_uuid,
}: {
  component_map: Record<string, ContentEditorComponentType<void>>;
  event_content_uuid: string;
}) {
  const sticker_image_condition_entries = useConditionQueryKeyEntryList({ component_map });
  useConditionQueries({ sticker_image_condition_entries, event_content_uuid });
}
