import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ComponentProps, FC } from 'react';
import { ProductCardSize } from '.';
import { IconHeartShadow24 } from 'components/base/Icon';
import Skeleton from 'components/base/Skeleton';
import Ratio from 'components/toolbox/croquis/core/Ratio';

interface Props {
  size?: ProductCardSize;
}
/**
 * 상품카드 - Skeleton
 * @component
 */
const ProductCardSkeleton: FC<Props> = ({ size = 'medium' }) => {
  const size_info = SKELETON_SIZE_INFO[size];
  return (
    <SC.ProductCard css={size_info.css}>
      <Ratio width={168} height={188}>
        <Skeleton radius={size_info.image_radius} />
        <SC.Heart className='heart'>
          <IconHeartShadow24 />
        </SC.Heart>
      </Ratio>
      <Skeleton className='seller' {...size_info.seller} />
      <Skeleton className='name' {...size_info.name} />
      <Skeleton className='add' {...size_info.add} />
    </SC.ProductCard>
  );
};

export default ProductCardSkeleton;

type SkeletonType = ComponentProps<typeof Skeleton>;

const SKELETON_SIZE_INFO: Record<
  ProductCardSize,
  {
    css: SerializedStyles;
    image_radius: number;
    seller: SkeletonType;
    name: SkeletonType;
    add: SkeletonType;
  }
> = {
  small: {
    css: css`
      .seller {
        margin-top: 4px;
      }

      .add {
        margin-top: 0.5px;
      }

      .heart {
        bottom: 6px;
        right: 6px;
        width: 22px;
        height: 22px;
      }
    `,
    image_radius: 2,
    seller: {
      width: 41,
      height: 18,
      radius: 2,
    },
    name: {
      width: 108,
      height: 14,
      radius: 2,
    },
    add: {
      width: 40,
      height: 13,
      radius: 2,
    },
  },
  medium: {
    css: css`
      .seller {
        margin-top: 6px;
      }

      .add {
        margin-top: 2.5px;
      }

      .heart {
        bottom: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
      }
    `,
    image_radius: 4,
    seller: {
      width: 50,
      height: 21,
      radius: 2,
    },
    name: {
      width: 155,
      height: 15,
      radius: 2,
    },
    add: {
      width: 55,
      height: 14,
      radius: 2,
    },
  },
  large: {
    css: css`
      .seller {
        margin-top: 6px;
      }

      .add {
        margin-top: 2px;
      }

      .heart {
        bottom: 11px;
        right: 10px;
        width: 22px;
        height: 22px;
      }
    `,
    image_radius: 4,
    seller: {
      width: 53,
      height: 22,
      radius: 1,
    },
    name: {
      width: 193,
      height: 18,
      radius: 2,
    },
    add: {
      width: 57,
      height: 15,
      radius: 2,
    },
  },
};

const SC = {
  ProductCard: styled.div`
    position: relative;
  `,
  Heart: styled.div`
    position: absolute;
  `,
};
