import { BasicTimerModuleProps, useTimer } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';

import { useEffect, useState } from 'react';

export const BasicTimer = ({
  end_time,
  background_color,
  complete_text,
  text_color,
  timer_color,
  unit_color,
  progress_text,
  timer_weight,
}: BasicTimerModuleProps) => {
  const { days, hours, minutes, seconds, in_progress } = useTimer({ end_time });

  const [is_client, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const with_title_node = Boolean(in_progress ? progress_text : complete_text);

  if (!is_client) {
    return <></>;
  }

  return (
    <Wrapper
      role='timer'
      aria-atomic='true'
      with_title_node={with_title_node}
      style={{ backgroundColor: background_color }}
    >
      {with_title_node && <Text style={{ color: text_color }}>{in_progress ? progress_text : complete_text}</Text>}
      <Timer style={{ color: timer_color, fontWeight: timer_weight === 'bold' ? 700 : 400 }}>
        <Unit unit='DAYS' unit_color={unit_color}>
          {String(days).padStart(2, '0')}
        </Unit>
        <Comma />
        <Unit unit='HRS' unit_color={unit_color}>
          {String(hours).padStart(2, '0')}
        </Unit>
        <Comma />
        <Unit unit='MINS' unit_color={unit_color}>
          {String(minutes).padStart(2, '0')}
        </Unit>
        <Comma />
        <Unit unit='SECS' unit_color={unit_color}>
          {String(seconds).padStart(2, '0')}
        </Unit>
      </Timer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ with_title_node: boolean }>`
  padding: ${(props) => (props.with_title_node ? 0 : 30)}px 36px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  padding: 16px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

const Timer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  gap: 16px;
`;

const Unit = styled.div<{ unit: string; unit_color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  line-height: 20px;
  gap: 8px;
  width: 53px;

  &:after {
    content: '${(props) => props.unit}';
    color: ${(props) => props.unit_color};
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }
`;

const Comma = styled.div`
  opacity: 0.5;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: currentColor;
  }
`;
