import { cx } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ComponentProps, useState } from 'react';
import Image from 'components/base/Image';

interface Props extends Omit<ComponentProps<typeof Image>, 'layout'> {
  classname?: string;
  css?: SerializedStyles;
  use_fade?: boolean;
  radius?: number;
}

/**
 * 공통으로 사용 가능한 상품 카드의 썸네일 컴포넌트 입니다.
 * lazy loading 및 fade in animation 을 제공합니다.
 */
const Thumbnail: FC<Props> = ({ classname, css, use_fade, radius = 2, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const root_class_name = cx([classname, { loaded }]);

  const handleLoaded = () => {
    setLoaded(true);
  };

  return (
    <SC.ThumbnailRoot className={root_class_name} radius={radius} css={css}>
      <Image layout='fill' objectFit='cover' use_fade={use_fade} onLoaded={handleLoaded} {...props} />
      <SC.Filter />
    </SC.ThumbnailRoot>
  );
};

export default Thumbnail;

const SC = {
  ThumbnailRoot: styled.div<{ radius: number }>`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: ${({ radius }) => radius}px;

    &.loaded {
      background-color: rgba(0, 0, 0, 0.03);
    }
  `,
  Filter: styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: inherit;
  `,
};
