import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getActiveRecommendShopList } from 'api2';
import { GetActiveRecommendShopList } from 'api2/types';

const RECOMMEND_SHOP_LIST_QUERY_KEY = 'getRecommendShopList';

export type RecommendShop = GetActiveRecommendShopList['active_recommend_shop_list']['item_list'][number];
export type RecommendShopPageName = 'ZIGZIN' | 'KIDS' | 'TREND';
export type RecommendShopGroupName = 'SPORTY' | 'CASUAL' | 'ROMANTIC' | 'MODERN_CHIC' | 'UNIQUE';

export const ShopGroupInfo: Array<{ value: RecommendShopGroupName; name: string }> = [
  {
    value: 'SPORTY',
    name: '스포티',
  },
  {
    value: 'CASUAL',
    name: '캐주얼',
  },
  {
    value: 'ROMANTIC',
    name: '로맨틱',
  },
  {
    value: 'MODERN_CHIC',
    name: '모던시크',
  },
  {
    value: 'UNIQUE',
    name: '유니크',
  },
];

export const fetchRecommendShopList = async (
  { page_name, group_name }: { page_name: RecommendShopPageName; group_name?: RecommendShopGroupName },
  req?: IncomingMessage,
) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { active_recommend_shop_list },
    } = await getActiveRecommendShopList({ page_name, group_name }, context_request);
    return active_recommend_shop_list.item_list;
  } catch {
    return [];
  }
};

export const useRecommendShopList = ({
  page_name,
  group_name,
  props,
}: {
  page_name: RecommendShopPageName;
  group_name?: RecommendShopGroupName;
  props?: {
    use_suspense?: boolean;
    enabled?: boolean;
  };
}) => {
  const {
    data,
    isLoading: is_loading,
    isFetching: is_fetching,
    ...rest
  } = useQuery(
    [RECOMMEND_SHOP_LIST_QUERY_KEY, page_name, group_name],
    () => fetchRecommendShopList({ page_name, group_name }),
    {
      suspense: props?.use_suspense,
      enabled: props?.enabled,
    },
  );

  return {
    recommend_shop_list: data ?? [],
    is_loading,
    is_fetching,
    ...rest,
  };
};
