import { atom } from 'jotai';
import { ROOT_PRODUCT_CATEGORY } from 'hooks/queries';

// 신상품 활성화 탭 id
export const new_product_active_tab_id_atom = atom(ROOT_PRODUCT_CATEGORY);
// 카테고리 랭킹 활성화 탭
export const category_ranking_active_tab_id_atom = atom(ROOT_PRODUCT_CATEGORY);
// 캐러셀 스와이프 index
export const carousel_swipe_index_atom = atom<Record<string, number | undefined>>({});
// 최근 본 상품의 연관상품 활성화 상품 id
export const recent_related_active_product_id_atom = atom('');
// 최근 본 상품의 연관상품 캐러셀의 swipe index
export const recent_related_carousel_swipe_index_atom = atom(0);
// 최근 본 상품의 연관상품 캐러셀의 선택된 최근본 상품 index
export const object_idx_by_recent_list_atom = atom(-1);
