import { BoxItemModuleProps, useIsFixedImageModule, useTabLayout } from '@croquiscom-pvt/event-modules';
import { useEffect } from 'react';
import { colors } from 'styles';

export const BoxItem = ({ uuid, children }: BoxItemModuleProps) => {
  const { tab_header_top } = useTabLayout();
  const is_fixed_image_module = useIsFixedImageModule();

  useEffect(() => {
    const timeout = setTimeout(() => initializeModuleLandingScroll({ uuid, offset_top: tab_header_top }), 500);

    return () => clearTimeout(timeout);
  }, [tab_header_top, uuid]);

  return (
    <div
      id={uuid}
      {...(is_fixed_image_module && {
        css: { position: 'sticky', zIndex: 991, backgroundColor: colors.white, top: tab_header_top },
      })}
    >
      {children}
    </div>
  );
};

function initializeModuleLandingScroll({ uuid, offset_top }: { uuid?: string; offset_top: number }) {
  const module_landing_hash = window.location.hash.replace('#', '');
  const module_landing_target_element = document.getElementById(module_landing_hash);
  const should_landing_by_current_element = uuid === module_landing_hash;

  if (module_landing_target_element && should_landing_by_current_element) {
    window.scrollBy(0, module_landing_target_element.getBoundingClientRect().y - offset_top + 1);
  }
}
