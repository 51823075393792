// TODO: date-fns -> daysjs 전환 필요 (date-fns multiple import lint issue 참고: https://github.com/date-fns/date-fns/issues/1677 )
// eslint-disable-next-line import/no-duplicates
import format from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates
import formatDuration from 'date-fns/formatDuration';
// eslint-disable-next-line import/no-duplicates
import intervalToDuration from 'date-fns/intervalToDuration';
// eslint-disable-next-line import/no-duplicates
import ko from 'date-fns/locale/ko';
// eslint-disable-next-line import/no-duplicates
import parse from 'date-fns/parse';
// eslint-disable-next-line import/no-duplicates
import startOfToday from 'date-fns/startOfToday';
import dayjs from 'dayjs';

// format 형식
// https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
export default {
  toString: (date: Date | number, formatStr: string) => {
    return format(date, formatStr);
  },
  toKoString: (date: Date | number, formatStr: string) => {
    return format(date, formatStr, { locale: ko });
  },
  parseYMD: (value: number) => {
    const year = Math.floor(value / 10000);
    const month = Math.floor((value % 10000) / 100) - 1;
    const day = value % 100;
    return new Date(year, month, day);
  },
  countDDay: (date: Date | number) => {
    const dateInNumber = typeof date === 'number' ? new Date(date) : date;
    const today = startOfToday();
    return Math.floor((dateInNumber.valueOf() - today.valueOf()) / (24 * 60 * 60 * 1000));
  },
  distance: (start: Date, end: Date) => {
    return formatDuration(intervalToDuration({ start, end }), { locale: ko });
  },
};

export const parseDateYmd = (date_ymd: number) => parse(String(date_ymd), 'yyyyMMdd', new Date());

export const isBeforeToday = (date: Date | number) => dayjs().isBefore(date);

export const getWeekOfMonth = (date: Date) => {
  const selected_day_of_month = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const first_day_by_month = new Date(`${year}/${month}/01`);
  const month_first_day = first_day_by_month.getDay();
  return Math.ceil((selected_day_of_month + month_first_day) / 7);
};
