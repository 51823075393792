import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes, ReactNode } from 'react';
import HeaderIcon, { HeaderIconType } from './HeaderIcon';
import KakaoBrowserBackButton from './KakaoBrowserBackButton';
import Text from 'components/base/Text';
import { border4, white } from 'styles';
import { HEADER_HEIGHT } from 'styles/layout';

export type SideIconType = HeaderIconType[] | ReactNode;
interface Props extends Omit<HTMLAttributes<HTMLHeadElement>, 'title'> {
  left_icons?: SideIconType;
  right_icons?: SideIconType;
  title?: string | ReactNode;
  use_bottom_border?: boolean;
}

const DefaultHeader: FC<Props> = ({
  left_icons = [],
  right_icons = [],
  title,
  use_bottom_border = false,
  ...props
}) => {
  const is_string_title = typeof title === 'string';
  // MEMO: 왼쪽 백버튼 사용할때 왼쪽 아이콘이 2개이상인 경우 백버튼 마진 변경
  const use_backbutton_margin = Array.isArray(left_icons) && left_icons.length > 1 && left_icons[0] === 'back';

  const renderTitle = () => {
    if (is_string_title) {
      return (
        <Text variant='title4_bold' color='gray_primary' as='h2' alignment='center'>
          {title}
        </Text>
      );
    }
    return title;
  };

  return (
    <SC.Container use_bottom_border={use_bottom_border} {...props}>
      <SC.Left use_backbutton_margin={use_backbutton_margin}>{renderSideItem(left_icons)}</SC.Left>
      {title && (
        <SC.Center is_string_title={is_string_title} className='title'>
          {renderTitle()}
        </SC.Center>
      )}
      <SC.Right>
        {renderSideItem(right_icons)}
        <KakaoBrowserBackButton />
      </SC.Right>
    </SC.Container>
  );
};

export default DefaultHeader;

const renderSideItem = (side_item: SideIconType) => {
  if (Array.isArray(side_item)) {
    return [side_item.map((icon_type, index) => <HeaderIcon key={index} type={icon_type} />)];
  }
  return side_item;
};

const item_css = css`
  display: flex;
  column-gap: 14px;
  flex: 1 1 0%;

  svg {
    cursor: pointer;
  }
`;

const SC = {
  Container: styled.header<{ use_bottom_border: boolean }>`
    display: flex;
    height: ${HEADER_HEIGHT}px;
    width: 100%;
    align-items: center;
    ${({ use_bottom_border }) => use_bottom_border && `border-bottom: 1px solid ${border4};`}
    padding: 12px 12px 12px 16px;
    z-index: 310;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    background-color: ${white};
  `,
  Center: styled.div<{ is_string_title: boolean }>`
    width: 100%;
    ${({ is_string_title }) => is_string_title && 'flex: 2 1 0%;'}

    > h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `,
  Left: styled.div<{ use_backbutton_margin: boolean }>`
    ${item_css}
    justify-content: flex-start;

    svg:first-of-type {
      ${({ use_backbutton_margin }) => use_backbutton_margin && 'margin-right: -10px;'}
    }
  `,
  Right: styled.div`
    ${item_css}
    justify-content: flex-end;
  `,
};
