import styled from '@emotion/styled';
import { FC } from 'react';
import { IconHeartShadow24 } from 'components/base/Icon';
import Skeleton from 'components/base/Skeleton';
import { invisible_scroll_bar } from 'components/products/details/styles';
import Ratio from 'components/toolbox/croquis/core/Ratio';

type Size = 'small' | 'large' | 'medium';
const SIZE_INFO: Record<
  Size,
  {
    product: { width: number; height: number; radius: number };
    seller_height: number;
    price_height: number;
    name_height: number;
  }
> = {
  small: {
    product: { width: 110, height: 123, radius: 2 },
    seller_height: 13,
    name_height: 17,
    price_height: 20,
  },
  // medium 은 허쉬와 조정 필요
  medium: {
    product: { width: 110, height: 123, radius: 2 },
    seller_height: 13,
    name_height: 17,
    price_height: 20,
  },
  large: {
    product: { width: 220, height: 247, radius: 4 },
    seller_height: 18,
    name_height: 18,
    price_height: 17,
  },
};
interface Props {
  classname?: string;
  size?: Size;
  padding?: string;
}
/**
 * 상품카드(small) - Skeleton
 * @component
 */
const SkeletonList: FC<Props> = ({ classname, size = 'small', padding = '0 16px' }) => {
  const size_info = SIZE_INFO[size];

  return (
    <SC.Container padding={padding} className={classname}>
      {[...Array(6)].map((_, index) => (
        <SC.ProductCard key={index} width={size_info.product.width}>
          <Ratio width={size_info.product.width} height={size_info.product.height}>
            <Skeleton radius={size_info.product.radius} />
            <SC.Heart>
              <IconHeartShadow24 />
            </SC.Heart>
          </Ratio>
          <Skeleton className='seller' width={39} height={size_info.seller_height} radius={4} />
          <Skeleton className='name' width={102} height={size_info.name_height} radius={4} />
          <Skeleton className='price' width={46} height={size_info.price_height} radius={4} />
        </SC.ProductCard>
      ))}
    </SC.Container>
  );
};

export default SkeletonList;

const SC = {
  Container: styled.ul<{ padding: string }>`
    ${invisible_scroll_bar}
    padding: ${({ padding }) => padding};

    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  `,
  ProductCard: styled.li<{ width: number }>`
    position: relative;
    margin-right: 3px;
    min-width: ${({ width }) => width}px;

    .seller {
      margin-top: 7px;
    }
    .name {
      margin-top: 2px;
    }
    .price {
      margin-top: 2px;
    }
  `,
  Heart: styled.div`
    position: absolute;
    bottom: 4px;
    right: 4px;
  `,
};
