import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { DiscountIcon, LabelIcon, LikeIcon, RankIcon, SearchIcon } from './icons';
import IconSlot from './IconSlot';
import Tags from './Tags';
import { ProductCardProps, ProductCardSize, ProductUbl } from '.';
import Thumbnail from 'components/base/Thumbnail';
import { white } from 'styles';

interface Props extends ProductCardProps {
  product_ubl: ProductUbl;
  classname?: string;
}

/**
 * @components 상품카드 이미지
 */
const Image: FC<Props> = (props) => {
  const {
    image_url,
    title,
    rank,
    discount_rate,
    size = 'medium',
    icon_top_left = { type: 'rank' },
    free_shipping = false,
    is_new,
    is_zonly,
    is_fast_delivery,
    product_ubl,
    use_icon_slot = true,
    direction = 'vertical',
    thumbnail_emblem_badge_list,
  } = props;
  const is_show_tags = direction === 'vertical';

  const renderIconTopLeft = () => {
    const top_left_icons = {
      rank: <RankIcon rank={rank} />,
      discount: <DiscountIcon discount_rate={discount_rate} size={size} />,
      label: <LabelIcon label={icon_top_left.label} />,
    };
    return top_left_icons[icon_top_left.type];
  };

  return (
    <SC.ImageContainer className={props.classname}>
      <SC.Thumbnail
        src={image_url ?? ''}
        alt={title ?? ''}
        css={image_size_css[size]}
        radius={size === 'small' ? 2 : 4}
        use_fade
      />
      {use_icon_slot && (
        <IconSlot
          top_left={renderIconTopLeft()}
          top_right={<SearchIcon {...props} product_ubl={product_ubl} />}
          bottom_right={<LikeIcon {...props} product_ubl={product_ubl} />}
          bottom_left={
            is_show_tags && (
              <Tags
                size={size}
                custom_css={badge_css}
                free_shipping={free_shipping ?? false}
                is_new={!!is_new}
                is_zonly={is_zonly}
                is_fast_delivery={is_fast_delivery}
                thumbnail_emblem_badge_list={thumbnail_emblem_badge_list}
              />
            )
          }
        />
      )}
    </SC.ImageContainer>
  );
};

export default Image;

const image_size_css: Record<ProductCardSize, SerializedStyles> = {
  large: css`
    img,
    div {
      border-radius: 4px;
    }
  `,
  medium: css`
    img,
    div {
      border-radius: 4px;
    }
  `,
  small: css`
    img,
    div {
      border-radius: 2px;
    }
  `,
};

const badge_css = css`
  margin: 0 0 6px 8px;
`;

const SC = {
  Box: styled.div`
    position: relative;
    background-color: ${white};
    overflow: hidden;
  `,
  AppLink: styled.a``,
  ImageContainer: styled.div``,
  Thumbnail: styled(Thumbnail)`
    width: 100%;
    height: 100%;
  `,
  Megasale: styled.div`
    width: 46px;
    height: 22px;

    img,
    div {
      // 메가세일 임시대응으로 인한 처리 세일 이후 삭제 예정
      border-radius: 0 2px 0 2px !important;
    }
  `,
};
