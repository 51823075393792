import styled from '@emotion/styled';
import { FC } from 'react';
import Tabs, { TabItem } from 'components/base/Tabs';
import Chips from 'components/common/Chips';
import { ChipItemWithLink } from 'components/common/Chips/Item';
import { useCategoryStickeyHeight } from 'components/home/hooks/useCategoryStickeyHeight';
import useNewCategoryQueryString, { convertCategoryRouterProp } from 'components/home/hooks/useNewCategoryQueryString';
import { useStickeyScrollReset } from 'hooks/layout/useStickeyScrollReset';
import { PRODUCT_LIST_TYPE, useActivatedDisplayCategory } from 'hooks/queries';
import { Log } from 'util/Tracker';

interface Props {
  ubl: Pick<Log, 'navigation' | 'object_section'>;
  resetVirtualIndex: () => void;
}

/**
 * 신상탭 - 신상품 카테고리 리스트
 * @component
 */
const Categories: FC<Props> = ({ resetVirtualIndex, ubl }) => {
  const stickey_height = useCategoryStickeyHeight();
  const { category, sub_category } = useNewCategoryQueryString();
  const { scroll_target_ref, resetScrollTo } = useStickeyScrollReset();
  const { getSubCategoryList, product_category_list } = useActivatedDisplayCategory({
    product_list_type: PRODUCT_LIST_TYPE.NEW,
    use_root_category: true,
  });

  const common_ubl = { ...ubl, object_type: 'category' };
  const sub_category_chips: ChipItemWithLink[] | undefined = getSubCategoryList(String(category.id))?.map(
    (sub_category) => {
      const href_qs = `category=${convertCategoryRouterProp(category)}&sub_category=${convertCategoryRouterProp(
        sub_category,
      )}`;
      return {
        value: sub_category.id,
        name: sub_category.name,
        href: `/?tab=new&${href_qs}`,
      };
    },
  );

  const one_depth_catgory_tabs: TabItem[] = product_category_list.map((category) => ({
    id: category.id,
    label: category.name,
    href: `/?tab=new&category=${convertCategoryRouterProp(category)}`,
  }));

  const handleSubCategory = () => {
    resetVirtualIndex();
  };

  const handleCategoryClick = () => {
    resetScrollTo(stickey_height);
    resetVirtualIndex();
  };

  return (
    <>
      <SC.Category ref={scroll_target_ref} stickey_height={stickey_height}>
        <Tabs
          className='main_category'
          is_show_tab_line={false}
          tab_list={one_depth_catgory_tabs}
          selected_tab_id={category.id}
          onClickTab={handleCategoryClick}
          size='small'
          ubl={common_ubl}
        />
      </SC.Category>
      {sub_category_chips && (
        <>
          <Chips
            is_replace
            chips={sub_category_chips}
            active_chip_value={sub_category ? sub_category.id : category.id}
            onChipClick={handleSubCategory}
            ubl={common_ubl}
          />
        </>
      )}
    </>
  );
};

export default Categories;

const SC = {
  Category: styled.div<{ stickey_height: number }>`
    position: sticky;
    z-index: 1;
    top: ${({ stickey_height }) => stickey_height}px;
    .main_category {
      padding-bottom: 4px;
    }
  `,
};
