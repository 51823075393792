import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getProductMetaInfo, getProductOptionInfo } from 'api2';
import { GetProductMetaInfo, GetProductOptionInfo } from 'api2/types';

export type Product = NonNullable<GetProductOptionInfo['product_option_info']>['product'] &
  NonNullable<GetProductMetaInfo['product_meta_info']>['product'];

export enum OptionType {
  BASIC = 'BASIC',
  ADDITIONAL = 'ADDITIONAL',
}

const OPTIONS_QUERY_KEY = 'getCatalogProductOptionsInfo';

export const fetchCatalogProductOptions = async (catalog_product_id: string) => {
  try {
    const [
      {
        data: { product_option_info },
      },
      {
        data: { product_meta_info },
      },
    ] = await Promise.all([
      getProductOptionInfo({ catalog_product_id }, { show_alert: false }),
      getProductMetaInfo({ catalog_product_id }, { show_alert: false }),
    ]);
    if (isNil(product_option_info) || isNil(product_option_info.product) || isNil(product_meta_info)) {
      throw Error('failed to fetch');
    }
    return {
      ...product_option_info.product,
      ...product_meta_info.product,
    };
  } catch (e) {
    if (!(e instanceof Error)) {
      return;
    }
    console.error(e);
  }
};

export const useOptions = (id: string) => {
  const DEFAULT_DATA: Product = {
    id: '',
    shop_main_domain: '',
    product_price: {
      discount_price: 0,
      discount_amount: 0,
      discount_rate: 0,
      original_price: 0,
      final_price: 0,
      zpay_discount_amount: null,
      max_price: 0,
      coupon_discount_amount: null,
    },
    product_image_list: [],
    product_option_list: [],
    item_list: [],
    custom_input_option_list: [],
    color_image_list: [],
    meta_catalog_product_info: null,
  };

  const queryResponse = useQuery([OPTIONS_QUERY_KEY, id], () => fetchCatalogProductOptions(id), {
    // TODO: 셀렉트 옵션 개선 시 onMount false 옵션 줄 것
    refetchOnWindowFocus: false,
    placeholderData: DEFAULT_DATA,
    enabled: id !== '',
  });
  return {
    ...queryResponse,
    options: queryResponse.error || !queryResponse.data ? DEFAULT_DATA : excludeAdditionOptionType(queryResponse.data),
  };
};

function excludeAdditionOptionType(product: Product) {
  // NOTE: 추가상품 옵션 노출 제외
  return {
    ...product,
    product_option_list: product.product_option_list.filter((option) => option.option_type !== OptionType.ADDITIONAL),
    item_list: product.item_list.filter((item) => item.option_type !== OptionType.ADDITIONAL),
  };
}
