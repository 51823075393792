import dayjs from 'dayjs';
import { SHOW_NOTIFY_SHEET_KEY, useAdNotiAllow } from 'components/home/hooks/useAdNotiAllow';
import { useFinishSplash } from 'hooks/app/useFinishSplash';
import useBrowserEffect from 'hooks/useBrowserEffect';
import { checkAppNotificationStatus } from 'util/app_utils';
import { BENEFIT_DIALOG_TITLE, appPushAllowCheck, getStorage } from 'util/home_dialog_info_util';
import { useIsApp } from 'util/is_app';

/**
 * 앱 최초 진입 시점 마케팅 알림 허용 유도 팝업 표시
 * @component
 */
const AppAdNotifyAllow = () => {
  const is_app = useIsApp();
  const { onAdNotify } = useAdNotiAllow();
  const { is_finish_splash } = useFinishSplash();

  useBrowserEffect(() => {
    if (!is_app) {
      return;
    }

    if (!is_finish_splash) {
      return;
    }

    const initAppNotifyAllow = async () => {
      checkAppNotificationStatus(appPushAllowCheck);
      const allow_done = getStorage.notiAllowDone();
      const benefit_remind_date = getStorage.benefitRemind();
      const push_remind_date = getStorage.pushRemind();

      if (!benefit_remind_date && !push_remind_date && !allow_done) {
        await onAdNotify({ benefit_dialog_title: BENEFIT_DIALOG_TITLE.FIRST });
        return;
      }

      const is_benefit_remind = dayjs().isAfter(benefit_remind_date);
      const is_push_remind = dayjs().isAfter(push_remind_date);

      if (is_benefit_remind || is_push_remind) {
        window.localStorage.removeItem(SHOW_NOTIFY_SHEET_KEY);
        onAdNotify({ benefit_dialog_title: BENEFIT_DIALOG_TITLE.REMIND, is_benefit_remind, is_push_remind });
        return;
      }
    };

    initAppNotifyAllow();
  }, [is_app, is_finish_splash]);

  return null;
};

export default AppAdNotifyAllow;
