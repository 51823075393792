import {
  ActionContext,
  ConditionalStatus,
  EventMetadataAction,
  EventMetadataActionHandler,
} from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { getClickedImage } from '../image-action';
import { getCmsEventLoggers } from '../log';
import { getConditionStateQueryKey } from '../sticker-conditions/getConditionStateQueryKey';
import { cmsParticipateEventMetadata } from 'api2';
import { ParticipateEventMetadataResultAlertType, CmsParticipateEventMetadata } from 'api2/types';
import { getEnabledState } from 'util/content-editor/action-state';
import { message } from 'util/content-editor/feedback';
import { getStatus, insuffientUserInput } from 'util/content-editor/user';
import { getDevice } from 'util/device';

export const handleEventMetadata: EventMetadataActionHandler<ModuleRootCustomData> = async (
  action,
  context,
  action_state,
) => {
  const { is_app } = getDevice(navigator.userAgent);
  logAction([action, context, action_state]);

  const { is_login, openLoginDialog, openAppLandingDialog } = context.metadata.custom_data;
  const { is_user } = await getStatus();

  if (!is_login || !is_user) {
    openLoginDialog();
    return;
  }

  const [user_input] = context.metadata.event_metadata_user_input;
  if (await insuffientUserInput(user_input)) {
    return;
  }

  let is_error = false;
  const data = await cmsParticipateEventMetadata(
    { input: { event_content_uuid: context.metadata.uuid, event_metadata_uuid: action.value.uuid, user_input } },
    { show_alert: false },
  )
    .then(({ data, errors }) => {
      if (errors?.[0]) {
        is_error = true;
        throw errors[0];
      }

      invalidateParticipateEventMetadataConditionQuery(context, action);

      return data.participateEventMetadata;
    })
    .catch((error) => {
      is_error = true;
      return catchErrorToMessage(error);
    });

  // 이벤트 사전조건으로 '마케팅 수신동의' 세팅 && 웹 일 경우, 앱 진입 유도 팝업 호출
  if (Boolean(is_error) && !is_app && data.type === 'ad_agreement') {
    openAppLandingDialog();
    return;
  }

  await message(data, context.i18next, context.metadata);

  return getEnabledState();
};

type ParticipateEventMetadata = CmsParticipateEventMetadata['participateEventMetadata'];

export type EventMetadataError = unknown & {
  extensions?: unknown & { contents?: Omit<ParticipateEventMetadata, 'alert_type'> };
  message: string;
};

export async function catchErrorToMessage(error: EventMetadataError) {
  if (error.extensions?.contents) {
    return {
      alert_type: ParticipateEventMetadataResultAlertType.POPUP,
      ...error.extensions.contents,
    } as ParticipateEventMetadata;
  }

  return {
    alert_type: ParticipateEventMetadataResultAlertType.TOAST,
    message: error.message,
  } as ParticipateEventMetadata;
}

function invalidateParticipateEventMetadataConditionQuery(
  context: ActionContext<ModuleRootCustomData>,
  action: EventMetadataAction,
) {
  context.metadata.custom_data.query_client.setQueryData(
    [
      getConditionStateQueryKey({
        sticker_image_condition: {
          status: ConditionalStatus.EVENT_METADATA_PARTICIPATED,
          event_metadata: { title: action.value.title, uuid: action.value.uuid },
        },
      }),
    ],
    () => true,
  );
}

function logAction([action, { metadata, module_hierarchy }]: Parameters<
  EventMetadataActionHandler<ModuleRootCustomData>
>) {
  try {
    const { logEventMetadataByFloatingButton, logEventMetadataByImageModule } = getCmsEventLoggers(metadata);
    const current_module = module_hierarchy.modules[module_hierarchy.modules.length - 1];

    if (current_module.__type === 'FloatingButton') {
      logEventMetadataByFloatingButton({
        component_uuid: current_module.uuid,
        event_metadata_title: action.value.title,
        event_metadata_uuid: action.value.uuid,
      });
    }

    if (current_module.__type === 'ImageCarousel' || current_module.__type === 'ImageMap') {
      const clicked_image = getClickedImage(current_module, action);

      if (clicked_image) {
        logEventMetadataByImageModule({
          component_type: current_module.__type,
          component_uuid: current_module.uuid,
          event_metadata_title: action.value.title,
          event_metadata_uuid: action.value.uuid,
          image_id: clicked_image.id,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}
