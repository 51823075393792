import { ConditionalStatus, StickerImageCondition } from '@croquiscom-pvt/event-modules';

interface GetConditionStateQueryKeyOptions {
  sticker_image_condition: StickerImageCondition;
}

export function getConditionStateQueryKey({ sticker_image_condition }: GetConditionStateQueryKeyOptions) {
  switch (sticker_image_condition.status) {
    case ConditionalStatus.USER_LOGGED_IN:
    case ConditionalStatus.USER_NOT_LOGGED_IN:
      return 'USER_LOGGED_IN';
    case ConditionalStatus.EVENT_METADATA_IN_PROGRESS:
    case ConditionalStatus.EVENT_METADATA_NOT_IN_PROGRESS:
      return sticker_image_condition.event_metadata
        ? 'EVENT_METADATA_IN_PROGRESS__' + sticker_image_condition.event_metadata.uuid
        : null;
    case ConditionalStatus.EVENT_METADATA_PARTICIPATED:
    case ConditionalStatus.EVENT_METADATA_NOT_PARTICIPATED:
      return sticker_image_condition.event_metadata
        ? 'EVENT_METADATA_PARTICIPATED__' + sticker_image_condition.event_metadata.uuid
        : null;
    default:
      return null;
  }
}
