import { ContentReplyItem } from './ContentReplyItem';
import { DeleteReplyMutationArgs } from './hooks';
import { GetCmsEventContentReplyList } from 'api2/types';

export type ContentReplyDataItemProps = {
  data: GetCmsEventContentReplyList['getEventContentReplyList']['item_list'][number];
  handleDeleteReply: ({ id, event_content_reply_metadata_uuid }: DeleteReplyMutationArgs) => void;
};

export const ContentReplyDataItem = ({ data, handleDeleteReply }: ContentReplyDataItemProps) => {
  return (
    <ContentReplyItem
      content={data.content}
      date_created={data.date_created}
      user_name={data.user_account_profile_nickname}
      status={data.status}
      is_mine={data.is_mine ?? false}
      handleDeleteReply={() =>
        handleDeleteReply({ id: data.id, event_content_reply_metadata_uuid: data.event_content_reply_metadata_uuid })
      }
    />
  );
};
