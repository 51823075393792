import styled from '@emotion/styled';
import { FC, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductCardItem } from '../ProductCard/product';
import Item from './Item';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { useFbkRouter } from 'hooks';
import { Log, useTracker } from 'util/Tracker';

const SIZE = {
  MAIN_IMAGE: 300,
};

interface Props {
  /**
   * 메인 이미지를 지정합니다.
   */
  main_image: {
    /**
     * 메인 이미지를 주소를 정의합니다.
     */
    src: string;
    /**
     * 메인 이미지를 alt를 정의합니다.
     */
    alt: string;
    /**
     * 메인 이미지 클릭시 이동할 링크를 지정합니다.
     */
    link: string;
  };
  /**
   * 제목을 지정합니다.
   * @default ''
   */
  title?: string;
  /**
   * 설명을 지정합니다.
   * @default ''
   */
  description?: string;
  /**
   * ubl 을 지정합니다.
   */
  ubl?: Pick<Log, 'data' | 'object_section' | 'navigation' | 'object_idx'>;
  /**
   * 하단 상품 리스트를 정의합니다.
   * @default []
   */
  product_list?: ProductCardItem[];
  /**
   * 메인 이미지 콜백 이벤트
   */
  onImageClick?: () => void;
}

const ImageAndProductGrid: FC<Props> = ({
  main_image: { src, alt, link },
  ubl,
  title = '',
  description = '',
  product_list = [],
  onImageClick,
}) => {
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const router = useFbkRouter();
  const tracker = useTracker();
  const common_ubl = useMemo(
    () =>
      ubl
        ? {
            ...ubl,
            data: {
              ...ubl.data,
              title,
              description,
            },
          }
        : undefined,
    [ubl, title, description],
  );

  useEffect(() => {
    if (in_view && common_ubl) {
      tracker.addLog({ ...common_ubl, object_type: 'button', category: 'impression' });
    }
  }, [in_view, tracker, common_ubl]);

  const handleImageClick = () => {
    if (common_ubl) {
      tracker.addLog({ ...common_ubl, object_type: 'button', category: 'click' });
    }
    onImageClick?.();
  };

  return (
    <SC.Container ref={ref}>
      <div className='main_image' onClick={handleImageClick}>
        <Link href={link} className='main_image link'>
          <Image layout='fill' objectFit='cover' src={src} alt={alt} use_fade />
        </Link>
        <SC.Info>
          <Text variant='title3_bold' color='white' as='h4' dangerouslySetInnerHTML={{ __html: title }} />
          <Text className='description' variant='body4_medium' color='white' as='p' mt={2}>
            {description}
          </Text>
        </SC.Info>
        <div className='dimmed' onClick={() => router.push(link)} />
      </div>
      <SC.ProductList>
        {product_list.map((product, index) => {
          return (
            <Item
              product_id={product.catalog_product_id ?? ''}
              image_url={product.image_url ?? ''}
              ubl={common_ubl}
              index={index}
              key={index}
            />
          );
        })}
      </SC.ProductList>
    </SC.Container>
  );
};

export default Object.assign(ImageAndProductGrid, {
  Skeleton: () => (
    <SC.Container>
      <Ratio className='main_image' width={SIZE.MAIN_IMAGE} height={SIZE.MAIN_IMAGE}>
        <Skeleton />
      </Ratio>
      <SC.ProductList>
        {[...Array(3)].map((_, index) => (
          <Item.Skeleton key={index} />
        ))}
      </SC.ProductList>
    </SC.Container>
  ),
});

const SC = {
  Container: styled.section`
    cursor: pointer;

    .main_image {
      min-width: ${SIZE.MAIN_IMAGE}px;
      position: relative;
      aspect-ratio: 343 / 261;
      a img {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .link {
        position: relative;
      }

      .dimmed {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      .description {
        opacity: 0.8;
      }
    }
  `,
  Info: styled.div`
    position: absolute;
    bottom: 20px;
    z-index: 1;
    left: 0;
    padding: 0 20px;
  `,
  ProductList: styled.ul`
    margin-top: 2px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 2px;

    li {
      aspect-ratio: 110/ 110;
    }

    &:first-of-type {
      img {
        border-bottom-left-radius: 4px;
      }
    }
    &:last-of-type {
      img {
        border-bottom-right-radius: 4px;
      }
    }
  `,
};
