import { css } from '@emotion/react';
import styled from '@emotion/styled';
import throttle from 'lodash-es/throttle';
import { useEffect } from 'react';

export type PopoverProps = {
  is_shown: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

export const Popover = ({ is_shown, children, onClose }: PopoverProps) => {
  useEffect(() => {
    const handleScroll = throttle(onClose, 200);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onClose]);

  return (
    <Wrapper shown={is_shown} onClick={onClose}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.ol<{ shown: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transform: scale(0.2);
  transform-origin: top right;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  ${({ shown }) =>
    shown &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
    `}
`;
