import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { my_custom_filter_checked_atom, search_size_filter_atom } from '../store/review';
import { ProductReviewListOrderType } from 'api2/types';

/**
 * 리뷰 필터 옵션 조회용 hook
 * @hook
 */
export const useReviewSearch = () => {
  const { query } = useRouter();
  const size_option_list = useAtomValue(search_size_filter_atom);
  const my_custom_filter_checked = useAtomValue(my_custom_filter_checked_atom);

  const sort_type = (query.sort_type || ProductReviewListOrderType.BEST_SCORE_DESC) as ProductReviewListOrderType;

  return {
    search_value: {
      sort_type,
      size_option_list,
      my_custom_filter_checked,
    },
  };
};
