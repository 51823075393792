import styled from '@emotion/styled';
import { useAtomValue } from 'jotai/utils';
import React, { FC, MouseEvent, ReactNode, useEffect } from 'react';

import { kakao_share_option_atom } from './store/KakaoShareOption';
import {
  IconCancel20,
  IconShareFacebook32,
  IconShareUrl32,
  IconShareKakaoTalk32,
  IconShareBand32,
} from 'components/base/Icon';
import Text from 'components/base/Text';
import BottomDialog from 'components/toolbox/BottomDialog';
import { useIsKakaoPrivateBrowser } from 'hooks/useIsKakaoPrivateBrowser';
import { useIsApp } from 'util/is_app';
import { createKakaoShare, shareWithFbWithBand, shareWithUrl } from 'util/share';
import { Log, useTracker } from 'util/Tracker';
export enum ShareType {
  URL = 'URL',
  KAKAO_TALK = 'KAKAO_TALK',
  FACEBOOK = 'FACEBOOK',
  NAVER_BAND = 'NAVER_BAND',
}

const share_list: Array<{
  title: string;
  icon: ReactNode;
  share_type: ShareType;
}> = [
  {
    title: '카카오톡으로 알리기',
    icon: <IconShareKakaoTalk32 />,
    share_type: ShareType.KAKAO_TALK,
  },
  {
    title: '밴드로 알리기',
    icon: <IconShareBand32 />,
    share_type: ShareType.NAVER_BAND,
  },
  {
    title: '페이스북으로 알리기',
    icon: <IconShareFacebook32 />,
    share_type: ShareType.FACEBOOK,
  },
  {
    title: '링크 복사하기',
    icon: <IconShareUrl32 />,
    share_type: ShareType.URL,
  },
];

interface Props {
  /**
   * bottomSheet 제목을 작성합니다.
   * @default '친구에게 공유하기'
   */
  title?: string;
  /**
   * 공유할 url을 정의합니다.
   */
  share_url: string;
  /**
   * 공유하기 bottomSheet의 활성화 유무를 정의 합니다,
   */
  is_open: boolean;
  /**
   * 공유하기 bottomSheet을 닫는 이벤트 입니다.
   */
  onCloseDialog: () => void;
  /**
   * 공유하기 아이탭 클릭 이벤트 입니다.
   */
  onShareItemClick?: (share_type: ShareType) => void;
  /**
   * 공유하기 바텀싯의 ubl을 정의합니다.
   */
  ubl?: Omit<Log, 'category'>;
}

/**
 * 공통스펙 - 링크 공유하기 bottomSheet
 * @component
 */
const ShareBottomSheet: FC<Props> = ({
  title = '친구에게 공유하기',
  is_open,
  share_url,
  ubl,
  onCloseDialog,
  onShareItemClick,
}) => {
  const is_app = useIsApp();
  const tracker = useTracker();
  const is_kakao_private_browser = useIsKakaoPrivateBrowser();
  const kakao_share_contents = useAtomValue(kakao_share_option_atom);

  useEffect(() => {
    if (ubl && is_open) {
      tracker.addLog({
        category: 'pageview',
        ...ubl,
      });
    }
  }, [ubl, is_open, tracker]);

  const handleShareClickSendLog = (is_where: string) => {
    if (ubl) {
      tracker.addLog({
        category: 'click',
        object_type: 'button',
        data: { is_where },
        ...ubl,
      });
    }
  };

  const handleShareClick = async (e: MouseEvent<HTMLLIElement>) => {
    const share_type = e.currentTarget.getAttribute('data-type') as ShareType;
    const is_open_window = !is_app && !is_kakao_private_browser;

    onShareItemClick?.(share_type);

    switch (share_type) {
      case ShareType.URL: {
        handleShareClickSendLog('url');
        shareWithUrl(share_url);
        return;
      }
      case ShareType.FACEBOOK: {
        handleShareClickSendLog('facebook');
        shareWithFbWithBand(ShareType.FACEBOOK, share_url, is_open_window);
        return;
      }
      case ShareType.KAKAO_TALK: {
        handleShareClickSendLog('kakao');
        createKakaoShare(share_url, kakao_share_contents)();
        return;
      }
      case ShareType.NAVER_BAND: {
        handleShareClickSendLog('band');
        shareWithFbWithBand(ShareType.NAVER_BAND, share_url, is_open_window);
        return;
      }
    }
  };

  const handleClose = (e?: MouseEvent) => {
    e && e.stopPropagation();
    onCloseDialog();
  };

  return (
    <BottomDialog active={is_open} handleOutsideClick={handleClose}>
      <SC.Head>
        <Text variant='title4_bold' color='black'>
          {title}
        </Text>
        <IconCancel20 onClick={handleClose} />
      </SC.Head>
      <SC.ShareList>
        {share_list.map((share_item) => {
          return (
            <li onClick={handleShareClick} key={share_item.title} data-type={share_item.share_type}>
              {share_item.icon}
              <Text variant='title5_medium' color='gray_primary'>
                {share_item.title}
              </Text>
            </li>
          );
        })}
      </SC.ShareList>
    </BottomDialog>
  );
};

export default ShareBottomSheet;

const SC = {
  ShareList: styled.ul`
    width: 100%;
    padding: 8px 0 20px 20px;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      padding: 11px 0;

      svg {
        margin-right: 14px;
      }
    }
  `,
  Head: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -9.5px 16px 15.5px;

    > svg {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  `,
};
