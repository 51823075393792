import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getCmsStorePresetShopList } from 'api2';

export type useStorePresetDataOptions = { store_preset_uuid: string };

export function useStorePresetItemList({ store_preset_uuid }: useStorePresetDataOptions) {
  return useQuery(getQueryKey(store_preset_uuid), () => fetchStorePresetItemList(store_preset_uuid), {
    refetchOnWindowFocus: false,
  });
}

export function getQueryKey(store_preset_uuid: string) {
  return ['getStorePresetItemList', store_preset_uuid];
}

export async function fetchStorePresetItemList(store_preset_uuid: string, context?: IncomingMessage) {
  const { data } = await getCmsStorePresetShopList({ store_preset_uuid }, { context });
  return data.cms_store_preset_shop_list.shop_list;
}
