import { ConditionalStatus, StickerImageCondition } from '@croquiscom-pvt/event-modules';
import { useQueries } from '@tanstack/react-query';
import { getStatus } from '../user';
import { getCmsEventMetadata, getCmsMyParticipateEventMetadata } from 'api2';
import { ApiErrors } from 'util/request';

interface UseConditionQueriesOptions {
  sticker_image_condition_entries: Array<[string, StickerImageCondition]>;
  event_content_uuid: string;
}

export function useConditionQueries({
  sticker_image_condition_entries,
  event_content_uuid,
}: UseConditionQueriesOptions) {
  const queries = sticker_image_condition_entries.map(([query_key, condition]) => ({
    queryKey: [query_key],
    queryFn: () => {
      switch (condition.status) {
        case ConditionalStatus.USER_LOGGED_IN:
        case ConditionalStatus.USER_NOT_LOGGED_IN:
          return getUserLoggedInConditionResult(condition);
        case ConditionalStatus.EVENT_METADATA_IN_PROGRESS:
        case ConditionalStatus.EVENT_METADATA_NOT_IN_PROGRESS:
          return getEventMetadataInProgressConditionResult(condition);
        case ConditionalStatus.EVENT_METADATA_PARTICIPATED:
        case ConditionalStatus.EVENT_METADATA_NOT_PARTICIPATED:
          return getEventMetatdataParticipatedConditionResult(condition, event_content_uuid);
        default:
          return false;
      }
    },
    staleTime: Infinity,
  }));

  return useQueries({ queries });
}

async function getEventMetatdataParticipatedConditionResult(
  condition: Extract<
    StickerImageCondition,
    { status: ConditionalStatus.EVENT_METADATA_PARTICIPATED | ConditionalStatus.EVENT_METADATA_NOT_PARTICIPATED }
  >,
  event_content_uuid: string,
) {
  if (!condition.event_metadata) {
    return false;
  }

  const is_participated = await getCmsMyParticipateEventMetadata(
    {
      event_metadata_uuid: condition.event_metadata.uuid,
      event_content_uuid,
    },
    { ignore_error: ApiErrors.NOT_LOGGED_IN },
  ).then(({ data }) => data.getMyParticipateEventMetadata?.is_participate ?? false);

  return is_participated;
}

async function getEventMetadataInProgressConditionResult(
  condition: Extract<
    StickerImageCondition,
    { status: ConditionalStatus.EVENT_METADATA_IN_PROGRESS | ConditionalStatus.EVENT_METADATA_NOT_IN_PROGRESS }
  >,
) {
  if (!condition.event_metadata) {
    return false;
  }

  const {
    server_time,
    metadata: { date_started, date_ended },
  } = await getCmsEventMetadata({ event_metadata_uuid: condition.event_metadata.uuid }).then(
    ({ data }) => data.getEventMetadata,
  );

  const is_in_progress = date_started <= server_time && server_time <= date_ended;
  return is_in_progress;
}

async function getUserLoggedInConditionResult(_: StickerImageCondition) {
  const { is_user } = await getStatus();
  return is_user;
}
