import { Atom, atom } from 'jotai';
import cloneDeep from 'lodash-es/cloneDeep';

export enum FILTER_KEY {
  HEIGHT = 'height',
  WEIGHT = 'weight',
  BOTTOM_SIZE = 'bottom_size',
  UPPER_SIZE = 'upper_size',
  SHOE_SIZE = 'shoe_size',
}

export enum COMPONENT_TYPES {
  RANGE_OPTION = 'RANGE_OPTION',
  CHECK_OPTION_LIST = 'CHECK_OPTION_LIST',
}
export interface SearchOptions {
  key: string;
  value_list: string[];
}

export const size_search_sheet_open_atom = atom<boolean>(false);

export const check_type_option_atom = atom<SearchOptions[]>([]);
export const add_check_type_option_atom = atom(null, (_, set, { key, value_list }: SearchOptions) => {
  set(check_type_option_atom, (prev) => {
    const is_reset_range = value_list.length < 1;
    const next = cloneDeep(prev);
    if (is_reset_range) {
      return next.filter((n) => n.key !== key);
    }
    return [{ key, value_list }];
  });
});

export const range_type_option_atom = atom<SearchOptions[]>([]);
export const add_range_type_option_atom = atom(
  null,
  (_, set, { key, min, max }: { key: string; min: number; max: number }) => {
    set(range_type_option_atom, (prev) => {
      const is_reset_range = min === -1 && max === -1;
      const next = cloneDeep(prev);

      if (is_reset_range) {
        return next.filter((n) => n.key !== key);
      }

      return [...next.filter((n) => n.key !== key), { key, value_list: [String(min), String(max)] }];
    });
  },
);

export const remove_check_search_filter_options_atom = atom(null, (get, set, { key, value }) => {
  const client_search_filter_options = get(check_type_option_atom);
  const copied = cloneDeep(client_search_filter_options);

  const has_option_index = copied.findIndex((x) => x.key === key);
  if (has_option_index > -1) {
    copied[has_option_index].value_list = copied[has_option_index].value_list.filter((x) => x !== value);

    set(check_type_option_atom, copied);
  }
});

export const is_all_reset_click_atom = atom<boolean>(false);
export const reset_client_option_atom = atom(null, (_, set, is_reset_click?: boolean) => {
  set(check_type_option_atom, []);
  set(range_type_option_atom, []);
  is_reset_click && set(is_all_reset_click_atom, true);
});

export const search_size_filter_atom = atom<SearchOptions[]>([]);
export const on_search_size_atom = atom(null, (get, set) => {
  const check_type_option = get(check_type_option_atom);
  const range_type_option = get(range_type_option_atom);

  const search_filter_options = [...check_type_option, ...range_type_option];
  set(search_size_filter_atom, search_filter_options);
});

export const my_custom_filter_checked_atom = atom<boolean>(false);

export const createReviewSearchPageInitialValues = (): Array<[Atom<unknown>, unknown]> => {
  return [[my_custom_filter_checked_atom, false]];
};
