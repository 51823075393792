import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import { useMemo } from 'react';
import useNewCategoryQueryString from './useNewCategoryQueryString';
import { getNewProductList } from 'api2';
import { GetNewProductListVariables } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const NEW_PRODUCT_LIST_QUERY_KEY = 'getNewProductList';

interface NewProductList {
  product_list: ProductCardProps[];
  total_count: number;
  page: number;
}

const init_data: NewProductList = {
  product_list: [],
  total_count: 0,
  page: 0,
};

const PAGE_PER_COUNT = 10;

export const fetchNewProductList = async (
  props: Pick<GetNewProductListVariables, 'display_category_id_list' | 'page'>,
  req?: IncomingMessage,
): Promise<NewProductList> => {
  try {
    const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
    const { data } = await getNewProductList({ ...props, page_size: PAGE_PER_COUNT }, context_request);
    const { total_count, item_list } = data.new_product_list;
    return {
      product_list: convertProductList(item_list),
      total_count,
      page: props.page ?? 0,
    };
  } catch {
    return init_data;
  }
};

/**
 * 신상탭 콘텐츠 리스트 조회
 * @hook
 */
export const useNewProductList = () => {
  const { category_id, sub_category_id } = useNewCategoryQueryString();

  const {
    data,
    isInitialLoading: is_initial_loading,
    isLoading: is_loading,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    [NEW_PRODUCT_LIST_QUERY_KEY, category_id, sub_category_id],
    ({ pageParam = 1 }) =>
      fetchNewProductList({
        display_category_id_list: sub_category_id ? [`${category_id};${sub_category_id}`] : [category_id],
        page: pageParam,
      }),
    {
      getNextPageParam: (last_page) =>
        last_page.total_count > last_page.page * PAGE_PER_COUNT ? last_page.page + 1 : undefined,
    },
  );

  const product_list = useMemo(() => {
    return flatten(data?.pages.map((value) => value.product_list));
  }, [data]);

  const total_count = useMemo(() => {
    return data?.pages[0].total_count ?? 0;
  }, [data]);

  return {
    product_list,
    total_count,
    has_new_content: total_count > 0,
    is_loading,
    has_next_page,
    ...rest,
  };
};
