import styled from '@emotion/styled';
import React, { HTMLAttributes, cloneElement, isValidElement, Children, useCallback, forwardRef } from 'react';

export interface HScrollBoxProps extends HTMLAttributes<HTMLUListElement> {}

export const HScrollBox = forwardRef<HTMLUListElement, HScrollBoxProps>(({ children, ...props }, ref) => {
  const { className, ...rest } = props;
  const setRef = useCallback((node: HTMLLIElement | null) => {
    if (node) {
      const node_left_pos = node.offsetLeft;
      const node_width_harf = node.clientWidth / 2;
      const parent_width_half = (node.parentElement?.clientWidth || 0) / 2;

      if (node_left_pos > parent_width_half) {
        node.parentElement?.scrollTo({
          left: node_left_pos - parent_width_half + node_width_harf,
          top: 0,
          behavior: 'smooth',
        });
      } else {
        node.parentElement?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
    }
  }, []);

  const EnhandedChildren = Children.map(children, (child) => {
    if (isValidElement(child) && child.props.is_selected) {
      // @ts-ignore
      return cloneElement(child, { ref: setRef });
    }

    return child;
  });

  return (
    <SC.TabConrainer className={className} ref={ref} {...rest}>
      {EnhandedChildren}
    </SC.TabConrainer>
  );
});

HScrollBox.displayName = 'HScrollBox';

export interface HScrollBoxItemProps extends HTMLAttributes<HTMLLIElement> {
  is_selected: boolean;
}

export const HScrollBoxItem = forwardRef<HTMLLIElement, HScrollBoxItemProps>(
  ({ children, is_selected, ...props }, ref) => {
    const { onClick, ...rest } = props;

    return (
      <li
        aria-selected={props.role === 'tab' ? is_selected : undefined}
        ref={ref}
        tabIndex={0}
        onClick={onClick}
        {...rest}
      >
        {children}
      </li>
    );
  },
);

HScrollBoxItem.displayName = 'HScrollBoxItem';

const SC = {
  TabConrainer: styled.ul`
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: flex;
    gap: 5px;

    & li {
      cursor: pointer;
    }

    & li:first-of-type {
      margin-left: 16px;
    }

    & li:last-of-type {
      margin-right: 16px;
    }
  `,
};
