import { useRouter } from 'next/router';
import config from 'util/config';
import { jsonToQueryString } from 'util/route_util';

export enum SEARCH_BANNER_TYPE {
  ZIGIN = 'zigin',
}

// 검색페이지 진입 타입
export enum SEARCH_FROM_TYPE {
  ZIGZIN = 'zigzin',
  KID = 'kid',
  FAST_FELIVERY = 'fast_delivery',
}
/**
 * 검색 페이지에서 사용하는 query string hook
 * @hook
 */
const useSearchRouterProps = () => {
  const { query, replace } = useRouter();

  const keyword = typeof query.keyword === 'string' ? query.keyword : '';
  const is_hide_store_section = typeof query.hide_store === 'string' ? query.hide_store === 'true' : false;

  const banner_type = typeof query.banner_type === 'string' ? (query.banner_type as SEARCH_BANNER_TYPE) : '';
  const search_from_type = typeof query.from === 'string' ? (query.from as SEARCH_FROM_TYPE) : '';

  const getProductSearchPath = (keyword: string) => {
    const search_from_info = {
      [SEARCH_FROM_TYPE.ZIGZIN]: {
        is_zigin_shipping: true,
        keyword,
        from: SEARCH_FROM_TYPE.ZIGZIN,
      },
      [SEARCH_FROM_TYPE.FAST_FELIVERY]: {
        is_fast_shipping: true,
        keyword,
        from: SEARCH_FROM_TYPE.FAST_FELIVERY,
      },
      [SEARCH_FROM_TYPE.KID]: {
        keyword,
        category_list: JSON.stringify([`${config.kid_category_id}/키즈/1`]),
        from: SEARCH_FROM_TYPE.KID,
      },
    };

    const search_query_string = search_from_type
      ? `${jsonToQueryString(search_from_info[search_from_type])}`
      : `keyword=${keyword}`;
    return `/search?${search_query_string}`;
  };

  const productSearch = (keyword: string) => {
    replace(getProductSearchPath(keyword));
  };

  return {
    keyword,
    is_hide_store_section,
    banner_type,
    search_from_type,
    productSearch,
    getProductSearchPath,
  };
};

export default useSearchRouterProps;
