import { ActionMap } from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { handleAddFavoriteStore } from 'util/content-editor/actions/add-favorite-store';
import { handleEventMetadata } from 'util/content-editor/actions/event-metadata';
import { handleFacebookShare } from 'util/content-editor/actions/facebook-share';
import { handleKakaoShare } from 'util/content-editor/actions/kakao-share';
import { handleManualDeepLink } from 'util/content-editor/actions/manual-deep-link';
import { handleMarketingConsent } from 'util/content-editor/actions/marketing-consent';
import { handlePdpDeepLink } from 'util/content-editor/actions/pdp-deep-link';
import { handleStoreHomeDeepLink } from 'util/content-editor/actions/store-deep-link';
import { handleUrlShare } from 'util/content-editor/actions/url-share';

export const action_map: Partial<ActionMap<ModuleRootCustomData>> = {
  MANUAL_DEEP_LINK: { handler: handleManualDeepLink },
  PDP_DEEP_LINK: { handler: handlePdpDeepLink },
  STORE_HOME_DEEP_LINK: { handler: handleStoreHomeDeepLink },
  EVENT_METADATA: { handler: handleEventMetadata },
  MARKETING_CONSENT: { handler: handleMarketingConsent },
  KAKAO_SHARE: { handler: handleKakaoShare },
  FACEBOOK_SHARE: { handler: handleFacebookShare },
  URL_SHARE: { handler: handleUrlShare },
  ADD_FAVORITE_STORE: { handler: handleAddFavoriteStore },
};
