import styled from '@emotion/styled';
import { FC } from 'react';
import { IconSearch16 } from 'components/base/Icon';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { bg_secondary, white } from 'styles';
import { useTracker } from 'util/Tracker';

interface Props {
  search_path: string;
}
/**
 * 키즈관 - 검색
 * @component
 */
const Search: FC<Props> = ({ search_path }) => {
  const tracker = useTracker();

  const handleSearchClick = () => {
    tracker.addLog({
      category: 'click',
      navigation: 'kids_main',
      object_section: 'search_form',
      object_type: 'button',
    });
  };

  return (
    <SC.Container>
      <Link href={search_path} onClick={handleSearchClick}>
        <SC.SearchBar>
          <IconSearch16 />
          <Text variant='body2' color='gray_inactive' ml={8}>
            키즈 상품을 검색해 보세요.
          </Text>
        </SC.SearchBar>
      </Link>
    </SC.Container>
  );
};

export default Search;

const SC = {
  Container: styled.section`
    width: 100%;
    padding: 16px 16px 8px;
    background-color: ${white};
  `,
  SearchBar: styled.div`
    cursor: pointer;
    background-color: ${bg_secondary};
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  `,
};
