import styled from '@emotion/styled';
import Section from '../../Section';
import CircleCategories from 'components/base/CircleCategories';
import Skeleton from 'components/base/Skeleton';
import { STORE_PRODUCT_TYPE } from 'components/shop/product-list/hooks/useStoreProductListRouterProps';
import { useRecommendShopList } from 'hooks/queries/useRecommendShopList';

const CIRCLE_CATEGORY_PADDING = '0 20px 6px';
const CIRCLE_CATEGORY_GAP = '16px 14px';

/**
 * 키즈관 - 추천스토어
 * @component
 */
const RecommendStore = () => {
  const { recommend_shop_list, is_loading } = useRecommendShopList({ page_name: 'KIDS' });
  const categories = recommend_shop_list.map((store) => {
    return {
      title: store.shop_name,
      link: `/store/${store.shop_id}/product-list?title=${store.shop_name}&type=${STORE_PRODUCT_TYPE.KID}`,
      img_url: store.typical_image_url,
      ubl: {
        navigation: 'kids_main',
        object_section: 'recommend_store_plp',
        object_type: 'catalog',
        object_id: store.shop_id,
      },
    };
  });

  if (is_loading) {
    return (
      <Section>
        <SC.Title>
          <Skeleton width={160} height={22.5} />
        </SC.Title>
        <CircleCategories is_loading gap={CIRCLE_CATEGORY_GAP} padding={CIRCLE_CATEGORY_PADDING} />
      </Section>
    );
  }

  return (
    <Section title='추천 키즈 스토어'>
      <CircleCategories categories={categories} gap={CIRCLE_CATEGORY_GAP} padding={CIRCLE_CATEGORY_PADDING} />
    </Section>
  );
};

export default RecommendStore;

const SC = {
  Title: styled.div`
    padding: 12px 16px 0;
  `,
};
