import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';
import CategoryChips from './CategoryChips';
import ProductRefresh from './ProductRefresh';
import Text from 'components/base/Text';
import { HScrollBox } from 'components/common/HScrollBox';
import ProductCardList from 'components/common/ProductCardList';
import ProductCardVirtualList from 'components/common/ProductCardVirtualList';
import { resetVirtualProductListScrollIndexAtom } from 'components/common/store/product';
import TopButton from 'components/common/TopButton';
import { useTrendProductList } from 'components/home/hooks/useTrendProductList';
import { invisible_scroll_bar } from 'components/products/details/styles';
import { white } from 'styles';
import { Log } from 'util/Tracker';

export const HOT_PRODUCT_LIST_KEY_NAME = 'hotProductList';
const ALL_CATEGORY = null;
interface Props {
  ubl?: Pick<Log, 'navigation' | 'data'>;
  use_category_chips?: boolean;
  renderBeforeProductRow?: (index: number, ad_product_component_idx: number, data_ubl?: Pick<Log, 'data'>) => ReactNode;
  renderAfterProductRow?: (index: number, ad_product_component_idx: number, data_ubl?: Pick<Log, 'data'>) => ReactNode;
}
/**
 * 홈 페이지 - HOT탭 상품 리스트
 * @component
 */
const AdProductList: FC<Props> = ({ ubl, use_category_chips, renderBeforeProductRow, renderAfterProductRow }) => {
  const { query } = useRouter();
  const product_category = getProductCategory(query.product_category, use_category_chips);
  const { ad_product_list, top_product_list, refetchAdIdList, is_loading, has_next_page, fetchNextPage } =
    useTrendProductList(product_category);
  const resetVirtualProductListScrollIndex = useUpdateAtom(resetVirtualProductListScrollIndexAtom);
  const ad_product_component_idx = getComponentIdxByUbl(ubl);

  const handleProductRefresh = () => {
    refetchAdIdList();
    resetProductScrollIndex();
  };

  const resetProductScrollIndex = () => {
    resetVirtualProductListScrollIndex(HOT_PRODUCT_LIST_KEY_NAME);
  };

  const renderBeforeRowItem = (row_index: number) => {
    return renderBeforeProductRow?.(row_index, ad_product_component_idx, ubl?.data);
  };
  const renderAfterRowItem = (row_index: number) => {
    return renderAfterProductRow?.(row_index, ad_product_component_idx, ubl?.data);
  };

  const is_show_footer = !has_next_page && !is_loading && ad_product_list.length > 0;
  const common_ubl = ubl && {
    ...ubl,
    object_section: 'ad_plp',
  };
  const product_ubl = common_ubl && {
    ...common_ubl,
    object_type: 'catalog',
  };

  return (
    <SC.Container>
      <div className='title-container'>
        <Text className='title' variant='title4_bold' color='black' as='p'>
          오늘의 트렌드 상품
        </Text>
        <Text className='title align_center' variant='small1_regular' color='border1' as='p'>
          sponsored
        </Text>
      </div>
      {use_category_chips && (
        <CategoryChips category={product_category} ubl={common_ubl} onChipClick={resetProductScrollIndex} />
      )}
      {!use_category_chips && (
        <ProductCardList column={3} product_list={top_product_list} product_ubl={product_ubl} is_loading={is_loading} />
      )}
      <ProductCardVirtualList
        key_name={HOT_PRODUCT_LIST_KEY_NAME}
        is_loading={is_loading}
        product_ubl={product_ubl}
        product_list={ad_product_list}
        start_index={use_category_chips ? 0 : 3}
        padding='8px 0 24px'
        row_css={row_css}
        beforeRowItem={renderBeforeRowItem}
        afterRowItem={renderAfterRowItem}
        has_next_page={has_next_page}
        onFetchNextPage={fetchNextPage}
      />
      {is_show_footer && <ProductRefresh onRefresh={handleProductRefresh} />}
      <TopButton ubl_navigation='home_ad' />
    </SC.Container>
  );
};

function getComponentIdxByUbl(ubl?: Pick<Log, 'navigation' | 'data'>) {
  if (!ubl) {
    return 0;
  }

  return ubl.data?.component_idx ?? 0;
}

function getProductCategory(category: string | string[] | undefined, use_category_chips?: boolean) {
  if (!use_category_chips) {
    return ALL_CATEGORY;
  }

  if (category) {
    return category === 'ALL' ? ALL_CATEGORY : (category as string);
  }

  return ALL_CATEGORY;
}

const row_css = css`
  padding: 0 16px 20px;
`;

const SC = {
  Container: styled.section`
    min-height: 100vh;

    .title {
      padding: 24px 16px 9px 16px;
    }

    .banner {
      padding: 6px 16px 22px;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
    }

    .align_center {
      align-self: center;
    }

    .row-item {
      padding: 0 8px 28px;
    }
  `,
  Tab: styled(HScrollBox)<{ is_app: boolean }>`
    ${invisible_scroll_bar}
    height: 51px;
    display: flex;
    align-items: center;
    position: sticky;
    top: ${({ is_app }) => (is_app ? 48 : 100)}px;
    z-index: 2;
    background-color: ${white};
  `,
};

export default AdProductList;
