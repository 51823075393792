import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, useMemo, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import EndDimmed from './EndDimmed';
import SlideProductList from './SlideProductList';
import TimeTitle from './TimeTitle';
import { LARGE_PRODUCT_SIMPLE_TYPE } from 'components/common/ProductCard';
import ProductCarouselList from 'components/common/ProductCarouselList';
import { useTimeSaleProductList } from 'components/home/hooks/useTimeSaleProductList';
import useBrowserLayoutEffect from 'hooks/useBrowserLayoutEffect';
import { white } from 'styles';
import { Log } from 'util/Tracker';

interface Props {
  title: string;
  product_expose_type?: 'list' | 'slide';
  custom_css?: SerializedStyles;
  ubl?: Omit<Log, 'category'>;
}

/**
 * 타임특가 상품리스트
 * @component
 */
const TimeSaleList: FC<Props> = ({ product_expose_type = 'list', title, ubl, custom_css }) => {
  const [is_end_sale_time, setIsEndSaleTime] = useState(false);
  const { time_sale_product_list, date_ended, refetchTimeSaleProductList, is_loading } = useTimeSaleProductList();
  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setIsEndSaleTime(true),
  });
  const product_ubl = useMemo(() => {
    return ubl ? { ...ubl, object_section: 'big_product_slide_section' } : undefined;
  }, [ubl]);

  useBrowserLayoutEffect(() => {
    if (time_sale_product_list.length > 0) {
      setIsEndSaleTime(new Date().getTime() >= date_ended);
      restart(new Date(date_ended));
    }
  }, [time_sale_product_list]);

  const list_top = product_expose_type === 'slide' ? 6 : 2;

  const SaleProductList = useMemo(() => {
    switch (product_expose_type) {
      case 'slide':
        return <SlideProductList product_list={time_sale_product_list} is_loading={is_loading} ubl={product_ubl} />;
      case 'list':
        return (
          <ProductCarouselList
            {...LARGE_PRODUCT_SIMPLE_TYPE}
            product_list={time_sale_product_list}
            is_loading={is_loading}
            gap={7}
            ubl={product_ubl}
          />
        );
    }
  }, [product_expose_type, is_loading, time_sale_product_list, product_ubl]);

  if (!is_loading && !time_sale_product_list.length) {
    return null;
  }

  return (
    <SC.Container css={custom_css}>
      <TimeTitle
        title={title}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        is_expired={is_end_sale_time}
        is_loading={is_loading}
      />
      <SC.List top={list_top}>
        {SaleProductList}
        {is_end_sale_time && <EndDimmed onRefresh={refetchTimeSaleProductList} />}
      </SC.List>
    </SC.Container>
  );
};

export default TimeSaleList;

const SC = {
  Container: styled.article`
    position: relative;
    margin: 16px 0 28px;
  `,
  List: styled.section<{ top: number }>`
    position: relative;
    margin-top: ${({ top }) => top}px;

    .slide_item {
      // splide pagePage, perMove 옵션이 정수 타입으로만 세팅 가능하여 다음 슬라이드 일부노출 영역을 css로 강제함
      // inline style로 splide에서 적용되어 !important로 우선순위 적용
      width: calc(((100% + 10px) / 1.15) - 10px) !important;
      padding-left: 16px;
    }

    a {
      &:active {
        background-color: ${white};
      }
    }
  `,
};
