import Best from './Best';
import Kids from './Kids';
import New from './New';
import Promotion from './Promotion';
import Sale from './Sale';
import Trend from './Trend';

export enum HomeTabType {
  HOT = 'hot',
  BEST = 'best',
  SALE = 'sale',
  PROMOTION = 'promotion',
  NEW = 'new',
  KIDS = 'kids',
}

/**
 * 홈탭 (Trend, Best, Sale, Promotion)
 * @component
 */
const Tabs = {
  [HomeTabType.HOT]: Trend,
  [HomeTabType.BEST]: Best,
  [HomeTabType.SALE]: Sale,
  [HomeTabType.PROMOTION]: Promotion,
  [HomeTabType.NEW]: New,
  [HomeTabType.KIDS]: Kids,
};

export default Tabs;
