import { IncomingMessage } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { QueryClient } from '@tanstack/react-query';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { getCmsEventContentReplyList, getCmsEventContentReplyMetadata } from 'api2';

export type PrefetchEventContentReplyListOptions = {
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
  query_client: QueryClient;
};

export function prefetchEventContentReplyList({
  context: { query, req },
  query_client,
}: PrefetchEventContentReplyListOptions) {
  const { uuid } = query as { uuid: string };
  return query_client.prefetchQuery({
    queryKey: getQueryKeyContentReplyList(uuid),
    queryFn: () => fetchContentReplyList(uuid, req),
  });
}

export function getQueryKeyContentReplyList(uuid: string) {
  return ['getCmsEventContentReplyList', uuid];
}

export function fetchContentReplyList(uuid: string, context?: IncomingMessage) {
  return getCmsEventContentReplyList({ event_content_reply_metadata_uuid: uuid }, { context }).then(
    ({ data }) => data.getEventContentReplyList,
  );
}

export type PrefetchEventContentReplyMetadataOptions = {
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
  query_client: QueryClient;
};

export function prefetchEventContentReplyMetadata({
  context: { query, req },
  query_client,
}: PrefetchEventContentReplyMetadataOptions) {
  const { uuid } = query as { uuid: string };
  return query_client.prefetchQuery({
    queryKey: getQueryKeyContentReplyMetadata(uuid),
    queryFn: () => fetchContentReplyMetadata(uuid, req),
  });
}

export function getQueryKeyContentReplyMetadata(uuid: string) {
  return ['getCmsEventContentReplyMetadata', uuid];
}

export function fetchContentReplyMetadata(uuid: string, context?: IncomingMessage) {
  return getCmsEventContentReplyMetadata({ uuid }, { context }).then(({ data }) => data.getEventContentReplyMetadata);
}
