import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import { FC } from 'react';
import List, { BEST_PRODUCT_LIST } from './List';
import Chips from 'components/common/Chips';
import { ChipItemWithLink } from 'components/common/Chips/Item';
import { resetVirtualProductListScrollIndexAtom } from 'components/common/store/product';
import { useCategoryStickeyHeight } from 'components/home/hooks/useCategoryStickeyHeight';
import { useHomeBestTabQuery } from 'components/home/hooks/useHomeBestTabQuery';
import { PRODUCT_LIST_TYPE, useActivatedDisplayCategory } from 'hooks/queries';

const CHIP_UBL = {
  navigation: 'home_best',
  object_type: 'catalog',
  object_section: 'PRODUCT',
};
/**
 * Best탭 상품 리스트
 * @component
 */
const BestProductList: FC = () => {
  const { category } = useHomeBestTabQuery();
  const { product_category_chip_list, is_loading } = useActivatedDisplayCategory({
    product_list_type: PRODUCT_LIST_TYPE.BEST,
    use_root_category: true,
  });
  const resetVirtualProductListScrollIndex = useUpdateAtom(resetVirtualProductListScrollIndexAtom);
  const stickey_height = useCategoryStickeyHeight();

  const handleChipClick = () => {
    resetVirtualProductListScrollIndex(BEST_PRODUCT_LIST);
  };

  const renderTopSkeleton = () => {
    return (
      <SC.Container>
        <Chips is_loading />
      </SC.Container>
    );
  };

  const chips: ChipItemWithLink[] = product_category_chip_list.map((chip) => ({
    ...chip,
    href: `/?tab=best&category=${chip.value}`,
  }));

  return (
    <SC.Container>
      {is_loading ? (
        renderTopSkeleton()
      ) : (
        <Chips
          is_replace
          chips={chips}
          active_chip_value={category}
          ubl={CHIP_UBL}
          stickey_height={stickey_height}
          onChipClick={handleChipClick}
        />
      )}
      <List category_id={category} />
    </SC.Container>
  );
};

export default BestProductList;

const SC = {
  Container: styled.section`
    padding-top: 4px;
  `,
  SkeletonChipList: styled.ul`
    padding: 16px;
    white-space: nowrap;
    overflow: scroll hidden;
    position: relative;
    gap: 6px;
    height: 51px;
    display: flex;
    align-items: center;
  `,
  Item: styled.li`
    width: 58px;
    height: 100%;
    margin-right: 12px;
    display: inline-block;
    align-items: center;

    cursor: pointer;
    .category {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  `,
};
