import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getCmsContentEditorGroup } from 'api2';
import { GetCmsContentEditorGroup } from 'api2/types';

type CMSContentEditorGroup = GetCmsContentEditorGroup['content_editor_group'];

export function getRelatedContentQueryKey(content_group_id: string, target_uuid: string) {
  return ['relatedContentGroup', content_group_id, target_uuid];
}

export type UseContentGroupItemOptions = Omit<
  UseQueryOptions<CMSContentEditorGroup | null, unknown, CMSContentEditorGroup | null, string[]>,
  'queryKey' | 'queryFn'
>;

export function useRelatedContentGroup(
  content_group_id: string,
  target_uuid: string,
  options?: UseContentGroupItemOptions,
) {
  return useQuery(
    getRelatedContentQueryKey(content_group_id, target_uuid),
    async () => {
      const result = await getCmsContentEditorGroup({ group_id: content_group_id, target_uuid });
      return result.data.content_editor_group;
    },
    { refetchOnWindowFocus: false, ...options },
  );
}
