import styled from '@emotion/styled';
import throttle from 'lodash-es/throttle';
import { useEffect, useState } from 'react';
import DeleteIcon from './DeleteIcon';
import MoreIcon from './MoreIcon';
import { Popover } from './Popover';
import { colors } from 'styles';

export type ContentReplyMoreMenuProps = {
  is_mine: boolean;
  handleDeleteReply: () => void;
};

export const ContentReplyMoreMenu = ({ is_mine, handleDeleteReply }: ContentReplyMoreMenuProps) => {
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const handleTogglePopover = () => {
    setIsPopoverShown((shown) => !shown);
  };
  const handleClosePopover = () => {
    setIsPopoverShown(false);
  };

  useEffect(() => {
    const handleScroll = throttle(handleClosePopover, 200);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {is_mine && (
        <Wrapper onBlur={handleClosePopover}>
          <MoreButton onClick={handleTogglePopover}>
            <MoreIcon />
          </MoreButton>
          <Popover is_shown={isPopoverShown} onClose={handleClosePopover}>
            <MenuItem onMouseDown={handleDeleteReply}>
              <DeleteIcon />
              삭제
            </MenuItem>
          </Popover>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
`;

const MoreButton = styled.button`
  flex: 1;
  margin-left: 8px;
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  flex: 0 0 auto;
  color: ${colors.gray800};
`;

const MenuItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  padding: 12px 40px 12px 44px;
  word-break: keep-all;
  & + & {
    border-top: 1px solid ${colors.gray200};
  }

  & > svg {
    position: absolute;
    top: 13px;
    left: 12px;
  }
`;
