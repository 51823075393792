import { LayoutVariablesContextValue } from '@croquiscom-pvt/event-modules';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { HEADER_HEIGHT, WEB_MAX_WIDTH } from 'styles';

export const PRODUCT_TAB_HEADER_HEIGHT = 66;
export const TAB_AREA_HEADER_HEIGHT = 52;

export function useLayoutVariables(is_home_tab?: boolean): Partial<LayoutVariablesContextValue> {
  const { is_native_version } = useAppVersion();
  const global_header_height = getGlobalHeaderHeight(is_native_version, is_home_tab);

  return {
    container_max_width: WEB_MAX_WIDTH,
    tab_area_tab_header_height: TAB_AREA_HEADER_HEIGHT,
    product_tab_header_height: PRODUCT_TAB_HEADER_HEIGHT,
    global_header_height,
  };
}

function getGlobalHeaderHeight(is_native_version: boolean, is_home_tab?: boolean) {
  if (is_native_version && is_home_tab) {
    return 0;
  }
  if (is_home_tab) {
    return HEADER_HEIGHT * 2;
  }
  return HEADER_HEIGHT;
}
