import { css, Global } from '@emotion/react';
import React, { ReactNode } from 'react';
import { gray900, WEB_MAX_WIDTH, white } from 'styles';
import { colorWithAlpha } from 'util/colors';

export interface LayoutProps {
  children: ReactNode;
}
export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Global
        styles={css`
          #__next,
          #root {
            max-width: ${WEB_MAX_WIDTH}px;
            width: 100%;
            height: 100%;
            background: ${white};
            margin: 0 auto;
          }
          a,
          button {
            cursor: pointer;
            transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1), color 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            :active {
              background: ${colorWithAlpha(gray900, 0.05)};
            }
          }
        `}
      />
      {children}
    </>
  );
}
