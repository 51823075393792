import { forwardRef, HTMLAttributes } from 'react';
import Row from '.';
import ProductCard, { ProductCardSize } from 'components/common/ProductCard';

interface Props extends HTMLAttributes<HTMLDivElement> {
  column?: number;
  size?: ProductCardSize;
}

/**
 * 상품카드 1row 로딩 처리 영역
 * @component
 */
const Skeleton = forwardRef<HTMLLIElement, Props>(({ size = 'medium', ...rest }, ref) => (
  <li ref={ref}>
    <Row {...rest}>
      <ProductCard.Skeleton size={size} />
      <ProductCard.Skeleton size={size} />
    </Row>
  </li>
));

export default Skeleton;
