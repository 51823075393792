import { ModuleRoot, SiteId } from '@croquiscom-pvt/event-modules';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import Head from 'next/head';
import { useState } from 'react';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { useAppLandingDialog } from 'components/common/AppLandingNotify/useAppLandingDialog';
import Header from 'components/common/Header';
import { module_map } from 'components/content-editor';
import { CmsEventsLayout, LoadingSkeleton } from 'components/content-editor/CmsEvents';
import { cms_scroll_restoration_atom } from 'components/content-editor/store';
import { useUserAdNotiStatus, useUserInfo } from 'components/my-page/hooks';
import { action_map } from 'util/content-editor/actions';
import { ContentEditorParsedPayload, useContentEditorQuery } from 'util/content-editor/content-editor-data';
import { ModuleRootCustomData } from 'util/content-editor/custom-data';
import { useLayoutVariables } from 'util/content-editor/layout';
import { useFindMatchedStickerImage } from 'util/content-editor/sticker-conditions/useFindMatchedStickerImage';
import { useIntegratedStickerImageConditionQueries } from 'util/content-editor/sticker-conditions/useIntegratedStickerImageConditionQueries';
import { useTracker } from 'util/Tracker';

interface Props {
  uuid: string;
  canonical_url: string;
  direct?: boolean;
  is_home_tab?: boolean;
}

/**
 * CMS 기획전 메인
 * @component
 */
export const CmsEventsMain = ({ uuid, canonical_url, is_home_tab }: Props) => {
  const query_client = useQueryClient();
  const { data } = useContentEditorQuery(uuid);
  const [now] = useState(() => Date.now());
  const tracker = useTracker();
  const { is_login } = useUserInfo();
  const { setAppPush } = useUserAdNotiStatus();
  const { openLoginDialog } = useLoginDialog();
  const { openAppLandingDialog } = useAppLandingDialog();
  const is_cms_scroll_restoration = useAtomValue(cms_scroll_restoration_atom);

  const has_floating_button = getHasFloatingButton({ data, now });
  const share_info = getShareInfo(data);
  const layout_variables = useLayoutVariables(is_home_tab);

  useIntegratedStickerImageConditionQueries({
    component_map: data?.content_editor_json.component_map ?? {},
    event_content_uuid: uuid,
  });
  const findMatchedStickerImage = useFindMatchedStickerImage();

  if (!data) {
    if (is_home_tab) {
      return null;
    }
    return (
      <div css={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        콘텐츠가 없습니다.
      </div>
    );
  }

  return (
    <CmsEventsLayout
      metadata={{
        page_url: canonical_url,
        button_title: share_info.button_title ?? '',
        description: share_info.description ?? '',
        image_src: share_info.image_src ?? '',
        title: share_info.title ?? '',
      }}
      has_floating_button={has_floating_button}
      is_home_tab={is_home_tab}
    >
      {!is_home_tab && <Header.Sub title={data.title ?? ''} />}
      {is_cms_scroll_restoration && <LoadingSkeleton />}
      <ModuleRoot<ModuleRootCustomData>
        next_head={Head}
        page_title={data.title ?? ''}
        page_url={canonical_url}
        date_started={data.date_started ?? now - 1}
        date_ended={data.date_ended ?? now + 1}
        language={data.language!}
        site_id={SiteId.FASHION_BY_KAKAO}
        country={data.country!}
        currency={data.currency!}
        uuid={data.uuid}
        support_agent_list={data.support_agent_list}
        share_info={share_info}
        component_map={data.content_editor_json.component_map}
        component_relations={data.content_editor_json.component_relations}
        action_map={action_map}
        module_map={module_map}
        layout_variables={layout_variables}
        findMatchedStickerImage={findMatchedStickerImage}
        custom_data={{
          tracker,
          query_client,
          is_login,
          is_home_tab,
          setAppPush,
          openLoginDialog,
          openAppLandingDialog,
        }}
      />
    </CmsEventsLayout>
  );
};

function getHasFloatingButton({ data, now }: { data?: ContentEditorParsedPayload | null; now: number }) {
  if (!data) {
    return false;
  }

  return Object.entries(data.content_editor_json.component_map).some(([, props]) => {
    let visible_with_date = true;

    if (props.visible_between) {
      const [start_date, end_date] = props.visible_between;
      visible_with_date = now >= start_date && now < end_date;
    }

    return props.__type === 'FloatingButton' && visible_with_date;
  });
}

function getShareInfo(data?: ContentEditorParsedPayload | null) {
  if (!data) {
    return {};
  }
  const { share_info } = data;

  return {
    title: share_info?.title ?? '',
    button_title: share_info?.button_title ?? '',
    description: share_info?.description ?? '',
    image_src: share_info?.image_src ?? '',
    disabled: share_info?.disabled ?? false,
  };
}
