import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import useCategoryRouterProps from './useCategoryRouterProps';
import { getCategoryProductList } from 'api2';
import { SearchedGoodsPriceRange, UxGoodsCardItem } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { useSearchQueryString } from 'hooks';
import { convertIsFastDelivery } from 'util/product_util';
import { SearchFilter, getFilterIdList } from 'util/search_utils';

type CategoryProductItem = Omit<UxGoodsCardItem, 'ubl'>;
type ProductCard = Omit<ProductCardProps, 'thumbnail_emblem_badge_list'>;

export const CATEGORY_PRODUCT_LIST_QUERY_KEY = 'getCategoryProductList';

const PAGE_PER_COUNT = 101;

const init_value = {
  product_list: [],
  ad_product_list: [],
  total_count: 0,
  end_cursor: null,
  has_next: false,
};

function uiItemToCardProps(item: CategoryProductItem) {
  const product_card: ProductCard = {
    ...item,
    catalog_product_id: item.catalog_product_id ?? '',
    is_new: item.is_new ?? false,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
    zpay: item.zpay ?? null,
    image_url: item.image_url ?? '',
    webp_image_url: item.webp_image_url ?? '',
    title: item.title ?? '',
    discount_rate: item.discount_rate ?? null,
    price: item.price ?? null,
    free_shipping: item.free_shipping ?? null,
    shop_product_no: item.shop_product_no ?? null,
    is_fast_delivery: convertIsFastDelivery(item.badge_list),
  };
  return product_card;
}

interface FetchProps {
  after: string | null;
  req?: IncomingMessage;
  category_id: string;
  search_filter: SearchFilter;
  limit_count?: number;
}

export const fetchCategoryProductList = async (props: FetchProps) => {
  const { after, req, category_id, search_filter, limit_count } = props;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const price_range: SearchedGoodsPriceRange | null = search_filter.price
    ? {
        gte: search_filter.price.min,
        lte: search_filter.price.max,
      }
    : null;

  const { data } = await getCategoryProductList(
    {
      after,
      goods_filter_option: {
        price_range,
        limit_count: limit_count ?? PAGE_PER_COUNT,
        color_list: search_filter.color_list,
        display_category_id_list: [category_id],
      },
      filter_id_list: getFilterIdList(search_filter),
    },
    context_request,
  );

  if (!data.fbk_srp_search_product_list) {
    return init_value;
  }

  const { ui_item_list, top_item_list, end_cursor, has_next } = data.fbk_srp_search_product_list;

  return {
    product_list: ui_item_list,
    ad_product_list: top_item_list,
    end_cursor,
    has_next,
  };
};

/**
 * 카테고리 상품조회 리스트
 * @hook
 */
const useCategoryProductList = (limit_count?: number) => {
  const { search_props } = useSearchQueryString();
  const { category_id_with_sub_category_id } = useCategoryRouterProps();

  const {
    data,
    isSuccess: is_success,
    isInitialLoading: is_product_first_loading,
    isFetching: is_product_fetching,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    makeQueryKey(category_id_with_sub_category_id, search_props),
    ({ pageParam }) =>
      fetchCategoryProductList({
        after: pageParam,
        category_id: category_id_with_sub_category_id,
        search_filter: search_props,
        limit_count,
      }),
    {
      getNextPageParam: (last_page) => {
        return last_page.end_cursor;
      },
      keepPreviousData: true,
    },
  );

  const category_product_list = useMemo(() => {
    // @ts-ignore
    return flatten(data?.pages.map((value) => value.product_list)).map(uiItemToCardProps);
  }, [data]);

  const ad_product_list: ProductCardProps[] = useMemo(() => {
    if (isNil(data) || isNil(data.pages[0]?.ad_product_list)) {
      return [];
    }
    // @ts-ignore
    return data.pages[0].ad_product_list.map(uiItemToCardProps);
  }, [data]);

  return {
    category_product_list,
    ad_product_list,
    is_product_first_loading,
    is_product_fetching,
    has_next_page,
    is_success,
    ...rest,
  };
};

export default useCategoryProductList;

function makeQueryKey(category_id: string, search_filter: SearchFilter) {
  return [
    CATEGORY_PRODUCT_LIST_QUERY_KEY,
    category_id,
    search_filter.color_list,
    search_filter.is_fast_shipping,
    search_filter.is_free_shipping,
    search_filter.is_zigin_shipping,
    search_filter.sort_type,
    search_filter.price?.max,
    search_filter.price?.min,
  ];
}
