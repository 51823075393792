import { ContentEditorComponentType, StickerImageCondition } from '@croquiscom-pvt/event-modules';
import flatten from 'lodash-es/flatten';
import { useMemo } from 'react';
import { getConditionStateQueryKey } from './getConditionStateQueryKey';

type ModuleType = ContentEditorComponentType<void>['__type'];
type ExtractModuleType<T extends ModuleType> = Extract<ContentEditorComponentType<void>, { __type: T }>;

function isStickerGroupModule(
  currentModule: ContentEditorComponentType<void>,
): currentModule is ExtractModuleType<'StickerGroup'> {
  return currentModule.__type === 'StickerGroup';
}

function getStickerGroupModulesFromComponentMap(component_map: Record<string, ContentEditorComponentType<void>>) {
  return Object.keys(component_map)
    .map((uuid) => component_map[uuid])
    .filter(isStickerGroupModule);
}

interface UseConditionQueryKeyEntryListOptions {
  component_map: Record<string, ContentEditorComponentType<void>>;
}

export function useConditionQueryKeyEntryList({ component_map }: UseConditionQueryKeyEntryListOptions) {
  const query_key_condition_entry_list = useMemo(() => {
    const sticker_group_modules = getStickerGroupModulesFromComponentMap(component_map);
    const stickers = flatten(sticker_group_modules.map(({ stickers }) => stickers));
    const conditional_images = flatten(stickers.map(({ conditional_images }) => conditional_images));
    const conditions = flatten(conditional_images.flatMap(({ conditions }) => conditions));
    const uniq_query_key_condition_map = new Map<string, StickerImageCondition>();

    conditions.forEach((condition) => {
      const key = getConditionStateQueryKey({ sticker_image_condition: condition });

      if (key) {
        uniq_query_key_condition_map.set(key, condition);
      }
    });

    return Array.from(uniq_query_key_condition_map.entries());
  }, [component_map]);

  return query_key_condition_entry_list;
}
