import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { MouseEvent, useEffect, useMemo, forwardRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from 'components/base/Image';
import { ProductCardProps, ProductCardUbl } from 'components/common/ProductCard';
import { object_idx_by_recent_list_atom } from 'components/home/store/trend';
import { convertStaticImageUrl } from 'util/product_util';
import { useTracker } from 'util/Tracker';

interface Props {
  active_id: string;
  product: ProductCardProps;
  index: number;
  product_ubl?: ProductCardUbl;
  onClick?: (e: MouseEvent<HTMLElement>, selected_id: string) => void;
}

/**
 * 트랜드탭 - 최근 본 상품의 연관상품 > 최근 본 상품 버튼 캐러셀 아이템
 * @component
 */
const Item = forwardRef<HTMLLIElement, Props>(({ active_id, product, index, product_ubl, onClick }, parents_ref) => {
  const { ref: button_ref, inView: in_view } = useInView({ threshold: 0.5 });
  const tracker = useTracker();
  const object_idx_by_recent_list = useAtomValue(object_idx_by_recent_list_atom);
  const [is_first_render_recent_list, setIsFirstRenderRecentList] = useState(true);

  const product_id = product.catalog_product_id ?? '';

  const button_ubl = useMemo(() => {
    if (product_ubl) {
      return {
        ...product_ubl,
        object_id: product_id,
        object_idx: index,
      };
    }
  }, [index, product_id, product_ubl]);

  useEffect(() => {
    if (in_view && button_ubl) {
      tracker.addLog({
        ...button_ubl,
        category: 'impression',
      });
    }
  }, [button_ubl, in_view, tracker]);

  useEffect(() => {
    if (object_idx_by_recent_list > 0) {
      setIsFirstRenderRecentList(false);
    }
  }, [object_idx_by_recent_list]);

  useEffect(() => {
    if (is_first_render_recent_list) {
      return;
    }

    if (button_ubl && product_id === active_id) {
      tracker.addLog({
        ...button_ubl,
        category: 'click',
      });
    }
  }, [product_id, active_id, button_ubl, tracker, is_first_render_recent_list]);

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    onClick?.(e, product_id);
  };

  return (
    <SC.ButtonItem ref={parents_ref} onClick={handleButtonClick}>
      <SC.ImageWrap ref={button_ref} className={cx({ active: product_id === active_id })}>
        <Image
          src={convertStaticImageUrl({ image_url: product.webp_image_url ?? '' })}
          alt={product.title ?? ''}
          layout='fill'
          objectFit='cover'
          use_fade
        />
        <SC.Filter />
      </SC.ImageWrap>
    </SC.ButtonItem>
  );
});

export default Item;

const SC = {
  ButtonItem: styled.li`
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  `,
  ImageWrap: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;

    img {
      border-radius: 50px;
    }

    &.active {
      box-shadow: 0 0 0 2px rgba(15, 15, 15, 0.7);
    }
  `,
  Filter: styled.div`
    position: absolute;
    inset: 0;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.03);
  `,
};
