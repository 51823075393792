import { useRouter } from 'next/router';

/**
 * 카테고리 id 쿼리 관련 hook
 * @hook
 */
export const useCategoryQueryString = () => {
  const { replace, pathname, query } = useRouter();
  const main_category_id = query.category_id as string;

  const handleChangeCategory = (id: string) => {
    if (main_category_id === id) {
      replace({
        pathname,
      });
      return;
    }

    replace(
      {
        pathname,
        query: {
          category_id: id,
        },
      },
      undefined,
      { scroll: false },
    );
  };

  return {
    main_category_id,
    handleChangeCategory,
  };
};
