import { cx } from '@emotion/css';
import { MouseEvent, forwardRef, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Chip, { ChipItem } from 'components/base/Chip';
import Link from 'components/base/Link';
import { mergeRefs } from 'util/mergeRefs';
import { Log, useTracker } from 'util/Tracker';

export interface ChipItemWithLink extends ChipItem {
  href: string;
}

interface Props {
  chip: ChipItemWithLink;
  active_chip_value: string;
  onClick?: (e: MouseEvent<HTMLElement>, chip: ChipItemWithLink, idx: number) => void;
  ubl?: Omit<Log, 'category' | 'object_idx' | 'object_id'>;
  index: number;
  is_replace?: boolean;
}

/**
 * 칩리스트 - 아이탬
 * @component
 */
const Item = forwardRef<HTMLLIElement, Props>(
  ({ chip, active_chip_value, ubl, onClick, index, is_replace }, parents_ref) => {
    const tracker = useTracker();
    const { ref: chip_ref, inView: in_view } = useInView({ threshold: 0.5 });

    const chip_ubl: Omit<Log, 'category'> | undefined = useMemo(() => {
      if (ubl) {
        return { object_id: chip.name, object_idx: index, ...ubl };
      }
      return undefined;
    }, [ubl, index, chip.name]);

    useEffect(() => {
      if (in_view && chip_ubl) {
        tracker.addLog({ category: 'impression', ...chip_ubl });
      }
    }, [chip_ubl, tracker, in_view]);

    const handleChipClick = (e: MouseEvent<HTMLElement>) => {
      if (chip_ubl) {
        tracker.addLog({ category: 'click', ...chip_ubl });
      }
      onClick?.(e, chip, index);
    };

    const link_props = is_replace ? { href: chip.href, replace: true } : { href: chip.href };

    return (
      <li ref={mergeRefs([chip_ref, parents_ref])}>
        <Link {...link_props} onClick={handleChipClick}>
          <Chip
            text={chip.name}
            active={chip.value === active_chip_value}
            className={cx({ active: chip.value === active_chip_value })}
          />
        </Link>
      </li>
    );
  },
);

export default Item;
