import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ComponentProps, FC, useEffect, MouseEvent, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Tabs, { TabItem, TabSize } from '.';
import { gray800, pink600, white } from 'styles';
import { typography } from 'styles/font';
import { useTracker } from 'util/Tracker';

interface Props extends Pick<ComponentProps<typeof Tabs>, 'ubl' | 'size'> {
  index: number;
  tab: TabItem;
  local_active_tab_id: string;
  onClick?: (tab: TabItem, e: MouseEvent<HTMLElement>) => void;
}

const Tab: FC<Props> = ({ ubl, size = 'medium', tab, local_active_tab_id, index, onClick }) => {
  const tracker = useTracker();
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const tab_ubl = useMemo(() => {
    return ubl
      ? {
          ...ubl,
          object_type: 'category',
          object_id: tab.label,
          object_idx: index,
        }
      : null;
  }, [ubl, index, tab.label]);

  useEffect(() => {
    if (in_view && tab_ubl) {
      tracker.addLog({ category: 'impression', ...tab_ubl });
    }
  }, [ref, in_view, tracker, tab_ubl]);

  const handleTabClick = (e: MouseEvent<HTMLElement>) => {
    if (tab_ubl) {
      tracker.addLog({ category: 'click', ...tab_ubl });
    }
    onClick?.(tab, e);
  };

  const {
    id,
    label,
    start_icon,
    end_icon,
    is_new,
    tab_css,
    active_name_color = '#242424',
    inactive_name_color = '#919191',
  } = tab;
  const selected = local_active_tab_id === id;
  const is_show_new_badge = is_new && !selected;
  const name_color = selected ? active_name_color : inactive_name_color;

  return (
    <SC.Item ref={ref} onClick={handleTabClick} css={tab_css}>
      <SC.ItemWrapper size={size} is_new={is_new} name_color={name_color}>
        {start_icon}
        <p>{label}</p>
        {end_icon}
        {is_show_new_badge && <SC.NewBadge />}
      </SC.ItemWrapper>
      {selected ? (
        <motion.div
          transition={{
            duration: 0.1,
          }}
          className='indicator'
          layoutId='indicator'
        />
      ) : (
        <div className='hide-indicator' />
      )}
    </SC.Item>
  );
};

export default Tab;

const SC = {
  Item: styled.button`
    width: 100%;
    cursor: pointer;

    .indicator {
      height: 2px;
      background: ${gray800};
    }

    .hide-indicator {
      height: 2px;
      background-color: transparent;
    }

    &:active {
      background-color: ${white};
    }
  `,
  ItemWrapper: styled.div<{ size: TabSize; is_new?: boolean; name_color: string }>`
    ${({ size }) => tab_size_css[size]}
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: ${({ name_color }) => name_color};

    > svg {
      margin: ${({ is_new }) => (is_new ? '0' : '0 4px')};
    }
  `,
  NewBadge: styled.div`
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${pink600};
    margin-left: 3px;
  `,
};

const tab_size_css = {
  small: css`
    ${typography.body4_medium}
    height: 40px;
    p {
      padding: 11px 10px;
    }
  `,
  medium: css`
    ${typography.title5_medium}
    height: 46px;
    padding: 14px 10px 12px;
  `,
};
