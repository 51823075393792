import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { setShowMessageAtom } from '../store';
import { USER_INFO_QUERY_KEY, UserInfo } from './useUserInfo';
import { updateUserAccount, setUserAccountBody } from 'api2';
import { SetUserAccountBodyInput, UpdateUserAccountInput } from 'api2/types';

export enum ActionName {
  INIT_NAME = 'INIT_NAME',
  CHANGE_NAME = 'CHANGE_NAME',
  CLICK_CLEAR_BUTTON = 'CLICK_CLEAR_BUTTON',
  UPDATE_USER_NAME = 'UPDATE_USER_NAME',
}

export interface BodySizeInput {
  height: number;
  weight: number;
  upper_size: number;
  bottom_size: number;
  shoe_size: number;
}

interface ProfileState {
  name: string;
  disabled_edit_button: boolean;
  is_show_clear_button: boolean;
}
type ActionType =
  | { type: ActionName.INIT_NAME; payload: { init_nmae: string } }
  | { type: ActionName.CHANGE_NAME; payload: { new_name: string } }
  | { type: ActionName.CLICK_CLEAR_BUTTON; payload: { init_nmae: string } }
  | { type: ActionName.UPDATE_USER_NAME };

/**
 * 회원정보를 수정을 위한 hook
 * @hook
 */
export const useUpdateUserAccount = () => {
  const query_client = useQueryClient();
  const { mutate: updateAccountMutate, isLoading: is_loading_update_account } = useMutation(updateUserAccount);
  const { mutate: updateBodyInfoMutate } = useMutation(setUserAccountBody);
  const router = useRouter();
  const setShowMessage = useUpdateAtom(setShowMessageAtom);

  // 이름 수정 시 발생하는 리듀서
  const profileReducer = (state: ProfileState, action: ActionType): ProfileState => {
    switch (action.type) {
      case ActionName.INIT_NAME:
        return {
          ...state,
          name: action.payload.init_nmae,
        };
      case ActionName.CHANGE_NAME:
        return {
          ...state,
          name: action.payload.new_name,
          disabled_edit_button: false,
          is_show_clear_button: true,
        };
      case ActionName.CLICK_CLEAR_BUTTON:
        return {
          ...state,
          name: action.payload.init_nmae,
          is_show_clear_button: false,
          disabled_edit_button: true,
        };
      case ActionName.UPDATE_USER_NAME:
        return {
          ...state,
          disabled_edit_button: true,
          is_show_clear_button: false,
        };
    }
  };

  const updateAccountInfo = (input: UpdateUserAccountInput) => {
    updateAccountMutate(
      {
        input,
      },
      {
        onSuccess() {
          query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, (prev) => {
            if (prev) {
              prev.full_name = input.full_name!;
            }
            return prev;
          });
          setShowMessage('회원 정보가 저장되었어요.');
          router.replace('/my-page/profile');
        },
      },
    );
  };

  const updateBodyInfo = (input: BodySizeInput) => {
    updateBodyInfoMutate(
      {
        input,
      },
      {
        onSuccess() {
          query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, (prev) => {
            const { height, weight, upper_size, bottom_size, shoe_size } = input;
            if (prev) {
              prev.body = {
                height: { value: height ?? 0, label: height?.toString() ?? '' },
                weight: { value: weight ?? 0, label: weight?.toString() ?? '' },
                upper_size: { value: upper_size ?? 0, label: height?.toString() ?? '' },
                bottom_size: { value: bottom_size ?? 0, label: bottom_size?.toString() ?? '' },
                shoe_size: { value: shoe_size ?? 0, label: shoe_size?.toString() ?? '' },
              };
            }
            return prev;
          });
          setShowMessage('사이즈 정보가 저장되었어요.');
          router.back();
        },
      },
    );
  };

  return {
    profileReducer,
    updateAccountInfo,
    updateBodyInfo,
    is_loading_update_account,
  };
};
