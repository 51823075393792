import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getActiveBannerList } from 'api2';
import { GetActiveBannerList } from 'api2/types';

type ActiveLineBanner = GetActiveBannerList['active_line_banner'];

export const ACTIVE_BANNER_LIST_QUERY_KEY = 'getActiveBannerList';

export interface ActiveBanner {
  id: string;
  title: string;
  image_url: string;
  link: string;
  date_start: number;
  date_end: number;
}

export interface ActiveBannerList {
  main_banner: {
    item_list: ActiveBanner[];
    total_count: number;
  };
  line_banner: ActiveLineBanner;
}

/**
 * 백오피스 '패바카 배너관리'에 세팅된 노출지면 배너 이름 모음
 */
export enum BANNER_PAGE_NAME {
  TREND = 'TREND_TAB',
  SALE = 'SALE_TAB',
  ZIGZIN = 'ZIGZIN',
  KIDS = 'KIDS',
  FAST_DELIVERY = 'FAST_DELIVERY',
}

const init_fetch_data = {
  item_list: [],
  total_count: 0,
};

export const fetchActiveBannerList = async (
  banner_page_name: BANNER_PAGE_NAME,
  req?: IncomingMessage,
): Promise<ActiveBannerList> => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { active_banner_list, active_line_banner },
    } = await getActiveBannerList({ banner_page_name }, context_request);

    return {
      main_banner: {
        total_count: active_banner_list.total_count,
        item_list: active_banner_list.item_list.map((banner) => {
          const { id, title, image_url, landing_url, date_start, date_end } = banner;
          const active_banner: ActiveBanner = {
            id,
            title,
            image_url,
            date_start,
            date_end,
            link: getBannerHref(landing_url),
          };
          return active_banner;
        }),
      },
      line_banner: active_line_banner,
    };
  } catch {
    return {
      main_banner: init_fetch_data,
      line_banner: null,
    };
  }
};

export const useActiveBannerList = (banner_page_name: BANNER_PAGE_NAME) => {
  const {
    data,
    isInitialLoading: is_initial_loading,
    isLoading: is_loading,
  } = useQuery<ActiveBannerList>([ACTIVE_BANNER_LIST_QUERY_KEY, banner_page_name], () =>
    fetchActiveBannerList(banner_page_name),
  );

  return {
    main_banner_list: {
      active_banner_list: data?.main_banner.item_list ?? [],
      total_count: data?.main_banner.total_count ?? 0,
    },
    line_banner: data?.line_banner ?? null,
    is_loading,
    is_initial_loading,
  };
};

function getBannerHref(landing_url: string | null) {
  if (landing_url === null) {
    return '';
  }
  // 스토어픽 내재화 랜딩
  const is_storepick_url = landing_url.indexOf('/storepick') > 0;
  if (is_storepick_url) {
    const store_query_param = landing_url.split('/storepick')[1];
    return `/storepick${store_query_param}`;
  }

  // CMS, 이벤트킷 내재화 랜딩
  const eck_url = landing_url.indexOf('/events') > 0;
  if (eck_url) {
    const event_router_path = landing_url.split('events/')[1];
    const [uuid, rest] = event_router_path.split('?');
    return `/events/${uuid}${rest ? `?${rest}` : ''}`;
  }

  //live 페이지 렌딩
  const live_url = landing_url.indexOf('/lives') > 0;
  if (live_url) {
    const campaign_key = landing_url.split('lives/')[1];
    return `/lives/${campaign_key}`;
  }
  return landing_url;
}
