import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { FC, MouseEvent, useState } from 'react';
import { ProductCardProps, ProductUbl } from '../index';
import { IconCancel16, IconSearch16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';
import { dismiss_image_search_tooltip_atom, push_image_search_history_atom } from 'components/search/image/store';
import { ImageSearchType } from 'pages/search/image/index.page';
import { pink_primary, white } from 'styles';
import { useTracker } from 'util/Tracker';

interface Props extends ProductCardProps {
  product_ubl: ProductUbl;
}

const SearchIcon: FC<Props> = ({
  use_search_tooltip,
  product_ubl,
  image_url,
  catalog_product_id,
  use_search,
  index,
}) => {
  const tracker = useTracker();
  const router = useRouter();
  const pushImageSearchHistory = useUpdateAtom(push_image_search_history_atom);

  const [is_dismiss_image_search_tooltip, setIsDismissImageSearchTooltip] = useAtom(dismiss_image_search_tooltip_atom);
  const [open_tooltip, setOpenTooltip] = useState<boolean>(
    !is_dismiss_image_search_tooltip && (use_search_tooltip ?? false),
  );

  const handleUbl = () => {
    if (product_ubl) {
      const { navigation, object_section, object_id } = product_ubl;
      tracker.addLog({
        category: 'click',
        navigation,
        object_section,
        object_type: 'button',
        object_id: 'result_image_search',
        object_idx: index,
        data: {
          catalog_product_id: object_id,
        },
      });
    }
  };

  const handleSearchClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.currentTarget.contains(e.target as HTMLElement)) {
      return;
    }
    handleUbl();
    pushImageSearchHistory({
      source: image_url ?? '',
      cropped: image_url ?? '',
    });
    router.push(`/search/image?search_type=${ImageSearchType.catalog}&catalog_product_id=${catalog_product_id}`);
  };
  const handleTooltipCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsDismissImageSearchTooltip(true);
    setOpenTooltip(false);
  };

  if (!use_search) {
    return null;
  }

  return (
    <SC.Root onClick={handleSearchClick}>
      <SC.Tooltip
        use_portal
        open={open_tooltip}
        modifiers={[{ name: 'preventOverflow', options: { padding: 8 } }]}
        contents={
          <>
            <Text variant='small1_regular' mr={10}>
              검색 아이콘을 클릭하면 <br />
              상품과 비슷한 상품을 찾아드려요.
            </Text>
            <button onClick={handleTooltipCloseClick}>
              <IconCancel16 color={white} />
            </button>
          </>
        }
      >
        <SC.IconContainer>
          <IconSearch16 color={white} />
        </SC.IconContainer>
      </SC.Tooltip>
    </SC.Root>
  );
};

export default SearchIcon;

const SC = {
  Root: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px 0 0;

    &:active {
      background-color: transparent;
    }
  `,
  IconContainer: styled.div`
    padding-bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
  `,
  Tooltip: styled(Tooltip)`
    background-color: ${pink_primary};
  `,
};
