import { useProductBaseInfo, useProductMaxBenefit } from '.';
import useIssueCoupon, { COUPON_ISSUE_MSG } from 'queries/coupon/useIssueCoupon';
import { Toast } from 'util/Toast';

/**
 * PDP - 예상최저가 쿠폰 다운로드하는 hook
 * @hook
 */
export const useDownloadMaxBenefitCoupon = () => {
  const { mutate: issue_coupon } = useIssueCoupon();
  const { updateDownloadCouponStatus } = useProductBaseInfo();
  const { updateMaxBenefitCouponIssued } = useProductMaxBenefit();

  const issueCoupon = async (coupon_policy_id: string, toast_message?: string | null) => {
    issue_coupon(
      { type: 'coupon', id: coupon_policy_id },
      {
        onSuccess(status) {
          if (status === 'SUCCEEDED' || status === 'ALREADY_ISSUED') {
            updateDownloadCouponStatus();
            updateMaxBenefitCouponIssued();
          }

          if (status === 'SUCCEEDED' && toast_message) {
            Toast.show(toast_message);
            return;
          }

          Toast.show(COUPON_ISSUE_MSG[status]);
        },
        onError() {
          Toast.show(COUPON_ISSUE_MSG.FAILED);
        },
      },
    );
  };

  return {
    issueCoupon,
  };
};
