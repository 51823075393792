import { ComponentProps, FC } from 'react';
import Header from 'components/common/Header';

interface Props {
  is_searchable?: boolean;
  is_direct?: boolean;
}

const HEADER_TYPE: {
  [type: string]: ComponentProps<typeof Header>;
} = {
  DEFAULT: {
    left_icons: ['back'],
    right_icons: ['search', 'home'],
    title: '이미지 검색',
  },
  DIRECT: {
    left_icons: ['home'],
    right_icons: ['search'],
    title: '이미지 검색',
  },
};

const ImageSearchHeader: FC<Props> = ({ is_searchable = false, is_direct = false }) => {
  const header_props = is_searchable && is_direct ? HEADER_TYPE.DIRECT : HEADER_TYPE.DEFAULT;
  return <Header {...header_props} />;
};

export default ImageSearchHeader;
