import { useProductBaseInfo } from './useProductBaseInfo';
import { PdpCatalogProductImageType } from 'api2/types';

export const useProductMainImage = () => {
  const { product } = useProductBaseInfo();
  const imageOrder = [
    PdpCatalogProductImageType.MAIN,
    PdpCatalogProductImageType.SUB,
    PdpCatalogProductImageType.CONTENT,
  ];
  const imageList = [...product.product_image_list].sort(
    (a, b) => imageOrder.indexOf(a.image_type) - imageOrder.indexOf(b.image_type),
  );

  return imageList[0];
};
