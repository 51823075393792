import ProductCardVirtualList from 'components/common/ProductCardVirtualList';
import { useDiscountedNewProductList } from 'components/home/hooks/useDiscountedNewProductList';

export const DISCOUNTED_NEW_PRODUCT_LIST_KEY_NAME = 'discountedNewProductList';

const product_ubl = {
  navigation: 'home_benefits',
  object_section: 'discount_new_products',
};
interface Props {
  active_category_id: string;
}

/**
 * 세일탭 > 할인하는 신상품
 * @component
 */
const ProductList = ({ active_category_id }: Props) => {
  const { discounted_new_product_list, is_loading, has_next_page, fetchNextPage } = useDiscountedNewProductList(
    Number(active_category_id),
  );

  return (
    <ProductCardVirtualList
      key_name={DISCOUNTED_NEW_PRODUCT_LIST_KEY_NAME}
      is_loading={is_loading}
      product_list={discounted_new_product_list}
      product_ubl={product_ubl}
      has_next_page={has_next_page}
      onFetchNextPage={fetchNextPage}
    />
  );
};

export default ProductList;
