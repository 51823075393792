import { cx } from '@emotion/css';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { gray_secondary, pink050, pink800, white } from '../../../styles';
import { ProductCardSize } from '.';
import Image from 'components/base/Image';
import { typography } from 'styles/font';

type TagType = 'zigzin' | 'new' | 'free' | 'fast' | 'promotion';

const MAX_TAG_COUNT_INFO: Record<ProductCardSize, number> = {
  small: 2,
  medium: 3,
  large: 3,
};

const TAG_HEIGHT: Record<ProductCardSize, string> = {
  small: '17',
  medium: '19',
  large: '19',
};

const TAG_KO_NAME: Record<ProductCardSize, Record<TagType, string>> = {
  small: {
    zigzin: '직진',
    new: '신상',
    free: '무료배송',
    fast: '빠른',
    promotion: '프로모션',
  },
  medium: {
    zigzin: '직진배송',
    new: '신상',
    free: '무료배송',
    fast: '빠른출발',
    promotion: '프로모션',
  },
  large: {
    zigzin: '직진배송',
    new: '신상',
    free: '무료배송',
    fast: '빠른출발',
    promotion: '프로모션',
  },
};

export interface Props {
  /**
   * 무료 배송 여부
   */
  free_shipping?: boolean;
  /**
   * 신 상품 여부
   */
  is_new?: boolean;
  /**
   * 직진 배송 여부
   */
  is_zonly?: boolean;
  /**
   * 빠른 배송 여부
   */
  is_fast_delivery?: boolean;
  /**
   * 프로모션 뱃지 정보
   */
  thumbnail_emblem_badge_list?: Array<{ type: string; image_url: string }> | null;
  size?: ProductCardSize;
  /**
   * 커스텀 스타일 수정을 위한 css를 정의 합니다.
   */
  custom_css?: SerializedStyles;
}

const Tags: FC<Props> = ({
  free_shipping,
  is_new,
  is_zonly,
  is_fast_delivery,
  thumbnail_emblem_badge_list,
  size = 'medium',
  custom_css,
}) => {
  const is_empty = !free_shipping && !is_zonly && !is_new && !thumbnail_emblem_badge_list?.length;

  if (is_empty) {
    return null;
  }

  const getTagList = () => {
    const tag_list: TagType[] = [];
    const is_fbk_badge_list = thumbnail_emblem_badge_list?.some((badge) => badge.type === 'FBK_PROMOTION_BADGE');

    if (is_fbk_badge_list) {
      tag_list.push('promotion');
    }

    if (is_zonly) {
      tag_list.push('zigzin');
    }

    // 빠른출발은 직진배송이 있는 경우 미노출
    if (is_fast_delivery && !is_zonly) {
      tag_list.push('fast');
    }

    if (is_new) {
      tag_list.push('new');
    }

    if (free_shipping && !is_fast_delivery && !is_zonly) {
      tag_list.push('free');
    }

    const max_tag_count = MAX_TAG_COUNT_INFO[size];

    if (tag_list.length > max_tag_count) {
      return tag_list.slice(0, max_tag_count);
    }

    return tag_list;
  };

  const tag_list = getTagList();
  const badge_image_url = thumbnail_emblem_badge_list?.length ? thumbnail_emblem_badge_list[0].image_url : '';
  const classname = getClassName(size);
  return (
    <SC.Container css={custom_css} height={TAG_HEIGHT[size]}>
      {tag_list.map((tag) => (
        <SC.Tag
          key={tag}
          css={badge_style[tag]}
          className={cx(classname, tag === 'promotion' && 'promotion')}
          height={TAG_HEIGHT[size]}
        >
          {tag === 'promotion' ? (
            <Image
              src={badge_image_url}
              layout='fill'
              objectFit='cover'
              alt='프로모션 뱃지'
              className={cx(tag_list.length === 1 && 'only_badge')}
            />
          ) : (
            <> {TAG_KO_NAME[size][tag]}</>
          )}
        </SC.Tag>
      ))}
    </SC.Container>
  );
};

export default Tags;

const getClassName = (size: ProductCardSize) => {
  if (size === 'small') {
    return 'small-font';
  }

  return 'medium-font';
};

const badge_style: Record<TagType, SerializedStyles> = {
  zigzin: css`
    background: rgba(68, 92, 209, 0.8);
    color: ${white};
  `,
  fast: css`
    background: rgba(113, 135, 254);
    color: ${white};
  `,
  new: css`
    background: ${pink050};
    color: ${pink800};
  `,
  free: css`
    background: rgba(233, 233, 233);
    color: ${gray_secondary};
  `,
  promotion: css``,
};

const SC = {
  Container: styled.div<{ height: string }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ height }) => height}px;

    .small-font {
      font-size: 9px;
      padding: 2px 4px;
    }

    .medium-font {
      font-size: 10px;
      padding: 3px 5px;
    }

    .promotion {
      padding: 0;

      img {
        // 이미지 뱃지의 height 고정, width 가변되어야 하는데 Image 컴포넌트에서 height를 auto로 주고 있어 !important로 우선순위 적용
        width: auto !important;
        height: ${({ height }) => height}px !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        &.only_badge {
          border-radius: 4px;
        }
      }
    }
  `,
  Tag: styled.span<{ height: string }>`
    ${typography.small4_bold};
    font-weight: 700;
    backdrop-filter: blur(3px);
    height: ${({ height }) => height}px;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `,
};
