export const PaymentGatewayVendorLabel = {
  NICEPAY: '나이스페이',
  1: '나이스페이',
  SETTLE_BANK: '세틀뱅크',
  2: '세틀뱅크',
};

export const SettleBankLogLabel = {
  0: '예금주 조회',
  1: 'ARS 인증 요청',
};

export enum DepositRefundStatusLabel {
  WAITING = '대기중',
  REFUNDED = '수기환불예정',
  COMPLETED_BY_MANUAL = '수기환불완료',
  CANCELLING = '취소처리중',
  COMPLETED_BY_PG = 'PG취소완료',
  REFUND_ACCOUNT_CHANGE_WAITING = '계좌변경요청중',
}

export enum PaymentCashReceiptPurposeLabel {
  DEDUCTION = '개인소득공제',
  EXPENDITURE = '법인지출증빙',
}

export enum PaymentCashReceiptIdentityTypeLabel {
  CARD = '현금영수증 카드번호',
  MOBILE_NUMBER = '휴대폰 번호',
  REGISTER_BUSINESS = '사업자등록번호',
}

export const BANK_CODES: Array<{ name: string; code: string }> = [
  { name: '산업은행', code: '002' },
  { name: '기업은행', code: '003' },
  { name: '국민은행', code: '004' },
  { name: '외환은행', code: '005' },
  { name: '수협', code: '007' },
  { name: '농협', code: '011' },
  { name: '우리은행', code: '020' },
  { name: 'SC제일은행', code: '023' },
  { name: '한국씨티은행', code: '027' },
  { name: '광주은행', code: '034' },
  { name: '경남은행', code: '039' },
  { name: '대구은행', code: '031' },
  { name: '부산은행', code: '032' },
  { name: '전북은행', code: '037' },
  { name: '제주은행', code: '035' },
  { name: '새마을금고', code: '045' },
  { name: '신협', code: '048' },
  { name: '상호저축은행', code: '050' },
  { name: 'HSBC은행', code: '054' },
  { name: '우체국', code: '071' },
  { name: '하나은행', code: '081' },
  { name: '신한은행', code: '088' },
  { name: '케이뱅크', code: '089' },
  { name: '카카오뱅크', code: '090' },
];

/**
 * 은행 코드로 은행 이름을 반환한다.
 * @param bank_code 은행 코드
 */
export function getBankNameByCode(bank_code: string): string {
  for (const bank of BANK_CODES) {
    if (bank.code === bank_code) {
      return bank.name;
    }
  }
  return '(에러)';
}

export const CARD_CODES: Array<{ name: string; code: string }> = [
  {
    name: 'KB국민',
    code: 'KB',
  },
  {
    name: '비씨',
    code: 'BC',
  },
  {
    name: '씨티',
    code: 'CITY',
  },
  {
    name: '광주',
    code: 'GWANGJU',
  },
  {
    name: '하나',
    code: 'HANA',
  },
  {
    name: '현대',
    code: 'HYUNDAI',
  },
  {
    name: '제주',
    code: 'JEJU',
  },
  {
    name: '전북',
    code: 'JEONBUK',
  },
  {
    name: '카카오뱅크',
    code: 'KAKAOBANK',
  },
  {
    name: 'KDB산업은행',
    code: 'KDB',
  },
  {
    name: '롯데',
    code: 'LOTTE',
  },
  {
    name: 'NH농협',
    code: 'NH',
  },
  {
    name: '우체국',
    code: 'POST',
  },
  {
    name: '삼성',
    code: 'SAMSUNG',
  },
  {
    name: '신한',
    code: 'SHINHAN',
  },
  {
    name: '신협',
    code: 'SHINHYUP',
  },
  {
    name: '수협',
    code: 'SUHYUP',
  },
  {
    name: '우리',
    code: 'WOORI',
  },
  {
    name: '케이뱅크',
    code: 'KBANK',
  },
  {
    name: '',
    code: 'UNKNOWN',
  },
];

export const CARD_POINT_LIST: Array<{ name: string; code: string }> = [
  {
    name: '포인트리',
    code: 'KB',
  },

  {
    name: '보너스포인트',
    code: 'SAMSUNG',
  },

  {
    name: 'M포인트',
    code: 'HYUNDAI',
  },

  {
    name: 'TOP포인트',
    code: 'BC',
  },

  {
    name: '마이신한포인트',
    code: 'SHINHAN',
  },

  {
    name: 'L.POINT',
    code: 'LOTTE',
  },
  {
    name: '하나머니',
    code: 'HANA',
  },
  {
    name: '채움포인트',
    code: 'NH농협',
  },
  {
    name: '',
    code: 'UNKNOWN',
  },
];

export const PAYMENT_METHOD_LABEL: Record<string, string> = {
  KAKAOPAY: '카카오페이',
  TOSSPAY: '토스페이',
};

/**
 * 카드 코드로 카드사 이름을 반환한다.
 * @param card_code 카드 코드
 */
export function getCardNameByCode(card_code: string): string {
  for (const card of CARD_CODES) {
    if (card.code === card_code) {
      return card.name + '카드';
    }
  }
  return '-';
}

/**
 * 카드 코드로 카드 포인트 이름을 반환한다.
 * @param lang 언어
 * @param card_code 카드 코드
 */
export const getCardPointNameByCode = (card_code: string): string => {
  for (const card_point of CARD_POINT_LIST) {
    if (card_point.code === card_code) {
      return card_point.name;
    }
  }
  return '-';
};

/**
 * 파이낸셜 컴터니 코드로 카드 및 은행 이름을 반환한다.
 * @param financial_company_code 파이낸셜 컴터니 코드
 */
export const getNameByFinancialCompanyCode = (financial_company_code: string): string => {
  if (!financial_company_code) {
    return '';
  }

  const [type, code] = financial_company_code.split('_');

  if (type === 'B') {
    return getBankNameByCode(code);
  }
  if (type === 'C') {
    return getCardNameByCode(code);
  }
  return '';
};

/**
 * 파이낸셜 컴터니 코드로 카드 포인트 이름을 반환한다.
 * @param financial_company_code 파이낸셜 컴터니 코드
 */
export const getPointNameByFinancialCompanyCode = (financial_company_code: string): string => {
  const [, code] = financial_company_code.split('_');
  return getCardPointNameByCode(code);
};
