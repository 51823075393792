import { css } from '@emotion/react';
import Section from '../../Section';
import { ProductCardUbl } from 'components/common/ProductCard';
import ProductCardVirtualList from 'components/common/ProductCardVirtualList';
import TopButton from 'components/common/TopButton';
import { useBestProductRankingList } from 'hooks/queries';
import config from 'util/config';
export const KID_PRODUCT_LIST_KEY_NAME = 'kidProductList';

export interface CategoryChipItem {
  name: string;
  value: string;
}

/**
 * 키즈 -베스트 상품 리스트
 * @component
 */
const BestProductList = () => {
  const { best_product_list, is_loading: is_best_product_loading } = useBestProductRankingList(config.kid_category_id);

  const product_ubl: ProductCardUbl = {
    navigation: 'kids_main',
    object_section: 'best_plp',
  };

  return (
    <Section title='키즈 베스트 상품'>
      <ProductCardVirtualList
        use_rank
        key_name={KID_PRODUCT_LIST_KEY_NAME}
        is_loading={is_best_product_loading}
        product_list={best_product_list}
        product_ubl={product_ubl}
        padding='4px 0 24px'
        row_css={row_css}
      />
      <TopButton ubl_navigation='kids_main' />
    </Section>
  );
};

const row_css = css`
  padding: 0 8px 20px;
`;

export default BestProductList;
