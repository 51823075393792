import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { IconCoupon60 } from 'components/base/Icon';
import Image from 'components/base/Image';
import { CONFIRM_ID, OpenDialogType } from 'context/ButtomDialogContext';
import config from 'util/config';

const dialog_image_css = css`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 12px;

  &.benefit {
    & > span {
      width: 106px !important;
      position: unset !important;
    }
  }
  &.push {
    & > span {
      width: 180px !important;
      position: unset !important;
    }
  }
`;

const remind_date = dayjs().add(30, 'day').format('YYYY-MM-DD HH:mm');

export enum HOME_DIALOG_TYPE {
  BENEFIT = 'benefit',
  APP_PUSH = 'app_push',
  REGISTER_WELCOME_POINT = 'register_welcome_point',
  REGISTER_WELCOME_POINT_WITH_COUPON = 'register_welcome_point_with_coupon',
}

export enum BENEFIT_DIALOG_TITLE {
  FIRST = '패션 바이 카카오에 오신 걸 환영해요. \n 혜택과 소식을 알려드릴까요?',
  REMIND = '혜택과 소식을 알려드릴까요?',
  SIGNUP = '쇼핑 전 체크하면 좋은 \n 혜택과 소식을 알려드릴까요?',
}

export const home_dialog_info: Record<HOME_DIALOG_TYPE, OpenDialogType> = {
  [HOME_DIALOG_TYPE.BENEFIT]: {
    contents: {
      icon: (
        <Image
          wrapper_css={dialog_image_css}
          src={`${config.s3_cf_url}/common/app_noti_popup_coupon.png`}
          alt='기기알림 허용 이미지'
          layout='fill'
          wrapper_classname='benefit'
        />
      ),
      type: 'image',
      src: `${config.s3_cf_url}/common/app_noti_popup_coupon.png`,
      text: '패션 바이 카카오에서 준비하는 혜택과 이벤트 소식을 \n앱 푸시로 전달해드려요.',
    },
    buttons: [
      { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '필요 없어요' },
      { id: CONFIRM_ID.OK, size: 'large', children: '네, 알려주세요!' },
    ],
    disable_outside_click: true,
    use_native_sheet: true,
    sheet_type: 'image',
    src: `${config.s3_cf_url}/common/app_noti_popup_coupon.png`,
  },
  [HOME_DIALOG_TYPE.APP_PUSH]: {
    contents: {
      icon: (
        <Image
          wrapper_css={dialog_image_css}
          src={`${config.s3_cf_url}/common/app_noti_popup_device_alarm.png`}
          alt='알림 수신 동의 이미지'
          layout='fill'
          wrapper_classname='push'
        />
      ),
      type: 'image',
      src: `${config.s3_cf_url}/common/app_noti_popup_device_alarm.png`,
      title: '혜택 소식을 위해 기기 알림을 켜주세요',
      text: '기기 설정에서 알림을 표시 및 허용해 주세요.',
    },
    buttons: [
      { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '다음에 할게요' },
      { id: CONFIRM_ID.OK, size: 'large', children: '기기 알림 켜기' },
    ],
    disable_outside_click: true,
    use_native_sheet: true,
    sheet_type: 'image',
    src: `${config.s3_cf_url}/common/app_noti_popup_device_alarm.png`,
  },
  [HOME_DIALOG_TYPE.REGISTER_WELCOME_POINT_WITH_COUPON]: {
    contents: {
      title: '5,000 쿠폰 지급 완료!',
      text: '친구 초대 코드 입력이 완료되어, 보상 쿠폰이 지급됐어요.\n패션바이카카오에서 즐거운 쇼핑 되세요!',
      icon: <IconCoupon60 />,
      type: 'icon',
      src: `${config.s3_cf_url}/common/CouponPink60.svg`,
    },
    buttons: [
      { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '쇼핑하러 가기' },
      { id: CONFIRM_ID.OK, size: 'large', children: '쿠폰 확인하기' },
    ],
    use_native_sheet: true,
    sheet_type: 'icon',
    src: `${config.s3_cf_url}/common/CouponPink60.svg`,
  },
  [HOME_DIALOG_TYPE.REGISTER_WELCOME_POINT]: {
    contents: {
      title: '준비된 쿠폰이 모두 소진됐어요',
      text: '',
      icon: <IconCoupon60 />,
      type: 'icon',
      src: `${config.s3_cf_url}/common/CouponPink60.svg`,
    },
    buttons: [{ id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '쇼핑하러 가기' }],
    use_native_sheet: true,
    sheet_type: 'icon',
    src: `${config.s3_cf_url}/common/CouponPink60.svg`,
  },
};

// 사용자가 설정에서 직접 푸시 여부를 변경할 수 있어 앱 실행 시 확인
export const appPushAllowCheck = (noti_status: string) => {
  if (noti_status === 'agree') {
    localStorage.removeItem('PUSH_NOTI_ALLOW_REMIND_DATE');
    sessionStorage.removeItem('APP_PUSH_STATUS');
  } else {
    sessionStorage.setItem('APP_PUSH_STATUS', 'disagree');
  }
};

// 스토리지 관련 작업들(get,set, remove)
export const getStorage = {
  benefitRemind: () => {
    return window.localStorage.getItem('BENEFIT_NOTI_ALLOW_REMIND_DATE');
  },
  pushRemind: () => {
    return window.localStorage.getItem('PUSH_NOTI_ALLOW_REMIND_DATE');
  },
  notiAllowDone: () => {
    return window.localStorage.getItem('NOTIFIY_ALLOW_DONE');
  },
  appPushStatus: () => {
    return window.sessionStorage.getItem('APP_PUSH_STATUS');
  },
};

export const setStorage = {
  benefitRemind: () => {
    window.localStorage.setItem('BENEFIT_NOTI_ALLOW_REMIND_DATE', remind_date);
  },
  pushRemind: () => {
    window.localStorage.setItem('PUSH_NOTI_ALLOW_REMIND_DATE', remind_date);
  },
  notiAllowDone: () => {
    window.localStorage.setItem('NOTIFIY_ALLOW_DONE', 'true');
  },
};

export const removeStorage = {
  benefitRemind: () => {
    window.localStorage.removeItem('BENEFIT_NOTI_ALLOW_REMIND_DATE');
  },
  pushRemind: () => {
    window.localStorage.removeItem('PUSH_NOTI_ALLOW_REMIND_DATE');
  },
  notiAllowDone: () => {
    window.localStorage.removeItem('NOTIFIY_ALLOW_DONE');
  },
};
