import { useRouter } from 'next/router';
import { ROOT_PRODUCT_CATEGORY } from 'hooks/queries';
import config from 'util/config';
import { SORT_TYPE } from 'util/search_utils';

export enum STORE_PRODUCT_TYPE {
  ZIGZIN = 'ZIGZIN',
  KID = 'KID',
}

const NAVIGATION_SUB_INFO = {
  [STORE_PRODUCT_TYPE.KID]: 'kid',
  [STORE_PRODUCT_TYPE.ZIGZIN]: 'zigzin',
};

export const useStoreProductListRouterProps = () => {
  const {
    query: { category_id, shop_id, title, type, sort_type },
  } = useRouter();
  const header_title = String(title ?? '스토어 상품');
  const is_kid_store = type !== undefined && type === STORE_PRODUCT_TYPE.KID;
  const is_zigzin_store = type !== undefined && type === STORE_PRODUCT_TYPE.ZIGZIN;
  const product_category = getProductCategory(category_id, is_kid_store);
  const display_category_id_list = getDisplayCategories(product_category, is_kid_store);
  const navigation_sub = type === undefined ? '' : NAVIGATION_SUB_INFO[type as STORE_PRODUCT_TYPE];

  return {
    header_title,
    shop_id: String(shop_id),
    product_category,
    display_category_id_list,
    type,
    sort_type: sort_type ? (sort_type as SORT_TYPE) : SORT_TYPE.SCORE_DESC,
    is_kid_store,
    is_zigzin_store,
    navigation_sub,
  };
};

function getProductCategory(category_id: string | string[] | undefined, is_kid_category: boolean) {
  if (is_kid_category) {
    return String(category_id || '') || config.kid_category_id || ROOT_PRODUCT_CATEGORY.id;
  }
  return String(category_id || '') || ROOT_PRODUCT_CATEGORY.id;
}

export function getDisplayCategories(category_id: string | string[] | undefined, is_kid_category: boolean) {
  if (!is_kid_category) {
    return category_id === '' ? undefined : [String(category_id)];
  }

  const is_all_kid_category = category_id && category_id === config.kid_category_id;

  if (is_all_kid_category) {
    return [category_id];
  }

  return category_id ? [`${config.kid_category_id};${category_id}`] : [String(config.kid_category_id)];
}
