import BestProductList from './BestProductList';
import TopButton from 'components/common/TopButton';
import { useMount } from 'hooks';
import { useTracker } from 'util/Tracker';

/**
 * 홈 - Best탭
 * @component
 */
const Best = () => {
  const tracker = useTracker();

  useMount(() => {
    tracker.homeBestTab({ home_best: 'home_best' });
  });

  return (
    <div>
      <BestProductList />
      <TopButton ubl_navigation='home_best' />
    </div>
  );
};

export default Best;
