import styled from '@emotion/styled';
import Skeleton from 'components/base/Skeleton';
import Ratio from 'components/toolbox/croquis/core/Ratio';

const ItemSkeleton = () => {
  return (
    <SC.Container>
      <SC.ImageWrap width={168} height={120}>
        <Skeleton />
      </SC.ImageWrap>
      <SC.MetaInfo className='skeleton'>
        <Skeleton width={118} height={20} radius={2} />
        <Skeleton width={72} height={20} radius={2} />
        <Skeleton width={39} height={20} radius={2} />
      </SC.MetaInfo>
    </SC.Container>
  );
};

export default ItemSkeleton;

const SC = {
  Container: styled.li`
    padding-bottom: 14px;
  `,
  ImageWrap: styled(Ratio)`
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.03);

    img {
      border-radius: 2px;
    }
  `,
  MetaInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 8px;
    height: 54px;

    &.skeleton > * {
      margin-bottom: 2px;
    }
  `,
};
