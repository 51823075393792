import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash-es/chunk';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import { getRelatedProductListByRecentView } from 'api2';
import { GetRelatedProductListByRecentView } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertIsFastDelivery } from 'util/product_util';

export type RelatedProductListByRecentView = GetRelatedProductListByRecentView['related_product_list_by_recent_view'];
export type RecentViewProductList = RelatedProductListByRecentView['recent_view_product_list'][number];
export type RecentViewProduct = RecentViewProductList['recent_view_product'];
export type RecentViewProductListProductItem = RecentViewProductList['product_list'][number];

export const RECENT_RELATED_PRODUCT_LIST_QUERY_KEY = ['getRecentRelatedProductList'];

const chunk_size = 6;
const init_fetch_data = {
  main_title: '',
  sub_title: '',
  recent_view_product_list: [],
};

export const fetchRecentRelatedProductList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  try {
    const {
      data: { related_product_list_by_recent_view },
    } = await getRelatedProductListByRecentView(undefined, context_request);

    if (isNil(related_product_list_by_recent_view)) {
      return init_fetch_data;
    }
    return related_product_list_by_recent_view;
  } catch (error) {
    return init_fetch_data;
  }
};

/**
 * 최근 본 상품의 연관상품 리스트를 조회하는 hook
 * @hook
 */
export const useRecentRelatedProductList = () => {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery(RECENT_RELATED_PRODUCT_LIST_QUERY_KEY, () => fetchRecentRelatedProductList());

  const recent_related_product_list = useMemo(() => {
    const recent_view_product_list = data?.recent_view_product_list;

    if (!recent_view_product_list) {
      return {
        recent_view_list: [],
        product_list_by_recent_view: [],
      };
    }

    const recent_view_list = recent_view_product_list.map((product) => uiItemToCardProps(product.recent_view_product));
    const product_list_by_recent_view = recent_view_product_list.flatMap((entry) => {
      const { recent_view_product, product_list } = entry;
      const chunked_product_list = chunk(product_list, chunk_size);

      return chunked_product_list
        .filter((item) => item.length === chunk_size)
        .map((product) => ({
          recent_view_product_id: recent_view_product.catalog_product_id,
          product_list: product.map((item) => uiItemToCardProps(item, recent_view_product.catalog_product_id)),
        }));
    });

    return {
      recent_view_list,
      product_list_by_recent_view,
    };
  }, [data?.recent_view_product_list]);

  return {
    main_title: data?.main_title ?? init_fetch_data.main_title,
    sub_title: data?.sub_title ?? init_fetch_data.sub_title,
    recent_related_product_list,
    is_loading,
    ...rest,
  };
};

function uiItemToCardProps(item: RecentViewProductListProductItem, recent_view_product_id?: string) {
  const product_card: ProductCardProps = {
    ...item,
    ...(item.catalog_product_id === recent_view_product_id
      ? { icon_top_left: { type: 'label', label: '최근 본 상품' } }
      : {}),
    is_new: item.is_new ?? false,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
    zpay: item.zpay ?? null,
    image_url: item.image_url ?? '',
    webp_image_url: item.webp_image_url ?? '',
    title: item.title ?? '',
    discount_rate: item.discount_rate ?? null,
    price: item.price ?? null,
    free_shipping: item.free_shipping ?? null,
    shop_product_no: item.shop_product_no ?? null,
    is_fast_delivery: convertIsFastDelivery(item.badge_list),
  };
  return product_card;
}
