import styled from '@emotion/styled';
import React, { FC } from 'react';

import { IconRefresh16, IconCheckCircle60 } from 'components/base/Icon';
import { black, gray300, gray800, gray700, pink600 } from 'styles';
import { typography } from 'styles/font';

interface Props {
  onRefresh: () => void;
}

/**
 * 홈 페이지 - HOT탭 상품 Refresh
 * @component
 */
const ProductRefresh: FC<Props> = ({ onRefresh }) => {
  return (
    <Container>
      <IconCheckCircle60 color={pink600} sub_color={pink600} />
      <Paragraph>아이템을 모두 확인했어요.</Paragraph>
      <RefreshButton onClick={onRefresh}>
        <IconRefresh16 color={gray700} />
        새로운 아이템 보기
      </RefreshButton>
    </Container>
  );
};

export default ProductRefresh;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

const Paragraph = styled.p`
  ${typography.button}
  color: ${black};
  padding-top: 16px;
`;

const RefreshButton = styled.button`
  ${typography.small1}
  display: flex;
  align-items: end;
  padding: 8px 12px;
  margin: 20px 0 52px;
  border: 1px solid ${gray300};
  color: ${gray800};

  & svg {
    margin-right: 4px;
  }
`;
