import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getCategoryAdProductList } from 'api2';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const CATEGORY_AD_PRODUCT_LIST_QUERY_KEY = 'getCategoryAdProductList';

interface FetchProps {
  product_id: string;
}

interface CategoryAdProductList {
  item_list: ProductCardProps[];
  category_name: string | null;
}

const init_fetch_data: CategoryAdProductList = {
  item_list: [],
  category_name: null,
};

export const fetchCategoryAdProductList = async ({ product_id }: FetchProps, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { category_ad_product_list },
    } = await getCategoryAdProductList({ product_id }, context_request);

    if (isNil(category_ad_product_list.item_list) || isNil(category_ad_product_list.ad_category_name)) {
      return init_fetch_data;
    }

    return {
      item_list: convertProductList(category_ad_product_list.item_list),
      category_name: category_ad_product_list.ad_category_name,
    };
  } catch {
    return init_fetch_data;
  }
};

export const useCategoryAdProductList = ({ product_id }: FetchProps) => {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery<CategoryAdProductList>([CATEGORY_AD_PRODUCT_LIST_QUERY_KEY, product_id], () =>
    fetchCategoryAdProductList({ product_id }),
  );
  const { item_list: category_ad_product_list, category_name } = data ?? init_fetch_data;

  return {
    category_ad_product_list,
    category_name,
    is_loading,
    ...rest,
  };
};
