import { FacebookShareActionHandler } from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { isZigzag } from 'util/device';

export function shareWithFacebook(url: string) {
  const destination = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

  if (isZigzag()) {
    return window.location.assign(destination);
  }

  window.open(destination, '_blank');
}

export const handleFacebookShare: FacebookShareActionHandler<ModuleRootCustomData> = async (_, context) => {
  return shareWithFacebook(context.metadata.page_url);
};
