import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { GetFbkStorePickList } from 'api2/types';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { useTracker } from 'util/Tracker';

type StorePickItem = GetFbkStorePickList['fbk_storepick_list']['item_list'][number];
interface Props {
  store_pick: StorePickItem;
  index?: number;
  ubl?: {
    navigation: string;
    object_section?: string;
  };
}

/**
 * 스토어픽 리스트 아이템
 * @component
 */
const Item = ({ store_pick, index = 0, ubl }: Props) => {
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const tracker = useTracker();

  const store_pick_ubl = useMemo(() => {
    if (!ubl) {
      return;
    }
    return {
      ...ubl,
      object_type: 'banner',
      object_idx: index,
      object_id: String(store_pick.store_pick_id),
    };
  }, [index, store_pick.store_pick_id, ubl]);

  useEffect(() => {
    if (in_view && store_pick_ubl) {
      tracker.addLog({
        ...store_pick_ubl,
        category: 'impression',
      });
    }
  }, [in_view, store_pick_ubl, tracker]);

  const handleStorePickClick = () => {
    store_pick_ubl &&
      tracker.addLog({
        ...store_pick_ubl,
        category: 'click',
      });
  };

  return (
    <SC.Container ref={ref}>
      <Link
        href={`/storepick?id=${store_pick.store_pick_id},${store_pick.shop.shop_id}`}
        onClick={handleStorePickClick}
      >
        <SC.ImageWrap width={168} height={120}>
          <Image
            layout='fill'
            objectFit='cover'
            src={store_pick.banner.image_url}
            alt={store_pick.banner.title}
            use_fade
          />
        </SC.ImageWrap>
        <SC.MetaInfo>
          {!!store_pick.banner.tag_product_discount && (
            <SC.Discount variant='small1_bold' color='pink_primary'>
              최대 {store_pick.banner.tag_product_discount}% 할인
            </SC.Discount>
          )}
          <SC.Title variant='title6_medium' color='gray_primary' mb={3}>
            {store_pick.banner.title}
          </SC.Title>
          <SC.Seller variant='small2_medium' color='gray_secondary'>
            {store_pick.shop.name}
          </SC.Seller>
        </SC.MetaInfo>
      </Link>
    </SC.Container>
  );
};

export default Item;

const SC = {
  Container: styled.li`
    padding-bottom: 14px;
  `,
  ImageWrap: styled(Ratio)`
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.03);

    img {
      border-radius: 2px;
    }
  `,
  MetaInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 8px;
    height: 54px;

    &.skeleton > * {
      margin-bottom: 2px;
    }
  `,
  Discount: styled(Text)`
    height: 17px;
  `,
  Title: styled(Text)`
    height: 20px;
  `,
  Seller: styled(Text)`
    height: 15px;
  `,
};
