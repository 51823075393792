import styled from '@emotion/styled';
import { FC } from 'react';
import Text from 'components/base/Text';
import { gray_primary } from 'styles';

interface Props {
  label?: string;
}

const LabelIcon: FC<Props> = ({ label }) => {
  if (!label) {
    return null;
  }
  return (
    <SC.LabelIcon variant='small3_medium' color='white'>
      {label}
    </SC.LabelIcon>
  );
};

export default LabelIcon;

const SC = {
  LabelIcon: styled(Text)`
    display: inline-flex;
    padding: 2px 5px 3px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0;
    background: ${gray_primary};
    backdrop-filter: blur(2px);
  `,
};
