import styled from '@emotion/styled';
import { FC, HTMLAttributes, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  top_left?: ReactNode;
  top_right?: ReactNode;
  bottom_left?: ReactNode;
  bottom_right?: ReactNode;
}

const IconSlot: FC<Props> = ({ top_left, top_right, bottom_left, bottom_right, ...rest }) => {
  return (
    <SC.Root {...rest}>
      <SC.Slot position='top_left'>{top_left}</SC.Slot>
      <SC.Slot position='top_right'>{top_right}</SC.Slot>
      <SC.Slot position='bottom_left'>{bottom_left}</SC.Slot>
      <SC.Slot position='bottom_right'>{bottom_right}</SC.Slot>
    </SC.Root>
  );
};

export default IconSlot;

const SC = {
  Root: styled.div`
    position: absolute;
    inset: 0;
    background-color: transparent;
  `,
  Slot: styled.div<{ position: 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right' }>(({ position }) => {
    let inset = '';
    switch (position) {
      case 'top_left':
        inset = 'top: 0; left: 0;';
        break;
      case 'top_right':
        inset = 'top: 0; right: 0;';
        break;
      case 'bottom_left':
        inset = 'bottom: 0; left: 0;';
        break;
      case 'bottom_right':
        inset = 'bottom: 0; right: 0;';
        break;
    }

    return `
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      ${inset}
    `;
  }),
};
