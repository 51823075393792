import styled from '@emotion/styled';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import { pink600 } from 'styles';

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface Props extends Time {
  is_expired: boolean;
  is_loading?: boolean;
  title: string;
}

/**
 * 타임특가 타이머
 * @component
 */
const TimeTitle: React.FC<Props> = ({ title, days, hours, minutes, seconds, is_expired, is_loading }) => {
  const time_text = is_expired ? '종료' : getTimeText({ days, hours, minutes, seconds });

  if (is_loading) {
    return (
      <SC.Container>
        <Skeleton width={180} height={44} radius={2} />
      </SC.Container>
    );
  }

  return (
    <SC.Container>
      <Text variant='title4_bold' color='gray_primary'>
        {title}
      </Text>
      <Text variant='body4_medium' color='gray_secondary' mt={2} mb={2}>
        <SC.Time>{time_text}</SC.Time> {is_expired ? '되었어요.' : '남았어요.'}
      </Text>
    </SC.Container>
  );
};

export default TimeTitle;

const SC = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;
    height: 58px;
  `,
  Time: styled.span`
    color: ${pink600};
  `,
};

function getTimeText({ days, hours, minutes, seconds }: Time) {
  const hour = days * 24 + hours;
  const hour_str = hour ? `${String(hour).padStart(2, '0')}시간` : '';
  const min_str = hour || minutes ? `${String(minutes).padStart(2, '0')}분` : '';
  const sec_str = hour || minutes || seconds ? `${String(seconds).padStart(2, '0')}초` : '';

  return `${hour_str} ${min_str} ${sec_str}`.trim();
}
