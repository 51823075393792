import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from 'styles';

export const SectionTitle = styled.h4`
  display: flex;
  align-items: center;
  padding: 18px 0 12px;
`;

export const invisible_scroll_bar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

SectionTitle.defaultProps = { className: Typography.TITLE4_SEMIBOLD };
