import { useContainerWidth } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { forwardRef, HTMLAttributes } from 'react';
import { GridComponents } from 'react-virtuoso';
import { WEB_MAX_WIDTH, white } from 'styles';

const PADDING_X = 16;
const GUTTER = 7;
const PRODUCT_CARD_RATIO = 0.89;
const PRODUCT_META_INFO_HEIGHT = 57;

export const getColumnList = (columns: number = 2) => {
  const List = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(({ children, ...props }, ref) => {
    const window_width = useContainerWidth();
    const container_width = window_width < WEB_MAX_WIDTH ? window_width : WEB_MAX_WIDTH;

    const padding_x = PADDING_X * 2;
    const gutters = GUTTER * (columns - 1);
    const product_card_width = Math.round((container_width - padding_x - gutters) / columns);
    const product_card_height = Math.round(product_card_width / PRODUCT_CARD_RATIO) + PRODUCT_META_INFO_HEIGHT;

    return (
      <SC.Wrapper
        ref={ref}
        columns={columns}
        card_width={product_card_width}
        card_height={product_card_height}
        {...props}
      >
        {children}
      </SC.Wrapper>
    );
  });
  List.displayName = 'List';

  return List as GridComponents['List'];
};

const SC = {
  Wrapper: styled.ul<{ columns: number; card_width: number; card_height: number }>`
    background-color: ${white};
    padding: 0px ${PADDING_X}px 24px;
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    gap: 20px ${GUTTER}px;

    > .virtuoso-grid-item {
      width: ${(props) => props.card_width}px;
      height: ${(props) => props.card_height}px;
    }
  `,
};
