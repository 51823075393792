import { SerializedStyles } from '@emotion/react';
import { useAtomValue } from 'jotai';
import NextLink from 'next/link';
import { forwardRef, AnchorHTMLAttributes, MouseEvent } from 'react';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import { WebViewType, getAppDeepLink } from 'util/app_utils';

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * classname 을 지정합니다.
   */
  classname?: string;
  /**
   * replace 링크로 전환시 사용합니다.
   */
  replace?: boolean;
  /**
   * shallow 여부를 설정합니다.(csr만 돌기 위함)
   * @default true
   */
  shallow?: boolean;
  /**
   * 현재 같은 페이지 인지 여부를 체크 합니다.
   * (같은 페이지에서 재 클릭시 페이지 전환이 아니라 top 이동 처리 위함)
   */
  is_same_route?: boolean;
  /**
   * custom_css 을 지정합니다.
   */
  custom_css?: SerializedStyles;
  /**
   * 새창웹뷰로 띄울경우 사용합니다.
   */
  show_new_webview?: boolean;
  /**
   * 웹뷰 타입을 지정합니다.
   * @default 'full'
   */
  webview_type?: WebViewType;
  /**
   * 새창웹뷰로 띄울경우 사용합니다. (네이티브 이전버전만 사용 됨 추후 deprecated 예정)
   */
  show_new_webview_for_legacy?: boolean;
  /**
   * 링크 버튼 클릭 이벤트를 정의합니다.
   */
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

/**
 * 공통 Link 컴포넌트
 * (a태그까지 같이 노출되도록 Next/Link 를 감싸서 사용)
 * @component
 */
const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      href,
      classname,
      replace,
      children,
      shallow = true,
      is_same_route,
      show_new_webview = true,
      show_new_webview_for_legacy,
      webview_type = 'full',
      custom_css,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const { is_app } = useAtomValue(device_info_atom, global_scope);
    const { is_native_version } = useAppVersion();
    const is_deep_link_tag = href && is_native_version && show_new_webview && !replace;

    const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
      // 네이티브 이전 버전 새창웹뷰 스펙 대응
      const is_show_legacy_new_webview = show_new_webview_for_legacy && is_app && href;
      if (is_show_legacy_new_webview) {
        e.preventDefault();
        location.href = href;
      }

      onClick?.(e);
    };

    // href 없는 경우는 a 태그가 아닌 div 로 ui 노출 처리
    if (!href) {
      return (
        <div className={classname} css={custom_css} onClick={onClick}>
          {children}
        </div>
      );
    }

    // 같은 페이지 라우팅은 최상단 처리하기 위함
    if (is_same_route) {
      return (
        <NextLink
          href='#top'
          className={classname}
          onClick={() => window.scrollTo(0, 0)}
          ref={ref}
          css={custom_css}
          {...rest}
          shallow={shallow}
        >
          {children}
        </NextLink>
      );
    }

    if (is_deep_link_tag) {
      return (
        <a
          ref={ref}
          css={custom_css}
          onClick={onClick}
          href={getAppDeepLink(href, webview_type)}
          className={classname}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <NextLink
        ref={ref}
        css={custom_css}
        href={href}
        replace={replace}
        className={classname}
        shallow={shallow}
        onClick={handleLinkClick}
        {...rest}
      >
        {children}
      </NextLink>
    );
  },
);

export default Link;
