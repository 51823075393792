import { ActionContext, dialog, DialogButtonProps, ManualDeepLinkActionHandler } from '@croquiscom-pvt/event-modules';
import isNil from 'lodash-es/isNil';
import router from 'next/router';
import { ModuleRootCustomData } from '../custom-data';
import { getClickedImage } from '../image-action';
import { getCmsEventLoggers } from '../log';
import { getStatus } from '../user';
import { shareWithKakaoTalk } from './kakao-share';
import { createInviteCode, getUserInfo, participateFbkEventWithEventMetadata } from 'api2';
import { fbkRouter, shareInviteCodeForkakao } from 'hooks';
import { isLiveAppVersion } from 'hooks/app/useAppVersion';
import { playShopLiveCampaign } from 'util/app_utils';
import { addPlusFriends } from 'util/kakao_utils';
import { FBK_EVENT_METADATA_UUID, getWelcomeDealInfo } from 'util/promotion_utils';
import { ErrorObject } from 'util/request';
import { Toast } from 'util/Toast';

// 패바카 커스텀 액션 모음
enum CUSTOM_ACTIONS {
  ADD_KAKAO_CHANNER = 'ADD_KAKAO_CHANNER',
  LIVE = 'LIVE',
  FBK_EVENT = 'FBK_EVENT',
  INVITE_FRIENDS = 'INVITE_FRIENDS',
}

export const handleManualDeepLink: ManualDeepLinkActionHandler<ModuleRootCustomData> = async (
  action,
  context,
  action_state,
) => {
  const { is_login, openLoginDialog } = context.metadata.custom_data;
  const { is_user } = await getStatus();

  if (action.value === CUSTOM_ACTIONS.ADD_KAKAO_CHANNER) {
    addPlusFriends();
    return;
  }

  if (action.value === CUSTOM_ACTIONS.INVITE_FRIENDS) {
    if (!is_login || !is_user) {
      openLoginDialog();
      return;
    }

    const [
      {
        data: { user_account },
      },
      {
        data: { createInviteCode: invite_code },
      },
    ] = await Promise.all([getUserInfo(), createInviteCode()]);

    shareInviteCodeForkakao(invite_code, user_account?.full_name ?? '');
    return;
  }

  if (action.value.startsWith(CUSTOM_ACTIONS.LIVE)) {
    const campaign_key = action.value.split('/')[1];
    if (typeof window === 'undefined') {
      return;
    }

    const is_live_version = isLiveAppVersion(navigator.userAgent);
    is_live_version ? playShopLiveCampaign(campaign_key) : router.push(`/lives/${campaign_key}`);
    return;
  }

  if (action.value === CUSTOM_ACTIONS.FBK_EVENT) {
    if (!is_login || !is_user) {
      openLoginDialog();
      return;
    }

    await fbkParticipateEvent(context);
    return;
  }

  logAction([action, context, action_state]);

  const { goOtherPage } = fbkRouter();
  goOtherPage(action.value);
};

function logAction([action, { metadata, module_hierarchy }]: Parameters<
  ManualDeepLinkActionHandler<ModuleRootCustomData>
>) {
  try {
    const deeplink = action.value;
    const current_module = module_hierarchy.modules[module_hierarchy.modules.length - 1];

    const { logManualDeepLinkByFloatingButton, logManualDeepLinkByImageModule } = getCmsEventLoggers(metadata);

    if (current_module.__type === 'FloatingButton') {
      logManualDeepLinkByFloatingButton(deeplink);
    }

    if (current_module.__type === 'ImageCarousel' || current_module.__type === 'ImageMap') {
      const clicked_image = getClickedImage(current_module, action);

      if (clicked_image) {
        logManualDeepLinkByImageModule({
          component_type: current_module.__type,
          component_uuid: current_module.uuid,
          deeplink,
          image_id: clicked_image.id,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * 패바카에서 어뷰징 방지가 필요한 이벤트에 참가 요청하는 함수
 * @description
 */
async function fbkParticipateEvent(context: ActionContext) {
  const { metadata } = context;
  const { uuid, meta_data_uuid } = getWelcomeDealInfo();

  const input = {
    event_content_uuid: metadata.uuid,
    event_metadata_uuid: meta_data_uuid,
    fbk_event_uuid: FBK_EVENT_METADATA_UUID,
  };

  try {
    const { data } = await participateFbkEventWithEventMetadata({ input }, { show_alert: false });
    if (!isNil(data)) {
      Toast.show('990원딜 쿠폰이 발급되었습니다.');
    }
  } catch (error) {
    const { message: error_message, extensions } = error as ErrorObject;
    const buttons: DialogButtonProps[] = [{ text: '닫기' }];

    if (uuid !== metadata.uuid) {
      return Toast.show(!isNil(error_message) ? error_message : '다시 시도해 주세요.');
    }

    if (extensions?.code === 'not_event_target') {
      buttons.push({
        theme: 'primary',
        text: '카톡으로 초대하기',
        onClick: () =>
          shareWithKakaoTalk(metadata.page_url, {
            title: metadata.title,
            description: metadata.description,
            image_src: metadata.image_src,
            button_title: metadata.button_title,
          }),
      });
      await dialog({
        title: '이벤트 참여 대상이 아니에요',
        content:
          '이벤트 참여 대상이 아니에요. 친구를 초대해 보세요. \n 친구가 첫 구매를 하면 5천 포인트를 드려요. \n (이벤트 참여 불가 : 재가입 상관없이 구매 이력이 있는 경우, 이미 쿠폰을 받은 경우)',
        buttons,
      });
      return;
    }

    if (extensions?.code === 'exceeded_the_participants_limit') {
      await dialog({
        title: '오늘 준비한 쿠폰이 모두 소진됐어요',
        content: '내일 오전 8시에 이벤트가 다시 오픈돼요. \n 내일 이벤트에 참여해 주세요.',
        buttons,
      });
      return;
    }

    await dialog({
      content: !isNil(error_message) ? error_message : '다시 시도해 주세요.',
      buttons,
    });
  }
}
