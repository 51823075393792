import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { updateUserAdNotiStatus } from 'api2';
import { UserNotiStatus } from 'api2/types';
import { useUserAdNotiStatus } from 'components/my-page/hooks';
import { CONFIRM_ID, useBottomDialog } from 'context/ButtomDialogContext';
import { checkAppNotificationStatus, openAppSettings } from 'util/app_utils';
import { HOME_DIALOG_TYPE, home_dialog_info, setStorage, removeStorage } from 'util/home_dialog_info_util';
import { useIsApp } from 'util/is_app';
import { Toast } from 'util/Toast';
import { Log, useTracker } from 'util/Tracker';

export const SHOW_NOTIFY_SHEET_KEY = 'SHOW_NOTIFY_SHEET';
const PUSH_POPUP_UBL: Pick<Log, 'navigation' | 'object_section'> = {
  navigation: 'install_first_modal',
  object_section: 'app_push_agree',
};
const NOTIFICATION_POPUP_UBL: Pick<Log, 'navigation' | 'object_section'> = {
  navigation: 'install_first_modal',
  object_section: 'notification_agree',
};
interface AdNotify {
  benefit_dialog_title: string;
  is_benefit_remind?: boolean;
  is_push_remind?: boolean;
}

/**
 * 알림 수신 동의 팝업 표시를 위해 정의한 hook
 * @hook
 */
export const useAdNotiAllow = () => {
  const { open: openBottomDialog } = useBottomDialog();
  const setUserAdNotiStatus = useMutation(updateUserAdNotiStatus);
  const { setAppPush } = useUserAdNotiStatus();
  const is_app = useIsApp();
  const tracker = useTracker();

  const updateUserAdNotiForAgree = ({ onSettled }: { onSettled?: () => void }) => {
    setUserAdNotiStatus.mutate(
      {
        input: { status: UserNotiStatus.AGREE },
      },
      {
        onSuccess() {
          const agree_date = dayjs().format('YY.MM.DD');
          Toast.show(`${agree_date}\n패션바이카카오 알림 수신 동의가 완료되었어요.`);
          setAppPush(true);
        },
        onSettled,
      },
    );
  };

  const openAppPushDialog = async (is_push_remind?: boolean) => {
    tracker.addLog({
      ...NOTIFICATION_POPUP_UBL,
      category: 'pageview',
      data: { is_first: !is_push_remind },
    });

    const device_status = await openBottomDialog({ ...home_dialog_info[HOME_DIALOG_TYPE.APP_PUSH] });

    if (device_status === CONFIRM_ID.OK) {
      tracker.addLog({
        ...NOTIFICATION_POPUP_UBL,
        category: 'click',
        object_type: 'button',
        object_id: 'turn on',
      });

      openAppSettings();
    } else {
      tracker.addLog({
        ...NOTIFICATION_POPUP_UBL,
        category: 'click',
        object_type: 'button',
        object_id: 'later',
      });
    }
    setStorage.pushRemind();
  };

  const deviceNotifyStatusCb = async (noti_status: string, is_push_remind?: boolean) => {
    if (noti_status === 'disagree') {
      await openAppPushDialog(is_push_remind);
    }

    updateUserAdNotiForAgree({});
  };

  const onAdNotify = async ({ benefit_dialog_title, is_push_remind, is_benefit_remind }: AdNotify) => {
    // app push 리마인드 팝업 표시할 경우
    if (is_push_remind && !is_benefit_remind) {
      openAppPushDialog(is_push_remind);
      return;
    }

    /**
     * MEMO: 네이티브 바텀싯이 중복 호출되는 문제로인해 바텀싯 표시된 경우 스토리지에 값 셋하도록 임시 수정
     * 앱인터페이스 호환 hook 생성 시 삭제 필요
     */
    const is_show_sheet = window.localStorage.getItem(SHOW_NOTIFY_SHEET_KEY);
    if (is_show_sheet) {
      return;
    }

    tracker.addLog({
      ...PUSH_POPUP_UBL,
      category: 'pageview',
      data: { is_first: !is_benefit_remind },
    });

    const benefit_button_status = await openBottomDialog({
      ...home_dialog_info[HOME_DIALOG_TYPE.BENEFIT],
      contents: {
        ...home_dialog_info[HOME_DIALOG_TYPE.BENEFIT].contents,
        title: benefit_dialog_title,
      },
      localstorage_key: SHOW_NOTIFY_SHEET_KEY,
    });

    if (benefit_button_status !== CONFIRM_ID.OK) {
      tracker.addLog({
        ...PUSH_POPUP_UBL,
        category: 'click',
        object_type: 'button',
        object_id: 'disagree',
      });

      setStorage.benefitRemind();
      return;
    }

    tracker.addLog({
      ...PUSH_POPUP_UBL,
      category: 'click',
      object_type: 'button',
      object_id: 'agree',
    });

    is_benefit_remind && removeStorage.benefitRemind();
    setStorage.notiAllowDone();

    if (is_app) {
      checkAppNotificationStatus((noti_status) => {
        deviceNotifyStatusCb(noti_status, is_push_remind);
      });
    } else {
      updateUserAdNotiForAgree({});
    }
  };

  return {
    onAdNotify,
    updateUserAdNotiForAgree,
  };
};
