import styled from '@emotion/styled';
import isString from 'lodash-es/isString';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Thumbnail from 'components/base/Thumbnail';
import { border2 } from 'styles';
import { useTracker } from 'util/Tracker';

interface Props {
  is_fetching: boolean;
  is_search_result_exist: boolean;
  result_count: number;
  thumbnail_image: string;
}

const SearchResultTitle: FC<Props> = ({ is_fetching, is_search_result_exist, result_count, thumbnail_image }) => {
  const router = useRouter();
  const { search_type, catalog_product_id } = router.query;
  const tracker = useTracker();
  const thumbnail_href =
    !!catalog_product_id && isString(catalog_product_id) ? `/catalog/products/${catalog_product_id}` : '';

  useEffect(() => {
    if (!is_fetching && !is_search_result_exist) {
      tracker.addLog({
        category: 'impression',
        navigation: 'image_search_result',
        object_section: 'result_plp',
        object_type: 'catalog',
        object_id: 'search_again',
        data: {
          search_type,
          catalog_product_id,
        },
      });
    }
  }, [is_fetching, is_search_result_exist, search_type, catalog_product_id, tracker]);

  const handleRetryClick = () => {
    tracker.addLog({
      category: 'click',
      navigation: 'image_search_result',
      object_section: 'result_plp',
      object_type: 'catalog',
      object_id: 'search_again',
      data: {
        search_type,
        catalog_product_id,
      },
    });
    router.replace('/search/image');
  };

  if (is_fetching) {
    return (
      <SC.Root>
        <SC.TextContainer>
          <Skeleton width={188} height={22} radius={4} className='skeleton_title' />
          <Skeleton width={185} height={17} radius={4} />
        </SC.TextContainer>
        {thumbnail_image && (
          <Link href={thumbnail_href} passHref className='source_thumnail'>
            <Thumbnail src={thumbnail_image} alt='검색 원본 이미지 썸네일' />
          </Link>
        )}
      </SC.Root>
    );
  }

  return (
    <SC.Root>
      <SC.TextContainer>
        <Text variant='title4_bold' color='gray_primary'>
          {is_search_result_exist ? `비슷한 상품 ${result_count}개를 찾았어요.` : '비슷한 상품을 찾지 못했어요.'}
        </Text>
        {is_search_result_exist ? (
          <Text variant='body4_regular' color='gray_secondary' mt={4}>
            상의, 하의 등 모든 카테고리를 보여드려요.
          </Text>
        ) : (
          <SC.RetryButton onClick={handleRetryClick}>
            <Text variant='body4_medium' color='gray_active'>
              다시 검색하기
            </Text>
          </SC.RetryButton>
        )}
      </SC.TextContainer>
      {thumbnail_image && (
        <Link href={thumbnail_href} passHref className='source_thumnail'>
          <Thumbnail src={thumbnail_image} alt='검색 원본 이미지 썸네일' use_fade />
        </Link>
      )}
    </SC.Root>
  );
};

export default SearchResultTitle;

const SC = {
  Root: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .source_thumnail {
      width: 70px;
      height: 70px;
    }
  `,
  TextContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .skeleton_title {
      margin-bottom: 4px;
    }
  `,
  RetryButton: styled.button`
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border: 1px solid ${border2};
    border-radius: 4px;

    &:active {
      background-color: transparent;
    }
  `,
};
