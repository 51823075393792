import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { useCallback, useMemo } from 'react';
import { getActivatedDisplayCategory } from 'api2';
import { ChipItem } from 'components/base/Chip';
import config from 'util/config';

export const ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY = 'getActivatedDisplayCategory';

export const ROOT_PRODUCT_CATEGORY: CategoryItem = {
  id: config.root_product_category_id ?? '1112',
  name: '전체',
  icon_url: '',
};

export enum PRODUCT_LIST_TYPE {
  BEST = 'BEST',
  DISCOUNTED_NEW = 'DISCOUNTED_NEW',
  ZIGZIN = 'ZIGZIN',
  FAST_DELIVERY = 'FAST_DELIVERY',
  NEW = 'NEW',
}

export interface CategoryItem {
  id: string;
  name: string;
  icon_url: string;
  sub_category_list?: CategoryItem[];
}

interface CategoryList {
  root_category: CategoryItem;
  category_list: CategoryItem[];
}

const init_fetch_data: CategoryList = {
  root_category: ROOT_PRODUCT_CATEGORY,
  category_list: [],
};

export const fetchActivatedDisplayCategory = async (
  product_list_type: PRODUCT_LIST_TYPE | null = null,
  req?: IncomingMessage,
): Promise<CategoryList> => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { activated_display_category },
    } = await getActivatedDisplayCategory({ product_list_type }, context_request);

    if (isNil(activated_display_category)) {
      return init_fetch_data;
    }

    return {
      root_category: {
        id: activated_display_category.id,
        name: activated_display_category.name,
        icon_url: activated_display_category.icon_url,
      },
      category_list: activated_display_category.sub_category_list,
    };
  } catch {
    return init_fetch_data;
  }
};

interface Props {
  product_list_type?: PRODUCT_LIST_TYPE | null;
  use_root_category?: boolean;
}

const init_props: Props = {
  product_list_type: null,
  use_root_category: false,
};

export const useActivatedDisplayCategory = (props: Props = {}) => {
  const { product_list_type, use_root_category } = { ...init_props, ...props };
  const query_key = product_list_type
    ? [ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY, product_list_type]
    : [ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY];
  const {
    data,
    isLoading: is_loading,
    isFetching: is_fetching,
    ...rest
  } = useQuery<CategoryList>(query_key, () => fetchActivatedDisplayCategory(product_list_type));

  const { root_category, category_list } = data ?? init_fetch_data;
  const product_category_list = useMemo(
    () => (use_root_category ? [root_category, ...category_list] : category_list),
    [use_root_category, root_category, category_list],
  );
  const product_category_chip_list = getProductCategoryChipList(product_category_list);

  const getSubCategoryList = useCallback(
    (category_id: string) => {
      const sub_category_info = product_category_list.find((category) => category.id === category_id);

      if (sub_category_info?.sub_category_list) {
        return [{ id: category_id, name: '전체', icon_url: '' }, ...sub_category_info.sub_category_list];
      }

      return undefined;
    },
    [product_category_list],
  );

  return {
    product_category_list,
    product_category_chip_list,
    getSubCategoryList,
    is_loading,
    is_fetching,
    ...rest,
  };
};

function getProductCategoryChipList(categories: CategoryItem[]): ChipItem[] {
  return categories.map((item) => ({
    name: item.name,
    value: item.id,
  }));
}
