import { atom } from 'jotai';
import isNil from 'lodash-es/isNil';
import DefaultRouter from 'next/router';
import { createProductReview, updateProductReview } from 'api2';
import { GetProductReview, CreateProductReviewInput } from 'api2/types';
import { ErrorObject } from 'util/request';

const enum ErrorMessage {
  INVALID_CHARACTER = 'Invalid character in header content ["x-amz-copy-source"]',
}

export const SAVED_WRITE_REVIEW_KEY = 'SAVED_WRITE_REVIEW_KEY';

export enum PRODUCT_TYPE {
  /**
   * 3천원 미만 상품
   */
  MIN_PRICE = 'MIN_PRICE',
  /**
   * 3천원 이상, 만원 미만 상품
   */
  BASE_PRICE = 'BASE_PRICE',
  /**
   * 만원 이상 상품
   */
  BASE_PRICE_OVER = 'BASE_PRICE_OVER',
}
interface SelectAttributeValue {
  question: { value: string };
  answer: { value: string };
}

export const order_item_number_atom = atom<string>('');

export const selected_attribute_list_atom = atom<SelectAttributeValue[]>([]);

export const is_show_contents_atom = atom<boolean>(false);

export const attachment_url_list_atom = atom<NonNullable<CreateProductReviewInput['attachment_url_list']>>([]);

export const contents_atom = atom<string>('');

export const rating_atom = atom(0);
export const rating_message_atom = atom((get) => {
  const rating = get(rating_atom);
  return (
    {
      1: '별로예요',
      2: '그저 그래요',
      3: '보통이에요',
      4: '아주 좋아요',
      5: '정말 최고예요',
    }[rating] ?? '평점을 입력해 주세요.'
  );
});

export const is_saved_review_data_atom = atom<boolean>(false);
export const saved_review_data_count_atom = atom<number>(0);
export const get_saved_data_atom = atom(null, (_, set, order_item_number: string) => {
  const resetAtom = () => {
    set(rating_atom, 0);
    set(selected_attribute_list_atom, []);
    set(attachment_url_list_atom, []);
    set(contents_atom, '');
    set(is_saved_review_data_atom, false);
    set(is_show_contents_atom, false);
  };

  const saved_data = localStorage.getItem(SAVED_WRITE_REVIEW_KEY);

  if (!saved_data) {
    resetAtom();
    return;
  }

  const parsing_saved_data = JSON.parse(saved_data);
  set(saved_review_data_count_atom, parsing_saved_data.length);

  // @ts-ignore
  const saved_item = parsing_saved_data?.filter((x) => x.order_item_number === order_item_number) ?? [];

  if (saved_item.length > 0) {
    set(is_saved_review_data_atom, true);
    set(order_item_number_atom, order_item_number);
    set(rating_atom, saved_item[0]?.rating);
    set(selected_attribute_list_atom, saved_item[0]?.select_attribute_list);
    set(contents_atom, saved_item[0]?.contents);
    set(is_show_contents_atom, saved_item[0]?.contents);
    set(attachment_url_list_atom, saved_item[0]?.attachment_list);
  } else {
    resetAtom();
  }
});

export const maximum_point_atom = atom<number>(0);

export const issued_point_atom = atom<number>(0);

export const product_type_atom = atom<PRODUCT_TYPE | null>(null);

export const set_storage_review_atom = atom(null, (get, set) => {
  const resetAtom = () => {
    set(order_item_number_atom, '');
    set(rating_atom, 0);
    set(selected_attribute_list_atom, []);
    set(attachment_url_list_atom, []);
    set(is_saved_review_data_atom, false);
    set(is_show_contents_atom, false);
  };

  const order_item_number = get(order_item_number_atom);
  const rating = get(rating_atom);
  const select_attribute_list = get(selected_attribute_list_atom);
  const contents = get(contents_atom);
  const attachment_list = get(attachment_url_list_atom);

  const review_info = {
    order_item_number,
    rating,
    contents,
    select_attribute_list,
    attachment_list,
  };

  const saved_data = localStorage.getItem(SAVED_WRITE_REVIEW_KEY);
  if (!saved_data) {
    localStorage.setItem(SAVED_WRITE_REVIEW_KEY, JSON.stringify([{ ...review_info }]));
    resetAtom();
    return;
  }

  const review_list = JSON.parse(saved_data);
  // @ts-ignore
  const already_index = review_list.findIndex((li) => li.order_item_number === order_item_number);

  if (already_index > -1) {
    review_list.splice(already_index, 1);
  }

  if (review_list.length > 4) {
    review_list.shift();
  }
  review_list.push(review_info);

  localStorage.setItem(SAVED_WRITE_REVIEW_KEY, JSON.stringify(review_list));
  resetAtom();
});

export const is_disabled_submit_atom = atom((get) => {
  const rating = get(rating_atom);
  const attribute_list = get(selected_attribute_list_atom);
  const contents = get(contents_atom);

  return Boolean(rating === 0 || attribute_list.some(({ answer }) => !answer.value) || contents.length < 20);
});

export const is_disabled_edit_submit_atom = atom(true);

export const is_completed_write_atom = atom<boolean>(false);
export const set_is_completed_write_atom = atom(null, (_, set, show: boolean) => {
  set(is_completed_write_atom, show);
});

export const sumbmit_error_atom = atom<{ is_error: boolean; message: string }>({
  is_error: false,
  message: '',
});
export const submit_review_atom = atom(null, (get, set) => {
  const reqCreateProductReview = async () => {
    try {
      const order_item_number = get(order_item_number_atom);
      const rating = get(rating_atom);
      const selected_attribute_list = get(selected_attribute_list_atom);
      const contents = get(contents_atom);
      const attachment_url_list = get(attachment_url_list_atom);

      const attribute_list = selected_attribute_list.map(({ question, answer }) => {
        return { question: question.value, answer: answer.value };
      });

      const input: CreateProductReviewInput = {
        order_item_number,
        rating,
        attribute_list,
        contents,
        attachment_url_list,
      };
      const saved_data = JSON.parse(localStorage.getItem(SAVED_WRITE_REVIEW_KEY) ?? '[]');
      // @ts-ignore
      const has_saved_item = saved_data.findIndex((item) => item.order_item_number === order_item_number);

      const { data } = await createProductReview({ input }, { show_alert: false });
      if (!isNil(data.createProductReview)) {
        set(is_completed_write_atom, true);
        await DefaultRouter.replace('/my-page/reviews?sheet_type=app_review');

        set(order_item_number_atom, '');
        set(rating_atom, 0);
        set(selected_attribute_list_atom, []);
        set(attachment_url_list_atom, []);
        set(is_saved_review_data_atom, false);
        set(saved_review_data_count_atom, 0);

        if (has_saved_item > -1) {
          saved_data.splice(has_saved_item, 1);
          localStorage.setItem(SAVED_WRITE_REVIEW_KEY, JSON.stringify(saved_data));
        }
      }
    } catch (e) {
      const { message } = e as ErrorObject;
      // invalid_product_review_user_account_info_status: 사용자계정의 상태가 상품리뷰 작성이 불가능한 경우
      // invalid_user_account: 입력된 user_account_id가 상품주문의 사용자계정 id가 다른 경우
      // invalid_order_item_status: 상품리뷰 작성이 불가능한 상품주문 상태의 경우
      // invalid_order_item_total_amount: 상품리뷰 작성가능한 상품주문의 최소금액 미만인 경우
      // product_review_create_due_over: 상품리뷰 작성기한을 넘긴 경우
      // contents_length_below_minimum_limit: 리뷰 내용의 길이가 최소길이 미만인 겨우
      // contents_length_above_maximum_limit: 리뷰 내용의 길이가 최대길이를 초과한 경우
      // attachment_length_above_maximum_limit: 첨부파일의 갯수가 최대갯수를 초과한 경우
      // product_review_by_order_item_number_already_created: 상품주문번호에 해당하는 상품리뷰가 이미 생성된 경우
      if (!message) {
        set(sumbmit_error_atom, {
          is_error: true,
          message: '잠시 후에 다시 시도해주세요. \n문제가 지속되면 고객센터로 문의해주세요. ',
        });
        return;
      }

      if (message === ErrorMessage.INVALID_CHARACTER) {
        return set(sumbmit_error_atom, {
          is_error: true,
          message: '특수문자, 한글로 제목이 구성된 이미지 파일은 등록할 수 없습니다.',
        });
      }
      set(sumbmit_error_atom, { is_error: true, message });
    }
  };
  reqCreateProductReview();
});

export interface ReviewDataProps {
  rating: number;
  contents: string;
  attribute_list: NonNullable<GetProductReview['product_review']>['attribute_list'];
  attachment_list: NonNullable<GetProductReview['product_review']>['attachment_list'];
}
export const set_edit_review_data_atom = atom(null, (_, set, review_data: ReviewDataProps) => {
  const selected_attribute_list = review_data.attribute_list.map(({ question, answer }) => {
    return { question: { value: question.value }, answer: { value: answer.value } };
  });
  const attachment_url_list = review_data.attachment_list.map(({ thumbnail_url }) => thumbnail_url);

  set(rating_atom, review_data.rating);
  set(contents_atom, review_data.contents);
  set(selected_attribute_list_atom, selected_attribute_list);
  set(attachment_url_list_atom, attachment_url_list);
});

export const is_completed_edit_review_atom = atom<boolean>(false);
export const submit_edit_review_atom = atom(null, (get, set, reveiw_id: string) => {
  const requestEditProductReview = async () => {
    try {
      const rating = get(rating_atom);
      const contents = get(contents_atom);
      const selected_attribute_list = get(selected_attribute_list_atom);
      const attachment_url_list = get(attachment_url_list_atom);
      const attribute_list = selected_attribute_list.map(({ question, answer }) => {
        return { question: question.value, answer: answer.value };
      });

      const input = {
        id: reveiw_id,
        contents,
        rating,
        attachment_url_list,
        attribute_list,
      };

      const { data } = await updateProductReview({ input }, { show_alert: false });
      if (data.updateProductReview) {
        await DefaultRouter.back();
        set(is_completed_edit_review_atom, true);
        set(rating_atom, 0);
        set(contents_atom, '');
        set(selected_attribute_list_atom, []);
        set(attachment_url_list_atom, []);
      }
    } catch (e) {
      const { message } = e as ErrorObject;

      if (!message) {
        set(sumbmit_error_atom, {
          is_error: true,
          message: '잠시 후에 다시 시도해주세요. \n문제가 지속되면 고객센터로 문의해주세요. ',
        });
        return;
      }

      if (message === ErrorMessage.INVALID_CHARACTER) {
        return set(sumbmit_error_atom, {
          is_error: true,
          message: '특수문자, 한글로 제목이 구성된 이미지 파일은 등록할 수 없습니다.',
        });
      }

      set(sumbmit_error_atom, { is_error: true, message });
    }
  };

  requestEditProductReview();
});
