import { useAtomValue } from 'jotai';
import { scroll_target_ref_atom } from '../store/product';
import { HEADER_HEIGHT } from 'styles';

export const useScrollDown = () => {
  const scroll_target_refs = useAtomValue(scroll_target_ref_atom);

  const scrollDown = () => {
    const target_tab_bar = scroll_target_refs.tab_bar_ref;
    const more_see_button = scroll_target_refs.more_see_button_ref;
    const current_position = Math.ceil(window.scrollY);

    if (target_tab_bar && current_position < target_tab_bar.offsetTop - HEADER_HEIGHT) {
      const target_y = target_tab_bar.offsetTop - HEADER_HEIGHT;
      window.scrollTo({ top: target_y, behavior: 'smooth' });
      return;
    }

    if (more_see_button && current_position < more_see_button.offsetTop - HEADER_HEIGHT) {
      const target_y = more_see_button.offsetTop - HEADER_HEIGHT;
      window.scrollTo({ top: target_y, behavior: 'smooth' });
      return;
    }

    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return {
    scrollDown,
  };
};
