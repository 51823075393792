import { atom } from 'jotai';

interface ContentData {
  title: string;
  description?: string;
  image_url: string;
}
interface CommerceData {
  regular_price: number;
  discount_rate?: number;
  discount_price?: number;
}

interface CustomData {
  template_id: number;
  template_args: {};
}

export interface CustomOptionProps {
  template_id: number;
  template_args: {
    web_url: string;
    mobile_url: string;
  };
}
export interface OptionType {
  type: 'event' | 'pdp' | 'custom';
  data: {
    content?: ContentData;
    commerce?: CommerceData;
    custom?: CustomData;
  };
  button_title?: string;
  // TODO: 카카오 전용 url (공유url 자체가 딥링크 일시 카카오만 분기이슈가 있어 장애 임시 대응)
  private_link_url?: string;
}

// 카카오톡 공유하기 option
export const kakao_share_option_atom = atom<OptionType>({
  type: 'event',
  data: {},
});
