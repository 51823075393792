import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ShopliveCouponStatus } from 'api2/types';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { Button } from 'components/base/Button';
import { IconArrowRight16 } from 'components/base/Icon';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import ProductCarouselList from 'components/common/ProductCarouselList';
import { useBoostShopCouponPolicy } from 'components/home/hooks/useBoostShopCouponPolicy';
import { useUserInfo } from 'components/my-page/hooks';
import { useFbkRouter } from 'hooks';
import useIssueCoupon, { COUPON_ISSUE_MSG } from 'queries/coupon/useIssueCoupon';
import { convertProductList } from 'util/product_util';
import { Toast } from 'util/Toast';
import { Log, useTracker } from 'util/Tracker';

interface Props {
  ubl?: Pick<Log, 'navigation' | 'data'>;
  custom_css?: SerializedStyles;
}
const CouponStore: FC<Props> = ({ ubl, custom_css }) => {
  const { boost_shop_coupon, is_loading } = useBoostShopCouponPolicy();
  const { mutate: issue_coupon } = useIssueCoupon();
  const { openLoginDialog } = useLoginDialog();
  const { is_login } = useUserInfo();
  const tracker = useTracker();
  const router = useFbkRouter();
  const { ref: coupon_store_ref, inView: in_view } = useInView({ threshold: 0.5 });
  const [is_issued, setIsIssued] = useState(false);

  const common_ubl: Pick<Log, 'navigation' | 'data' | 'object_section'> | undefined = useMemo(() => {
    if (!ubl) {
      return undefined;
    }

    return {
      ...ubl,
      object_section: 'store_boosting_coupon',
      data: {
        coupon_issuing_store_id: boost_shop_coupon?.shop.shop_id,
        coupon_id: boost_shop_coupon?.coupon_policy_id,
        ...ubl.data,
      },
    };
  }, [boost_shop_coupon?.shop.shop_id, ubl, boost_shop_coupon?.coupon_policy_id]);

  useEffect(() => {
    if (in_view && common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'impression',
        object_type: 'button',
      });
    }
  }, [common_ubl, in_view, tracker]);

  const handleCouponIssueClick = () => {
    if (!is_login) {
      openLoginDialog();
      return;
    }

    if (common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_type: 'button',
        object_id: 'get_coupon',
        data: {
          ...common_ubl.data,
          button_name: 'get_coupon',
        },
      });
    }

    issue_coupon(
      { id: boost_shop_coupon?.coupon_policy_id ?? '', type: 'coupon' },
      {
        onSuccess: (status) => {
          if (status === ShopliveCouponStatus.SUCCEEDED || status === ShopliveCouponStatus.ALREADY_ISSUED) {
            setIsIssued(true);
          }
          Toast.show(COUPON_ISSUE_MSG[status]);
        },
        onError: () => {
          Toast.show(COUPON_ISSUE_MSG.FAILED);
        },
      },
    );
  };

  const handleMoveStoreClick = () => {
    if (common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_type: 'button',
        object_id: 'get_coupon',
        data: {
          ...common_ubl.data,
          button_name: 'go_store',
        },
      });
    }
    router.push(`/store/${shop.shop_id}`);
  };

  const handleStoreClick = () => {
    if (common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_type: 'button',
        object_id: 'go_store',
      });
    }
  };

  if (!boost_shop_coupon) {
    return null;
  }

  const { shop, product_list, coupon_discount_rate_bp } = boost_shop_coupon;
  const coupon_rate = `${(coupon_discount_rate_bp / 100).toFixed(0)}%`;

  return (
    <SC.Container ref={coupon_store_ref} css={custom_css}>
      <SC.Title>
        <div className='item'>
          <div className='image'>
            <Image
              src={shop.typical_image_url ?? ''}
              layout='fill'
              objectFit='cover'
              alt={`${shop.name} 대표 이미지`}
            />
          </div>
          <SC.StoreInfo>
            <Text variant='title4_bold' color='gray_primary'>
              {shop.name}, 쿠폰 받고 구매해요
            </Text>
            <Text variant='body4_regular' color='gray_secondary'>
              최대 {coupon_rate} 쿠폰 제공
            </Text>
          </SC.StoreInfo>
        </div>
        <div className='item' onClick={handleStoreClick}>
          <Link href={`/store/${shop.shop_id}`}>
            <Text variant='body4_medium' color='gray_secondary' mr={2}>
              스토어
            </Text>
            <IconArrowRight16 />
          </Link>
        </div>
      </SC.Title>
      <ProductCarouselList
        classname='product_list'
        is_loading={is_loading}
        ubl={common_ubl}
        product_list={convertProductList(product_list)}
      />
      <div className='coupon_button'>
        {is_issued ? (
          <Button {...button_props} variant='primary' onClick={handleMoveStoreClick}>
            {shop.name} 쿠폰 사용하러가기
          </Button>
        ) : (
          <Button {...button_props} variant='tertiary_color' onClick={handleCouponIssueClick}>
            {shop.name} {coupon_rate} 쿠폰받기
          </Button>
        )}
      </div>
    </SC.Container>
  );
};

export default CouponStore;

const button_props: Pick<ComponentProps<typeof Button>, 'fill' | 'size'> = {
  fill: true,
  size: 'medium',
};

const SC = {
  Container: styled.section`
    padding: 10px 0 20px;

    .product_list {
      margin: 8px 0 12px;
    }

    .coupon_button {
      padding: 12px 20px 0;
    }
  `,
  Title: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;

    .image {
      width: 40px;
      height: 40px;

      img {
        border-radius: 34px;
      }
    }

    .item {
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }
    }
  `,
  StoreInfo: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  `,
};
