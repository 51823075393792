import React from 'react';

const useBrowserLayoutEffect: typeof React.useLayoutEffect = (effect, deps = []) => {
  React.useLayoutEffect(() => {
    if (typeof document !== 'undefined') {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useBrowserLayoutEffect;
