import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import { useMemo } from 'react';
import { getMdPickNewProductList } from 'api2';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const MD_PRODUCT_LIST_QUERY_KEY = 'getMdProductList';
const PAGE_PER_COUNT = 30;

interface MdPickProductList {
  product_list: ProductCardProps[];
  total_count: number;
  coupon_policy_id: string;
  page: number;
}

const init_data: MdPickProductList = {
  product_list: [],
  total_count: 0,
  coupon_policy_id: '',
  page: 0,
};

export const fetchMdPickProductList = async ({ page, req }: { page: number; req?: IncomingMessage }) => {
  try {
    const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
    const {
      data: {
        md_pick_new_prodduct_list: { item_list, total_count, coupon_policy_id },
      },
    } = await getMdPickNewProductList({ page, page_size: PAGE_PER_COUNT }, context_request);

    return {
      product_list: convertProductList(item_list),
      total_count,
      coupon_policy_id,
      page,
    };
  } catch (error) {
    return init_data;
  }
};

/**
 * 신상탭 MD 픽 상품 리스트
 * @hook
 */
export const useMdPickProductList = () => {
  const {
    data,
    isInitialLoading: is_initial_loading,
    isLoading: is_loading,
    hasNextPage: has_next_page,
    ...rest
  } = useInfiniteQuery(
    [MD_PRODUCT_LIST_QUERY_KEY],
    ({ pageParam = 1 }) =>
      fetchMdPickProductList({
        page: pageParam,
      }),
    {
      getNextPageParam: (last_page) =>
        last_page.total_count > last_page.page * PAGE_PER_COUNT ? last_page.page + 1 : undefined,
    },
  );

  const md_product_list = useMemo(() => {
    return flatten(data?.pages.map((value) => value.product_list));
  }, [data]);

  const total_count = useMemo(() => {
    return data?.pages[0].total_count ?? 0;
  }, [data]);

  const coupon_policy_id = useMemo(() => data?.pages[0].coupon_policy_id, [data]);

  return {
    md_product_list,
    total_count,
    coupon_policy_id,
    is_loading,
    has_next_page,
    ...rest,
  };
};
