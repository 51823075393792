import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes, MouseEvent } from 'react';

import { bg_primary, gray_tertiary } from '../../../../../styles';
import { typography } from '../../../../../styles/font';
import Text from '../../../../base/Text';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onDetailClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ImageSearchTip: FC<Props> = ({ onDetailClick, ...rest }) => {
  return (
    <SC.TipCard {...rest}>
      <Text variant='body4_bold' color='pink_primary' mr={10}>TIP</Text>
      <Text css={css`flex: 1;`} variant='body4_medium' color='gray_secondary' mr={10}>
        이미지 검색을 잘 하는 방법
      </Text>
      <SC.TipButton onClick={onDetailClick}>
        자세히 보기
      </SC.TipButton>
    </SC.TipCard>
  );
};

export default ImageSearchTip;

const SC = {
  TipCard: styled.div`
    background-color: ${bg_primary};
    border-radius: 7px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
  `,
  TipButton: styled.button`
    ${typography.body4_medium}
    color: ${gray_tertiary};
    &:active {
      background-color: transparent;
    }
  `,
};
