import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import throttle from 'lodash-es/throttle';
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { IconArrowDown16 } from 'components/base/Icon';
import { isOpenAppLandingNavigationAtom } from 'components/common/AppLandingNotify/store/isOpenAppLandingNavigation';
import { isShowTopButtonAtom } from 'components/common/AppLandingNotify/store/isShowTopButton';
import { useAppVersion } from 'hooks/app/useAppVersion';
import useBrowserEffect from 'hooks/useBrowserEffect';
import { border1, gray_active, WEB_MAX_WIDTH, white } from 'styles';
import { useTracker } from 'util/Tracker';

/** https://easings.net/#easeOutQuint */
const easeOutQuint = [0.22, 1, 0.36, 1];

interface Props extends HTMLAttributes<HTMLButtonElement> {
  ubl_navigation: string;
}

const TopButton: FC<Props> = (props) => {
  const [is_show_top_button, setIsShowTopButton] = useAtom(isShowTopButtonAtom);
  const { is_show, bottom } = useAtomValue(isOpenAppLandingNavigationAtom);
  const { ref: top_button_ref, inView: in_view } = useInView({ triggerOnce: true });
  const tracker = useTracker();

  const floating_ubl = useMemo(() => {
    return {
      navigation: props.ubl_navigation,
      object_section: 'floating_button',
      object_type: 'button',
      object_id: 'scroll to top',
    };
  }, [props.ubl_navigation]);

  const { is_native_version } = useAppVersion();

  useBrowserEffect(() => {
    const handleScroll = () => {
      setIsShowTopButton(window.scrollY >= window.innerHeight * 0.75);
    };
    const throttleHandleScroll = throttle(handleScroll, 900);

    document.addEventListener('scroll', throttleHandleScroll);
    return () => {
      document.removeEventListener('scroll', throttleHandleScroll);
    };
  }, []);

  useEffect(() => {
    if (in_view) {
      tracker.addLog({
        category: 'impression',
        ...floating_ubl,
      });
    }
  }, [in_view, tracker, floating_ubl]);

  const goToTop = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    tracker.addLog({
      category: 'click',
      ...floating_ubl,
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [tracker, floating_ubl]);

  // 하단 앱 설치 유도 배너 존재시 해당 크기 이상으로 올려주어야 함.
  const floating_bottom = useMemo(() => {
    if (is_native_version) {
      return bottom;
    }
    return is_show ? 100 : bottom;
  }, [is_show, bottom, is_native_version]);

  return (
    <AnimatePresence>
      {is_show_top_button && (
        <ButtonWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.23, ease: easeOutQuint }}
        >
          <GoToTopButton {...props} bottom={floating_bottom} onClick={goToTop} ref={top_button_ref}>
            <IconArrowDown16 className='up' color={gray_active} />
          </GoToTopButton>
        </ButtonWrapper>
      )}
    </AnimatePresence>
  );
};

export default React.memo(TopButton);

const ButtonWrapper = styled(motion.div)`
  position: fixed;
  max-width: ${WEB_MAX_WIDTH}px;
  width: 100%;
  bottom: 0;
  margin: auto;
  z-index: 200;
`;
const GoToTopButton = styled.button<{ bottom: number }>`
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  bottom: ${({ bottom }) => `calc(env(safe-area-inset-bottom) + ${bottom}px)`};
  right: 14px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid ${border1};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  background-color: ${white};
  cursor: pointer;

  .up {
    transform: rotate(180deg);
  }
`;
