import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { getShopLiveCampaignList } from 'api2';
import { GetShopLiveCampaignList, GetShopLiveCampaignListVariables } from 'api2/types';
import config from 'util/config';

const is_alpha = config.app_scheme === 'fbk-alpha';

export type ShopLiveCampaign = GetShopLiveCampaignList['fbk_shoplive_campaign_list']['item_list'][number];

// 방송 상태 타입
export enum CampaignStatusType {
  NOT_EXIST = 'NOT_EXIST',
  READY = 'READY',
  ONAIR = 'ONAIR',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  ENDED = 'ENDED',
}

export const LIVE_INFO_QUERY_KEY = 'get_live_shop';

/*
 * 라이브 이전에는 티징, 이후에는 기획전 랜딩이 필요
 * 백오피스가 이번스펙에 없어 수기 관리 진행
 */
export const PREV_OR_ENDED_LIVE_INFO = {
  prev_link: `/events/cms/${
    is_alpha ? 'b6f800ed-db34-4859-9a6c-a06d568b8051' : 'e20ef879-1776-484c-8969-c29401af1ba7'
  }`,
  ended_link: `/events/cms/${
    is_alpha ? '6e3cbbd1-0abf-4fac-b58a-88fbb85415ff' : '5220f243-a8c5-4bab-a451-9d01b5fb043f'
  }`,
};

export const fetchLiveList = async (props: GetShopLiveCampaignListVariables, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const { data } = await getShopLiveCampaignList(props, context_request);
    return data.fbk_shoplive_campaign_list.item_list;
  } catch (e) {
    console.error(e);
  }
  return [];
};

export const useLiveShop = () => {
  const { data, refetch, ...rest } = useQuery([LIVE_INFO_QUERY_KEY], () => fetchLiveList({}));

  const live_list = data || [];

  // 라이브 정보
  const live_info = live_list.find((info) => info.status === CampaignStatusType.ONAIR);
  // 라이브 날 정보
  const live_day_info = live_list.find((info) => dayjs().isSame(info.date_start_scheduled, 'day'));
  const is_live = !!live_info;

  return { live_info, is_live, live_day_info, ...rest };
};
