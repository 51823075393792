import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getActiveFeatureMenu } from 'api2';
import { CircleCategory } from 'components/base/CircleCategories';

export const ACTIVE_FEATURE_MENU_QUERY_KEY = 'getActiveFeatureMenu';

export enum PageName {
  CATEGORY = 'CATEGORY',
  TREND = 'TREND',
}

export interface FeatureCategory extends CircleCategory {
  loop_count: number;
}
export interface FeatureMenuItem {
  name: string;
  icon_url: string;
  landing_url: string;
  loop_count: number;
}
interface FeatureMenuList {
  feature_menu_list: FeatureMenuItem[];
}

const init_fetch_data: FeatureMenuList = {
  feature_menu_list: [],
};

export const fetchActiveFeatureMenu = async (page_name: string, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { fbk_activated_feature_menu_group },
  } = await getActiveFeatureMenu({ page_name }, context_request);

  if (isNil(fbk_activated_feature_menu_group?.feature_menu_list)) {
    return init_fetch_data;
  }

  return fbk_activated_feature_menu_group;
};

/**
 * 피처메뉴 리스트 조회
 * @hook
 */
export const useActiveFeatureMenu = (page_name: string) => {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery([ACTIVE_FEATURE_MENU_QUERY_KEY, page_name], () => fetchActiveFeatureMenu(page_name));

  const list = data?.feature_menu_list ?? init_fetch_data.feature_menu_list;
  const feature_menu_list: FeatureCategory[] = getCircleMenus(list);

  return {
    feature_menu_list,
    is_loading,
    ...rest,
  };
};

function getCircleMenus(feature_menu_list: FeatureMenuItem[]): FeatureCategory[] {
  const circle_menus: FeatureCategory[] = feature_menu_list.map((item) => {
    return {
      title: item.name,
      link: item.landing_url,
      img_url: item.icon_url,
      loop_count: item.loop_count,
    };
  });

  return circle_menus;
}
