interface ImageSize {
  width: number;
  height: number;
}

export const getImageSize = (source: string) => new Promise<ImageSize>((resolve, reject) => {
  const image = new Image();
  image.onload = () => {
    resolve({
      width: image.naturalWidth,
      height: image.naturalHeight,
    });
  };
  image.onerror = reject;
  image.src = source;
});
