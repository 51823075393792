import styled from '@emotion/styled';
import chunk from 'lodash-es/chunk';
import { useMemo, useState, useCallback } from 'react';
import Title from '../Title';
import Item from './Item';
import ItemSkeleton from './ItemSkeleton';
import { IconRefresh16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { useStorePickList } from 'components/home/hooks/useStorePickList';
import GridTile from 'components/toolbox/croquis/core/GridTile';
import { useFbkRouter } from 'hooks';
import { bg_secondary, border2, gray_tertiary, white } from 'styles';
import { useTracker } from 'util/Tracker';

const store_pick_ubl = {
  navigation: 'home_benefits',
  object_section: 'store_pick',
};

const GROUPED_COUNT = 4;

/**
 * 세일탭 > 스토어픽 리스트
 * @component
 */
const StorePickList = () => {
  const { push } = useFbkRouter();
  const [current_index, setCurrentIndex] = useState(0);
  const { store_pick_list, is_loading } = useStorePickList();
  const tracker = useTracker();

  const handleAllViewClick = () => {
    tracker.addLog({
      ...store_pick_ubl,
      category: 'click',
      object_type: 'button',
      object_id: 'view_all',
    });
    push('/storepick-views', { only_csr: false });
  };

  const grouped_store_pick = useMemo(() => {
    const grouped = chunk(
      store_pick_list.map((item, index) => ({ ...item, index })),
      GROUPED_COUNT,
    );
    return grouped;
  }, [store_pick_list]);

  const handleNextClick = useCallback(() => {
    setCurrentIndex((state) => (state < grouped_store_pick.length - 1 ? state + 1 : 0));
  }, [grouped_store_pick.length]);

  if (!is_loading && !grouped_store_pick.length) {
    return null;
  }

  return (
    <>
      <SC.Container>
        <Title
          title='스토어 PICK'
          sub_title='스토어가 직접 고른 상품들을 만나보세요.'
          is_loading={is_loading}
          use_all_view_button
          onAllViewClick={handleAllViewClick}
        />
        <SC.ListWrap>
          <GridTile gap={6} as='ul'>
            {is_loading && [...Array(4)].map((_, index) => <ItemSkeleton key={index} />)}
            {!is_loading &&
              grouped_store_pick[current_index].map((item, index) => (
                <Item store_pick={item} index={item.index} ubl={store_pick_ubl} key={`${item.shop.shop_id}_${index}`} />
              ))}
          </GridTile>
          <SC.PagingWrap>
            <SC.NextButton onClick={handleNextClick}>
              <IconRefresh16 color={gray_tertiary} />
              <Text variant='title5_medium' color='gray_active' ml={4}>
                새로운 스토어픽 보기
              </Text>
            </SC.NextButton>
          </SC.PagingWrap>
        </SC.ListWrap>
      </SC.Container>
      <SC.Line />
    </>
  );
};

export default StorePickList;

const SC = {
  Container: styled.article`
    padding: 8px 0 20px;
  `,
  ListWrap: styled.section`
    padding: 12px 16px 0;
  `,
  PagingWrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
  `,
  NextButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;
    padding: 11px 0 10px;
    background-color: ${white};
    border: 1px solid ${border2};
    border-radius: 4px;
  `,
  Line: styled.div`
    width: 100%;
    height: 8px;
    background-color: ${bg_secondary};
  `,
};
