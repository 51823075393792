import styled from '@emotion/styled';
import { FC } from 'react';

import { white } from '../../../../styles';
import { ProductCardProps } from '../index';
import Text from 'components/base/Text';

type IconType = Pick<ProductCardProps, 'rank'>;

const RankIcon: FC<IconType> = ({ rank }) => {
  if (!rank) {
    return null;
  }

  return (
    <SC.RankIcon variant='small1_medium' color='white'>
      {rank}
    </SC.RankIcon>
  );
};

export default RankIcon;

const SC = {
  RankIcon: styled(Text)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0;
    width: 21px;
    height: 21px;
    color: ${white};
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  `,
};
