import styled from '@emotion/styled';
import { FC, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import Link from 'components/base/Link';
import { BANNER_PAGE_NAME, useActiveBannerList } from 'hooks/queries';
import { typography } from 'styles/font';
import { Log, useTracker } from 'util/Tracker';

const COMMON_UBL: Pick<Log, 'navigation' | 'object_section' | 'object_type' | 'object_id'> = {
  navigation: 'home_ad',
  object_section: 'mainbanner_below_strip_banner',
  object_type: 'banner',
  object_id: '-1',
};

interface Props {
  ubl?: Omit<Log, 'category'>;
}

const LineBanner: FC<Props> = ({ ubl }) => {
  const tracker = useTracker();
  const { ref, inView: in_view } = useInView({ threshold: 0.5 });
  const { line_banner } = useActiveBannerList(BANNER_PAGE_NAME.TREND);

  const common_ubl = useMemo(() => {
    if (line_banner) {
      return { ...COMMON_UBL, navigation_sub: { page_url: line_banner.landing_url }, data: { ...ubl?.data } };
    }
    return null;
  }, [line_banner, ubl]);

  useEffect(() => {
    if (in_view && common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'impression',
      });
    }
  }, [in_view, tracker, common_ubl]);

  const handleBannerClick = () => {
    if (common_ubl) {
      tracker.addLog({ ...common_ubl, category: 'click' });
    }
  };

  if (!line_banner) {
    return null;
  }

  return (
    <Link href={line_banner.landing_url} ref={ref} onClick={handleBannerClick}>
      <SC.Container
        text_color={line_banner.text_color}
        bg_color={line_banner.background_color}
        highlight_color={line_banner.highlight_text_color}
      >
        <div dangerouslySetInnerHTML={{ __html: line_banner.banner_text_content }} />
      </SC.Container>
    </Link>
  );
};

export default LineBanner;

const SC = {
  Container: styled.section<{ text_color: string; bg_color: string; highlight_color: string }>`
    ${typography.body4_bold};
    padding: 9px 0;
    background-color: ${({ bg_color }) => bg_color};
    color: ${({ text_color }) => text_color};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    b {
      font-weight: 600;
      color: ${({ highlight_color }) => highlight_color};
    }
  `,
};
