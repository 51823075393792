import { FAST_START_TILTE } from './constant';
import { ProductCardProps } from 'components/common/ProductCard';

export interface Product {
  catalog_product_id?: string | null;
  image_url?: string | null;
  webp_image_url?: string | null;
  shop_name?: string | null;
  title?: string | null;
  price?: number | null;
  discount_rate?: number | null;
  free_shipping?: boolean | null;
  zpay?: boolean | null;
  log?: string | null;
  aid?: string | null;
  product_url?: string | null;
  has_coupon: boolean;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  shop_product_no?: string | null;
  shop_id: string;
  is_zonly: boolean;
  is_brand: boolean;
  is_saved_product: boolean;
  review_count?: number | null;
  review_score?: number | null;
  is_new?: boolean | null;
  is_ad?: boolean;
  badge_list?: Array<{ type?: string | null }> | null;
}

/**
 *
 * 상품 Item 을 상품카드 Prop 값으로 전달해준다.
 *
 * @param Product fbk 상품
 * @returns ProductCardProps
 */
export function convertProductCard(item: Product): ProductCardProps {
  const {
    image_url = null,
    webp_image_url = null,
    shop_name = null,
    title = null,
    price = null,
    discount_rate = null,
    free_shipping = null,
    zpay = null,
    log = null,
    aid = null,
    product_url = null,
    shop_product_no = null,
    review_count = null,
    review_score = null,
    is_new = null,
    shop_id,
    badge_list,
    catalog_product_id = '',
    ...rest
  } = item;
  const product_card: ProductCardProps = {
    ...rest,
    catalog_product_id,
    image_url,
    webp_image_url,
    title,
    price,
    discount_rate,
    free_shipping,
    zpay,
    log,
    aid,
    url: product_url ?? '',
    shop_product_no,
    review_count,
    review_score,
    is_new: is_new ?? false,
    shop_id,
    shop: { id: shop_id, name: shop_name ?? '' },
    is_fast_delivery: convertIsFastDelivery(badge_list),
  };
  return product_card;
}

/**
 *
 * 상품카드 리스트로 치환시켜준다.
 *
 * @param Product fbk 상품
 * @returns ProductCardProps
 */
export function convertProductList(item?: Product[] | null | undefined): ProductCardProps[] {
  if (!item) {
    return [];
  }

  return item.map(convertProductCard);
}

/**
 * 지그재그에서 쓰이는 제트 할인가는 패바카에서는 카카오 할인가로 치환
 * 서버에서 내려오는 값이라 추후 site 구분 요청 필요
 */
export function replaceKakaoDiscount(discount_title: string, replace_title?: string) {
  return discount_title.replace(
    /Z할인가|서머위크|하루특가|위클리특가|3시간특가|12시간특가|72시간특가|선착순특가|한정수량특가|단독특가|카테고리특가|신상특가|스토어특가/,
    replace_title ?? '카카오할인가',
  );
}

/**
 * 상품카드의 이미지를 정적이미지 처리
 */
export function convertStaticImageUrl({
  image_url,
  width = '720',
  height = '720',
  quality = '80',
  format = 'jpeg',
}: {
  image_url: string;
  width?: string;
  height?: string;
  quality?: string;
  format?: string;
}) {
  const [origin_url] = image_url.split('?');
  if (!origin_url) {
    return '';
  }
  return origin_url + `?width=${width}&height=${height}&quality=${quality}&format=${format}`;
}

/**
 * badge_list에 넘어오는 값을 확인하여 is_fast_delivery 값으로 전달해준다.
 */
export function convertIsFastDelivery(badge_list?: Array<{ type?: string | null }> | null) {
  return badge_list?.some((badge) => badge.type === FAST_START_TILTE);
}
