import styled from '@emotion/styled';
import { FC, HTMLAttributes, useEffect, useRef, MouseEvent } from 'react';
import { useInView } from 'react-intersection-observer';

import { useForkElementRef } from '../../../../../hooks';
import { useTracker } from '../../../../../util/Tracker';
import Thumbnail from '../../../../base/Thumbnail';

interface Props extends HTMLAttributes<HTMLLIElement> {
  catalog_product_id: string;
  image_url: string;
}

/**
 * 이미지 검색 페이지의 preset 상품 아이템 입니다.
 */
const PresetImageItem: FC<Props> = ({ catalog_product_id, image_url, ...props }) => {
  const { ref: in_view_ref, inView: in_view } = useInView({ triggerOnce: true });
  const element = useRef<HTMLLIElement>(null);
  const fork = useForkElementRef<HTMLLIElement>(in_view_ref, element);
  const tracker = useTracker();

  useEffect(() => {
    if (in_view && element.current) {
      const {
        navigation,
        objectId: object_id,
        objectIdx: object_idx,
      } = element.current.dataset;
      if (navigation && object_id && object_id) {
        tracker.addLog({
          category: 'impression',
          object_section: 'preset',
          object_type: 'catalog',
          navigation,
          object_id,
          object_idx: object_idx ? parseInt(object_idx, 10) : undefined,
        });
      }
    }
  }, [in_view, tracker]);

  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    const {
      navigation = '',
      objectId: object_id,
      objectIdx: object_idx,
    } = e.currentTarget.dataset;
    if (navigation && object_id && object_idx) {
      tracker.addLog({
        category: 'click',
        object_section: 'preset',
        object_type: 'catalog',
        navigation,
        object_id,
        object_idx: object_idx ? parseInt(object_idx, 10) : undefined,
      });
    }
    props.onClick?.(e);
  };

  return (
    <SC.PresetImageItem {...props} {...fork()} onClick={handleClick}>
      <SC.PresetImage src={image_url} alt='상품 썸네일' use_fade />
    </SC.PresetImageItem>
  );
};

export default PresetImageItem;

const SC = {
  PresetImageItem: styled.li`
    position: relative;
    flex: 0;
    min-width: calc(50% - 1.5px);
    aspect-ratio: 0.89;
    margin-bottom: 3px;
    border-radius: 2px;

    &:nth-of-type(odd) {
      margin-right: 3px;
    }

    @media (min-width: 450px) {
      min-width: calc(100% / 3 - 3px);
      margin-right: 3px;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    @supports not (aspect-ratio: 0.89) {
      padding-top: calc((50% - 1.5px) * (1 / 0.89));
    }
  `,
  PresetImage: styled(Thumbnail)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;

    @supports not (aspect-ratio: 0.89) {
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
};
