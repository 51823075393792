const MoreIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='18' r='1.5' transform='rotate(-90 12 18)' fill='#121314' />
      <circle cx='12' cy='12' r='1.5' transform='rotate(-90 12 12)' fill='#121314' />
      <circle cx='12' cy='6' r='1.5' transform='rotate(-90 12 6)' fill='#121314' />
    </svg>
  );
};

export default MoreIcon;
