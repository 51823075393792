import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { colors, gray500 } from 'styles';

export type ContentReplyInputProps = {
  placeholder: string;
  disabled: boolean;
  need_auth: boolean;
  onSubmit?: (comment: string) => void;
  onRedirectAuth?: () => void;
};

export const ContentReplyInput = ({
  placeholder,
  disabled,
  need_auth,
  onSubmit,
  onRedirectAuth,
}: ContentReplyInputProps) => {
  const input_ref = useRef<HTMLInputElement>(null);
  const [is_over_typing, set_is_over_typing] = useState(false);

  const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    set_is_over_typing(event.target.value.length > 500);
  };

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();

        if (input_ref.current?.value) {
          onSubmit?.(input_ref.current.value);
          input_ref.current.value = '';
          return;
        }

        input_ref.current?.focus();
      }}
    >
      <Fill
        onClick={() => {
          if (need_auth) {
            onRedirectAuth?.();
          }
        }}
      >
        <Input ref={input_ref} disabled={disabled} placeholder={placeholder} onChange={handleChangeInput} />
        <Button type='submit' disabled={disabled || is_over_typing}>
          등록
        </Button>
      </Fill>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  padding: 9px 16px;
`;

const Fill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 10px;
  gap: 10px;
  background: ${colors.gray200};
  border-radius: 8px;
`;

const Input = styled.input`
  padding: 6px;
  flex: 1;
  color: ${colors.gray800};
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;

  &::placeholder {
    color: ${colors.gray500};
  }
`;

const Button = styled.button`
  padding: 6px;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  color: ${colors.pink400};

  &:disabled {
    color: ${gray500};
  }
`;
