import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { IconNavigationBack24 } from 'components/base/Icon';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import { back } from 'util/app_utils';

interface Props {
  onClick?: () => void;
}
/**
 * 헤더 - 뒤로가기 아이콘
 * @component
 */
const Back: FC<Props> = ({ onClick }) => {
  const router = useRouter();
  const { is_app } = useAtomValue(device_info_atom, global_scope);

  const handleBackClick = () => {
    if (is_app) {
      back();
    } else {
      router.back();
    }
    onClick?.();
  };
  return <IconNavigationBack24 onClick={handleBackClick} />;
};

export default Back;
