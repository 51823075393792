import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { FC } from 'react';
import DefaultHeader from './DefaultHeader';
import Image from 'components/base/Image';
import { useHomeScrollReset } from 'components/home/hooks/useHomeScrollReset';
import config from 'util/config';

const logo_src = `${config.s3_cf_url}/home/logo.png`;

const HomeHeader: FC = () => {
  const router = useRouter();
  const { resetHotTabScrollRestoration } = useHomeScrollReset();

  const handleLogoClick = () => {
    if (router.asPath === '/' || router.asPath.includes('/?tab=hot')) {
      window.scrollTo({ top: 0 });
      return;
    }
    resetHotTabScrollRestoration();
    router.replace('/');
  };

  return (
    <DefaultHeader
      left_icons={
        <SC.LogoButton onClick={handleLogoClick} aria-label='패션바이카카오 로고(홈진입)'>
          <Image src={logo_src} alt='홈로고' layout='fill' objectFit='cover' />
        </SC.LogoButton>
      }
      right_icons={['search', 'cart']}
      use_bottom_border={false}
    />
  );
};

export default HomeHeader;

const SC = {
  LogoButton: styled.button`
    width: 107px;
    height: 22px;
  `,
};
