import { atom } from 'jotai';

interface ImageSearchHistory {
  source: string;  // 사용자가 업로드 한 원본 이미지
  cropped: string;  // 편집된 이미지
}

/**
 * 이미지 검색에 사용된 이미지 데이터를 큐 형태로 보관 합니다.
 * 뒤로가기 시 큐에서 하나씩 pop 합니다.
 */
const image_search_history_atom = atom<ImageSearchHistory[]>([]);

/**
 * read & write
 * 현재 검색 대상 이미지 입니다.
 */
export const current_search_image_atom = atom(
  (get) => {
    const histories = get(image_search_history_atom);
    return histories[histories.length - 1] || null;
  },
  (get, set, history: ImageSearchHistory) => {
    const histories = [...get(image_search_history_atom)];
    histories[histories.length - 1] = { ...history };
    set(image_search_history_atom, histories);
  },
);

/**
 * write only
 * 이미지 검색 히스토리에 push 합니다.
 */
export const push_image_search_history_atom = atom(null, (get, set, history: ImageSearchHistory) => {
  const histories = get(image_search_history_atom);
  set(image_search_history_atom, [...histories, history]);
});

/**
 * write only
 * 이미지 검색 히스토리에서 마지막 하나를 pop 합니다.
 */
export const pop_image_search_history_atom = atom(null, (get, set) => {
  const histories = get(image_search_history_atom);
  set(image_search_history_atom, histories.slice(0, histories.length - 1));
});
