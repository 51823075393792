import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import { getDiscountedNewProductList } from 'api2';
import { GetDiscountedNewProductList } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertIsFastDelivery } from 'util/product_util';

export type DiscountedNewProductList = GetDiscountedNewProductList['discounted_new_product_list'];
export type DiscountedNewProudctListItem = NonNullable<DiscountedNewProductList['item_list']>[number];

export const DISCOUNTED_NEW_PRODUCT_LIST_QUERY_KEY = 'getDiscountedNewProductList';

const PAGE_COUNT = 50;

const init_fetch_data: DiscountedNewProductList = {
  end_cursor: null,
  item_list: [],
  total_count: 0,
  has_next_page: false,
};

interface FetchProps {
  display_category_id: number;
  after: string | null;
  req?: IncomingMessage;
}

export const fetchDiscountedNewProductList = async (props: FetchProps) => {
  const { display_category_id, after, req } = props;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const { data } = await getDiscountedNewProductList(
      { display_category_id, after, limit: PAGE_COUNT },
      context_request,
    );

    if (isNil(data) || isNil(data.discounted_new_product_list)) {
      return init_fetch_data;
    }
    return data.discounted_new_product_list;
  } catch {
    return init_fetch_data;
  }
};

export const useDiscountedNewProductList = (display_category_id: number) => {
  const {
    data,
    isInitialLoading: is_loading,
    hasNextPage: has_next_page,
    fetchNextPage,
  } = useInfiniteQuery(
    [DISCOUNTED_NEW_PRODUCT_LIST_QUERY_KEY, display_category_id],
    ({ pageParam }) => fetchDiscountedNewProductList({ display_category_id, after: pageParam }),
    {
      getNextPageParam: (last_page) => last_page.end_cursor,
    },
  );

  const discounted_new_product_list = useMemo(
    () => flatten(data?.pages.map((value) => value.item_list)).map(uiItemToCardProps),
    [data],
  );

  return {
    discounted_new_product_list,
    is_loading,
    has_next_page,
    fetchNextPage,
  };
};

function uiItemToCardProps(item: DiscountedNewProudctListItem) {
  const product_card: ProductCardProps = {
    ...item,
    catalog_product_id: item.catalog_product_id ?? '',
    is_new: item.is_new ?? false,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
    zpay: item.zpay ?? null,
    image_url: item.image_url ?? '',
    webp_image_url: item.webp_image_url ?? '',
    title: item.title ?? '',
    discount_rate: item.discount_rate ?? null,
    price: item.price ?? null,
    free_shipping: item.free_shipping ?? null,
    shop_product_no: item.shop_product_no ?? null,
    is_fast_delivery: convertIsFastDelivery(item.badge_list),
  };
  return product_card;
}
