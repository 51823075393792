import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getCmsProductsPresetProductCardItemList } from 'api2';
import { CmsProductsPresetProductCardItemListPart } from 'api2/types';
import { ProductCardProps } from 'components/common/ProductCard';
import { FAST_START_TILTE } from 'util/constant';
import { convertProductList } from 'util/product_util';

export type ProductsPresetData = CmsProductsPresetProductCardItemListPart & {
  product_card_list?: ProductCardProps[];
  is_prefetched_data: boolean;
};

export function getQueryKey(products_preset_uuid: string, group_index: number) {
  return ['getProductsPresetData', products_preset_uuid, group_index];
}

export type UseProductsPresetGroupDataOptions = { products_preset_uuid: string; group_index: number; enabled: boolean };

export function useProductsPresetGroupData({
  products_preset_uuid,
  group_index,
  enabled,
}: UseProductsPresetGroupDataOptions) {
  const { data, refetch, ...rest } = useQuery(
    getQueryKey(products_preset_uuid, group_index),
    () => fetchProductsPresetGroupData({ products_preset_uuid, group_index }),
    { refetchOnWindowFocus: false, enabled },
  );

  // NOTE: server에서 일부 데이터를 호출하고, client에서 전체 데이터를 호출하기에 effect시점에서 refetch 한다.
  useEffect(() => {
    if (data?.is_prefetched_data) {
      refetch();
    }
  }, [data?.is_prefetched_data, refetch]);

  return { data, refetch, ...rest };
}

export async function fetchProductsPresetGroupData(
  { products_preset_uuid, group_index, limit }: { products_preset_uuid: string; group_index: number; limit?: number },
  context?: IncomingMessage,
): Promise<ProductsPresetData> {
  const {
    data: { cms_products_preset_product_card_item_list },
  } = await getCmsProductsPresetProductCardItemList({ products_preset_uuid, group_index, limit }, { context });

  const product_card_list = convertProductList(
    cms_products_preset_product_card_item_list.item_list.map(({ product, shop }) => {
      const is_fast_delivery = product.thumbnail_emblem_badge_list?.some((badge) => badge.type === FAST_START_TILTE);
      return {
        ...product,
        is_fast_delivery,
        catalog_product_id: String(product.catalog_product_id),
        shop_name: shop.name,
        is_saved_product: false,
        is_ad: false,
      };
    }),
  );

  return {
    ...cms_products_preset_product_card_item_list,
    product_card_list,
    is_prefetched_data: false,
  };
}
