import Kid from 'components/special-categories/kid';

/**
 * 홈 - 키즈탭
 * @component
 */
const Kids = () => {
  return <Kid />;
};

export default Kids;
