import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { useMemo } from 'react';
import Categories from './Categories';
import Coupon from './Coupon';
import Text from 'components/base/Text';
import useCategoryProductList from 'components/categories/detail/hooks/useCategoryProductList';
import ProductCardVirtualList from 'components/common/ProductCardVirtualList';
import { resetVirtualProductListScrollIndexAtom } from 'components/common/store/product';
import useNewCategoryQueryString from 'components/home/hooks/useNewCategoryQueryString';
import { useNewProductList } from 'components/home/hooks/useNewProductList';
import { Log } from 'util/Tracker';

const NEW_TAB_PRODUCT_LIST_KEY = 'newTabProductList';
const PAGE_COUNT = 50;

const UBL: Pick<Log, 'navigation' | 'object_section'> = {
  navigation: 'home_curation',
  object_section: 'new_product_plp',
};

/**
 * 신상탭 - 신상품 리스트
 * @component
 */
const NewProductList = () => {
  const { is_product_fetching } = useCategoryProductList(PAGE_COUNT);
  const resetVirtualProductListScrollIndex = useUpdateAtom(resetVirtualProductListScrollIndexAtom);
  const { product_list, has_next_page, fetchNextPage, is_loading } = useNewProductList();
  const { category, sub_category } = useNewCategoryQueryString();

  const resetNewProductListVirtualIndex = () => {
    resetVirtualProductListScrollIndex(NEW_TAB_PRODUCT_LIST_KEY);
  };

  const product_ubl = useMemo(
    () => ({
      ...UBL,
      data: omitBy(
        {
          categort_1st_name: category.name,
          category_2nd_name: sub_category ? sub_category.name : null,
        },
        isNil,
      ),
    }),
    [category, sub_category],
  );

  return (
    <section>
      <SC.TitleWrapper>
        <Text variant='title4_bold'>신상품 여기서 더 확인해요</Text>
      </SC.TitleWrapper>
      <Categories resetVirtualIndex={resetNewProductListVirtualIndex} ubl={UBL} />
      <Coupon ubl={UBL} />
      <ProductCardVirtualList
        is_loading={is_product_fetching || is_loading}
        key_name={NEW_TAB_PRODUCT_LIST_KEY}
        product_ubl={product_ubl}
        product_list={product_list}
        has_next_page={has_next_page}
        onFetchNextPage={fetchNextPage}
      />
    </section>
  );
};

export default NewProductList;

const SC = {
  TitleWrapper: styled.div`
    margin: 15px 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
  `,
};
