import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { SearchOptions } from '../store/review';
import { useReviewSearch } from './useReviewSearch';
import { getProductReviewList } from 'api2';
import { ProductReviewListOrderType } from 'api2/types';

export const REVIEW_LIST_QUERY_KEY = 'getProductReviewListKey';

export interface FilterProps {
  sort_type: ProductReviewListOrderType | null;
  size_option_list: SearchOptions[];
  my_custom_filter_checked: boolean;
}
interface FetchProps {
  product_id: string;
  skip_count: number | null;
  limit_count?: number;
  search_value: FilterProps;
  req?: IncomingMessage;
}

export const fetchReviewList = async ({ product_id, skip_count, limit_count = 5, search_value, req }: FetchProps) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const custom_filter_query = {
    product_id,
    my_custom_filter_checked: search_value.my_custom_filter_checked,
    option_list: search_value.size_option_list,
  };

  const {
    data: { product_review_list },
  } = await getProductReviewList(
    {
      product_id,
      limit_count,
      skip_count,
      order: search_value.sort_type,
      custom_filter_query_input: custom_filter_query,
    },
    context_request,
  );

  return product_review_list;
};

/**
 * 상품의 리뷰 리스트 조회
 * @hook
 */
export const useReviewList = (limit_count?: number) => {
  const { search_value } = useReviewSearch();
  const {
    query: { catalog_product_id },
  } = useRouter();
  const product_id = String(catalog_product_id);

  const {
    data,
    hasNextPage: has_next_page,
    isFetching: is_loading,
    ...rest
  } = useInfiniteQuery(
    [REVIEW_LIST_QUERY_KEY, product_id, search_value],
    ({ pageParam }) => {
      return fetchReviewList({
        product_id,
        skip_count: typeof pageParam === 'number' ? pageParam : 0,
        limit_count,
        search_value,
      });
    },
    {
      getNextPageParam: (last_page, all_pages) => {
        const current_page_list_count = flatten(all_pages.map((value) => value.item_list)).length;
        return last_page.total_count > current_page_list_count ? current_page_list_count : false;
      },
    },
  );

  const total_count = useMemo(() => (data ? data.pages[0]?.total_count || 0 : 0), [data]);

  const review_list = useMemo(() => {
    return flatten(data ? data.pages.map((value) => value.item_list) : []);
  }, [data]);

  return {
    total_count,
    review_list,
    is_loading: rest.isInitialLoading,
    is_success: rest.isSuccess,
    has_next_page,
    ...rest,
  };
};
