import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { IconNavigationBasket24 } from 'components/base/Icon';
import Link from 'components/base/Link';
import { useCartCount } from 'queries/cart';
import { pink600, white } from 'styles';
import { typography } from 'styles/font';

interface Props {
  onClick?: () => void;
}
/**
 * 헤더 - 장바구니 아이콘
 * @component
 */
const Cart: FC<Props> = ({ onClick }) => {
  const { cart_count } = useCartCount();

  const formatted_count = useMemo(() => {
    if (cart_count < 0) {
      return 0;
    }
    if (cart_count > 99) {
      return '99+';
    }
    return cart_count;
  }, [cart_count]);

  return (
    <SC.Link href='/checkout/cart' aria-label='장바구니 페이지로 이동합니다.' onClick={onClick}>
      <IconNavigationBasket24 />
      {Boolean(formatted_count) && <SC.Badge>{formatted_count}</SC.Badge>}
    </SC.Link>
  );
};

export default Cart;

const SC = {
  Link: styled(Link)`
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
  `,
  Badge: styled.p`
    ${typography.small4}
    padding: 2px;
    height: 15px;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    border-radius: 8px;
    background: ${pink600};
    color: ${white};
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    right: -7px;
  `,
};
