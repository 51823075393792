import { ContentMetadata, useContentMetadata, UseContentMetadataResult } from '@croquiscom-pvt/event-modules';
import { Tracker } from 'util/Tracker';

type LogNavigation = 'content_detail' | 'magazine_detail';
type LogCategory =
  | 'click'
  | 'impression'
  | 'save_product'
  | 'remove_saved_product'
  | 'add_bookmark'
  | 'remove_bookmark';
type LogPayload = {
  navigation: LogNavigation;
  category: LogCategory;
  page_url: string;
  data?: any;
  object_section?: string;
  object_type?: string;
  object_url?: string;
  object_id?: string;
  object_idx?: number;
};
type CustomData = {
  tracker: Tracker;
};

export function useCmsEventLoggers() {
  return getCmsEventLoggers(useContentMetadata());
}

export function getCmsEventLoggers(metadata: ContentMetadata) {
  const { type, page_url, custom_data } = metadata as UseContentMetadataResult<CustomData>;
  const { tracker } = custom_data;
  const logCmsEvent = addLogs(tracker);
  const navigation = getNavigationWithContentType(type);

  return {
    logContentShare(object_id: 'share_facebook' | 'copy_link') {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'content_share',
        object_type: 'button',
        object_id,
      });
    },
    logShareKakao(object_section: 'content_share' | 'content_body' | 'result_dialog') {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section,
        object_type: 'button',
        object_id: 'share_kakao',
      });
    },
    logTabClick(payload: { tab_title: string; tab_index: number; tab_type: 'page_tab' | 'product_tab' }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_type: 'button',
        object_id: payload.tab_title,
        object_idx: payload.tab_index,
        object_section: payload.tab_type + '_section',
      });
    },
    logManualDeepLinkByFloatingButton(deeplink: string) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_type: 'button',
        object_id: 'floating_button',
        object_url: deeplink,
      });
    },
    logManualDeepLinkByImageModule({
      deeplink,
      component_type,
      component_uuid,
      image_id,
    }: {
      deeplink: string;
      component_uuid: string;
      component_type: string;
      image_id: string;
    }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'image_list_section',
        object_type: 'image',
        object_url: deeplink,
        data: {
          type: component_type,
          image_id,
          uuid: component_uuid,
        },
      });
    },
    logPdpDeepLinkByFloatingButton(catalog_product_id: string) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_id: 'floating_button',
        object_type: 'button',
        data: { catalog_product_id },
      });
    },
    logPdpDeepLinkByImageModule({
      catalog_product_id,
      component_type,
      component_uuid,
      image_id,
    }: {
      catalog_product_id: string;
      component_uuid: string;
      component_type: string;
      image_id: string;
    }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_id: catalog_product_id,
        object_section: 'image_list_section',
        object_type: 'catalog',
        data: {
          type: component_type,
          image_id,
          uuid: component_uuid,
        },
      });
    },
    logProductCard({
      category,
      tab_title_list,
      catalog_product_id,
    }: {
      category: LogCategory;
      tab_title_list: string[];
      catalog_product_id: string | null;
    }) {
      return logCmsEvent({
        category,
        navigation,
        page_url,
        data: { tab_title_list },
        object_section: 'product_section',
        object_type: 'catalog',
        ...(catalog_product_id && { object_id: catalog_product_id }),
      });
    },
    logSavedUmdShop({
      category,
      shop_id,
      is_bookmarked,
    }: {
      category: Extract<LogCategory, 'add_bookmark' | 'remove_bookmark'>;
      shop_id: string;
      is_bookmarked: boolean;
    }) {
      return logCmsEvent({
        category,
        navigation,
        page_url,
        object_type: 'shop',
        object_id: shop_id,
        object_section: 'store_section',
        data: {
          shop_id,
          is_bookmarked,
        },
      });
    },
    logEventMetadataByImageModule({
      event_metadata_title,
      event_metadata_uuid,
      component_uuid,
      component_type,
      image_id,
    }: {
      event_metadata_title: string;
      event_metadata_uuid: string;
      component_uuid: string;
      component_type: string;
      image_id: string;
    }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'image_list_section',
        object_type: 'image',
        object_id: event_metadata_uuid,
        data: {
          type: component_type,
          image_id,
          image_uuid: component_uuid,
          event_title: event_metadata_title,
          action_type: 'event_uuid',
        },
      });
    },
    logEventMetadataByFloatingButton({
      event_metadata_title,
      event_metadata_uuid,
      component_uuid,
    }: {
      event_metadata_title: string;
      event_metadata_uuid: string;
      component_uuid: string;
    }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'floating_button_section',
        object_type: 'button',
        object_id: event_metadata_uuid,
        data: {
          button_uuid: component_uuid,
          event_title: event_metadata_title,
          action_type: 'event_uuid',
        },
      });
    },
    logMarketingConsentByImageModule({
      component_uuid,
      component_type,
      image_id,
    }: {
      component_uuid: string;
      component_type: string;
      image_id: string;
    }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'image_list_section',
        object_type: 'image',
        object_id: 'marketing_consent',
        data: {
          type: component_type,
          image_id,
          image_uuid: component_uuid,
        },
      });
    },
    logMarketingConsentByFloatingButton({ component_uuid }: { component_uuid: string }) {
      return logCmsEvent({
        category: 'click',
        navigation,
        page_url,
        object_section: 'floating_button_section',
        object_type: 'button',
        object_id: 'marketing_consent',
        data: {
          button_uuid: component_uuid,
          action_type: 'marketing_consent',
        },
      });
    },
  };
}

function addLogs(tracker: Tracker) {
  return ({
    category,
    navigation,
    page_url,
    data,
    object_id,
    object_idx,
    object_section,
    object_type,
    object_url,
  }: LogPayload) =>
    tracker.addLog({
      category,
      navigation,
      navigation_sub: { page_url },
      data,
      object_id,
      object_idx,
      object_section,
      object_type,
      object_url,
    });
}

export function getNavigationWithContentType(type?: string | null): LogPayload['navigation'] {
  if (isMagazineDetail(type)) {
    return 'magazine_detail';
  }

  return 'content_detail';
}

function isMagazineDetail(type?: string | null) {
  return type === 'MAGAZINE';
}
