import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

import config from 'util/config';

const DEFAULT_META_INFO = {
  DESCRIPTION: '이제 패션도 카카오에서',
  TYPE: 'website',
  IMAGE: `${config.appUrl}/logo.png`,
};

interface Props {
  title?: string;
  is_hide_site_name?: boolean;
  og?: {
    path?: string;
    type?: string;
    image?: string;
    description?: string;
  };
}

/**
 * 페이지 Head Meta info 관리 컴포넌트
 * @component
 */
const PageHeadMeta: FC<Props> = ({ title, og, is_hide_site_name }) => {
  const { asPath: as_path } = useRouter();
  const meta_og = og?.path ?? as_path;
  const meta_title = is_hide_site_name ? title : `${config.site_name} | ${title}`;

  return (
    <Head>
      <title>{meta_title}</title>
      {title && <meta property='og:title' content={meta_title} />}
      {<meta property='og:url' content={`${config.appUrl}${meta_og}`} />}
      <meta property='og:image' content={og?.image ?? DEFAULT_META_INFO.IMAGE} />
      <meta property='og:type' content={og?.type ?? DEFAULT_META_INFO.TYPE} />
      <meta property='og:site_name' content={config.site_name} />
      <meta property='og:description' content={og?.description ?? DEFAULT_META_INFO.DESCRIPTION} />
      <meta name='description' content={DEFAULT_META_INFO.DESCRIPTION} />
    </Head>
  );
};

export default PageHeadMeta;
