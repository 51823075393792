import { DependencyList, useEffect } from 'react';

export default function useIntersectionObserver(
  // @ts-ignore
  { target, onIntersect, threshold = 0, rootMargin = '0px', enabled = true },
  deps: DependencyList,
) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      },
      {
        rootMargin,
        threshold,
      },
    );

    const el = target && target.current;
    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, rootMargin, onIntersect, target, threshold, ...deps]);
}
