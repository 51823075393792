import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { FC, MouseEvent, useState } from 'react';

import { ImageSearchTip } from '../common';
import ImageSearchGuidance from '../common/ImageSearchGuidance';
import PresetImageItem from '../common/PresetImageItem';
import { push_image_search_history_atom } from '../store';
import SearchResultTitle from './SearchResultTitle';
import Loading from 'components/base/Loading';
import { LogoType } from 'components/base/Loading/LogoTypes';
import Text from 'components/base/Text';
import Thumbnail from 'components/base/Thumbnail';
import ProductCardList from 'components/common/ProductCardList';
import { useSimilarProductList, useProductImageList } from 'components/products/details/hooks';
import { useImageSearchPresetProducts } from 'components/search/hooks/useImageSearchPresetProducts';
import { useSearchProductsByImage } from 'components/search/hooks/useSearchProductsByImage';
import { ImageSearchType } from 'pages/search/image/index.page';
import { bg_secondary, border2 } from 'styles';

interface Base64ImageSearchProps {
  search_type: ImageSearchType.upload;
  source_image: string;
}

interface SimilarCategorySearchProps {
  search_type: ImageSearchType.catalog;
  catalog_product_id: string;
  source_image: string;
}

type Props = Base64ImageSearchProps | SimilarCategorySearchProps;

const image_search_result_ubl = {
  navigation: 'image_search_result',
  object_section: 'result_plp',
};

/**
 * 이미지 검색 결과 화면입니다.
 */
const ImageSearchResult: FC<Props> = (props) => {
  const router = useRouter();
  const pushImageSearchHistory = useUpdateAtom(push_image_search_history_atom);
  const [is_show_guidance, setIsShowGuidance] = useState<boolean>(false);
  const { products: preset_products } = useImageSearchPresetProducts();

  // 업로드 검색 여부
  const is_upload_search = props.search_type === ImageSearchType.upload;
  // 비슷한 상품 검색 여부
  const is_similar_product_search = props.search_type === ImageSearchType.catalog;

  const is_base64_image_search = is_upload_search && props.source_image.startsWith('data:image');
  const { search_result, is_search_result_fetching } = useSearchProductsByImage(
    {
      content_type: 'BASE64',
      content: props.source_image,
    },
    {
      enabled: is_base64_image_search,
    },
  );

  const catalog_product_id_list = is_similar_product_search ? [props.catalog_product_id] : [];
  const { product_image_list: thumbnail_image_list, is_product_image_list_fetching: is_thumbnail_image_list_fetching } =
    useProductImageList({ catalog_product_id_list }, { enabled: !props.source_image });

  const { similar_product_list, is_similar_product_list_fetching } = useSimilarProductList(
    {
      catalog_product_id: is_similar_product_search ? props.catalog_product_id : '',
      limit_count: 50,
    },
    { enabled: is_similar_product_search },
  );

  const thumbnail_image =
    // 저장된 썸네일이 없는 경우 (url로 직접 진입 시)
    is_similar_product_search && thumbnail_image_list.length > 0
      ? thumbnail_image_list.find((item) => item.catalog_product_id === props.catalog_product_id)?.image_url ?? ''
      : props.source_image;

  const is_fetching = is_search_result_fetching || is_similar_product_list_fetching || is_thumbnail_image_list_fetching;
  const search_result_products = is_similar_product_search ? similar_product_list : search_result;
  const is_search_result_exist = search_result_products.length > 0;

  const openGuidance = () => setIsShowGuidance(true);
  const closeGuidance = () => setIsShowGuidance(false);

  const handlePresetClick = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const { currentTarget } = e;
    const { imageUrl: image_url, objectId: catalog_product_id } = currentTarget.dataset;
    pushImageSearchHistory({
      source: image_url!,
      cropped: image_url!,
    });
    router.push(`/search/image?search_type=${ImageSearchType.catalog}&catalog_product_id=${catalog_product_id}`);
  };

  return (
    <>
      <SC.Root>
        <SC.SearchResultTitleSection>
          <SearchResultTitle
            is_fetching={is_fetching}
            is_search_result_exist={is_search_result_exist}
            result_count={search_result_products.length}
            thumbnail_image={thumbnail_image}
          />
          {!is_fetching && !is_search_result_exist && (
            <SC.ImageSearchTipWrap>
              <ImageSearchTip onDetailClick={openGuidance} />
            </SC.ImageSearchTipWrap>
          )}
        </SC.SearchResultTitleSection>
        {is_search_result_exist && (
          <ProductCardList
            product_list={search_result_products}
            product_ubl={image_search_result_ubl}
            use_search
            search_tooltip_options={{ is_show: true, expose_index_list: [1] }}
            only_csr
            breakpoint={[{ column: 3, minWidth: 450 }]}
          />
        )}
        {is_fetching && (
          <SC.Loading>
            <div className='center'>
              <Loading logo_type={LogoType.WAVE_DOTS} />
              <Text variant='title6_medium' color='gray_secondary' mt={27}>
                이미지를 세밀하게
                <br />
                분석하는 중이에요
              </Text>
            </div>
          </SC.Loading>
        )}
        {!is_fetching && (
          <>
            <SC.PresetSection>
              <Text variant='title4_bold' color='gray_primary' mb={2}>
                마음에 드는 상품이 없나요?
              </Text>
              <Text variant='body4_regular' color='gray_secondary'>
                코디와 비슷한 상품을 찾아보세요.
              </Text>
            </SC.PresetSection>
            <SC.PresetImageListSection>
              <SC.PresetImageList>
                {preset_products.map(({ catalog_product_id, image_url }, index) => {
                  return (
                    <PresetImageItem
                      key={catalog_product_id}
                      catalog_product_id={catalog_product_id ?? ''}
                      image_url={image_url ?? ''}
                      onClick={handlePresetClick}
                      data-image-url={image_url}
                      data-navigation='image_search_result'
                      data-object-id={catalog_product_id}
                      data-object-idx={index}
                    />
                  );
                })}
              </SC.PresetImageList>
            </SC.PresetImageListSection>
          </>
        )}
      </SC.Root>
      <ImageSearchGuidance active={is_show_guidance} close={closeGuidance} />
    </>
  );
};

export default ImageSearchResult;

const SC = {
  Root: styled.div``,
  SearchResultTitleSection: styled.section`
    padding: 10px 16px 12px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  ImageSearchTipWrap: styled.div`
    padding-top: 16px;
  `,
  RetryButton: styled.button`
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border: 1px solid ${border2};
    border-radius: 4px;

    &:active {
      background-color: transparent;
    }
  `,
  SourceThumbnail: styled(Thumbnail)`
    width: 70px;
    height: 70px;
  `,
  PresetImageListSection: styled.section`
    padding: 8px;
  `,
  PresetImageList: styled.ul`
    display: flex;
    flex-wrap: wrap;
  `,
  PresetSection: styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 8px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 8px;
      background-color: ${bg_secondary};
    }
  `,
  Loading: styled.div`
    position: fixed;
    left: 0;
    z-index: 100;

    .center {
      width: 100%;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `,
};
