import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { isOpenAppLandingNavigationAtom } from 'components/common/AppLandingNotify/store/isOpenAppLandingNavigation';

/**
 * @hook
 * @description
 * 이벤트 페이지의 Float 컴포넌트(Share, TopButton)의 위치를 셋해주는 hook
 * @example
 * useUpdateFloatButtonBottom();
 */
export const useUpdateFloatButtonBottom = (default_bottom = 0) => {
  const setIsOpenAppLandingNavigation = useUpdateAtom(isOpenAppLandingNavigationAtom);
  useEffect(() => {
    setIsOpenAppLandingNavigation((c) => {
      c.bottom = 20 + default_bottom;
    });
    window.history.scrollRestoration = 'manual';

    return () => {
      setIsOpenAppLandingNavigation((c) => {
        c.bottom = 64 + default_bottom;
      });
      window.history.scrollRestoration = 'auto';
    };
  }, [setIsOpenAppLandingNavigation, default_bottom]);

  return { default_bottom };
};
