import { ActionContext, DialogButtonProps, toastMessage } from '@croquiscom-pvt/event-modules';
import { dialog } from './dialog';
import { getCmsEventLoggers } from './log';
import {
  CmsParticipateEventMetadata,
  CmsEventMetadataReward,
  ParticipateEventMetadataResultAlertType,
  EventMetadataResultButtonActionType,
} from 'api2/types';
import { fbkRouter } from 'hooks';
import { shareWithKakaoTalk } from 'util/content-editor/actions/kakao-share';

type CmsEventMetaDataRewardButtonList = NonNullable<CmsEventMetadataReward['button_list']>[number];

export async function message(
  {
    type,
    alert_type,
    title,
    message,
    link_title,
    link_url,
    image_url,
    reward = [],
  }: CmsParticipateEventMetadata['participateEventMetadata'],
  i18next: ActionContext['i18next'],
  metadata: ActionContext['metadata'],
) {
  if (alert_type === ParticipateEventMetadataResultAlertType.POPUP) {
    const buttons = getManualButtonsOrFallbackButtons(i18next, metadata, reward, link_url, link_title);

    await dialog({
      icon_src: image_url ?? '',
      title: i18next.t(title ?? ''),
      content: i18next.t(message ?? ''),
      type,
      buttons,
    });
  }

  if (alert_type === ParticipateEventMetadataResultAlertType.TOAST) {
    await toastMessage(i18next.t(message ?? ''));
  }
}

function getManualButtonsOrFallbackButtons(
  i18next: ActionContext['i18next'],
  metadata: ActionContext['metadata'],
  reward: Array<CmsEventMetadataReward | null>,
  link_url?: string | null,
  link_title?: string | null,
) {
  const manual_buttons = getButtonsByReward(i18next, metadata, reward);
  if (manual_buttons.length > 0) {
    return manual_buttons;
  }

  const { goOtherPage } = fbkRouter();
  const buttons: DialogButtonProps[] = [
    {
      text: '닫기',
    },
  ];
  if (link_url && link_title) {
    buttons.push({
      theme: 'primary',
      text: link_title,
      onClick: () => goOtherPage(link_url),
    });
  }

  return buttons;
}

function getButtonsByReward(
  i18next: ActionContext['i18next'],
  metadata: ActionContext['metadata'],
  reward?: Array<CmsEventMetadataReward | null>,
) {
  const mapDialogButtonProps = getDialogButtonPropsMapper({ i18next, metadata });
  const manual_buttons =
    reward?.flatMap((reward_item) => reward_item?.button_list?.map(mapDialogButtonProps) ?? []) ?? [];
  return manual_buttons;
}

function getDialogButtonPropsMapper({ metadata }: Pick<ActionContext, 'i18next' | 'metadata'>) {
  const { logShareKakao } = getCmsEventLoggers(metadata);

  return function mapDialogButtonProps({
    action,
    background_color,
    color,
    text,
  }: CmsEventMetaDataRewardButtonList): DialogButtonProps {
    return {
      text,
      color,
      background_color,
      onClick() {
        if (
          action.type === EventMetadataResultButtonActionType.DEEP_LINK &&
          action.option?.__typename === 'EventMetadataResultButtonActionOptionDeepLink'
        ) {
          window.location.assign(action.option.zigzag_link);
        }

        if (action.type === EventMetadataResultButtonActionType.KAKAO_SHARE) {
          logShareKakao('result_dialog');

          shareWithKakaoTalk(metadata.page_url, {
            title: metadata.title,
            description: metadata.description,
            image_src: metadata.image_src,
            button_title: metadata.button_title,
          });
        }
      },
    };
  };
}
