import styled from '@emotion/styled';
import { FC, HTMLAttributes, PropsWithChildren, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Skeleton from './Skeleton';

interface Props extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  index?: number;
  column?: number;
  onView?: (index: number) => void;
}

/**
 * 상품카드 1row
 * @component
 */
const Row: FC<Props> = ({ children, index = 0, onView, column = 2, ...rest }) => {
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView) {
      onView?.(index);
    }
  }, [ref, inView, index, onView]);

  return (
    <SC.Row ref={ref} column={column} {...rest}>
      {children}
    </SC.Row>
  );
};

export default Object.assign(Row, { Skeleton });

const SC = {
  Row: styled.div<{ column: number }>`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${({ column }) => `repeat(${column}, 1fr)`};
    grid-gap: 0 7px;
    width: 100%;
    min-width: 0px;
    padding-bottom: 20px;
  `,
};
