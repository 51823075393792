import { StoreGridModuleProps } from '@croquiscom-pvt/event-modules';
import styled from '@emotion/styled';
import { CmsStorePresetShopPart } from 'api2/types';
import { useFbkRouter } from 'hooks/useFbkRouter';

export type StoreGridItemProps = { data: CmsStorePresetShopPart } & Pick<
  StoreGridModuleProps,
  'border_color' | 'border_radius' | 'color'
>;

export const StoreGridItem = ({ data, color, border_color, border_radius }: StoreGridItemProps) => {
  const { goStorePage } = useFbkRouter();
  const handleClick = async () => {
    goStorePage(`${data.id}`);
  };

  return (
    <Wrapper style={{ color }} onClick={handleClick}>
      <Thumbnail image={data.image_url} style={{ borderRadius: border_radius, borderColor: border_color }} />
      <Name>{data.name}</Name>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
`;

const Thumbnail = styled.div<{ image: string | null }>`
  background-image: ${(props) => (props.image ? `url(${props.image})` : '')};
  background-size: 80px;
  background-color: gray;
  background-position: -3px;
  width: 80px;
  height: 80px;
  border: 3px solid transparent;
  margin-bottom: 10px;
`;

const Name = styled.div`
  width: 80px;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.2;
  height: 24px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  word-break: break-all;
`;
