export const ZIGZAG_ORDER_REQUEST_STATUS: { [key: string]: string } = {
  CANCEL_REQUESTED: '취소 요청',
  CANCELLING: '취소처리중',
  CANCELLED: '취소완료',
  CANCEL_DEFERRED: '취소보류',
  RETURN_REQUESTED: '반품 요청',
  RETURN_COLLECTING: '반품수거중',
  RETURN_COLLECTED: '반품수거완료',
  RETURNED: '반품완료',
  RETURN_DEFERRED: '반품보류',
  EXCHANGE_REQUESTED: '교환 요청',
  EXCHANGE_COLLECTING: '교환수거중',
  EXCHANGE_COLLECTED: '교환수거완료',
  EXCHANGED: '교환완료',
};

export const ZIGZAG_ORDER_STATUS: { [key: string]: string } = {
  ...ZIGZAG_ORDER_REQUEST_STATUS,
  PENDING: '결제대기',
  BEFORE_TRANSFER: '입금대기',
  NEW_ORDER: '결제완료',
  CANCELLED_BEFORE_TRANSFER: '미입금취소',
  AWAITING_SHIPMENT: '배송준비중',
  IN_TRANSIT: '배송중',
  SHIPPED: '배송완료',
  SHIPPING_DEFERRED: '배송보류',
  CONFIRMED: '구매확정',
  CANCELLED_AFTER_CONFIRMED: '구매확정후취소',
  SHIPMENT_PROCESS_REQUESTED: '배송 준비중',
};

export const ZIGZAG_ORDER_ITEM_REQUEST_TYPE = {
  CANCEL: '취소',
  RETURN: '반품',
  EXCHANGE: '교환',
};

export const ZIGZAG_PAYMENT_METHOD: { [key: string]: string } = {
  1: '신용카드',
  CARD: '신용카드',
  2: '계좌이체',
  BANK: '계좌이체',
  3: '가상계좌',
  VBANK: '가상계좌',
  4: '휴대폰',
  PHONE: '휴대폰',
  5: '카드 간편결제',
  SIMPLE_CARD: '카드 간편결제',
  6: '계좌 간편결제',
  SIMPLE_BANK: '계좌 간편결제',
  7: '카카오페이',
  KAKAOPAY: '카카오페이',
  8: '해외카드',
  GLOBAL_CARD: '해외카드',
  100: '포인트',
  POINT: '포인트',
};

export const WEEK_DAY: { [key: string]: string } = {
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
  SUN: '일',
};

export const ORDER_ITEM_DELAY_REASON_CATEGORY: { [key: string]: string } = {
  CONFIRMED_NOT_SHIPPED: '상품 배송 받지 못함',
  CONFIRMED_SHIPPED_WRONG_OR_LATE: '오배송 및 지연',
  CONFIRMED_DEFECTIVE_PRODUCT: '파손 및 불량',
  CONFIRMED_DIFFERENT_FROM_PRODUCT_INFO: '상품정보 상이',
  CONFIRMED_ETC: '기타',
  IN_TRANSIT_AWAITING_PRODUCT: '상품준비중',
  IN_TRANSIT_CUSTOM_ORDER: '주문제작',
  IN_TRANSIT_CUSTOMER_REQUEST: '고객요청',
  IN_TRANSIT_RESERVE_SHIPMENT: '예약 발송',
  IN_TRANSIT_ETC: '기타사유',
  IN_TRANSIT_UPDATE_EXPECTED_IN_TRANSIT: '배송 지연',
};

export const PROMOTION_TAG = {
  SHARE_KING: 'FBK_SHARE_KING_202210',
};

export const FAST_START_TILTE = 'FAST_START';
export const FAST_DELIVERY_URL =
  'https://cf.image-farm.s.zigzag.kr/original/mkt/2023/8/28/202308281205530794_28811.png';

export const ZIGZAG_SITE_ID = '1';
export const FBK_SITE_ID = '3';

export const REVIEW_RATING_TITLE: { [key: string]: string } = {
  1: '별로예요',
  2: '그저 그래요',
  3: '보통이에요',
  4: '아주 좋아요',
  5: '정말 최고예요',
};
