import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState, useRef, useLayoutEffect } from 'react';
import { ContentReplyMoreMenu } from './ContentReplyMoreMenu';
import { EventContentReplyStatus } from 'api2/types';
import { colors } from 'styles';
import { typography } from 'styles/font';
import DateFormat from 'util/date-format';

export type ContentReplyItemProps = {
  user_name: string;
  content: string;
  date_created: number;
  is_mine: boolean;
  status: EventContentReplyStatus;
  handleDeleteReply: () => void;
};

export const ContentReplyItem = ({
  user_name,
  date_created,
  content,
  is_mine,
  status,
  handleDeleteReply,
}: ContentReplyItemProps) => {
  const [is_extend, set_is_extend] = useState(false);
  const [is_shown_more_button, set_is_shown_more_button] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);
  const is_deleted = [EventContentReplyStatus.DELETED, EventContentReplyStatus.DELETED_BY_ADMIN].includes(status);

  useLayoutEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.offsetHeight !== contentRef.current.scrollHeight) {
        set_is_shown_more_button(true);
      }
    }
  }, [contentRef]);

  const handleUnfold = () => {
    set_is_shown_more_button(false);
    set_is_extend(true);
  };

  if (is_deleted) {
    return <SystemMessage>삭제된 댓글입니다</SystemMessage>;
  }

  return (
    <Wrapper>
      <Header>
        <Meta>
          <Profile>{user_name}</Profile>
          <LocaleDate>{`${DateFormat.toKoString(new Date(date_created), 'MM.dd HH:mm')}`}</LocaleDate>
        </Meta>
        <ContentReplyMoreMenu is_mine={is_mine} handleDeleteReply={handleDeleteReply} />
      </Header>
      <Content ref={contentRef} is_ellipsis={!is_extend}>
        {content}
        {is_shown_more_button && <ExtendButton onClick={handleUnfold}>... 더보기</ExtendButton>}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  position: relative;
  padding: 20px 16px;
`;

const SystemMessage = styled.li`
  position: relative;
  width: 100%;
  padding: 20px 16px;
  ${typography.body3}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  column-gap: 6px;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
`;

const Profile = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${colors.gray800};
`;
const LocaleDate = styled.span`
  color: ${colors.gray500};
  white-space: nowrap;
`;

const Content = styled.p<{ is_ellipsis: boolean }>`
  position: relative;
  padding-top: 10px;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  color: ${colors.gray800};

  ${({ is_ellipsis }) =>
    is_ellipsis &&
    css`
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

const ExtendButton = styled.span`
  position: absolute;
  bottom: -8px;
  right: -8px;
  color: ${colors.gray500};
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 0 8px 8px 32px;
  cursor: pointer;
`;
