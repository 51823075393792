import { MarketingConsentActionHandler } from '@croquiscom-pvt/event-modules';
import dayjs from 'dayjs';
import { ModuleRootCustomData } from '../custom-data';
import { getClickedImage } from '../image-action';
import { getCmsEventLoggers } from '../log';
import { updateUserAdNotiStatus } from 'api2';
import { UserNotiStatus } from 'api2/types';
import { getDisabledState } from 'util/content-editor/action-state';
import { getStatus } from 'util/content-editor/user';
import { Toast } from 'util/Toast';

export const handleMarketingConsent: MarketingConsentActionHandler<ModuleRootCustomData> = async (
  action,
  context,
  action_state,
) => {
  logAction([action, context, action_state]);

  const { is_login, setAppPush, openLoginDialog } = context.metadata.custom_data;
  const { is_user } = await getStatus();

  if (!is_login || !is_user) {
    openLoginDialog();
    return;
  }

  const {
    data: {
      updateUserAdNotiStatus: { success },
    },
  } = await updateUserAdNotiStatus({
    input: { status: UserNotiStatus.AGREE },
  });
  if (success) {
    const agree_date = dayjs().format('YY.MM.DD');
    Toast.show(`${agree_date}\n패션바이카카오 알림 수신 동의가 완료되었어요.`);
    // query_client.setQueryData 처리
    setAppPush(true);
  }

  return getDisabledState();
};

function logAction([action, { metadata, module_hierarchy }]: Parameters<
  MarketingConsentActionHandler<ModuleRootCustomData>
>) {
  try {
    const { logMarketingConsentByFloatingButton, logMarketingConsentByImageModule } = getCmsEventLoggers(metadata);
    const current_module = module_hierarchy.modules[module_hierarchy.modules.length - 1];

    if (current_module.__type === 'FloatingButton') {
      logMarketingConsentByFloatingButton({
        component_uuid: current_module.uuid,
      });
    }

    if (current_module.__type === 'ImageCarousel' || current_module.__type === 'ImageMap') {
      const clicked_image = getClickedImage(current_module, action);

      if (clicked_image) {
        logMarketingConsentByImageModule({
          component_type: current_module.__type,
          component_uuid: current_module.uuid,
          image_id: clicked_image.id,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}
