import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { check_type_option_atom, range_type_option_atom } from '../store/review';
import { getProductReviewList } from 'api2';
import { ReviewOptionInput } from 'api2/types';

export const SIZE_SEARCH_FILTER_COUNT_QUERY_KEY = 'getSizeSearchFilterCount';

export const fetchSearchReviewCount = async (product_id: string, search_filter_options: ReviewOptionInput[]) => {
  const custom_filter_query_input = {
    product_id,
    my_custom_filter_checked: false,
    option_list: search_filter_options,
  };

  const {
    data: { product_review_list },
  } = await getProductReviewList({ product_id, custom_filter_query_input });

  return {
    total_count: product_review_list.total_count,
  };
};

/**
 * 내 사이즈에 맞는 리뷰가 있는지 확인하는 hook
 * @component
 */
export const useReviewSearchCount = () => {
  const {
    query: { catalog_product_id },
  } = useRouter();
  const product_id = String(catalog_product_id);
  const check_type_option = useAtomValue(check_type_option_atom);
  const range_type_option = useAtomValue(range_type_option_atom);

  const search_filter_options = [...range_type_option, ...check_type_option];

  const {
    data,
    isInitialLoading: is_loading,
    ...rest
  } = useQuery([SIZE_SEARCH_FILTER_COUNT_QUERY_KEY, search_filter_options, product_id], () =>
    fetchSearchReviewCount(product_id, search_filter_options),
  );

  return {
    is_loading,
    total_count: data?.total_count ?? 0,
    ...rest,
  };
};
