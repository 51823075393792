import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { SpecialCategoryType } from '../constants';
import { useCarouselProductList, CAROUSEL_KEY } from '../hooks/useCarouselProductList';
import Section from '../Section';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import ProductCarouselList from 'components/common/ProductCarouselList';
import { Log } from 'util/Tracker';

const CAROUSEL_TYPE_INFO: Record<SpecialCategoryType, CAROUSEL_KEY> = {
  kid: 'KIDS_CAROUSEL',
  zigzin: 'ZIGZIN_CAROUSEL',
};

interface Props {
  category_type: SpecialCategoryType;
  store_exhibition_ubl: Pick<Log, 'navigation'>;
}
/**
 * 전용 카테고리 - 스토어 기획전
 * @component
 */
const StoreExhibition: FC<Props> = ({ category_type, store_exhibition_ubl }) => {
  const carousel_type = CAROUSEL_TYPE_INFO[category_type];
  const { carousel_product_list, is_loading } = useCarouselProductList(carousel_type);

  if (is_loading) {
    return (
      <Section>
        <SC.StoreExhibition>
          <Skeleton className='store_name' width={133} height={22} />
          <ProductCarouselList.Skeleton />
        </SC.StoreExhibition>
      </Section>
    );
  }

  if (carousel_product_list.length === 0) {
    return null;
  }

  return (
    <Section custom_css={main_css}>
      {carousel_product_list.map((carousel_product, index) => (
        <SC.StoreExhibition key={carousel_product.benefit_section_id}>
          <div className='store_name'>
            <Text variant='title4_bold' color='gray_active' as='h3' mb={2}>
              {carousel_product.main_title}
            </Text>
            <Text variant='body4_regular' color='gray_secondary'>
              {carousel_product.sub_title}
            </Text>
          </div>
          <ProductCarouselList
            product_list={carousel_product.product_list}
            ubl={{
              ...store_exhibition_ubl,
              object_section: 'big_product_carousel',
              object_type: 'catalog',
              data: {
                carousel_idx: index,
                carousel_id: carousel_product.benefit_section_id,
                carousel_title: carousel_product.main_title,
              },
            }}
          />
        </SC.StoreExhibition>
      ))}
    </Section>
  );
};

export default StoreExhibition;

const main_css = css`
  > * + * {
    padding-top: 16px;
  }
  padding-bottom: 24px;
`;

const SC = {
  StoreExhibition: styled.article`
    .store_name {
      margin: 15px 16px;
    }
  `,
};
