import { FC, useCallback, useMemo } from 'react';
import Banners, { BannerItem } from 'components/base/Banners';
import { useFbkRouter } from 'hooks';
import { BANNER_PAGE_NAME, useActiveBannerList } from 'hooks/queries';
import { Log, useTracker } from 'util/Tracker';

const BANNER_UBL = {
  object_type: 'banner',
  object_section: 'top_banner',
};

interface Props {
  banner_page_name: BANNER_PAGE_NAME;
  banner_size?: 'large' | 'medium';
  ubl?: Omit<Log, 'category'>;
}

/**
 * 홈 - 상단 배너
 * @component
 */
const TopBanner: FC<Props> = ({ banner_page_name, banner_size = 'large', ubl }) => {
  const { push } = useFbkRouter();
  const tracker = useTracker();
  const {
    main_banner_list: { active_banner_list },
    is_loading,
  } = useActiveBannerList(banner_page_name);
  const common_ubl = useMemo(() => {
    return ubl ? { ...ubl, ...BANNER_UBL } : undefined;
  }, [ubl]);

  const banner_list: BannerItem[] = active_banner_list.map((banner) => ({
    id: banner.id,
    image_url: banner.image_url,
    link: banner.link,
    alt: banner.title,
  }));

  const handleImpressionBanner = useCallback(
    (banner: BannerItem, index: number) => {
      if (common_ubl) {
        tracker.addLog({
          ...common_ubl,
          category: 'impression',
          object_id: banner.id,
          object_idx: index,
        });
      }
    },
    [common_ubl, tracker],
  );

  const handleBannerClick = (banner: BannerItem, index: number) => {
    if (common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_id: banner.id,
        object_idx: index,
      });
    }

    if (banner_page_name === BANNER_PAGE_NAME.TREND) {
      tracker.homeBanner({ banner_id: banner.id });
    }
  };

  const handleAllViewClick = () => {
    if (common_ubl) {
      tracker.addLog({
        ...common_ubl,
        category: 'click',
        object_type: 'button',
        object_id: 'view_all',
      });
    }
    push(`/banner-views/${banner_page_name}`, { only_csr: false });
  };

  if (!banner_list.length && !is_loading) {
    return null;
  }

  return (
    <Banners
      is_loading={is_loading}
      banner_list={banner_list}
      size={banner_size}
      onBannerClick={handleBannerClick}
      onViewBanner={handleImpressionBanner}
      onAllViewClick={handleAllViewClick}
    />
  );
};

export default TopBanner;
