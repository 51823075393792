import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, MouseEvent } from 'react';

import { useProductLike } from '../../../../queries/like/useProductLike';
import { ProductCardProps, ProductUbl } from '../index';
import { IconHeartFill24, IconHeartShadow24, IconHeartUnFill24 } from 'components/base/Icon';
import { gray_tertiary } from 'styles';

interface Props extends ProductCardProps {
  product_ubl: ProductUbl;
  custom_css?: SerializedStyles;
  /**
   * 아이콘 스타일을 정의합니다.
   * @default 'shadow'
   */
  icon_style?: 'shadow' | 'border';
}

const LikeIcon: FC<Props> = ({
  shop,
  is_saved_product,
  price,
  product_ubl,
  shop_product_no,
  catalog_product_id,
  toast_bottom_floating_position = 52,
  title,
  custom_css,
  icon_style = 'shadow',
}) => {
  const { is_like_product, toggleProductLike } = useProductLike({
    shop_product_no,
    catalog_product_id,
    shop_name: shop.name ?? '',
    shop_id: shop.id,
    is_like: is_saved_product ?? false,
    product_name: title ?? '',
    price: price ?? 0,
    product_like_ubl: product_ubl,
    bottom_floating_position: toast_bottom_floating_position,
  });

  const handleLikeClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    toggleProductLike();
  };

  return (
    <SC.LikeButton aria-label='상품을 찜목록에 추가하기' onClick={handleLikeClick} css={custom_css}>
      {is_like_product ? <IconHeartFill24 /> : getUnFillHeartIcon(icon_style)}
    </SC.LikeButton>
  );
};

const getUnFillHeartIcon = (icon_style: string) => {
  return icon_style === 'shadow' ? <IconHeartShadow24 /> : <IconHeartUnFill24 color={gray_tertiary} />;
};

export default LikeIcon;

const SC = {
  LikeButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;

    &:active {
      background-color: transparent;
    }
  `,
};
