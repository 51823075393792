import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CircleCategory, CircleSize } from '.';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { colors, ColorType } from 'styles';
import { useTracker } from 'util/Tracker';

interface Props {
  category: CircleCategory;
  index: number;
  size: CircleSize;
  title_image_gap: string;
  use_filter: boolean;
  /**
   * @default bg_primary
   */
  background_color?: ColorType;
  onClick?: (category: CircleCategory) => void;
}

/**
 * 공통 원형 카테고리 리스트 - 카테고리 아이템
 * @component
 */
const Item: FC<Props> = ({
  category,
  size,
  index,
  title_image_gap,
  use_filter,
  background_color = 'bg_primary',
  onClick,
}) => {
  const { link, ubl, title, img_url } = category;
  const tracker = useTracker();
  const { ref: store_ref, inView: in_view } = useInView({ threshold: 0.5, triggerOnce: true });
  const [is_send_impression, setIsSendImpression] = useState<boolean>(false);

  useEffect(() => {
    if (in_view && ubl && !is_send_impression) {
      tracker.addLog({ ...ubl, object_idx: index, category: 'impression' });
      setIsSendImpression(true);
    }
  }, [tracker, ubl, index, in_view, is_send_impression]);

  const handleStoreClick = () => {
    if (ubl) {
      tracker.addLog({ ...ubl, object_idx: index, category: 'click' });
    }

    onClick?.(category);
  };

  return (
    <SC.Item ref={store_ref} size={size} title_image_gap={title_image_gap} background_color={background_color}>
      <Link href={link} onClick={handleStoreClick}>
        <div className='store_img'>
          <Image src={img_url} alt={`${title} 이미지`} layout='fill' objectFit='cover' use_fade />
          {use_filter && <SC.Filter />}
        </div>
        <Text className='name' variant='small1_medium' color='gray900' alignment='center' as='p'>
          {title}
        </Text>
      </Link>
    </SC.Item>
  );
};

export default Item;

const SC = {
  Item: styled.li<{ size: CircleSize; title_image_gap: string; background_color: ColorType }>`
    width: 100%;
    min-width: ${({ size }) => size.min}px;
    max-width: ${({ size }) => size.max}px;
    cursor: pointer;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .store_img {
      position: relative;
      width: 100%;
      aspect-ratio: 1;

      img {
        border-radius: 48px;
        background-color: ${({ background_color }) => colors[background_color]};
      }
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 70px;
      height: 17px;
      margin-top: ${({ title_image_gap }) => title_image_gap};
    }
  `,
  Filter: styled.div`
    position: absolute;
    inset: 0;
    border-radius: 48px;
    background-color: rgba(0, 0, 0, 0.03);
  `,
};
