import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Text from 'components/base/Text';
import { ProductCardSize } from 'components/common/ProductCard';

interface Props {
  size?: ProductCardSize;
  discount_rate: number | null;
}

/**
 * @components 상품카드 이미지 핧인율 뱃지
 */
const DiscountIcon = ({ size = 'medium', discount_rate }: Props) => {
  if (discount_rate === null || discount_rate === 0) {
    return null;
  }

  return (
    <SC.Container css={size_css[size]}>
      <Text variant='title6_bold' color='white' as='p'>
        {discount_rate}%
      </Text>
    </SC.Container>
  );
};

export default DiscountIcon;

const SC = {
  Container: styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(230, 47, 113, 0.7);
    backdrop-filter: blur(2px);
  `,
};

const size_css = {
  large: css`
    width: 45px;
    height: 28px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `,

  medium: css`
    width: 64px;
    height: 21px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
  `,
  small: css`
    width: 50px;
    height: 16px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
  `,
};
