import { useRouter } from 'next/router';

/**
 * 파트너센터에서 pdp preview 표시를 위해 query 체크하는 hook
 * @hook
 */

export const useProductPreview = () => {
  const {
    query: { preview },
  } = useRouter();

  return { is_preview: preview === 'true' };
};
