import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getCouponIssueStatus } from 'api2';
import { GetCouponIssueStatus, GetCouponIssueStatusVariables } from 'api2/types';

export const NEW_COUPON_QUERY_KEY = 'getNewCouponStatus';

export type CouponIssueStstus = GetCouponIssueStatus['getCouponIssueStatus'];

const init_value: CouponIssueStstus = {
  is_issued_coupon_pack: false,
  issued_policy_id_list: [],
  not_issued_policy_id_list: [],
};
export const fetchCouponIssueStatus = async (props: GetCouponIssueStatusVariables, req?: IncomingMessage) => {
  if (!props.coupon_pack_id && !props.policy_id_list) {
    return init_value;
  }
  try {
    const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
    const { data } = await getCouponIssueStatus(props, context_request);

    return data.getCouponIssueStatus;
  } catch (error) {
    return init_value;
  }
};

/**
 * 쿠폰 발급 여부 조외
 * @hook
 */
export const useCouponIssueStatus = (props: GetCouponIssueStatusVariables, options: { enabled?: boolean }) => {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery([NEW_COUPON_QUERY_KEY, props], () => fetchCouponIssueStatus(props), options);

  const coupon_issue_status = data ?? init_value;
  const is_issue_policy_id_list = coupon_issue_status.issued_policy_id_list.length > 0;

  return {
    coupon_issue_status: data ?? init_value,
    is_issue_policy_id_list,
    is_loading,
    ...rest,
  };
};
