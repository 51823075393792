import { ComponentProps, FC } from 'react';
import DefaultHeader from './DefaultHeader';
import HomeHeader from './HomeHeader';
import MainHeader from './MainHeader';
import SubHeader from './SubHeader';

const Header: FC<ComponentProps<typeof DefaultHeader>> = ({ ...rest }) => {
  return <DefaultHeader {...rest} />;
};

export default Object.assign(Header, {
  Home: HomeHeader,
  Main: MainHeader,
  Sub: SubHeader,
});
