import { ContentShareInfo, KakaoShareActionHandler, toastMessage } from '@croquiscom-pvt/event-modules';
import { ModuleRootCustomData } from '../custom-data';
import { shareWithNavigator } from './url-share';
import { APP_VERSION_TYPE, isLowAppVersion } from 'util/app_utils';
import { getDevice } from 'util/device';

export function shareWithKakaoTalk(url: string, share_info: ContentShareInfo) {
  const Kakao = (window as any).Kakao;
  Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: share_info.title,
      description: share_info.description,
      imageUrl: share_info.image_src,
      link: { mobileWebUrl: url, webUrl: url },
    },
    buttons: [{ title: share_info.button_title, link: { mobileWebUrl: url, webUrl: url } }],
    installTalk: true,
  });
}

export const handleKakaoShare: KakaoShareActionHandler<ModuleRootCustomData> = async (_, context) => {
  const { metadata } = context;
  const { is_app } = getDevice(navigator.userAgent);
  const is_native_version = isLowAppVersion(APP_VERSION_TYPE.NATIVE_SUPPORT, navigator.userAgent);
  const is_share_navigator = is_app && metadata.custom_data.is_home_tab && !is_native_version;

  // 홈 탭 웹뷰에서 카카오 공유하기 안되는 기존 앱 버그 이슈로 링크 복사로 대체
  if (is_share_navigator) {
    return shareWithNavigator(metadata.page_url, { title: metadata.title, description: metadata.description }).then(
      () => toastMessage('이벤트 링크가 복사되었습니다.'),
    );
  }

  return shareWithKakaoTalk(metadata.page_url, {
    title: metadata.title,
    description: metadata.description,
    image_src: metadata.image_src,
    button_title: metadata.button_title,
  });
};
