import { atom } from 'jotai';
import { createAtomFamily } from 'util/jotai-util';

/**
 * @desciption
 * 상품 가상 스크롤 스크롤 복원을 위한 스크롤 index 저장
 */
export const virtual_product_list_scroll_index_atom = createAtomFamily(0, (index: number) => index);

/**
 * @desciption
 * 설정된 key에 대한 상품 가상 스크롤 스크롤 원복
 */
export const resetVirtualProductListScrollIndexAtom = atom(null, (_, set, key_name: string) => {
  set(virtual_product_list_scroll_index_atom(key_name), 0);
});
