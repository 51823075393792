import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import Skeleton from '../Skeleton';
import Text from 'components/base/Text';

type ContentTitleSize = 'small' | 'large';
type ContentFontSize = {
  title: ComponentProps<typeof Text>;
  description: ComponentProps<typeof Text>;
};

interface Props {
  /**
   * 제목을 정의합니다.
   */
  title: string;
  /**
   * Title 에 대한 설명글을 작성합니다.
   */
  description?: string;
  /**
   * 텍스트 영역 가운데 정렬 유무
   * @default false
   */
  is_text_center?: boolean;
  /**
   * 좌측 영역을 그리는 컴포넌트를 정의합니다.
   */
  left?: ReactNode;
  /**
   * 우측 영역을 그리는 컴포넌트를 정의합니다.
   */
  right?: ReactNode;
  /**
   * 컴포넌트 사이즈
   * @default small
   */
  size?: ContentTitleSize;
  /**
   * 클래스 이름
   * @default null
   */
  classname?: string;
  /**
   * 로딩 유무
   * @default false
   */
  is_loading?: boolean;
}

/**
 * Contents 상단 Title 섹션 컴포넌트
 * @component
 */
const ContentTitle = forwardRef<HTMLElement, Props>(
  (
    { title, description, left, right, size = 'small', is_text_center = false, classname, is_loading, ...props },
    ref,
  ) => {
    const is_exist_description = !!description;

    const content_title_css = content_title_size_css(is_exist_description)[size];
    const text_css = font_size_css[size];
    const text_info_css = getTextInfoCss(is_text_center, size);
    // 제목이 없을 경우만 로딩인터렉션을 진행한다.
    const is_show_loading = is_loading && title === '';

    return (
      <SC.Container ref={ref} css={content_title_css} className={classname} {...props}>
        <div className={cx('left', is_exist_description ? 'full_size' : 'small_size')}>
          {left && <SC.SideInfo className='left_item'>{left}</SC.SideInfo>}
          <div className='text-info' css={text_info_css}>
            {is_show_loading && <Skeleton width={100} height={22} />}
            {!is_show_loading && (
              <>
                <Text {...text_css.title}>{title}</Text>
                {description && (
                  <Text className='description' {...text_css.description}>
                    {description}
                  </Text>
                )}
              </>
            )}
          </div>
        </div>
        <div className='right'>{right && <SC.SideInfo className='right_item'>{right}</SC.SideInfo>}</div>
      </SC.Container>
    );
  },
);

export default ContentTitle;

function getTextInfoCss(is_center: boolean, size: ContentTitleSize) {
  if (is_center) {
    return css`
      ${size === 'small' && 'justify-content: center'}
      ${size === 'large' && 'align-items: center'}
    `;
  }
  return css``;
}

const content_title_size_css = (is_full_height: boolean) => {
  return {
    small: css`
      height: 40px;

      .text-info {
        align-items: center;
      }

      .left_item {
        margin-right: 10px;
      }

      .right_item {
        margin-left: 10px;
      }

      .description {
        margin-left: 4px;
      }
    `,
    large: css`
      height: ${is_full_height ? 58 : 52}px;

      .text-info {
        flex-direction: column;
      }

      .left_item {
        margin-right: 12px;
      }

      .right_item {
        margin-left: 12px;
      }
    `,
  };
};

const font_size_css: Record<ContentTitleSize, ContentFontSize> = {
  small: {
    title: {
      variant: 'title6_medium',
      color: 'gray_secondary',
    },
    description: {
      variant: 'title6_medium',
      color: 'gray_tertiary',
    },
  },
  large: {
    title: {
      variant: 'title4_bold',
      color: 'gray_primary',
    },
    description: {
      variant: 'body4_regular',
      color: 'gray_secondary',
      mt: 2,
    },
  },
};

const SC = {
  Container: styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .left {
      display: flex;
      overflow: hidden;
    }

    .description {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .text-info {
      overflow: hidden;
      display: flex;
      width: 100%;
    }
  `,
  SideInfo: styled.div`
    display: flex;
    align-items: center;
  `,
};
