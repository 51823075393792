import styled from '@emotion/styled';
import ProductCard from 'components/common/ProductCard';
import GridTile from 'components/toolbox/croquis/core/GridTile';
import { WEB_MAX_WIDTH, white } from 'styles';

export const LoadingSkeleton = () => {
  return (
    <SC.Container>
      <SC.SkeletonWrap>
        <SC.SkeletonGrid column={2} gap='20px 6px'>
          {[...Array(12)].map((_, index) => (
            <ProductCard.Skeleton key={index} />
          ))}
        </SC.SkeletonGrid>
      </SC.SkeletonWrap>
    </SC.Container>
  );
};

const SC = {
  Container: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: ${WEB_MAX_WIDTH}px;
    background: ${white};
    z-index: 300;
  `,
  SkeletonWrap: styled.div`
    position: relative;
    padding: 8px 0 24px;
  `,
  SkeletonGrid: styled(GridTile)`
    padding: 0 8px 24px;

    > div {
      padding-bottom: 26px;
    }
  `,
};
