import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, useMemo, useRef } from 'react';
import ProductCard, { ProductCardProps, ProductCardSize, ProductCardUbl } from '../ProductCard';
import GridTile, { GridTileProps } from 'components/toolbox/croquis/core/GridTile';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { white } from 'styles';

interface Props extends Omit<GridTileProps, 'gap'> {
  /**
   * 상품카드 리스트를 그리기 위한 상품 리스트를 설정합니다.
   */
  product_list?: ProductCardProps[];
  /**
   * 상품카드 section 에 대한 custom margin 을 설정합니다.
   * @default '0'
   */
  margin?: string;
  /**
   * 상품카드 section 에 대한 custom padding 을 설정합니다.
   * @default '8px 8px 24px'
   */
  padding?: string;
  /**
   * 랭킹을 보여주는 유무를 설정합니다.
   * @default false
   */
  use_rank?: boolean;
  /**
   * 상품카드 사이즈를 설정 합니다.
   * @default 'medium'
   */
  card_size?: ProductCardSize;
  /**
   * 상품리스트가 로딩중인지 상태를 설정합니다.
   * @default false
   */
  is_loading?: boolean;
  /**
   * 상품 스켈레톤 갯수를 정의합니다.
   * @default 6
   */
  skeleton_count?: number;
  /**
   * 상품 노출 시작 인덱스
   * @default 0
   */
  start_index?: number;
  /**
   * 검색 아이콘 사용 여부를 설정합니다.
   * @default false
   */
  use_search?: boolean;
  /**
   * 검색 아이콘 툴팁 노출 옵션을 설정합니다.
   * @property is_show 툴팁 노출 여부
   * @property expose_index_list 노출할 인덱스
   */
  search_tooltip_options?: {
    is_show: boolean;
    expose_index_list?: number[];
  };
  /**
   * 상품카드 클릭시 app 에서도 csr 로 접근가능하도록(새창 안뜨도록하기위함) 설정합니다.
   * @default false
   */
  only_csr?: boolean;
  /**
   * 상품 ubl에 대한 공통 ubl을 설정합니다.
   */
  product_ubl?: ProductCardUbl;
  /**
   * custom 스타일 css 를 정의합니다.
   */
  custom_css?: SerializedStyles;
  /**
   * 좌측 하단 icon 유형을 정의합니다.
   * @default
   * { type: 'md' }
   */
  icon_bottom_left?: {
    type: 'md';
    label?: string;
  };
  /**
   * 다음페이지 fetching 이 필요한 경우를 설정합니다.
   * @default false
   */
  has_next_page?: boolean;
  /**
   * 다음 페이징 호출이 필요한 경우 해당 함수에서 호출합니다.
   */
  onIntersect?: () => void;
  /**
   * 상품카드 클릭시 pdp 이동 말고 추가 callback 액션이 필요한 경우 정의합니다.
   */
  onProductClick?: (catalog_product_id: string, index: number) => void;
}

/**
 * 상품카드 리스트
 * @component
 */
const ProductCardList: FC<Props> = ({
  product_list = [],
  column = 2,
  margin = '0',
  padding = '8px 16px 24px',
  card_size = 'medium',
  breakpoint,
  use_rank,
  is_loading,
  start_index = 0,
  skeleton_count = 6,
  use_search,
  custom_css,
  only_csr = false,
  product_ubl,
  icon_bottom_left,
  has_next_page,
  search_tooltip_options = {
    is_show: false,
    expose_index_list: [],
  },
  onIntersect,
  onProductClick,
}) => {
  const skeleton_footer_ref = useRef<HTMLElement>(null);
  const gap = useMemo(() => gapByColumn(column), [column]);
  // 한줄에 3개 노출시 small 고정
  const size = column === 3 ? 'small' : card_size;

  const getUseSearchTooltip = (index: number) => {
    if (!search_tooltip_options.is_show) {
      return false;
    }
    if (search_tooltip_options.expose_index_list?.length) {
      return search_tooltip_options.expose_index_list.includes(index);
    }
    return true;
  };

  useIntersectionObserver(
    {
      target: skeleton_footer_ref,
      onIntersect,
      enabled: has_next_page,
      rootMargin: '200px',
    },
    [has_next_page],
  );

  if (is_loading) {
    return (
      <SC.Section margin={margin} padding={padding} css={custom_css}>
        <GridTile column={column} gap={gapByColumn(column)} breakpoint={breakpoint}>
          {[...Array(skeleton_count)].map((_, index) => (
            <ProductCard.Skeleton key={index} size={size} />
          ))}
        </GridTile>
      </SC.Section>
    );
  }

  if (product_list.length === 0) {
    return null;
  }

  return (
    <SC.Section margin={margin} padding={padding} css={custom_css}>
      <GridTile column={column} gap={gap} breakpoint={breakpoint}>
        {product_list.map((product, index) => {
          const product_index = start_index + index;
          return (
            <ProductCard
              {...product}
              ubl={product_ubl}
              index={product_index}
              size={size}
              key={`${product.catalog_product_id},${product_index}`}
              rank={use_rank ? product_index + 1 : undefined}
              use_search={use_search}
              use_search_tooltip={getUseSearchTooltip(product_index)}
              icon_bottom_left={icon_bottom_left}
              onProductClick={onProductClick}
              only_csr={only_csr}
            />
          );
        })}
      </GridTile>
      {has_next_page && (
        <SC.Section ref={skeleton_footer_ref} margin={margin} padding={padding}>
          <GridTile column={column} gap={gapByColumn(column)} breakpoint={breakpoint}>
            {[...Array(2)].map((_, index) => (
              <ProductCard.Skeleton key={index} />
            ))}
          </GridTile>
        </SC.Section>
      )}
    </SC.Section>
  );
};

export default ProductCardList;

function gapByColumn(column_number: number) {
  switch (column_number) {
    case 2:
      return '22px 7px';
    default:
      return '16px 5px';
  }
}

const SC = {
  Section: styled.section<{
    margin: string;
    padding: string;
  }>`
    background-color: ${white};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
  `,
};
