import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { searchProductsByImage } from 'api2';
import { SearchProductsByImageVariables } from 'api2/types';
import { convertProductCard } from 'util/product_util';

type QueryData = Await<ReturnType<typeof searchProductsByImage>>;
type Option = Omit<
  UseQueryOptions<QueryData, unknown, QueryData, Array<string | SearchProductsByImageVariables>>,
  'queryKey' | 'queryFn'
>;

export const PRODUCTS_BY_IMAGE_SEARCH_QUERY_KEY = 'getProductsByImageSearch';

export const useSearchProductsByImage = (payload: SearchProductsByImageVariables, options?: Option) => {
  const {
    data,
    isFetching: is_search_result_fetching,
    ...rest
  } = useQuery([PRODUCTS_BY_IMAGE_SEARCH_QUERY_KEY, payload], () => searchProductsByImage(payload), options);
  const search_result = data?.data.fbk_image_search_product_list.item_list.map(convertProductCard) ?? [];
  return {
    data,
    search_result,
    is_search_result_fetching,
    ...rest,
  };
};
