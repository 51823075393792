import styled from '@emotion/styled';
import Image from 'components/base/Image';
import config from 'util/config';

const md_icon_src = `${config.s3_cf_url}/common/md_pick.png`;

const MdIcon = () => {
  return (
    <SC.Container>
      <Image src={md_icon_src} alt='md픽' layout='fill' objectFit='cover' />
    </SC.Container>
  );
};

export default MdIcon;

const SC = {
  Container: styled.div`
    width: 46px;
    height: 22px;
    border-radius: 0 2px;

    img,
    div {
      // MD pick 임시 대응
      border-radius: 0 2px 0 2px !important;
    }
  `,
};
