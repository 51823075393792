import { IncomingMessage } from 'http';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash-es/flatten';
import { useMemo } from 'react';
import { getProductReviewList } from 'api2';
import { GetProductReviewList, ProductReviewStatus } from 'api2/types';

export const MY_REVIEW_LIST_QUERY_KEY = 'getMyReviewList';

interface FetchProps {
  skip_count: number | null;
  req?: IncomingMessage;
}

export interface OrderProductInfo {
  product_info: {
    image_url: string;
    name: string;
    options: string;
  };
}

export interface MyReviewListProps extends OrderProductInfo {
  review_info: {
    id: string;
    date_created: number;
    display_limited: boolean;
    updatable: boolean;
    status: ProductReviewStatus;
    site_id: string;
  };
}

function uiItemToListProps(item: GetProductReviewList['product_review_list']['item_list'][number]) {
  const list_item: MyReviewListProps = {
    product_info: {
      image_url: item.order_item.product_info.image_url,
      name: item.order_item.product_info.name,
      options: item.order_item.product_info.options,
    },
    review_info: {
      id: item.id,
      date_created: item.date_created,
      display_limited: item.display_limited,
      updatable: item.updatable,
      status: item.status,
      site_id: item.site_id,
    },
  };

  return list_item;
}

export const fetchMyReviewList = async (props: FetchProps) => {
  const { req, skip_count } = props;
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { product_review_list },
  } = await getProductReviewList(
    {
      only_mine: true,
      limit_count: 10,
      skip_count,
    },
    context_request,
  );

  return product_review_list;
};

/**
 * 마이페이지 [리뷰] - 작성한 리뷰 목록 조회
 * @hook
 */
export const useCompletedReviewList = () => {
  const {
    data,
    isInitialLoading: is_loading,
    hasNextPage: has_next_page,
    refetch: refetchCompletedList,
    ...rest
  } = useInfiniteQuery(
    [MY_REVIEW_LIST_QUERY_KEY],
    ({ pageParam }) => {
      return fetchMyReviewList({ skip_count: typeof pageParam === 'number' ? pageParam : 0 });
    },
    {
      getNextPageParam: (last_page, all_pages) => {
        const current_page_list_count = flatten(all_pages.map((value) => value.item_list)).length;
        return last_page.total_count > current_page_list_count ? current_page_list_count : false;
      },
    },
  );

  const total_count = useMemo(() => data?.pages[0]?.total_count ?? 0, [data]);
  const my_review_list = useMemo(() => {
    return flatten(data?.pages.map((value) => value.item_list)).map((item) => uiItemToListProps(item));
  }, [data]);

  return {
    my_review_list,
    total_count,
    is_loading,
    has_next_page,
    refetchCompletedList,
    ...rest,
  };
};
