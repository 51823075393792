import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CategoryItem, ROOT_PRODUCT_CATEGORY } from 'hooks/queries';
/**
 * 신상탭 쿼리 대응
 * @hook
 */
const useNewCategoryQueryString = () => {
  const {
    query: { category, sub_category },
  } = useRouter();

  const category_item: CategoryItem = category ? convertCategory(String(category)) : ROOT_PRODUCT_CATEGORY;
  const sub_category_item: CategoryItem | undefined = sub_category ? convertCategory(String(sub_category)) : undefined;

  const sub_category_id = useMemo(() => {
    if (!sub_category_item) {
      return undefined;
    }

    if (sub_category_item.id === category_item.id) {
      return undefined;
    }
    return sub_category_item.id;
  }, [category_item, sub_category_item]);

  return {
    category: category_item,
    sub_category: sub_category_item,
    category_id: category_item.id,
    sub_category_id,
  };
};
export default useNewCategoryQueryString;

function convertCategory(category_qs: string): CategoryItem {
  const [name, id] = category_qs.split(';');
  return {
    name,
    id,
    icon_url: '',
  };
}

export function convertCategoryRouterProp(category: CategoryItem) {
  return `${category.name};${category.id}`;
}
