import { SerializedStyles } from '@emotion/react';
import { FC, ReactNode } from 'react';
import Row from './Row';
import Section from './Section';
import VirtualList from './VirtualList';
import { ProductCardProps, ProductCardUbl } from 'components/common/ProductCard';

export interface VirtialProductListProps {
  /**
   * 상품카드 클릭시 app 에서도 csr 로 접근가능하도록(새창 안뜨도록하기위함) 설정합니다.
   * @default false
   */
  only_csr?: boolean;
  /**
   * 상품카드 리스트를 그리기 위한 상품 리스트를 설정합니다.
   */
  product_list: ProductCardProps[];
  /**
   * 랭킹을 보여주는 유무를 설정합니다.
   * @default false
   */
  use_rank?: boolean;
  /**
   * 상품카드 section 에 대한 custom margin 을 설정합니다.
   * @default '0'
   */
  margin?: string;
  /**
   * 상품카드 section 에 대한 custom padding 을 설정합니다.
   * @default '8px 8px 24px'
   */
  padding?: string;
  /**
   * 다음페이지 fetching 이 필요한 경우를 설정합니다.
   * @default false
   */
  has_next_page?: boolean;
  /**
   * 검색 아이콘 사용 여부를 설정합니다.
   * @default false
   */
  use_search?: boolean;
  /**
   * 검색 아이콘 툴팁 노출 여부를 설정합니다.
   * @default false
   */
  use_search_tooltip?: boolean;
  /**
   * 스크롤 복원을 위해 각 가상리스트에서 사용할 key를 작성합니다.
   */
  key_name: string;
  /**
   * 상품 ubl에 대한 공통 ubl을 설정합니다.
   */
  product_ubl?: ProductCardUbl;
  /**
   * 시작할 ubl index를 설정 합니다.
   * @default 0
   */
  start_index?: number;
  /**
   * 다음 페이징 호출이 필요한 경우 해당 함수에서 호출합니다.
   */
  onFetchNextPage?: () => void;
  /**
   * 상품카드 클릭시 pdp 이동 말고 추가 callback 액션이 필요한 경우 정의합니다.
   */
  onProductClick?: (catalog_product_id: string, index: number) => void;
  /**
   * Row 컴포넌트의 css를 정의합니다.
   */
  row_css?: SerializedStyles;
  /**
   * 특정 Row Item 뒤에 외부 컴포넌트를 추가할 경우 정의합니다.
   * @param row_index 특정 Row Index
   */
  afterRowItem?: (row_index: number) => ReactNode;
  /**
   * 특정 Row Item 앞에 외부 컴포넌트를 추가할 경우 정의합니다.
   * @param row_index 특정 Row Index
   */
  beforeRowItem?: (row_index: number) => ReactNode;
  /**
   * 리스트의 컬럼 값을 정의합니다.
   * @default 2
   */
  column?: number;
}

interface Props extends VirtialProductListProps {
  /**
   * 상품리스트가 로딩중인지 상태를 설정합니다.
   * @default false
   */
  is_loading?: boolean;
}

/**
 * 상품카드 가상 리스트
 * @component
 */
const ProductCardVirtualList: FC<Props> = (props) => {
  const { product_list, margin, padding, is_loading, row_css, ...virtual_list_props } = props;

  if (is_loading) {
    return (
      <Section margin={margin} padding={padding}>
        <ul>
          {[...Array(12)].map((_, index) => (
            <Row.Skeleton key={index} css={row_css} />
          ))}
        </ul>
      </Section>
    );
  }

  if (product_list.length === 0) {
    return null;
  }

  return (
    <VirtualList
      product_list={product_list}
      margin={margin}
      padding={padding}
      row_css={row_css}
      {...virtual_list_props}
    />
  );
};

export default ProductCardVirtualList;
