import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getShopBestProductList } from 'api2';
import { GetShopBestProductList } from 'api2/types';
import { ProductCardProps } from 'components/toolbox/croquis/ProductCard';

type ProductListItem = NonNullable<GetShopBestProductList['fbk_shop_best_product_list']>[number];

export const SHOP_BEST_PRODUCT_LIST_QUERY_KEY = 'getShopBestProductList';

interface Props {
  shop_id: string;
  current_catalog_product_id: string;
}
function uiItemToCardProps(item: ProductListItem) {
  //@ts-ignore
  const product_card: ProductCardProps = {
    ...item,
    shop: { id: item.shop_id, name: item.shop_name ?? '' },
    url: item.product_url ?? '',
  };
  return product_card;
}

export const fetchShopBestProductList = async (props: Props, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const { data } = await getShopBestProductList(props, context_request);

  return data.fbk_shop_best_product_list.map(uiItemToCardProps);
};

export const useShopBestProductList = ({ shop_id, current_catalog_product_id }: Props) => {
  const { data, ...rest } = useQuery<ProductCardProps[]>(
    [SHOP_BEST_PRODUCT_LIST_QUERY_KEY, `${shop_id}-${current_catalog_product_id}`],
    () => fetchShopBestProductList({ shop_id, current_catalog_product_id }),
  );

  const shop_best_product_list: ProductCardProps[] = data ?? [];

  return {
    shop_best_product_list,
    ...rest,
  };
};
