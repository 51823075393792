import styled from '@emotion/styled';
import { FC } from 'react';
import { IconAlarm24 } from 'components/base/Icon';
import Link from 'components/base/Link';
import { pink600 } from 'styles';
import { typography } from 'styles/font';

interface Props {
  onClick?: () => void;
}
/**
 * 헤더 - 장바구니 아이콘
 * @component
 */
const Alarm: FC<Props> = ({ onClick }) => {
  //TODO: api 아직 미개발
  // const is_show_alarm_red_dot = true;

  return (
    // alarm routes 미정
    <SC.Link href='/' onClick={onClick}>
      <IconAlarm24 />
      {/* {is_show_alarm_red_dot && <SC.Reddot />} */}
    </SC.Link>
  );
};

export default Alarm;

const SC = {
  Link: styled(Link)`
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
  `,
  Reddot: styled.p`
    ${typography.small4_semibold}
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 5px;
    background: ${pink600};
    color: ${pink600};
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    right: -1px;
  `,
};
