import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getProductReview } from 'api2';
import {
  ProductReviewUserAccountInfoStatus,
  ProductReviewStatus,
  GetProductReview,
  ProductReviewAttachmentStatus,
} from 'api2/types';

export const PRODUCT_REVIEW_INFO_QUERY_KEY = 'getProductReviewInfo';

interface ProductInfo {
  id: string;
  image_url: string;
  name: string;
  options: string;
}
export interface ReviewStatus {
  id: string;
  status: ProductReviewStatus;
  user_account_status: ProductReviewUserAccountInfoStatus;
  delete_info: NonNullable<GetProductReview['product_review']>['delete_info'] | null;
  display_limited: boolean;
  updatable: boolean;
}

export const fetchProductReviewInfo = async (id: string, all_attachment_status: boolean, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const attachment_status_list = all_attachment_status
    ? [ProductReviewAttachmentStatus.DELETED_BY_ADMIN, ProductReviewAttachmentStatus.NORMAL]
    : [ProductReviewAttachmentStatus.NORMAL];

  const {
    data: { product_review },
  } = await getProductReview({ id, attachment_status_list }, context_request);

  return product_review;
};

/**
 * 상품의 특정 리뷰 정보 조회
 * @hook
 */
export const useReviewInfo = (review_id: string, all_attachment_status: boolean) => {
  const { data, ...rest } = useQuery([PRODUCT_REVIEW_INFO_QUERY_KEY, review_id], () =>
    fetchProductReviewInfo(review_id, all_attachment_status),
  );

  const product_info: ProductInfo = useMemo(() => {
    return {
      id: data?.catalog_product_id ?? '',
      image_url: data?.order_item.product_info.image_url ?? '',
      name: data?.order_item.product_info.name ?? '',
      options: data?.order_item.product_info.options ?? '',
    };
  }, [data]);

  const review_status: ReviewStatus = useMemo(() => {
    return {
      id: data?.id ?? '',
      status: data?.status ?? ProductReviewStatus.NORMAL,
      user_account_status: data?.user_account_info.status ?? ProductReviewUserAccountInfoStatus.NORMAL,
      delete_info: data?.delete_info ?? null,
      display_limited: data?.display_limited ?? false,
      updatable: data?.updatable ?? false,
    };
  }, [data]);

  const review_attachmnet_list = useMemo(() => {
    return data
      ? data.attachment_list.map((item) => {
          return {
            product_review_id: item.product_id,
            original_url: item.original_url,
            thumbnail_url: item.thumbnail_url,
            status: item.status,
          };
        })
      : [];
  }, [data]);

  return {
    is_loading: rest.isInitialLoading,
    is_fetching: rest.isFetching,
    product_info,
    review_status,
    review_attachmnet_list,
    review_list: data && !rest.isError ? [data] : [],
    ...rest,
  };
};
