import { IncomingMessage } from 'http';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getProductEstimateShippingInfo, getFbkProductInfo } from 'api2';
import {
  GetFbkProductInfo,
  PdpCatalogDisplayStatus,
  PdpCatalogPaymentType,
  PdpCatalogProductType,
  PdpCatalogSalesStatus,
  PdpCouponAvailableStatus,
  PdpShopType,
  GetProductEstimateShippingInfo,
  MinimumOrderQuantityType,
} from 'api2/types';

export type ProductInfo = NonNullable<GetFbkProductInfo['fbk_catalog_product_base_info']>['product'] &
  NonNullable<GetProductEstimateShippingInfo['catalog_product_estimate_shipping_info']>['product'];
export type ShopInfo = NonNullable<GetFbkProductInfo['fbk_catalog_product_base_info']>['shop'];
export type ProductDiscountInfo = ProductInfo['discount_info'];
export type ProductPriceInfo = ProductInfo['product_price'];
export type ProductImageList = ProductInfo['product_image_list'];
export type PolicyList = NonNullable<GetFbkProductInfo['fbk_catalog_product_base_info']>['policy_list'];
export type ProductShippingEstimateInfo = NonNullable<ProductInfo['estimated_shipping_date']>['estimate_list'];
export type ProductSellerNotice = NonNullable<GetFbkProductInfo['fbk_catalog_product_base_info']>['seller_notice'];
export type ShopAttributeInfo = GetFbkProductInfo['shop_attribute_info'];
export type ProductDeliverySummary = GetFbkProductInfo['product_delivery_summary'];
export type DeliveryEstimateSummary = ProductDeliverySummary['delivery_estimate_summary'];
export type DeliveryEstimateSummaryList = NonNullable<DeliveryEstimateSummary>['estimate_list'];
export type ProductReviewSummary = NonNullable<GetFbkProductInfo>['product_review_pdp_summary'];
export type ProductReviewSummaryList = NonNullable<ProductReviewSummary>['item_list'];
export type ProductReviewSummaryAttribute = NonNullable<ProductReviewSummary>['attribute'];

export interface CatalogProductBaseInfoData {
  policy_list: PolicyList;
  shop_attribute_info: ShopAttributeInfo;
  product_delivery_summary: ProductDeliverySummary;
  product: ProductInfo;
  shop: ShopInfo;
  seller_notice: ProductSellerNotice;
  product_review_pdp_summary: ProductReviewSummary;
}

export const PRODUCT_BASE_INFO_QUERY_KEY = 'getCatalogProductBaseInfo';

export async function fetchProductBaseInfo(catalog_product_id: string, req?: IncomingMessage) {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const [
      {
        data: {
          fbk_catalog_product_base_info,
          shop_attribute_info,
          product_delivery_summary,
          product_review_pdp_summary,
        },
      },
      {
        data: { catalog_product_estimate_shipping_info },
      },
    ] = await Promise.all([
      getFbkProductInfo({ catalog_product_id }, context_request),
      getProductEstimateShippingInfo({ catalog_product_id }, context_request),
    ]);
    if (!fbk_catalog_product_base_info || !catalog_product_estimate_shipping_info) {
      throw Error('failed to fetch');
    }
    return {
      shop_attribute_info,
      product_delivery_summary,
      product: {
        ...fbk_catalog_product_base_info.product,
        ...catalog_product_estimate_shipping_info.product,
      },
      policy_list: fbk_catalog_product_base_info.policy_list,
      shop: fbk_catalog_product_base_info.shop,
      seller_notice: fbk_catalog_product_base_info.seller_notice,
      product_review_pdp_summary,
    };
  } catch (e) {
    console.error(e);
  }
}
export const useProductBaseInfo = () => {
  const query_client = useQueryClient();
  const {
    query: { catalog_product_id },
  } = useRouter();
  const {
    data,
    isSuccess: is_success,
    isFetching: is_fetching,
    isError: is_error,
    ...rest
  } = useQuery([PRODUCT_BASE_INFO_QUERY_KEY, catalog_product_id], () =>
    fetchProductBaseInfo(String(catalog_product_id)),
  );

  const is_data = !!data?.shop && !!data.product;
  const base_info = setData(data);

  const is_fast_delivery = useMemo(
    () => base_info.product.shipping_send_date?.fast_shipping?.is_fast_type,
    [base_info.product.shipping_send_date],
  );

  const best_review_id_list = useMemo(
    () => base_info.product_review_pdp_summary?.item_list.map((review) => review.id),
    [base_info.product_review_pdp_summary],
  );

  const updateDownloadCouponStatus = () => {
    query_client.setQueryData<CatalogProductBaseInfoData>([PRODUCT_BASE_INFO_QUERY_KEY, catalog_product_id], (prev) => {
      if (prev) {
        prev.product.coupon_available_status = PdpCouponAvailableStatus.COUPON_ISSUED;
      }
      return prev;
    });
  };

  const is_order_quantity_product_type =
    base_info.product.minimum_order_quantity_type === MinimumOrderQuantityType.PRODUCT;
  const is_order_quantity_item_type = base_info.product.minimum_order_quantity_type === MinimumOrderQuantityType.ITEM;

  return {
    is_success,
    is_fetching,
    is_error,
    is_data,
    is_fast_delivery,
    best_review_id_list,
    is_order_quantity_product_type,
    is_order_quantity_item_type,
    ...base_info,
    ...rest,
    updateDownloadCouponStatus,
  };
};

const setData = (data?: CatalogProductBaseInfoData): CatalogProductBaseInfoData => {
  return {
    policy_list: data?.policy_list || [],
    shop_attribute_info: data?.shop_attribute_info || null,
    product_delivery_summary: {
      has_delivery_delayed_log: data?.product_delivery_summary.has_delivery_delayed_log || false,
      product_shipping_type: data?.product_delivery_summary.product_shipping_type || null,
      delivery_estimate_summary: data?.product_delivery_summary.delivery_estimate_summary || null,
    },
    product: {
      id: data?.product.id || '',
      name: data?.product.name || '',
      category_list: data?.product.category_list || [],
      product_url: data?.product.product_url || '',
      description: data ? data.product.description : null,
      essentials: data ? data.product.essentials : [],
      product_type: data?.product.product_type || PdpCatalogProductType.GENERAL,
      payment_type: data?.product.payment_type || PdpCatalogPaymentType.EXTERNAL,
      shop_id: data?.product.shop_id || 0,
      shop_main_domain: data?.product.shop_main_domain || '',
      preparation_period: data ? data.product.preparation_period : null,
      sales_status: data?.product.sales_status || PdpCatalogSalesStatus.ON_SALE,
      display_status: data?.product.display_status || PdpCatalogDisplayStatus.HIDDEN,
      is_saved_product: data?.product.is_saved_product || false,
      product_image_list: data?.product.product_image_list || [],
      estimated_shipping_date: data ? data.product.estimated_shipping_date : null,
      zonly_estimate_shipping_info: data?.product.zonly_estimate_shipping_info || null,
      product_price: {
        discount_price: data?.product.product_price.discount_price || 0,
        discount_amount: data?.product.product_price.discount_amount || 0,
        discount_rate: data?.product.product_price.discount_rate || 0,
        original_price: data?.product.product_price.original_price || 0,
        final_price: data?.product.product_price.final_price || 0,
        zpay_discount_amount: data ? data.product.product_price.zpay_discount_amount : null,
        max_price: data?.product.product_price.max_price || 0,
        coupon_discount_amount: data ? data.product.product_price.coupon_discount_amount : null,
      },
      promotion_message: data?.product.promotion_message || '',
      discount_info: data ? data.product.discount_info : null,
      coupon_available_status: data?.product.coupon_available_status || PdpCouponAvailableStatus.COUPON_UNAVAILABLE,
      minimum_order_quantity: data?.product.minimum_order_quantity || 0,
      minimum_order_quantity_type: data?.product.minimum_order_quantity_type || null,
      maximum_order_quantity: data ? data.product.maximum_order_quantity : null,
      category_key: data?.product.category_key || null,
      shipping_send_date: data?.product.shipping_send_date || null,
      shipping_fee: data?.product.shipping_fee || null,
    },
    shop: {
      id: data?.shop.id || '',
      main_domain: data?.shop.main_domain || '',
      name: data?.shop.name || '',
      url: data?.shop.url || '',
      keywords: data?.shop.keywords || '',
      typical_image_url: data ? data.shop.typical_image_url : null,
      bookmark_count: data ? data.shop.bookmark_count : null,
      is_saved_shop: data?.shop.is_saved_shop || false,
      trait: {
        category: data ? data.shop.trait.category : null,
        category_list: data ? data.shop.trait.category_list : null,
        style: data ? data.shop.trait.style : null,
        minor_style: data ? data.shop.trait.minor_style : null,
        minor_category: data ? data.shop.trait.minor_category : null,
        minor_category_list: data ? data.shop.trait.minor_category_list : null,
        age10: data ? data.shop.trait.age10 : null,
        age20early: data ? data.shop.trait.age20early : null,
        age20mid: data ? data.shop.trait.age20mid : null,
        age20late: data ? data.shop.trait.age20late : null,
        age30early: data ? data.shop.trait.age30early : null,
        age30mid: data ? data.shop.trait.age30mid : null,
        age30late: data ? data.shop.trait.age30late : null,
      },
      customer_center_tel: data ? data.shop.customer_center_tel : null,
      customer_center: data ? data.shop.customer_center : null,
      main_contact: data ? data.shop.main_contact : null,
      business_license: data ? data.shop.business_license : null,
      shop_business_info: data?.shop.shop_business_info || null,
      online_sales_license: data?.shop.online_sales_license || null,
      shipping_address: data?.shop.shipping_address || null,
      return_address: data?.shop.return_address || null,
      shipping_company1: data?.shop.shipping_company1 || null,
      shipping_company2: data ? data.shop.shipping_company2 : null,
      type: data?.shop.type || PdpShopType.META,
      zpay_status: data?.shop.zpay_status || null,
      return_shipping_company: data?.shop.return_shipping_company || null,
      shop_category_list: data?.shop.shop_category_list || [],
    },
    seller_notice: data?.seller_notice || null,
    product_review_pdp_summary: data?.product_review_pdp_summary || null,
  };
};
