import styled from '@emotion/styled';
import { dehydrate, DehydratedState, QueryClient, Hydrate } from '@tanstack/react-query';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import isNil from 'lodash-es/isNil';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { getImageSearchPresetProducts } from 'api2';
import PageLayout from 'components/common/Layout';
import PageHeadMeta from 'components/common/PageHeadMeta';
import TopButton from 'components/common/TopButton';
import Share from 'components/events/detail/Share';
import { IMAGE_SEARCH_PRESET_PRODUCTS_QUERY_KEY } from 'components/search/hooks/useImageSearchPresetProducts';
import ImageSearchMain from 'components/search/image/main';
import Header from 'components/search/image/main/Header';
import ImageSearchResult from 'components/search/image/result';
import { current_search_image_atom, pop_image_search_history_atom } from 'components/search/image/store';
import { white } from 'styles';
import config from 'util/config';

export enum ImageSearchType {
  upload = 'image_upload',
  catalog = 'catalog',
}

interface Props {
  dehydratedState: DehydratedState;
  direct: boolean;
}

/**
 * 이미지 검색 페이지 입니다.
 * query parameter 는 아래와 같습니다.
 * {
 *   search_type: ImageSearchType // 검색 유형 구분
 *   catalog_product_id: string // 유사상품 검색일 경우 상품 아이디
 * }
 * 이미지 데이터는 store 로 전달 받습니다.
 */
const ImageSearchPage: NextPage<Props> = ({ direct }) => {
  const current_image = useAtomValue(current_search_image_atom);
  const popImageSearchHistory = useUpdateAtom(pop_image_search_history_atom);
  const router = useRouter();
  const { search_type, catalog_product_id } = router.query;
  const cropped_image = isNil(current_image) || isNil(current_image.cropped) ? '' : current_image.cropped;

  useEffect(() => {
    // 뒤로가기 시 이미지 검색 상태에서 마지막 이미지를 pop
    window.addEventListener('popstate', popImageSearchHistory);
    return () => window.removeEventListener('popstate', popImageSearchHistory);
  }, [popImageSearchHistory]);

  const is_searchable = useMemo(() => {
    switch (search_type) {
      case ImageSearchType.upload:
        return !!cropped_image;
      case ImageSearchType.catalog:
        return !!catalog_product_id;
      default:
        return false;
    }
  }, [search_type, cropped_image, catalog_product_id]);

  return (
    <SC.Page>
      <PageHeadMeta title='이미지 검색' og={{ path: '/search/image' }} />
      <Header is_searchable={is_searchable} is_direct={direct} />
      {!is_searchable && <ImageSearchMain />}
      {is_searchable && (
        <ImageSearchResult
          search_type={search_type as ImageSearchType}
          source_image={cropped_image}
          catalog_product_id={catalog_product_id as string}
        />
      )}
      <SC.TopButton ubl_navigation='image_search' />
      {search_type === ImageSearchType.catalog && (
        <Share
          event_title={`${config.site_name} | 이미지 검색`}
          event_image_url={`${config.appUrl}/logo.png`}
          event_description='이제 패션도 카카오에서'
          share_url={getShareUrl()}
          ubl_navigation='image_search'
        />
      )}
    </SC.Page>
  );
};

ImageSearchPage.getLayout = (page) => {
  return (
    <PageLayout>
      <Hydrate state={page.props.dehydratedState}>{page}</Hydrate>
    </PageLayout>
  );
};

ImageSearchPage.getInitialProps = async ({ req, query }) => {
  const client = new QueryClient();
  const is_server_side = !!req;
  const direct = query.direct?.toString() === 'true';

  if (is_server_side) {
    await client.prefetchQuery(IMAGE_SEARCH_PRESET_PRODUCTS_QUERY_KEY, () =>
      getImageSearchPresetProducts(undefined, { context: req }),
    );
  }
  return {
    dehydratedState: dehydrate(client),
    direct,
  };
};

export default ImageSearchPage;

function getShareUrl() {
  if (typeof window === 'undefined') {
    return '';
  }
  const current_url = decodeURIComponent(window.location.href);
  const direct_str = '&direct=true';

  return current_url.includes(direct_str) ? current_url : current_url + direct_str;
}

const SC = {
  Page: styled.main`
    padding-bottom: env(safe-area-inset-bottom);
    background-color: ${white};
  `,
  TopButton: styled(TopButton)`
    bottom: 20px;
  `,
};
