import CircleCategories, { CircleCategory } from 'components/base/CircleCategories';
import { CategoryItem, useActivatedDisplayCategory } from 'hooks/queries';
import config from 'util/config';

/**
 * 키즈관 - 카테고리
 * @component
 */
const Category = () => {
  const { product_category_list, is_loading } = useActivatedDisplayCategory();

  const kid_categories: CircleCategory[] = getKidCategories(product_category_list);

  return (
    <section>
      <CircleCategories use_filter={false} categories={kid_categories} is_loading={is_loading} />
    </section>
  );
};

export default Category;

function getKidCategories(category_list: CategoryItem[]): CircleCategory[] {
  const kid_root_category = category_list.find((category) => category.id === config.kid_category_id);

  if (!kid_root_category?.sub_category_list) {
    return [];
  }

  return kid_root_category.sub_category_list.map((category) => {
    return {
      title: category.name,
      link: `/categories/${kid_root_category.id}?sub_category_id=${category.id}&hide_one_depth_category=true`,
      img_url: category.icon_url,
      ubl: {
        navigation: 'kids_main',
        object_sectionL: 'category_list',
        object_type: 'category',
        object_id: category.name,
      },
    };
  });
}
