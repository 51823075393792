import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getBoostShopCouponPolicy } from 'api2';

export const BOOST_SHOP_COUPON_QUERY_KEY = 'getBoostShopCoupon';

export const fetchBoostShopCouponPolicy = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const { data } = await getBoostShopCouponPolicy(undefined, context_request);
  return data.fbk_boost_shop_coupon_policy;
};

export const useBoostShopCouponPolicy = () => {
  const {
    data,
    isLoading: is_loading,
    ...rest
  } = useQuery([BOOST_SHOP_COUPON_QUERY_KEY], () => fetchBoostShopCouponPolicy());

  return { boost_shop_coupon: data, is_loading, ...rest };
};
