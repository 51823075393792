import { atom } from 'jotai';

export const IS_SHOW_LIKE_TAB_NEW_BADGE_KEY = 'IS_SHOW_LIKE_TAB_NEW_BADGE';

const is_show_like_tab_new_badge_init = atom<boolean>(false);

export const is_show_like_tab_new_badge_atom = atom(
  (get) => get(is_show_like_tab_new_badge_init),
  (_, set, is_show: boolean) => {
    set(is_show_like_tab_new_badge_init, is_show);
    localStorage.setItem(IS_SHOW_LIKE_TAB_NEW_BADGE_KEY, JSON.stringify(is_show));
  },
);
