import { isBrowser } from '@croquiscom/web2app';

const STORE_KEY = 'STORE_LIKE_INFO';

export interface StoreLikeInfo {
  shop_id: string;
  is_like: boolean;
}

export const setClientStoreLike = (store_like_info: StoreLikeInfo) => {
  if (isBrowser() && typeof localStorage !== 'undefined') {
    const store_like_list: StoreLikeInfo[] = JSON.parse(localStorage.getItem(STORE_KEY) || '[]');

    if (store_like_list.length > 0) {
      const list_info = [...store_like_list];
      const index = list_info.findIndex((i) => i.shop_id === store_like_info.shop_id);

      if (index >= 0) {
        list_info[index].is_like = store_like_info.is_like;
      } else {
        list_info.push(store_like_info);
      }
      localStorage.setItem(STORE_KEY, JSON.stringify(list_info));
    } else {
      localStorage.setItem(STORE_KEY, JSON.stringify([{ ...store_like_info }]));
    }
  }
};

export const getClientStoreLike = (shop_id: string) => {
  if (isBrowser() && typeof localStorage !== 'undefined') {
    try {
      const store_like_list: StoreLikeInfo[] = JSON.parse(localStorage.getItem(STORE_KEY) || '[]');
      return store_like_list.find((store_like) => store_like.shop_id === shop_id);
    } catch {
      return false;
    }
  } else {
    return false;
  }
};

export const removeClientStoreLikeInfo = () => {
  localStorage.removeItem(STORE_KEY);
};
