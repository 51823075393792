import styled from '@emotion/styled';
import React, { forwardRef } from 'react';

export interface RatioProps {
  width?: number;
  height?: number;
  aspectRatio?: number;
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const Ratio = forwardRef<HTMLDivElement, RatioProps>(
  ({ width = 1, height = 1, aspectRatio, children, className, onClick }, ref) => {
    const ratio = Math.floor((height / width) * 100);
    const aspect_ratio = aspectRatio ? aspectRatio : ratio;
    return (
      <RatioBox className={className} ratio={aspect_ratio} ref={ref} onClick={onClick}>
        <RatioInner>{children}</RatioInner>
      </RatioBox>
    );
  },
);

export default Ratio;
interface RatioBoxProps {
  ratio: number;
}

const RatioBox = styled.div<RatioBoxProps>`
  position: relative;
  margin-bottom: -0.6px;
  padding-bottom: ${({ ratio }) => ratio}%;
  width: 100%;
  overflow: hidden;
`;

const RatioInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`;
