import styled from '@emotion/styled';
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from 'components/base/Image';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Ratio from 'components/toolbox/croquis/core/Ratio';
import { useFbkRouter } from 'hooks';
import { gray050, gray200 } from 'styles';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';

const LIVE_REG = '/lives/';

type BANNER_SIZE = 'medium' | 'large';

const BANNER_SIZE_INFO: Record<
  BANNER_SIZE,
  {
    width: number;
    height: number;
  }
> = {
  medium: {
    width: 375,
    height: 160,
  },
  large: {
    width: 375,
    height: 212,
  },
};

const SPLIDE_OPTIONS: Options = {
  type: 'loop',
  autoplay: true,
  interval: 4000,
  lazyLoad: true,
  arrows: false,
  pagination: false,
};

export interface BannerItem {
  image_url: string;
  alt: string;
  link: string;
  id: string;
}

interface Props {
  /**
   * 배너 리스트를 설정 합니다.
   * @default []
   */
  banner_list?: BannerItem[];
  /**
   * 배너 사이즈를 설정합니다.
   * @default 'large'
   */
  size?: BANNER_SIZE;
  /**
   * 로딩 여부를 설정합니다.
   * @default false
   */
  is_loading?: boolean;
  /**
   * 배경 레이어 투명도를 설정합니다.
   * @default 0.01
   */
  bg_transparency?: number;
  /**
   * 전체보기 클릭시 이벤트를 지정합니다.
   */
  onAllViewClick?: () => void;
  /**
   * 배너 클릭시 이벤트를 정의 합니다.
   */
  onBannerClick?: (banner: BannerItem, index: number) => void;
  /**
   * 배너가 viewport에 보이는 순간 호출 합니다. (impression)
   */
  onViewBanner?: (banner: BannerItem, index: number) => void;
}
/**
 * 공통 - 배너 컴포넌트
 * @component
 */
const Banners: FC<Props> = ({
  size = 'large',
  banner_list = [],
  is_loading,
  bg_transparency = 0.01,
  onViewBanner,
  onBannerClick,
  onAllViewClick,
}) => {
  const [current_index, setCurrentIndex] = useState(0);
  const { ref: banner_ref, inView: in_view } = useInView({ threshold: 0.5 });
  const { goLive } = useFbkRouter();
  const banner_size = BANNER_SIZE_INFO[size];

  useEffect(() => {
    if (in_view && banner_list.length > 0) {
      onViewBanner?.(banner_list[current_index], current_index);
    }
  }, [current_index, banner_list, in_view, onViewBanner]);

  const handleBannerClick = (banner: BannerItem, index: number) => {
    const is_live_banner = banner.link.startsWith(LIVE_REG);

    if (is_live_banner) {
      const campaign_key = banner.link.split(LIVE_REG)[1];
      goLive(campaign_key);
    }
    onBannerClick?.(banner, index);
  };

  if (is_loading) {
    return (
      <SC.Container min_height={banner_size.height}>
        <Ratio {...banner_size}>
          <Skeleton />
        </Ratio>
      </SC.Container>
    );
  }

  const is_single_banner = banner_list.length === 1;
  // 단일 배너일 경우: 모두보기 버튼 비노출, splide 미적용
  if (is_single_banner) {
    const banner = banner_list[0];
    const banner_link = banner.link.startsWith(LIVE_REG) ? '' : banner.link;
    return (
      <SC.Container ref={banner_ref} min_height={banner_size.height}>
        <Ratio className='banner' {...banner_size}>
          <SC.BannerLink
            show_new_webview_for_legacy
            href={banner_link}
            bg_transparency={bg_transparency}
            onClick={() => handleBannerClick(banner, 0)}
          >
            <Image layout='fill' objectFit='cover' src={banner.image_url} alt={banner.alt} use_fade />
          </SC.BannerLink>
        </Ratio>
      </SC.Container>
    );
  }

  return (
    <SC.Container ref={banner_ref} min_height={banner_size.height}>
      <Ratio className='banner' {...banner_size}>
        <Splide
          options={SPLIDE_OPTIONS}
          onMove={(_, newIndex) => {
            setCurrentIndex(newIndex);
          }}
        >
          {banner_list.map((banner, index) => {
            const banner_link = banner.link.startsWith(LIVE_REG) ? '' : banner.link;
            return (
              <SplideSlide key={banner.id}>
                <SC.BannerLink
                  show_new_webview_for_legacy
                  href={banner_link}
                  bg_transparency={bg_transparency}
                  onClick={() => handleBannerClick(banner, index)}
                >
                  <Image layout='fill' objectFit='cover' src={banner.image_url} alt={banner.alt} />
                </SC.BannerLink>
              </SplideSlide>
            );
          })}
        </Splide>
        <SC.Paging className='custom-wrapper'>
          <Text variant='small1_medium' color='white' mr={2}>
            {current_index + 1}
          </Text>
          <Text variant='small1_regular' color='gray200' mr={2}>
            /
          </Text>
          <Text variant='small1_regular' color='gray200' mr={2}>
            {banner_list.length}
          </Text>
          <Text variant='small1_medium' color='gray200' onClick={onAllViewClick}>
            모두보기
          </Text>
        </SC.Paging>
      </Ratio>
    </SC.Container>
  );
};

const SC = {
  Container: styled.section<{ min_height: number }>`
    width: 100%;
    height: 100%;
    background-color: ${gray050};
    position: relative;
    cursor: pointer;

    .banner {
      min-height: ${({ min_height }) => min_height}px;
    }

    .splide {
      width: 100%;
      height: 100%;
    }

    .splide__track {
      width: 100%;
      height: 100%;
    }
  `,
  Paging: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 14px;
    right: 14px;
    left: auto;
    padding: 4px 10px 5px;
    height: 26px;
    width: auto;
    background-color: rgba(36, 36, 36, 0.6);
    backdrop-filter: blur(2px);
    color: ${gray200};
    border-radius: 70px;
    z-index: 1;
  `,
  BannerLink: styled(Link)<{ bg_transparency: number }>`
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, ${({ bg_transparency }) => bg_transparency});
    }
  `,
};

export default Banners;
