import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getBestProductRankingList } from 'api2';
import { ProductCardProps } from 'components/common/ProductCard';
import { convertProductList } from 'util/product_util';

export const BEST_PRODUCT_RANKING_LIST_QUERY_KEY = 'getBestProductRankingList';

export const fetchBestProductRankingList = async (display_category_id?: string, req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { best_product_ranking_list },
    } = await getBestProductRankingList({ display_category_id }, context_request);

    if (isNil(best_product_ranking_list)) {
      return [];
    }

    const { item_list } = best_product_ranking_list;

    return convertProductList(item_list);
  } catch {
    return [];
  }
};

export const useBestProductRankingList = (display_category_id?: string) => {
  const {
    data,
    isLoading: is_loading,
    isSuccess: is_success,
    ...rest
  } = useQuery<ProductCardProps[]>([BEST_PRODUCT_RANKING_LIST_QUERY_KEY, display_category_id], () =>
    fetchBestProductRankingList(display_category_id),
  );

  return {
    best_product_list: data ?? [],
    is_loading,
    is_success,
    ...rest,
  };
};
