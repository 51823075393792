import { cx } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai/utils';
import NextImage, { ImageProps } from 'next/legacy/image';
import { FC, useState } from 'react';
import { IconLoading60 } from '../Icon';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import { bg_secondary } from 'styles';

export interface Prop extends ImageProps {
  wrapper_css?: SerializedStyles;
  wrapper_classname?: string;
  use_fade?: boolean;
  image_placeholder?: boolean;
  onLoaded?: () => void;
}

/**
 * 공통 Image 컴포넌트 (Next/Image)
 * @component
 * @description
 * unoptimized 최적화는 비용문제로 고정
 * https://vercel.com/docs/concepts/image-optimization/limits-and-pricing#pricing
 */
const Image: FC<Prop> = ({
  wrapper_css,
  wrapper_classname,
  use_fade = false,
  image_placeholder = false,
  onLoaded,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const { is_ios, is_mobile } = useAtomValue(device_info_atom, global_scope);

  // IOS Next/Image fade-in 지연 이슈로 애니메이션 효과 제거
  const fade = use_fade && !(is_ios && is_mobile);
  const img_class_name = cx([rest.className, { fade }, fade && { in: loaded }]);

  const handleLoaded = () => {
    setLoaded(true);
    onLoaded && onLoaded();
  };

  return (
    <SC.Container css={wrapper_css} className={wrapper_classname}>
      <NextImage
        unoptimized
        {...rest}
        className={img_class_name}
        onLoadingComplete={handleLoaded}
        onError={handleLoaded}
      />
      {image_placeholder && !loaded && (
        <SC.StyledLoading>
          <IconLoading60 />
        </SC.StyledLoading>
      )}
    </SC.Container>
  );
};

export default Image;

const SC = {
  Container: styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    span {
      position: unset !important;
      height: 100% !important;
    }

    span > img {
      position: relative !important;
      height: auto !important;

      &.fade {
        opacity: 0;
        transition: 0.3s opacity ease-in-out;
      }

      &.fade.in {
        opacity: 1;
      }
    }
  `,
  StyledLoading: styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${bg_secondary};
  `,
};
