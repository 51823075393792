import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import {
  checked_coupon_discount_atom,
  checked_point_discount_atom,
  checked_payment_id_atom,
} from '../MaximumBenefit/store';
import { useProductBaseInfo } from './useProductBaseInfo';
import { useProductMaxBenefit } from './useProductMaxBenefit';

/**
 * 상품의 최대 혜택을 계산하는 hook
 * @hooks
 */
export const useMaxBenefitCalculate = () => {
  const { is_fetching: is_base_info_loading, product } = useProductBaseInfo();
  const {
    is_loading: is_max_benefit_loading,
    max_coupon_discount,
    user_account_point,
    payment_benefit_list,
  } = useProductMaxBenefit();

  const checked_coupon_discount = useAtomValue(checked_coupon_discount_atom);
  const checked_point_discount = useAtomValue(checked_point_discount_atom);
  const checked_payment_id = useAtomValue(checked_payment_id_atom);

  const coupon_discount_amount = useMemo(() => {
    if (!checked_coupon_discount) {
      return 0;
    }
    return max_coupon_discount?.discount_amount ?? 0;
  }, [checked_coupon_discount, max_coupon_discount]);

  const user_account_available_point = useMemo(() => user_account_point?.available_point ?? 0, [user_account_point]);

  const product_discount_info = useMemo(() => {
    const { original_price, zpay_discount_amount, discount_amount } = product.product_price;

    return {
      original_price,
      discount_amount,
      zpay_discount_amount: Number(zpay_discount_amount),
      total_discount_amount: discount_amount + Number(zpay_discount_amount),
    };
  }, [product.product_price]);

  const used_point_amount = useMemo(() => {
    if (!checked_point_discount) {
      return 0;
    }

    const product_coupon_discount_price =
      product_discount_info.original_price - (product_discount_info.total_discount_amount + coupon_discount_amount);

    if (product_coupon_discount_price < user_account_available_point) {
      return product_coupon_discount_price;
    }
    return user_account_available_point < 100 ? 0 : user_account_available_point;
  }, [checked_point_discount, product_discount_info, coupon_discount_amount, user_account_available_point]);

  const minimum_price = useMemo(() => {
    return (
      product_discount_info.original_price -
      (product_discount_info.total_discount_amount + coupon_discount_amount + used_point_amount)
    );
  }, [product_discount_info, coupon_discount_amount, used_point_amount]);

  const payment_discount_amount = useMemo(
    () => payment_benefit_list.find((payment) => payment?.id === checked_payment_id)?.discount_amount ?? 0,
    [payment_benefit_list, checked_payment_id],
  );

  const used_payment_list = useMemo(() => {
    return payment_benefit_list
      .filter((payment) => payment && payment.min_amount <= minimum_price)
      .sort((a, b) => Number(b?.discount_amount) - Number(a?.discount_amount));
  }, [payment_benefit_list, minimum_price]);

  const used_maximum_benefit_type = {
    is_zpay_discount: product_discount_info.zpay_discount_amount > 0,
    is_discount: product_discount_info.discount_amount > 0,
    is_coupon: !!max_coupon_discount,
    is_point: user_account_available_point >= 100,
    is_checkout: payment_discount_amount > 0,
  };

  const final_price = minimum_price - payment_discount_amount;

  return {
    is_calculating: is_base_info_loading || is_max_benefit_loading,
    product_discount_info,
    user_account_available_point,
    max_coupon_discount,
    used_point_amount,
    used_payment_list,
    final_price,
    final_rate: getMaxDiscountRate(product_discount_info.original_price, final_price),
    used_maximum_benefit_type,
  };
};

function getMaxDiscountRate(original_price: number, minimum_price: number) {
  const rate = ((original_price - minimum_price) / original_price) * 100;
  const round_rate = Math.round(rate);

  if (round_rate < 1) {
    return null;
  }
  if (round_rate > 100) {
    return 100;
  }
  return round_rate;
}
