import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { getPopularDisplayCategoryList } from 'api2';

export const GET_POPULAR_CATEGORY_LIST_QUERY = 'getPopularCategoryListQuery';

export const fetchPopularCategoryList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { fbk_popular_display_category_list },
  } = await getPopularDisplayCategoryList(undefined, context_request);

  return fbk_popular_display_category_list;
};

/**
 * 많이 찾는 카테고리 리스트 조회 hook
 * @hook
 */
export const usePopularCategoryList = () => {
  const {
    data,
    isSuccess: is_success,
    isLoading: is_loading,
    ...rest
  } = useQuery([GET_POPULAR_CATEGORY_LIST_QUERY], () => fetchPopularCategoryList());

  return {
    date_updated: data?.date_updated ?? undefined,
    popular_category_list: data?.item_list ?? [],
    is_loading,
    is_success,
    ...rest,
  };
};
