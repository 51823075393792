import { ContentReplyModuleProps } from '@croquiscom-pvt/event-modules';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, useMemo } from 'react';
import { ContentReplyDataItem } from './ContentReplyDataItem';
import { ContentReplyInput, ContentReplyInputProps } from './ContentReplyInput';
import { ContentReplyMoreButton } from './ContentReplyMoreButton';
import {
  useEventContentReplyListInfiinit,
  useDeleteReplyMutation,
  useEventContentReplyMetadata,
  useCreateReplyMutation,
} from './hooks';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { useUserInfo } from 'components/my-page/hooks/useUserInfo';
import { useIsAuthenticated } from 'hooks';
import { colors } from 'styles';

export const ContentReply = (props: ContentReplyModuleProps & { is_summary: boolean }) => {
  const { event_content_reply_metadata_uuid, is_summary = true } = props;
  const { is_login } = useUserInfo();
  const { openLoginDialog } = useLoginDialog();
  const { replies, loadMoreRef, isFetched } = useEventContentReplyListInfiinit(
    event_content_reply_metadata_uuid,
    is_summary,
  );
  const handleDeleteReply = useDeleteReplyMutation();
  const input_props = useInputProps(props);
  const createReply = useCreateReplyMutation(event_content_reply_metadata_uuid);
  const handleRedirectAuth = () => {
    if (!is_login) {
      openLoginDialog();
    }
  };

  return (
    <Fragment>
      <ContentReplyInput {...input_props} onSubmit={createReply} onRedirectAuth={handleRedirectAuth} />
      <ReplieList is_summary={is_summary}>
        {isFetched &&
          replies.map((props) => (
            <ContentReplyDataItem
              key={`${props.id} ${props.status}`}
              data={props}
              handleDeleteReply={handleDeleteReply}
            />
          ))}
      </ReplieList>
      {isFetched && replies.length === 0 && (
        <Empty>
          <EmptyTitle>댓글 없음</EmptyTitle>
          <EmptySubTitle>댓글을 남겨보세요</EmptySubTitle>
        </Empty>
      )}
      <InfiniteQueryIntersectionObserver ref={loadMoreRef} />
      {is_summary && replies.length > 3 && (
        <ContentReplyMoreButton reply_uuid={event_content_reply_metadata_uuid}>{'댓글 더보기'}</ContentReplyMoreButton>
      )}
    </Fragment>
  );
};

function useInputProps(props: ContentReplyModuleProps) {
  const { isLoggedIn } = useIsAuthenticated();
  const current_time = Date.now();

  const { data: metadata, isFetching: isMetadataFetching } = useEventContentReplyMetadata({
    uuid: props.event_content_reply_metadata_uuid,
    options: { refetchOnWindowFocus: false },
  });

  const input_props = useMemo<Pick<ContentReplyInputProps, 'disabled' | 'placeholder' | 'need_auth'>>(() => {
    if (isMetadataFetching) {
      return { disabled: true, placeholder: '', need_auth: false };
    }

    if (!metadata) {
      return { disabled: true, placeholder: props.complete_text, need_auth: false };
    }

    const is_before = current_time < metadata.date_started;
    const is_after = metadata.date_ended < current_time;

    if (!isLoggedIn) {
      return { disabled: true, placeholder: props.need_auth_text, need_auth: true };
    }

    if (is_before) {
      return { disabled: true, placeholder: props.prepare_text, need_auth: false };
    }

    if (is_after) {
      return { disabled: true, placeholder: props.complete_text, need_auth: false };
    }

    return { disabled: false, placeholder: props.progress_text, need_auth: false };
  }, [
    current_time,
    isLoggedIn,
    isMetadataFetching,
    metadata,
    props.complete_text,
    props.need_auth_text,
    props.prepare_text,
    props.progress_text,
  ]);

  return input_props;
}

const ReplieList = styled.ol<{ is_summary: boolean }>`
  margin: 0;
  padding: 0;

  li + li {
    border-top: 1px solid transparent;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      height: 1px;
      width: calc(100% - 32px);
      background-color: ${colors.gray150};
    }
  }

  ${({ is_summary }) =>
    is_summary &&
    css`
      & > li:nth-child(n + 4) {
        display: none;
      }
    `}
`;

const InfiniteQueryIntersectionObserver = styled.div`
  position: relative;
  height: 1px;
  top: -100px;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
`;

const EmptyTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: ${colors.gray900};
`;

const EmptySubTitle = styled.p`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.gray500};
  margin-top: 16px;
`;
