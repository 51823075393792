import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import TopButton from 'components/common/TopButton';
import Share from 'components/events/detail/Share';
import { useUpdateFloatButtonBottom } from 'hooks/useUpdateFloatButtonBottom';
import { white } from 'styles';

interface Props {
  metadata: Record<'page_url' | 'button_title' | 'description' | 'image_src' | 'title', string>;
  has_floating_button: boolean;
  is_home_tab?: boolean;
}

const FLOATING_BUTTON_BOTTOM = 100;
const HOME_BOTTOM_TAB_BAR_HEIGHT = 48;

export const CmsEventsLayout = ({ metadata, has_floating_button, is_home_tab, children }: PropsWithChildren<Props>) => {
  const { title, description, image_src } = metadata;
  const { default_bottom } = useUpdateFloatButtonBottom(has_floating_button ? FLOATING_BUTTON_BOTTOM : 0);

  return (
    <SC.Container is_home_tab={is_home_tab}>
      {children}
      {!is_home_tab && (
        <Share
          event_title={title || '공유하기'}
          event_description={description}
          event_image_url={image_src || ''}
          default_bottom={default_bottom}
          ubl_navigation='content_detail'
        />
      )}
      <TopButton ubl_navigation='content_detail' />
    </SC.Container>
  );
};

const SC = {
  Container: styled.div<{ is_home_tab?: boolean }>`
    position: relative;
    background-color: ${white};

    ${({ is_home_tab }) => is_home_tab && `padding-bottom: ${HOME_BOTTOM_TAB_BAR_HEIGHT}px;`}
  `,
};
