import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';
import Text from 'components/base/Text';
import { border4 } from 'styles';

interface Props extends PropsWithChildren {
  title?: string;
  custom_css?: SerializedStyles;
}

const Section: FC<Props> = ({ title, custom_css, children }) => {
  return (
    <>
      <SC.Line />
      <SC.Container css={custom_css}>
        {title && (
          <Text className='title' variant='title4_bold' color='gray_active' as='h3'>
            {title}
          </Text>
        )}
        {children}
      </SC.Container>
    </>
  );
};

export default Section;

const SC = {
  Container: styled.section`
    padding: 4px 0 16px;

    .title {
      padding: 15px 16px;
    }
  `,
  Line: styled.div`
    width: 100%;
    height: 8px;
    background-color: ${border4};
  `,
};
